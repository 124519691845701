import { fi } from 'date-fns/locale';
import {
  LOADING_TOGGLE_ACTION,
  LOGIN_CONFIRMED_ACTION,
  LOGIN_FAILED_ACTION,
  LOGOUT_ACTION,
  SIGNUP_CONFIRMED_ACTION,
  SIGNUP_FAILED_ACTION,
  COMPANY_SELECTED_ACTION,
  CREATE_NEW_COMPANY_ACTION,
  SET_ACCESS_TOKEN,
  SET_USER_DATA,
} from '../actions/AuthActions';

const initialState = {
  selectedCompany: {
    companyName: '',
    companyId: '',
    companyRegNo: '',
  },
  user: {
    googleUid: '',
    uid: '',
    email: '',
    firstName: '',
    lastName: '',
    companies: [],
  },
  accessToken: null,
  isAuthenticated: false,
  selectedCompanyId: '',
  isCompanySelected: false,
  errorMessage: '',
  successMessage: '',
  showLoading: false,
};

export function AuthReducer(state = initialState, action) {
  console.log('AuthReducer', action.type);
  console.log('AuthReducer', action.payload);
  console.log('Hello AuthReducer', state);

  switch (action.type) {
    case SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.payload,
        isAuthenticated: true,
      };

    case SET_USER_DATA:
      return {
        ...state,
        user: {
          ...state.user,
          uid: action.payload.user.user_id,
          firstName: action.payload.user.first_name,
          lastName: action.payload.user.last_name,
          phoneNumber: action.payload.user.phone_number,
          googleUid: action.payload.user.google_id,
          // Map the companies object to an array
          companies: Object.entries(action.payload.companies).map(([companyId, companyData]) => ({
            companyId,
            companyName: companyData.companyName,
            roles: companyData.roles,
          })),
        },
        isAuthenticated: true,
      };

    case SIGNUP_CONFIRMED_ACTION:
    case SIGNUP_CONFIRMED_ACTION:
      return {
        ...state,
        user: {
          ...state.user,
          uid: action.payload.user.user_id,
          firstName: action.payload.user.first_name,
          lastName: action.payload.user.last_name,
          phoneNumber: action.payload.user.phone_number,
          googleUid: action.payload.user.google_id,
          // Map companies as before
          companies: Object.entries(action.payload.companies).map(([companyId, companyData]) => ({
            companyId,
            companyName: companyData.companyName,
            roles: companyData.roles,
          })),
        },
        isAuthenticated: true,
      };
    case LOGIN_CONFIRMED_ACTION:
      return {
        ...state,
        user: {
          ...state.user,
          googleUid: action.payload.data.googleUid,
          email: action.payload.data.email,
          firstName: action.payload.data.firstName,
          lastName: action.payload.data.lastName,
          uid: action.payload.data.uid,
        },
        errorMessage: '',
        successMessage: 'Login Successfully Completed',
        showLoading: false,
        isAuthenticated: true,
      };

    case LOGOUT_ACTION:
      console.log('LOGOUT_ACTION');
      return {
        ...initialState,
        isAuthenticated: false, // Set to false explicitly on logout
      };

    case SIGNUP_FAILED_ACTION:
    case LOGIN_FAILED_ACTION:
      return {
        ...state,
        errorMessage: action.payload,
        successMessage: '',
        showLoading: false,
      };

    case LOADING_TOGGLE_ACTION:
      return {
        ...state,
        showLoading: action.payload,
      };

    case COMPANY_SELECTED_ACTION:
      return {
        ...state,
        selectedCompany: {
          companyName: action.payload.companyName,
          companyId: action.payload.companyId,
          companyRegNo: action.payload.companyRegNumber,
        },
        selectedCompanyId: action.payload.companyId,
        isCompanySelected: true,
      };

    case CREATE_NEW_COMPANY_ACTION:
      return {
        ...state,
        selectedCompany: {
          companyName: action.payload.companyName,
          companyId: action.payload.companyId,
          companyRegNo: action.payload.companyRegNo,
        },
        isCompanySelected: true,
      };

    default:
      return state;
  }
}
