import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import axios from '../../services/AxiosInstance';
import { getApiUrl } from '../../utils/ApiUtils';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import swal from 'sweetalert';

import { useSelector } from 'react-redux';

const AcceptInvitation = () => {
    const location = useLocation();
    const history = useHistory();
    const { companyId } = useParams();

    const userIsAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const token = new URLSearchParams(location.search).get('token');

    console.log('Token:', token);

    useEffect(() => {
        const acceptInvitation = async () => {
            try {
                const apiUrl = getApiUrl(`${companyId}/invitations/accept`);
                const result = await axios.post(apiUrl, { token }, { withCredentials: true });

                console.log('Invitation accepted:', result);
                swal('Invitation accepted!', 'You have successfully accepted the invitation.', 'success');
                history.push(`/${companyId}/dashboard`); // Redirect to user's dashboard

            } catch (error) {
                console.error('Error accepting invitation:', error);
                console.log('Error accepting invitation:', error.response.data.error);
                swal('Error', `An error occurred while accepting the invitation. ${error.response.data.error}`, 'error');
                history.push(`/${companyId}/dashboard`); // Redirect to login if not authenticated
            }
        };

        if (!token) {
            swal('Error', 'Invalid invitation token.', 'error');
            history.push(`$/${companyId}/dashboard`);
        } else if (!userIsAuthenticated) {
            console.log('User is not authenticated, will fix push with redirect');
            history.push(`/login?redirect=/${companyId}/invite?token=${token}`);
            return;
        } else {
            acceptInvitation();
        }
    }, [token, history]);

    return <div>Processing invitation...</div>;
};

export default AcceptInvitation;
