import React, { useState } from 'react';
import { Modal, Button, Form, InputGroup } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const PaymentReceivedModal = ({ show, handleClose, invoiceData, handlePaymentSubmit }) => {
    const [paymentDate, setPaymentDate] = useState(new Date());
    const [paidAmount, setPaidAmount] = useState(invoiceData.totals.grand_total); // Default to invoice total
    const [selectedAccount, setSelectedAccount] = useState('');

    const handleAccountChange = (e) => {
        setSelectedAccount(e.target.value);
    };

    const handleSubmit = () => {
        // Call the handler passed as props to pass payment details to parent component
        handlePaymentSubmit({
            paymentDate,
            paidAmount,
            selectedAccount,
        });
        handleClose(); // Close modal after submitting
    };

    return (
        <Modal show={show} onHide={handleClose} size="md" centered>
            <Modal.Header closeButton>
                <Modal.Title>Registrera betalning av faktura</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h5>Fakturanummer: {invoiceData.invoicenumber}</h5>
                <p>Kund: {invoiceData.customername}</p>

                <hr />

                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Betaldatum</Form.Label>
                        <DatePicker
                            selected={paymentDate}
                            onChange={(date) => setPaymentDate(date)}
                            className="form-control"
                            dateFormat="yyyy-MM-dd"
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Summa</Form.Label>
                        <InputGroup>
                            <Form.Control
                                type="number"
                                value={paidAmount}
                                onChange={(e) => setPaidAmount(e.target.value)}
                                min="0"
                                step="0.01"
                                placeholder="Enter amount paid"
                            />
                            <InputGroup.Text>kr</InputGroup.Text>
                        </InputGroup>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Betalt till konto</Form.Label>
                        <Form.Control as="select" value={selectedAccount} onChange={handleAccountChange}>
                            <option value="">Välj konto</option>
                            <option value="1930">1930 - Bankkonto</option>
                            <option value="1940">1940 - Kontant</option>
                            <option value="1510">1510 - Accounts Receivable</option>
                            {/* Add more accounts as needed */}
                        </Form.Control>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Avbryt
                </Button>
                <Button variant="primary" onClick={handleSubmit} disabled={!selectedAccount}>
                    Bekräfta
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default PaymentReceivedModal;
