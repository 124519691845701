import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';

function Submission({ verification, onSubmit, companyId }) {
    const history = useHistory();

    const handleCreateNew = () => {
        // Redirect to the beginning of the verification process
        history.push(`/company/${companyId}/create-verification`);
    };

    const handleGoToDashboard = () => {
        // Redirect to the dashboard
        history.push(`/${companyId}/dashboard`);
    };

    return (
        <div className="text-center">
            <FontAwesomeIcon icon={faCheckCircle} size="5x" color="green" className="mb-4" />
            <h3>Verifikation skapad framgångsrikt!</h3>
            <p className="mb-4">Din verifikation har skapats och bokförts korrekt.</p>
            <Button variant="primary" className="me-2" onClick={handleCreateNew}>
                Boka en ny
            </Button>
            <Button variant="secondary" onClick={handleGoToDashboard}>
                Klar
            </Button>
        </div>
    );
}

export default Submission;
