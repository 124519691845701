import {
  formatError,
  login,
  runLogoutTimer,
  saveTokenInLocalStorage,
  signUp,
} from '../../services/AuthService';

import axios from 'axios';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
const apiUrl = process.env.REACT_APP_API_URL;
const apiVersion = process.env.REACT_APP_DEFAULT_API_VERSION;

console.log("apiBaseUrl: ", apiBaseUrl);
console.log("apiUrl: ", apiUrl);
console.log("apiVersion: ", apiVersion);


export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const COMPANY_SELECTED_ACTION = '[company action] company selected';
export const CREATE_NEW_COMPANY_ACTION = '[company action] create new company';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';

export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_SELECTED_COMPANY_ID = 'SET_SELECTED_COMPANY_ID';

export function signupAction(firstName, lastName, phoneNumber, email, password, history, redirectUrl) {
  return (dispatch) => {
    signUp(email, password)
      .then((response) => {
        saveTokenInLocalStorage(response);

        //Using axios make call to backend api to store user in database
        // Should move this outsde of this function
        axios.post(`${apiBaseUrl}/${apiUrl}/${apiVersion}/auth/users`, {
          email: response.user.email,
          googleId: response.user.uid,
          firstName: firstName,
          lastName: lastName,
          phoneNumber: phoneNumber,
        })
          .then((response) => {
            console.log("USer added:", response);
          })
          .catch((error) => {
            console.log(error);
          });

        runLogoutTimer(
          dispatch,
          response._tokenResponse.expiresIn * 1000,
          history,
        );
        dispatch(confirmedSignupAction(response));
        //history.push('/auth/select-company');
        if (redirectUrl === '/') {
          history.push('/auth/select-company');
        } else {
          history.push(redirectUrl);
        }
      })
      .catch((error) => {
        const errorMessage = formatError(error.response);
        dispatch(signupFailedAction(errorMessage));
      });
  };
}

export const setAccessToken = (accessToken) => ({
  type: SET_ACCESS_TOKEN,
  payload: accessToken,
});

export const setUserData = (userData) => ({
  type: SET_USER_DATA,
  payload: userData,
});



export function logout(history) {

  const result = axios.post(`${apiBaseUrl}/${apiUrl}/${apiVersion}/auth/logout`, {}, { withCredentials: true })
    .then((response) => {
      console.log("Logout response:", response);
    })
    .catch((error) => {
      console.log("Logout error:", error);
    });

  localStorage.removeItem('selectedCompany');
  console.log("logoutAction");
  history.push('/login');
  return {
    type: LOGOUT_ACTION,
  };
}

async function doLLSignIn(googelSignInData) {
  try {
    const data = {
      name: googelSignInData.user.displayName,
      email: googelSignInData.user.email,
      userGoogleId: googelSignInData.user.uid
    };

    const response = await axios.post(`${apiBaseUrl}/${apiUrl}/${apiVersion}/auth/google-signin`, data, { withCredentials: true });
    console.log("LedgerLop sign in:", response.data);

    return {
      accessToken: response.data.accessToken,
      firstName: response.data.firstname,
      lastName: response.data.lastname,
      userId: response.data.userId,
    };

  } catch (error) {
    console.error(`Error: ${error}`);
  }
}

export function loginAction(email, password, history, url) {
  return (dispatch) => {
    login(email, password)
      .then(async (response) => {
        const data = await doLLSignIn(response);
        dispatch(loginConfirmedAction({
          email: response.user.email,
          googleUid: response.user.uid,
          uid: data.userId,
          firstName: data.firstName,
          lastName: data.lastName
        }));
        dispatch(setAccessToken(data.accessToken));

        if (url === '/') {
          history.push('/select-company'); //Used to be /dashboard
        } else {
          history.push(url);
        }

      })
      .catch((error) => {
        const errorMessage = error.message;
        formatError(error);
        dispatch(loginFailedAction(errorMessage));
      });
  };
}

export function selectCompany(companyId, companyName, companyRegNumber, history) {
  return (dispatch) => {
    localStorage.setItem('selectedCompany', JSON.stringify({ companyId, companyName, companyRegNumber }));

    dispatch(companySelectedAction(companyId, companyName, companyRegNumber));
    history.push(`/${companyId}/dashboard`);
  };
}

export function setPreSelectedCompany(companyId, companyName) {
  return (dispatch) => {
    dispatch(companySelectedAction(companyId, companyName));
  };
}

export function createNewCompany(history) {
  return (dispatch) => {
    dispatch(createNewCompanyAction());
    history.push('/create-company');
  };
}

export function loginFailedAction(data) {
  return {
    type: LOGIN_FAILED_ACTION,
    payload: data,
  };
}

export function loginConfirmedAction(data) {
  console.log("Login confirmed action: ", data);
  return {
    type: LOGIN_CONFIRMED_ACTION,
    payload: {
      data
    },
  };
}

export function confirmedSignupAction(payload) {
  return {
    type: SIGNUP_CONFIRMED_ACTION,
    payload,
  };
}

export function signupFailedAction(message) {
  return {
    type: SIGNUP_FAILED_ACTION,
    payload: message,
  };
}

export function loadingToggleAction(status) {
  return {
    type: LOADING_TOGGLE_ACTION,
    payload: status,
  };
}

export function companySelectedAction(companyId, companyName, companyRegNumber) {
  return {
    type: COMPANY_SELECTED_ACTION,
    payload: { companyId, companyName, companyRegNumber },
  };
}

export function createNewCompanyAction(newCompany) {
  return {
    type: CREATE_NEW_COMPANY_ACTION,
    payload: newCompany,
  };
}
