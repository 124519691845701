import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Select from 'react-select';
import axios from '../../../../../services/AxiosInstance';
import swal from 'sweetalert';
import { useEffect } from 'react';
import { getApiUrl } from '../../../../../utils/ApiUtils';

const NewSupplierModal = ({ show, handleClose, handleSave, handleUpdate, initialSupplierData }) => {

  const [companies, setCompanies] = useState([]);  // Store search results
  const [selectedCompany, setSelectedCompany] = useState(null);  // Store selected company

  const isNewArticle = initialSupplierData === null;

  const [supplierData, setSupplierData] = useState({
    orgnumber: '',
    vatnumber: '',
    name: '',
    address: '',
    city: '',
    postalcode: '',
    country: '',
    phonenumber: ''
  });


  console.log('Initial supplier data:', initialSupplierData);

  // Initialize supplierData with initialSupplierData when the modal opens
  useEffect(() => {
    if (initialSupplierData) {
      setSupplierData({
        supplier_id: initialSupplierData.supplier_id,
        orgnumber: initialSupplierData.orgnumber,
        vatnumber: initialSupplierData.vatnumber,
        name: initialSupplierData.name,
        address: initialSupplierData.postadress,
        city: initialSupplierData.city,
        postalcode: initialSupplierData.postnumber,
        country: initialSupplierData.country,
        phonenumber: initialSupplierData.phone,
      });
    }
  }, [initialSupplierData]);

  const handleOrgNumberChange = (e) => {
    setSupplierData({ ...supplierData, orgnumber: e.target.value });
  };

  const handleVatNumberChange = (e) => {
    setSupplierData({ ...supplierData, vatnumber: e.target.value });
  };

  const handleNameChange = (e) => {
    setSupplierData({ ...supplierData, name: e.target.value });
  };

  const handleAddressChange = (e) => {
    setSupplierData({ ...supplierData, address: e.target.value });
  };

  const handleCityChange = (e) => {
    setSupplierData({ ...supplierData, city: e.target.value });
  };

  const handlePostalCodeChange = (e) => {
    setSupplierData({ ...supplierData, postalcode: e.target.value });
  };

  const handleCountryChange = (e) => {
    setSupplierData({ ...supplierData, country: e.target.value });
  };

  const handlePhoneNumberChange = (e) => {
    setSupplierData({ ...supplierData, phonenumber: e.target.value });
  };

  const handleSaveClick = () => {
    console.log('Save supplier: ', supplierData);

    if (isNewArticle) {
      handleSave(supplierData);
    } else {
      handleUpdate(supplierData);
    }
    handleClose();
  };

  const searchCompany = async (inputValue) => {
    if (!inputValue) {
      return;
    }

    try {
      const searchComapnyApiUrl = getApiUrl('generic/search-company');
      const response = await axios.get(searchComapnyApiUrl, {
        params: { query: inputValue },
      });

      console.log('Search results:', response.data);

      setCompanies(
        response.data.hits.map((company) => ({
          label: `${company.document.names[0].nameOrIdentifier} (${company.document.registrationNumber})`,
          value: company,
        }))
      );
    } catch (err) {
      console.error('Error fetching company data:', err.message);
      swal(
        'Fel',
        'Kunde inte hämta företagsinformation, försök igen senare eller ange informationen manuellt.',
        'error'
      );
    }
  };


  const handleCompanySelect = (selectedOption) => {
    const company = selectedOption.value;

    const visitingAddress = company.document.addresses?.find(addr => addr.addressType === 'visitingAddress');
    const mailAddress = company.document.addresses?.find(addr => addr.addressType === 'mailAddress');
    const selectedAddress = visitingAddress || mailAddress || {};

    const phoneNumber = company.document.phoneNumbers?.[0]?.e164PhoneNumber || '';
    const name = company.document.names?.[0]?.nameOrIdentifier || '';

    setSelectedCompany(company);

    setSupplierData({
      supplier_id: initialSupplierData?.supplier_id || '',
      orgnumber: company.document.registrationNumber || '',
      vatnumber: company.document.isRegisteredForVAT ? `SE${company.document.registrationNumber}01` : '',
      name: name,
      address: `${selectedAddress.street || ''} ${selectedAddress.houseNumber || ''}`.trim(),
      city: selectedAddress.city || '',
      postalcode: selectedAddress.postalCode || '',
      country: company.document.iso3166CountryCode || '',
      phonenumber: phoneNumber
    });
  };

  return (
    <Modal className='fade bd-example-modal-lg' size='lg' show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Ny leverantör</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="basic-form">
          <div className='col-xl-12'>
            <div className="col-xl-4 mb-5">
              Fyll i information om leverantören.
            </div>
            {/* React-Select Section */}
            {companies.length > 0 && (
              <>
                <label>{companies.length} results found</label>
                <Select
                  options={companies}  // Use react-select for displaying companies
                  onChange={handleCompanySelect}
                  placeholder="Select a company"
                  value={selectedCompany ? { label: selectedCompany.document.names[0].nameOrIdentifier, value: selectedCompany } : null}
                />
              </>
            )}

            <form onSubmit={(e) => e.preventDefault()}>
              <div className="row">
                <div className="form-group mb-3 col-md-6">
                  <label>Företagsnamn</label>
                  <input size="16" type="text" className="form-control" value={supplierData.name} onChange={handleNameChange} />
                </div>
                <div className="form-group mb-3 col-md-6">
                  <Button variant="link" onClick={() => searchCompany(supplierData.name)}>Hämta företagsinformation</Button>
                </div>
              </div>
              <div className="row">
                <div className="form-group mb-3 col-md-6">
                  <label>Org-nummer</label>
                  <input size="16" type="text" className="form-control" value={supplierData.orgnumber} onChange={handleOrgNumberChange} />
                </div>
                <div className="form-group mb-3 col-md-6">
                  <label>Vat-nummer</label>
                  <input size="16" type="text" className="form-control" value={supplierData.vatnumber} onChange={handleVatNumberChange} />
                </div>
              </div>
              <div className="row">
                <div className="form-group mb-3 col-md-6">
                  <label>Adress</label>
                  <input size="16" type="text" className="form-control" value={supplierData.address} onChange={handleAddressChange} />
                </div>
              </div>
              <div className="row">
                <div className="form-group mb-3 col-md-3">
                  <label>Stad</label>
                  <input size="16" type="text" className="form-control" value={supplierData.city} onChange={handleCityChange} />
                </div>
                <div className="form-group mb-3 col-md-3">
                  <label>Postnummer</label>
                  <input size="16" type="text" className="form-control" value={supplierData.postalcode} onChange={handlePostalCodeChange} />
                </div>
              </div>
              <div className="row">
                <div className="form-group mb-3 col-md-6">
                  <label>Land</label>
                  <input size="16" type="text" className="form-control" value={supplierData.country} onChange={handleCountryChange} />
                </div>
              </div>
              <div className="row">
                <div className="form-group mb-3 col-md-6">
                  <label>Telefonnummer</label>
                  <input size="16" type="text" className="form-control" value={supplierData.phonenumber} onChange={handlePhoneNumberChange} />
                </div>
              </div>

            </form>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Avbryt
        </Button>
        <Button variant="primary" onClick={handleSaveClick}>
          Spara
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default NewSupplierModal;
