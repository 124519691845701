import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Accordion, Card, Button, Spinner, Alert } from "react-bootstrap";
import axios from '../../../../services/AxiosInstance';
import NonEditableEmployeePersonalDetails from './NoEditEmployeeDetailsComponent.js';
import NonEditableContractDetails from './NoEditContractDetailsComponent.js';
import EmployeeEditModal from './EmployeeEditModal.js'; // Import the modal component
import { getApiUrl } from '../../../../utils/ApiUtils';
import PageTitle from '../../../layouts/PageTitle.js';

const ViewEmployee = () => {
  const { companyId, id } = useParams();

  const [employee, setEmployee] = useState(null);
  const [contracts, setContracts] = useState([]);
  const [salarySpecs, setSalarySpecs] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false); // State to control the modal
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    loadContracts();
    loadEmployee();
  }, []);

  const loadEmployee = async () => {
    const getEmployeeApiUrl = getApiUrl(`${companyId}/hr/employees/${id}`);
    try {
      const result = await axios.get(getEmployeeApiUrl, { withCredentials: true });
      setEmployee(result.data);
      setLoading(false);
    } catch (error) {
      setError('Failed to load employee data.');
      setLoading(false);
    }
  };

  const loadContracts = async () => {
    const getEmployeeContractsApiUrl = getApiUrl(`${companyId}/hr/employees/${id}/contracts`);
    try {
      const result = await axios.get(getEmployeeContractsApiUrl, { withCredentials: true });
      setContracts(result.data);
    } catch (error) {
      setError('Failed to load contracts.');
    }
  };

  const handleShowEditModal = () => setShowEditModal(true);
  const handleCloseEditModal = () => setShowEditModal(false);

  const handleSaveChanges = (updatedEmployee) => {
    setEmployee(updatedEmployee); // Update employee state with new data
    setShowEditModal(false); // Close the modal after saving
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Alert variant="danger">{error}</Alert>
      </div>
    );
  }

  return (
    <>
      <PageTitle motherMenu="Personal" activeMenu={`Anställda / ${employee.firstname} ${employee.lastname}`} />
      <div className="row">
        <div className="col-md-6">
          <Link to={`/${companyId}/employees`} className="btn btn-link">
            <i className="fa fa-arrow-left" /> Tillbaka
          </Link>
          {/*<h3>{employee.firstname + " " + employee.lastname}</h3>*/}
        </div>
        <div className="col-md-6 text-right">
        </div>
      </div>

      <div className="d-flex">
        <div className="mb-3 align-items-center me-auto">
          <h4 className="fs-24 font-w800">Personalinformation</h4>
        </div>
        <Link to={`#`} onClick={handleShowEditModal} className="btn btn-outline-primary mb-3">Ändra</Link>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <NonEditableEmployeePersonalDetails employee={employee} />
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex mb-3">
        <div className="mb-3 align-items-center me-auto">
          <h4 className="fs-24 font-w800">Kontrakt</h4>
        </div>
        <Link to={`/${companyId}/employee/${id}/newcontract`} className="btn btn-outline-primary mb-3">Nytt kontrakt</Link>
      </div>

      <div className="row">
        <Card>
          <Card.Body>
            <Accordion className="accordion-rounded-stylish accordion-bordered" defaultActiveKey="0">
              {contracts.length > 0 ? (
                contracts.map((contract, i) => (
                  <Accordion.Item key={i} eventKey={`${i}`}>
                    <Accordion.Header className="accordion-header accordion-header-primary">
                      {contract.valid_from} - {contract.valid_to != null ? contract.valid_to : "Nuvarande"}
                    </Accordion.Header>
                    <Accordion.Collapse eventKey={`${i}`} className="accordion__body">
                      <div className="accordion-body">
                        <NonEditableContractDetails contractDetails={contract} />
                      </div>
                    </Accordion.Collapse>
                  </Accordion.Item>
                ))
              ) : (
                <p>Den anställda har inget kontrakt i systemet.</p>
              )}
            </Accordion>
          </Card.Body>
        </Card>
      </div>

      <div className="d-flex mb-3">
        <div className="mb-3 align-items-center me-auto">
          <h4 className="fs-24 font-w800">Lönespecar</h4>
        </div>
      </div>

      <div className="row">
        <Card>
          <Card.Body>
            <Accordion className="accordion accordion-rounded-stylish accordion-bordered" defaultActiveKey="0">
              {salarySpecs.length > 0 ? salarySpecs.map((d, i) => (
                <Accordion.Item key={i} eventKey={`${i}`}>
                  <Accordion.Header className="accordion-header accordion-header-primary">
                    {d.valid_from} -
                  </Accordion.Header>
                  <Accordion.Collapse eventKey={`${i}`} className="accordion__body">
                    <div className="accordion-body">{d.text}</div>
                  </Accordion.Collapse>
                </Accordion.Item>
              )) : <div className="accordion-body">Den anställda har inga lönespecifikationer i systemt.</div>}
            </Accordion>
          </Card.Body>
        </Card>
      </div>

      {/* Render the Edit Modal */}
      <EmployeeEditModal
        show={showEditModal}
        handleClose={handleCloseEditModal}
        employee={employee}
        companyId={companyId}
        onSave={handleSaveChanges}
      />
    </>
  );
}

export default ViewEmployee;
