import React, { useEffect, useRef } from 'react';
//import { SvelteGanttExternal, MomentSvelteGanttDateAdapter, SvelteGantt, SvelteGanttDependencies, SvelteGanttTable } from 'svelte-gantt';
//import moment from 'moment';


//import { HTML5Backend } from 'react-dnd-html5-backend'
//import { DndProvider } from 'react-dnd'

/*import Scheduler, {
  SchedulerData,
  ViewType,
  DATE_FORMAT,
} from "react-big-scheduler-stch";
import "react-big-scheduler-stch/lib/css/style.css";
import dayjs from "dayjs";
*/

const ViewSchedule = () => {

  /*const time = (time) => {
    return moment().startOf('day').add(moment.duration(time));
  }

  // Datas to load
  const data = {
    rows: [
      {
        id: 10,
        label: "Servering",
        class: 'row-group',
        iconClass: 'fas fa-user-tie',
        children: [
          {
            id: 11,
            label: "Servering  1"
          }, {
            id: 12,
            label: "Servering  2"
          }
        ]
      }, {
        id: 20,
        label: "Matlagning",
        class: 'row-group',
        iconClass: 'fas fa-fork-knife',
        children: [
          {
            id: 21,
            label: "Pizza 1"
          }, {
            id: 22,
            label: "Pizza 2"
          }, {
            id: 23,
            label: "Kök"
          }
        ]
      }
    ],
    tasks: [
      {
        id: 1,
        resourceId: 11,
        label: "LPCVD",
        from: time("9:00"),
        to: time("11:00"),
        classes: "orange",
        amountDone: 78
      }, {
        id: 2,
        resourceId: 12,
        label: "Entrepreneurship",
        from: time("10:00"),
        to: time("12:30"),
        classes: "orange"
      }, {
        id: 3,
        resourceId: 13,
        label: "PET-CT",
        from: time("13:30"),
        to: time("15:00"),
        classes: "orange"
      }, {
        id: 4,
        resourceId: 14,
        label: "Auditing",
        from: time("9:30"),
        to: time("11:30"),
        classes: "orange"
      }, {
        id: 5,
        resourceId: 21,
        label: "Security Clearance",
        from: time("15:15"),
        to: time("16:00"),
        classes: "green"
      }, {
        id: 6,
        resourceId: 22,
        label: "Policy Analysis",
        from: time("14:00"),
        to: time("17:00"),
        classes: "blue"
      }, {
        id: 7,
        resourceId: 23,
        label: "Xbox 360",
        from: time("13:30"),
        to: time("14:30"),
        classes: "blue"
      }, {
        id: 8,
        resourceId: 3,
        label: "GNU/Linux",
        from: time("14:00"),
        to: time("15:30"),
        classes: "blue"
      }, {
        id: 9,
        resourceId: 4,
        label: "Electronic Trading",
        from: time("15:00"),
        to: time("17:00"),
        classes: "green"
      }, {
        id: 10,
        resourceId: 5,
        label: "Alternative Medicine",
        from: time("14:30"),
        to: time("15:30"),
        classes: "orange"
      }
    ],
    dependencies: []
  }



  const timeRanges = [
    {
      id: 1,
      label: 'LUNCH',
      from: moment().startOf('day').add(12, 'hours'),
      to: moment().startOf('day').add(13, 'hours')
    },
  ]

  const currentStart = moment().startOf('day').add(8, 'hours');
  const currentEnd = currentStart.clone().add(15, 'hours');


  const ganttRef = useRef(null);
  let gantt;



  useEffect(() => {
    if (ganttRef.current) {
      var options = {
        dateAdapter: new MomentSvelteGanttDateAdapter(moment),
        rows: data.rows,
        tasks: data.tasks,
        dependencies: data.dependencies,
        timeRanges,
        columnOffset: 30,
        magnetOffset: 30,
        rowHeight: 80,
        rowPadding: 15,
        headers: [{ unit: 'day', format: 'MMMM Do' }, { unit: 'hour', format: 'H:mm' }],
        fitWidth: true,
        minWidth: 800,
        from: currentStart,
        to: currentEnd,
        tableHeaders: [{ title: 'Label', property: 'label', width: 140, type: 'tree' }],
        tableWidth: 240,
        ganttTableModules: [SvelteGanttTable],
        ganttBodyModules: [SvelteGanttDependencies],
        enableCreateTask: true,
        onCreatedTask: (task) => {
          console.log('Task created', task);
        },
        onCreatedTask: (task) => {
          console.log('Tasks created', task);
        }
      };

      gantt = new SvelteGantt({
        target: ganttRef.current,
        props: options
      });
    }

    const external = new SvelteGanttExternal(document.getElementById('new-task'), {
      gantt,
      onsuccess: (row, date, gantt) => {
        console.log(row.model.id, new Date(date).toISOString())
        const id = 5000 + Math.floor(Math.random() * 1000);
        gantt.updateTask({
          id,
          label: `Task #${id}`,
          from: date,
          to: date + 3 * 60 * 60 * 1000,
          classes: colors[(Math.random() * colors.length) | 0],
          resourceId: row.model.id
        });
      },
      elementContent: () => {
        const element = document.createElement('div');
        element.innerHTML = 'New Task';
        element.className = 'sg-external-indicator';
        return element;
      }
    });

  }, []);

  let externalTasks = [
    { id: 3, label: 'Task 3' },
    { id: 4, label: 'Task 4' },
  ];

  const colors = ['blue', 'green', 'orange']

*/
  return (
    <>
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title"> Arbetsschema</h4>
          </div>
          <div className="card-body">

            {/*<div id="example-gantt" ref={ganttRef}></div>
            <div id="new-task">
              Drag to create new task
            </div>*/}
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewSchedule;

