import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Table } from 'react-bootstrap';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faCopy, faTrash, faUpload } from '@fortawesome/free-solid-svg-icons';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import DeleteVerificationModal from './DeleteVerificationModal';
import axios from '../../../../services/AxiosInstance';
import { getApiUrl } from '../../../../utils/ApiUtils';

const VerificationDetailsModal = ({
  showVerificationDetails,
  setShowVerificationDetails,
  selectedRow,
  onVerificationUpdated,
}) => {
  const { companyId } = useParams();
  const location = useLocation();
  const history = useHistory();

  const fileInputRef = useRef(null);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [attachments, setAttachments] = useState([]);

  // Fetch the attachments for the selected verification
  useEffect(() => {
    if (selectedRow && selectedRow.verification_id) {
      const fetchAttachments = async () => {
        try {
          const response = await axios.get(
            getApiUrl(
              `${companyId}/accounting/verifications/${selectedRow.verification_id}/getattachments`
            ),
            { withCredentials: true }
          );
          const attachmentsData = response.data.attachments || [];

          // Fetch each attachment's thumbnail data
          const attachmentsWithThumbnails = await Promise.all(
            attachmentsData.map(async (attachment) => {
              try {
                const thumbnailResponse = await axios.get(
                  getApiUrl(
                    `${companyId}/accounting/verifications/thumbnail/${attachment.attachment_id}`
                  ),
                  {
                    responseType: 'blob',
                    withCredentials: true,
                  }
                );
                const thumbnailBlob = thumbnailResponse.data;
                // Create an object URL from the blob
                const thumbnailUrl = URL.createObjectURL(thumbnailBlob);
                return { ...attachment, thumbnailUrl };
              } catch (error) {
                console.error('Error fetching attachment thumbnail:', error);
                return attachment; // Return attachment without thumbnailUrl
              }
            })
          );

          setAttachments(attachmentsWithThumbnails);
        } catch (error) {
          console.error('Error fetching attachments:', error);
        }
      };
      fetchAttachments();
    }
  }, [selectedRow, companyId]);

  // Cleanup object URLs to prevent memory leaks
  useEffect(() => {
    return () => {
      attachments.forEach((attachment) => {
        if (attachment.thumbnailUrl) {
          URL.revokeObjectURL(attachment.thumbnailUrl);
        }
      });
    };
  }, [attachments]);

  const handleDelete = async () => {
    setShowDeleteModal(true);
    setShowVerificationDetails(false);
  };

  const handleCopy = () => {
    setShowVerificationDetails(false);
    openCreateVerificationModal(selectedRow);
  };

  const openCreateVerificationModal = (verificationData) => {
    history.push({
      pathname: `/${companyId}/copy-verification`,
      state: { verificationData, from: location.pathname },
    });
  };

  const handleEdit = () => {
    setShowVerificationDetails(false);
    openEditVerificationModal(selectedRow);
  };

  const openEditVerificationModal = (verificationData) => {
    history.push({
      pathname: `/${companyId}/edit-verification/${verificationData.verification_id}`,
      state: { verificationData, from: location.pathname },
    });
  };

  const handleUploadAttachment = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    // Handle the selected files
    console.log(files);
  };

  const renderAttachment = (attachment) => {
    if (!attachment.thumbnailUrl) {
      return <p>Loading attachment...</p>;
    }

    if (attachment.file_type.startsWith('image/')) {
      return (
        <img
          src={attachment.thumbnailUrl}
          alt={attachment.file_name}
          style={{ maxWidth: '100%', maxHeight: '300px' }}
        />
      );
    } else if (attachment.file_type === 'application/pdf') {
      return (
        <embed
          src={attachment.thumbnailUrl}
          type="application/pdf"
          width="100%"
          height="300px"
        />
      );
    } else {
      return <p>Unsupported file type</p>;
    }
  };

  return (
    <>
      <Modal
        show={showVerificationDetails}
        onHide={() => setShowVerificationDetails(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Verifikat - {selectedRow?.series}
            {selectedRow?.number}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            <strong>Beskrivning:</strong> {selectedRow?.description}
          </p>
          <p>
            <strong>Datum:</strong>{' '}
            {moment(selectedRow?.date).format('YYYY-MM-DD')}
          </p>

          <Table responsive className="mt-3" borderless>
            <thead>
              <tr>
                <th>Konto</th>
                <th>Debet</th>
                <th>Kredit</th>
              </tr>
            </thead>
            <tbody>
              {selectedRow?.transactions?.map((transaction, index) => (
                <tr key={index}>
                  <td>
                    {transaction.account_number} - {transaction.account_name}
                  </td>
                  <td>
                    {transaction.transaction_type === 'debit'
                      ? `${new Intl.NumberFormat('sv-SE').format(
                        transaction.amount
                      )} kr`
                      : '0.00 kr'}
                  </td>
                  <td>
                    {transaction.transaction_type === 'credit'
                      ? `${new Intl.NumberFormat('sv-SE').format(
                        transaction.amount
                      )} kr`
                      : '0.00 kr'}
                  </td>
                </tr>
              ))}
              <tr>
                <td>
                  <strong>Summa</strong>
                </td>
                <td>
                  <strong>
                    {new Intl.NumberFormat('sv-SE').format(
                      selectedRow.debitSum
                    )}{' '}
                    kr
                  </strong>
                </td>
                <td>
                  <strong>
                    {new Intl.NumberFormat('sv-SE').format(
                      selectedRow.creditSum
                    )}{' '}
                    kr
                  </strong>
                </td>
              </tr>
            </tbody>
          </Table>

          <p>
            <strong>Verifikat skapat:</strong>{' '}
            {moment(selectedRow?.created_at).format('YYYY-MM-DD')}
          </p>

          {attachments.length > 0 && (
            <>
              <h5>Bilagor:</h5>
              <div className="attachment-previews">
                {attachments.map((attachment, index) => (
                  <div
                    key={index}
                    className="attachment-item"
                    style={{ marginBottom: '15px' }}
                  >
                    {renderAttachment(attachment)}
                  </div>
                ))}
              </div>
            </>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="outline-primary" onClick={handleEdit}>
            <FontAwesomeIcon icon={faEdit} className="me-2" /> Redigera
          </Button>
          <Button variant="outline-primary" onClick={handleCopy}>
            <FontAwesomeIcon icon={faCopy} className="me-2" /> Kopiera
          </Button>
          <Button variant="outline-danger" onClick={handleDelete}>
            <FontAwesomeIcon icon={faTrash} className="me-2" /> Radera
          </Button>
          <Button variant="outline-primary" onClick={handleUploadAttachment}>
            <FontAwesomeIcon icon={faUpload} className="me-2" /> Ladda upp
            underlag
          </Button>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileChange}
            accept="application/pdf,image/*" // Accept both PDF and image files
          />
        </Modal.Footer>
      </Modal>

      {selectedRow && selectedRow.transactions?.length > 0 && (
        <DeleteVerificationModal
          show={showDeleteModal}
          handleClose={() => setShowDeleteModal(false)}
          verificationData={selectedRow}
          companyId={companyId}
        />
      )}
    </>
  );
};

export default VerificationDetailsModal;
