import React, { useMemo, useEffect, useState } from 'react';
import { useTable, useGlobalFilter, useFilters, usePagination } from 'react-table';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Dropdown, Row, Col, Card, Tab, Nav } from 'react-bootstrap';
import CreatePayrollModal from './CreatePayrollModal';
import axios from '../../../../services/AxiosInstance';

import { getApiUrl } from '../../../../utils/ApiUtils';
import PageTitle from '../../../layouts/PageTitle';
import { NoData } from '../../SharedComponents/NoData';

const Payrolls = () => {
  const { companyId } = useParams();

  const [showModal, setShowModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [payrollData, setPayrollData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSaveDate = (date) => {
    setSelectedDate(date);
    const currentDate = new Date();

    const getPayrollApiUrl = getApiUrl(`${companyId}/payroll`);
    axios.post(getPayrollApiUrl, {
      period: currentDate.toISOString().substring(0, 10),
      payoutDate: date.toISOString().substring(0, 10)
    }, { withCredentials: true })
      .then((response) => {
        console.log(response);
        fetchPayrollData(); // Fetch payroll data again after saving new payroll
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleNewPayroll = (event) => {
    event.preventDefault();
    handleShowModal();
  };

  const fetchPayrollData = () => {
    const getPayrollsApiUrl = getApiUrl(`${companyId}/payrolls`);
    axios.get(getPayrollsApiUrl, { withCredentials: true })
      .then((response) => {
        setPayrollData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchPayrollData();
  }, []);

  return (
    <>
      <div className="d-flex mb-3">
        <div className="mb-3 align-items-center me-auto">
          <h4 className="fs-24 font-w800">Löner</h4>
        </div>
        <Link to={`#`} onClick={handleShowModal} className="btn btn-outline-primary mb-3">Ny lönekörning</Link>
      </div>

      <PageTitle activeMenu="Löner" motherMenu="Personal" />

      {payrollData.length === 0 ? (
        <>
          <NoData message={"Hittade inga lönekörningar. Skapa din första lönekörning för att kunna se den här."} />
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            textAlign: 'center',
            padding: '10px'
          }}>
            < Link to={`#`} onClick={handleShowModal} className="btn btn-outline-primary mb-3">Ny lönekörning</Link >
          </div>
        </>)
        : (
          <>
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    {loading ? (
                      <p>Loading...</p>
                    ) : payrollData.length === 0 ? (
                      <p>Hittade inga lönekörningar, skapa din första lönekörnign</p>
                    ) : (
                      <PayrollsTable data={payrollData} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      <CreatePayrollModal show={showModal} handleClose={handleCloseModal} handleSave={handleSaveDate} />
    </>
  );
}

const PayrollsTable = ({ data }) => {
  const { companyId } = useParams();
  const history = useHistory();

  const PAYROLL_COLUMNS = [
    {
      Header: 'Id',
      accessor: 'id',
      show: false,
      hidden: 'true',
    },
    {
      Header: 'Period',
      Footer: 'Period',
      accessor: 'perioden',
      Cell: ({ row }) => (
        <span>
          {row.original.year} ,  {row.original.month}
        </span>
      ),
    },
    {
      Header: 'Skatt Senast',
      Footer: 'Skatt Senast',
      accessor: 'taxAtLatest',
    },
    {
      Header: 'Antal lönespecar',
      Footer: 'Antal lönespecar',
      accessor: 'numPayslips',
    },
    {
      Header: 'Datum för utbetalning',
      Footer: 'Datum för utbetalning',
      accessor: 'payoutDate',
    },
  ];

  const columns = useMemo(() => PAYROLL_COLUMNS, []);
  const tableInstance = useTable({
    columns,
    data,
    initialState: { pageIndex: 0 }
  }, useFilters, usePagination);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
    gotoPage,
    pageCount,
    pageOptions,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
  } = tableInstance;

  const { pageIndex } = state;

  const handleRowClick = (year, month) => {
    history.push(`/${companyId}/payroll/${year}/${month}`);
  };

  return (
    <div className="table-responsive">
      <table {...getTableProps()} className="table dataTable table-hover display">
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()} style={{ display: column.hidden ? 'none' : 'table-cell' }}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr className='clickable-row' onClick={() => handleRowClick(row.original.year, row.original.month)} {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()} style={{ display: cell.column.hidden ? 'none' : 'table-cell' }}>
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Payrolls;
