import React from 'react';
import { Form, Row, Col, Button, Card } from 'react-bootstrap';
import axios from '../../../../services/AxiosInstance';
import { getApiUrl } from '../../../../utils/ApiUtils';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';


const VATDetailView = ({ period }) => {

    const [vatData, setVatData] = useState({});
    const [totalVat, setTotalVat] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { companyId } = useParams();

    useEffect(() => {
        const fetchVATCalculations = async () => {
            try {
                const getVATCalculationsApiUrl = getApiUrl(`${companyId}/reporting/vat-calculation`);
                const response = await axios.get(getVATCalculationsApiUrl, {
                    withCredentials: true,
                    params: {
                        start_date: period.start_date,
                        end_date: period.end_date,
                    },
                });

                if (response.data.success) {
                    console.log('VAT calculations:', response.data.vatData);
                    setVatData(response.data.vatData);
                    setTotalVat(response.data.totalVat);
                } else {
                    throw new Error(response.data.message);
                }
            } catch (error) {
                console.error('Error fetching VAT calculations:', error);
                setError('Error fetching VAT calculations');
            } finally {
                setLoading(false);
            }
        };

        if (period) {
            fetchVATCalculations();
        }
    }, [companyId, period]);

    if (loading) return <p>Loading VAT details...</p>;
    if (error) return <p>{error}</p>;

    return (
        <Form>

            <Row style={{ border: '1px solid #ccc', padding: '15px', borderRadius: '5px', marginBottom: '20px' }}>
                <Col md={6}>
                    <h5>A. Momspliktig försäljning eller uttag exklusive moms</h5>
                    <Row className="mb-2">
                        <Col md={12}>
                            <Form.Group controlId="field05">
                                <Form.Label>05. Momspliktig försäljning som inte ingår i fält 06, 07 eller 08</Form.Label>
                                <Form.Control type="text" value={new Intl.NumberFormat('sv-SE').format(vatData['05']?.totalAmount || 0)} readOnly />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col md={12}>
                            <Form.Group controlId="field06">
                                <Form.Label>06. Momspliktiga uttag</Form.Label>
                                <Form.Control type="text" value={new Intl.NumberFormat('sv-SE').format(vatData['06']?.totalAmount || 0)} readOnly />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col md={12}>
                            <Form.Group controlId="field07">
                                <Form.Label>07. Beskattningsunderlag vid vinstmarginalbeskattning</Form.Label>
                                <Form.Control type="text" value={new Intl.NumberFormat('sv-SE').format(vatData['07']?.totalAmount || 0)} readOnly />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col md={12}>
                            <Form.Group controlId="field08">
                                <Form.Label>08. Hyresinkomster vid frivillig beskattning</Form.Label>
                                <Form.Control type="text" value={new Intl.NumberFormat('sv-SE').format(vatData['08']?.totalAmount || 0)} readOnly />
                            </Form.Group>
                        </Col>
                    </Row>
                </Col>
                <Col md={6}>
                    <h5>B. Utgående moms på försäljning eller uttag i fält 05–08</h5>
                    <Row className="mb-2">
                        <Col md={12}>
                            <Form.Group controlId="field10">
                                <Form.Label>10. Utgående moms 25 %</Form.Label>
                                <Form.Control type="text" value={new Intl.NumberFormat('sv-SE').format(vatData['10']?.totalAmount || 0)} readOnly />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col md={12}>
                            <Form.Group controlId="field11">
                                <Form.Label>11. Utgående moms 12 %</Form.Label>
                                <Form.Control type="text" value={new Intl.NumberFormat('sv-SE').format(vatData['11']?.totalAmount || 0)} readOnly />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col md={12}>
                            <Form.Group controlId="field12">
                                <Form.Label>12. Utgående moms 6 %</Form.Label>
                                <Form.Control type="text" value={new Intl.NumberFormat('sv-SE').format(vatData['12']?.totalAmount || 0)} readOnly />
                            </Form.Group>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row style={{ border: '1px solid #ccc', padding: '15px', borderRadius: '5px', marginBottom: '20px' }}>
                <Col md={6}>
                    <h5>C. Momspliktiga inköp vid omvänd betalningsskyldighet</h5>
                    <Row className="mb-2">
                        <Col md={12}>
                            <Form.Group controlId="field20">
                                <Form.Label>20. Inköp av varor från annat EU-land</Form.Label>
                                <Form.Control type="text" value={new Intl.NumberFormat('sv-SE').format(vatData['20']?.totalAmount || 0)} readOnly />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col md={12}>
                            <Form.Group controlId="field21">
                                <Form.Label>21. Inköp av tjänster från ett annat EU-land, enligt huvudregeln</Form.Label>
                                <Form.Control type="text" value={new Intl.NumberFormat('sv-SE').format(vatData['21']?.totalAmount || 0)} readOnly />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col md={12}>
                            <Form.Group controlId="field22">
                                <Form.Label>22. Inköp av tjänster från länder utanför EU</Form.Label>
                                <Form.Control type="text" value={new Intl.NumberFormat('sv-SE').format(vatData['22']?.totalAmount || 0)} readOnly />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col md={12}>
                            <Form.Group controlId="field23">
                                <Form.Label>23. Inköp av varor i Sverige som köparen är betalningsskyldig för</Form.Label>
                                <Form.Control type="text" value={new Intl.NumberFormat('sv-SE').format(vatData['23']?.totalAmount || 0)} readOnly />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col md={12}>
                            <Form.Group controlId="field24">
                                <Form.Label>24. Övriga inköp av tjänster i Sverige som köparen är betalningsskyldig för</Form.Label>
                                <Form.Control type="text" value={new Intl.NumberFormat('sv-SE').format(vatData['24']?.totalAmount || 0)} readOnly />
                            </Form.Group>
                        </Col>
                    </Row>
                </Col>
                <Col md={6}>
                    <h5>D. Utgående moms på inköp i fält 20–24</h5>
                    <Row className="mb-2">
                        <Col md={12}>
                            <Form.Group controlId="field30">
                                <Form.Label>30. Utgående moms 25 %</Form.Label>
                                <Form.Control type="text" value={new Intl.NumberFormat('sv-SE').format(vatData['30']?.totalAmount || 0)} readOnly />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col md={12}>
                            <Form.Group controlId="field31">
                                <Form.Label>31. Utgående moms 12 %</Form.Label>
                                <Form.Control type="text" value={new Intl.NumberFormat('sv-SE').format(vatData['31']?.totalAmount || 0)} readOnly />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col md={12}>
                            <Form.Group controlId="field32">
                                <Form.Label>32. Utgående moms 6 %</Form.Label>
                                <Form.Control type="text" value={new Intl.NumberFormat('sv-SE').format(vatData['32']?.totalAmount || 0)} readOnly />
                            </Form.Group>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row style={{ border: '1px solid #ccc', padding: '15px', borderRadius: '5px', marginBottom: '20px' }}>
                <Col md={6}>
                    <h5>H. Moms vid import</h5>
                    <Row className="mb-2">
                        <Col md={12}>
                            <Form.Group controlId="field50">
                                <Form.Label>50. Beskattningsunderlag vid import</Form.Label>
                                <Form.Control type="text" value={new Intl.NumberFormat('sv-SE').format(vatData['50']?.totalAmount || 0)} readOnly />
                            </Form.Group>
                        </Col>
                    </Row>
                </Col>
                <Col md={6}>
                    <h5>I. Utgående moms på import i fält 50</h5>
                    <Row className="mb-2">
                        <Col md={12}>
                            <Form.Group controlId="field60">
                                <Form.Label>60. Utgående moms 25 %</Form.Label>
                                <Form.Control type="text" value={new Intl.NumberFormat('sv-SE').format(vatData['60']?.totalAmount || 0)} readOnly />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col md={12}>
                            <Form.Group controlId="field61">
                                <Form.Label>61. Utgående moms 12 %</Form.Label>
                                <Form.Control type="text" value={new Intl.NumberFormat('sv-SE').format(vatData['61']?.totalAmount || 0)} readOnly />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col md={12}>
                            <Form.Group controlId="field62">
                                <Form.Label>62. Utgående moms 6 %</Form.Label>
                                <Form.Control type="text" value={new Intl.NumberFormat('sv-SE').format(vatData['62']?.totalAmount || 0)} readOnly />
                            </Form.Group>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row style={{ border: '1px solid #ccc', padding: '15px', borderRadius: '5px', marginBottom: '20px' }}>
                {/* Left side - Section E */}
                <Col md={6}>
                    <h5>E. Försäljning med mera som är undantagen från moms</h5>
                    <Row className="mb-2">
                        <Col md={12}>
                            <Form.Group controlId="field35">
                                <Form.Label>35. Försäljning av varor till ett annat EU-land</Form.Label>
                                <Form.Control type="text" value={new Intl.NumberFormat('sv-SE').format(vatData['35']?.totalAmount || 0)} readOnly />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col md={12}>
                            <Form.Group controlId="field36">
                                <Form.Label>36. Försäljning av varor utanför EU</Form.Label>
                                <Form.Control type="text" value={new Intl.NumberFormat('sv-SE').format(vatData['36']?.totalAmount || 0)} readOnly />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col md={12}>
                            <Form.Group controlId="field37">
                                <Form.Label>37. Mellanmans inköp av varor vid trepartshandel</Form.Label>
                                <Form.Control type="text" value={new Intl.NumberFormat('sv-SE').format(vatData['37']?.totalAmount || 0)} readOnly />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col md={12}>
                            <Form.Group controlId="field38">
                                <Form.Label>38. Mellanmans försäljning av varor vid trepartshandel</Form.Label>
                                <Form.Control type="text" value={new Intl.NumberFormat('sv-SE').format(vatData['38']?.totalAmount || 0)} readOnly />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col md={12}>
                            <Form.Group controlId="field39">
                                <Form.Label>39. Försäljning av tjänster till en beskattningsbar person i ett annat EU-land, enligt huvudregeln</Form.Label>
                                <Form.Control type="text" value={new Intl.NumberFormat('sv-SE').format(vatData['39']?.totalAmount || 0)} readOnly />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col md={12}>
                            <Form.Group controlId="field40">
                                <Form.Label>40. Övrig försäljning av tjänster som tillhandahållits utomlands</Form.Label>
                                <Form.Control type="text" value={new Intl.NumberFormat('sv-SE').format(vatData['40']?.totalAmount || 0)} readOnly />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col md={12}>
                            <Form.Group controlId="field41">
                                <Form.Label>41. Försäljning när köparen är betalningsskyldig i Sverige</Form.Label>
                                <Form.Control type="text" value={new Intl.NumberFormat('sv-SE').format(vatData['41']?.totalAmount || 0)} readOnly />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col md={12}>
                            <Form.Group controlId="field42">
                                <Form.Label>42. Övrig försäljning med mera</Form.Label>
                                <Form.Control type="text" value={new Intl.NumberFormat('sv-SE').format(vatData['42']?.totalAmount || 0)} readOnly />
                            </Form.Group>
                        </Col>
                    </Row>
                </Col>

                {/* Right side - Sections F and G */}
                <Col md={6}>
                    <h5>F. Ingående moms</h5>
                    <Row className="mb-2">
                        <Col md={12}>
                            <Form.Group controlId="field48">
                                <Form.Label>48. Ingående moms att dra av</Form.Label>
                                <Form.Control type="text" value={new Intl.NumberFormat('sv-SE').format(vatData['48']?.totalAmount || 0)} readOnly />
                            </Form.Group>
                        </Col>
                    </Row>

                    <h5>G. Moms att betala eller få tillbaka</h5>
                    <Row className="mb-2">
                        <Col md={12}>
                            <Form.Group controlId="field49">
                                <Form.Label>49. Moms att betala eller få tillbaka</Form.Label>
                                <Form.Control type="text" value={new Intl.NumberFormat('sv-SE').format(totalVat)} readOnly />
                            </Form.Group>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Form>
    );
}

export default VATDetailView;
