import React, { useState, useEffect } from 'react';
import { Card, Form, Button, Table } from 'react-bootstrap';
import axios from '../../../services/AxiosInstance';
import { getApiUrl } from '../../../utils/ApiUtils';
import PageTitle from '../../layouts/PageTitle';
import { useHistory, useParams } from 'react-router-dom';
import swal from 'sweetalert';

const UserSettings = () => {

    const { companyId, userId } = useParams();

    const history = useHistory();
    //const [userId, setUserId] = useState(null); // You might get this from context or props
    const [personalInfo, setPersonalInfo] = useState({
        firstName: '',
        lastName: '',
        phoneNumber: '',
    });
    const [preferences, setPreferences] = useState({
        language: 'en-US',
        timezone: 'UTC',
        dateFormat: 'YYYY-MM-DD',
        numberFormat: '1,234.56',
    });
    const [notifications, setNotifications] = useState({
        emailNotifications: true,
        smsNotifications: false,
        inAppNotifications: true,
    });

    const [associatedCompanies, setAssociatedCompanies] = useState([]);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // Fetch user profile and settings on component mount
        const fetchUserData = async () => {
            setLoading(true);
            try {
                // Fetch user profile information
                const profileApiUrl = getApiUrl(`/users/${userId}/profile`);
                const profileRes = await axios.get(profileApiUrl);
                const profileData = profileRes.data.profile;

                setPersonalInfo({
                    firstName: profileData.first_name || '',
                    lastName: profileData.last_name || '',
                    phoneNumber: profileData.phone_number || '',
                });

                // Fetch user settings
                const settingsApiUrl = getApiUrl(`/users/${userId}/settings`);
                const settingsRes = await axios.get(settingsApiUrl);
                const settings = settingsRes.data.settings.reduce((acc, setting) => {
                    acc[setting.key] = setting.value;
                    return acc;
                }, {});


                // Update state with fetched settings
                setPreferences({
                    language: settings.language || 'en-US',
                    timezone: settings.timezone || 'UTC',
                    dateFormat: settings.dateFormat || 'YYYY-MM-DD',
                    numberFormat: settings.numberFormat || '1,234.56',
                });
                setNotifications({
                    emailNotifications: settings.emailNotifications === '1',
                    smsNotifications: settings.smsNotifications === '1',
                    inAppNotifications: settings.inAppNotifications === '1',
                });

                // Fetch associated companies
                const companiesApiUrl = getApiUrl(`/users/${userId}/companies`);
                const companiesRes = await axios.get(companiesApiUrl);
                setAssociatedCompanies(companiesRes.data.companies);

            } catch (error) {
                // Optionally handle error
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, []);

    const handlePersonalInfoChange = (e) => {
        setPersonalInfo({ ...personalInfo, [e.target.name]: e.target.value });
    };

    const handlePreferencesChange = (e) => {
        setPreferences({ ...preferences, [e.target.name]: e.target.value });
    };

    const handleNotificationsChange = (e) => {
        setNotifications({ ...notifications, [e.target.name]: e.target.checked });
    };

    const handlePersonalInfoSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const profileApiUrl = getApiUrl(`/users/${userId}/profile`);
            await axios.put(profileApiUrl, {
                first_name: personalInfo.firstName,
                last_name: personalInfo.lastName,
                phone_number: personalInfo.phoneNumber,
            });
        } catch (error) {
            swal('Ett fel uppstod vid uppdatering av personlig information.', {
                icon: 'error',
            });
        } finally {
            setLoading(false);
        }
    };

    const handleSettingsSubmit = async (section) => {
        setLoading(true);
        try {
            let settingsToUpdate = {};

            if (section === 'preferences') {
                settingsToUpdate = preferences;
            } else if (section === 'notifications') {
                settingsToUpdate = notifications;
            }

            // Prepare data for bulk update
            const settingsData = {};
            for (const key in settingsToUpdate) {
                settingsData[key] = settingsToUpdate[key];
            }

            const settingsApiUrl = getApiUrl(`/users/${userId}/settings/bulk`);
            await axios.put(settingsApiUrl, { settings: settingsData });
        } catch (error) {
            swal('Ett fel uppstod vid uppdatering av inställningar.', {
                icon: 'error',
            });
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <div>Laddar inställningar...</div>;
    }

    return (
        <>
            <PageTitle activeMenu="Användarinställningar" motherMenu="Inställningar" />
            <div className="row">
                <div className="col-xl-12">
                    <Card>
                        <Card.Header>
                            <h4>Personlig Information</h4>
                        </Card.Header>
                        <Card.Body>
                            <Form onSubmit={handlePersonalInfoSubmit}>
                                <Form.Group className='mb-3' controlId="formFirstName">
                                    <Form.Label>Förnamn</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="firstName"
                                        value={personalInfo.firstName}
                                        onChange={handlePersonalInfoChange}
                                        placeholder="Ange förnamn"
                                    />
                                </Form.Group>
                                <Form.Group className='mb-3' controlId="formLastName">
                                    <Form.Label>Efternamn</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="lastName"
                                        value={personalInfo.lastName}
                                        onChange={handlePersonalInfoChange}
                                        placeholder="Ange efternamn"
                                    />
                                </Form.Group>
                                <Form.Group className='mb-3' controlId="formPhoneNumber">
                                    <Form.Label>Telefonnummer</Form.Label>
                                    <Form.Control
                                        type="tel"
                                        name="phoneNumber"
                                        value={personalInfo.phoneNumber}
                                        onChange={handlePersonalInfoChange}
                                        placeholder="Ange telefonnummer"
                                    />
                                </Form.Group>
                                <Button variant="primary" type="submit">
                                    Spara personlig information
                                </Button>
                            </Form>
                        </Card.Body>
                    </Card>
                </div>
            </div>

            <div className="row">
                <div className="col-xl-12">
                    <Card>
                        <Card.Header>
                            <h4>Preferenser</h4>
                        </Card.Header>
                        <Card.Body>
                            <Form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    handleSettingsSubmit('preferences');
                                }}
                            >
                                <Form.Group className='mb-3' controlId="formLanguage">
                                    <Form.Label>Språk</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="language"
                                        value={preferences.language}
                                        onChange={handlePreferencesChange}
                                    >
                                        <option value="en-US">English (US)</option>
                                        <option value="sv-SE">Svenska</option>
                                        {/* Add more language options */}
                                    </Form.Control>
                                </Form.Group>
                                {/*<Form.Group className='mb-3' controlId="formTimezone">
                                    <Form.Label>Tidszon</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="timezone"
                                        value={preferences.timezone}
                                        onChange={handlePreferencesChange}
                                    >
                                        <option value="UTC">UTC</option>
                                        <option value="Europe/Stockholm">Europe/Stockholm</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group className='mb-3' controlId="formDateFormat">
                            <Form.Label>Datumformat</Form.Label>
                            <Form.Control
                                as="select"
                                name="dateFormat"
                                value={preferences.dateFormat}
                                onChange={handlePreferencesChange}
                            >
                                <option value="YYYY-MM-DD">YYYY-MM-DD</option>
                                <option value="DD/MM/YYYY">DD/MM/YYYY</option>
                                <option value="MM/DD/YYYY">MM/DD/YYYY</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group className='mb-3' controlId="formNumberFormat">
                            <Form.Label>Sifferformat</Form.Label>
                            <Form.Control
                                as="select"
                                name="numberFormat"
                                value={preferences.numberFormat}
                                onChange={handlePreferencesChange}
                            >
                                <option value="1,234.56">1,234.56</option>
                                <option value="1.234,56">1.234,56</option>
                                <option value="1 234,56">1 234,56</option>
                            </Form.Control>
                        </Form.Group>
                                */}
                                <Button className="mt-3" variant="primary" type="submit">
                                    Spara preferenser
                                </Button>
                            </Form>
                        </Card.Body>
                    </Card>
                </div >
            </div >

            <div className="row">
                <div className="col-xl-12">
                    <Card>
                        <Card.Header>
                            <h4>Notisinställningar</h4>
                        </Card.Header>
                        <Card.Body>
                            <Form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    handleSettingsSubmit('notifications');
                                }}
                            >
                                <Form.Group className='mb-3' controlId="formEmailNotifications">
                                    <Form.Check
                                        type="checkbox"
                                        label="E-postnotiser"
                                        name="emailNotifications"
                                        checked={notifications.emailNotifications}
                                        onChange={handleNotificationsChange}
                                    />
                                </Form.Group>
                                <Form.Group className='mb-3' controlId="formSmsNotifications">
                                    <Form.Check
                                        type="checkbox"
                                        label="SMS-notiser"
                                        name="smsNotifications"
                                        checked={notifications.smsNotifications}
                                        onChange={handleNotificationsChange}
                                    />
                                </Form.Group>
                                <Form.Group className='mb-3' controlId="formInAppNotifications">
                                    <Form.Check
                                        type="checkbox"
                                        label="Notiser i appen"
                                        name="inAppNotifications"
                                        checked={notifications.inAppNotifications}
                                        onChange={handleNotificationsChange}
                                    />
                                </Form.Group>
                                <Button variant="primary" type="submit">
                                    Spara notisinställningar
                                </Button>
                            </Form>
                        </Card.Body>
                    </Card>
                </div>
            </div>

            {/* Associated Companies Section */}
            <div className="row">
                <div className="col-xl-12">
                    <Card>
                        <Card.Header>
                            <h4>Dina Företag</h4>
                        </Card.Header>
                        <Card.Body>
                            {associatedCompanies.length > 0 ? (
                                <Table responsive hover>
                                    <thead>
                                        <tr>
                                            <th>Företagsnamn</th>
                                            <th>Organisationsnummer</th>
                                            <th>Roller</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {associatedCompanies.map((company) => (
                                            <tr key={company.company_id}>
                                                <td>{company.company_name}</td>
                                                <td>{company.registration_number}</td>
                                                <td>{company.roles.join(', ')}</td>
                                                <td>
                                                    {/* Add any actions like 'View' or 'Manage' if necessary */}
                                                    {/* <Button variant="outline-primary" onClick={() => handleCompanyClick(company.company_id)}>Hantera</Button> */}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            ) : (
                                <p>Du är inte kopplad till några företag.</p>
                            )}
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </>
    );
};

export default UserSettings;
