import React from 'react';
import Select from 'react-select';

const EmployeeContractDetails = ({ employee, handleInputChange }) => {
    // Options for the Select components
    const employmentTypes = [
        { value: 'Permanent', label: 'Tillsvidare' },
        { value: 'Probation', label: 'Provanställning' },
        { value: 'Hourly', label: 'Timanställning' },
    ];

    const salaryPeriods = [
        { value: 'Advance', label: 'Förskott' },
        { value: 'Arrears', label: 'Efterskott' },
    ];

    const employmentExtents = [
        { value: 'Fulltime', label: 'Heltid' },
        { value: 'Part-time', label: 'Deltid (specificera i %)' },
    ];

    const taxTables = Array.from({ length: 14 }, (_, i) => ({
        value: (i + 29).toString(),
        label: (i + 29).toString(),
    }));

    const taxColumns = Array.from({ length: 6 }, (_, i) => ({
        value: (i + 1).toString(),
        label: (i + 1).toString(),
    }));

    return (
        <>
            <div className="basic-form">
                <div className="row">
                    <div className="form-group mb-3 col-md-4">
                        <label>Startdatum</label>
                        <input
                            size="16"
                            type="date"
                            className="form-control"
                            name="startdate"
                            value={employee.contractDetails.startdate}
                            onChange={(e) => handleInputChange(e, 'startdate')}
                        />
                    </div>
                    <div className="form-group mb-3 col-md-4">
                        <label>Anställningsform</label>
                        <Select
                            value={employmentTypes.find(option => option.value === employee.contractDetails.employmenttype)}
                            onChange={(selectedOption) => handleInputChange({ target: { value: selectedOption.value } }, 'employmenttype')}
                            options={employmentTypes}
                            placeholder="Välj..."
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group mb-3 col-md-4">
                        <label>Lön</label>
                        <input
                            type="number"
                            className="form-control"
                            name="salary"
                            value={employee.contractDetails.salary}
                            onChange={(e) => handleInputChange(e, 'salary')}
                        />
                    </div>
                    <div className="form-group mb-3 col-md-4">
                        <label>Löneperiod</label>
                        <Select
                            value={salaryPeriods.find(option => option.value === employee.contractDetails.salaryperiod)}
                            onChange={(selectedOption) => handleInputChange({ target: { value: selectedOption.value } }, 'salaryperiod')}
                            options={salaryPeriods}
                            placeholder="Välj..."
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="form-group mb-3 col-md-4">
                        <label>Arbetsvecka (Timmar)</label>
                        <input
                            type="number"
                            className="form-control"
                            value={employee.contractDetails.workweek}
                            name="workweek"
                            onChange={(e) => handleInputChange(e, 'workweek')}
                        />
                    </div>
                    <div className="form-group mb-3 col-md-4">
                        <label>Omfattning</label>
                        <Select
                            value={employmentExtents.find(option => option.value === employee.contractDetails.employmentextent)}
                            onChange={(selectedOption) => handleInputChange({ target: { value: selectedOption.value } }, 'employmentextent')}
                            options={employmentExtents}
                            placeholder="Välj..."
                        />
                    </div>
                    <div className="form-group mb-4 mt-3">
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                name="accordingtocollectiveagreement"
                                value={employee.contractDetails.accordingtocollectiveagreement}
                                checked={employee.contractDetails.accordingtocollectiveagreement}
                                onChange={(e) => handleInputChange(e, 'accordingtocollectiveagreement')}
                            />
                            <label className="form-check-label">Enligt kollektivavtal</label>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="form-group mb-3 col-md-4">
                        <label>Skattetabell</label>
                        <Select
                            value={taxTables.find(option => option.value === employee.contractDetails.taxtable)}
                            onChange={(selectedOption) => handleInputChange({ target: { value: selectedOption.value } }, 'taxtable')}
                            options={taxTables}
                            placeholder="Välj..."
                        />
                    </div>
                    <div className="form-group mb-3 col-md-4">
                        <label>Skattekollumn</label>
                        <Select
                            value={taxColumns.find(option => option.value === employee.contractDetails.taxcolumn)}
                            onChange={(selectedOption) => handleInputChange({ target: { value: selectedOption.value } }, 'taxcolumn')}
                            options={taxColumns}
                            placeholder="Välj..."
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group mb-3 col-md-6">
                        <label>Tjänsteställe</label>
                        <input
                            type="text"
                            className="form-control"
                            name="workplace"
                            value={employee.contractDetails.workplace}
                            onChange={(e) => handleInputChange(e, 'workplace')}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group mb-3 col-md-6">
                        <label>Övriga vilkor</label>
                        <input
                            type="text"
                            className="form-control"
                            name="otherterms"
                            value={employee.contractDetails.otherterms}
                            onChange={(e) => handleInputChange(e, 'otherterms')}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default EmployeeContractDetails;
