import React, { useState, useEffect } from 'react';
import { Button, Table, Form } from 'react-bootstrap';
import axios from '../../../../services/AxiosInstance';
import { getApiUrl } from '../../../../utils/ApiUtils';

function ReviewVerification({ template, parameters, companyId, onVerificationAdded, attachments }) {
    const [transactions, setTransactions] = useState([]);
    const [totalDebit, setTotalDebit] = useState(0);
    const [totalCredit, setTotalCredit] = useState(0);

    useEffect(() => {

        if (parameters.transactions && parameters.transactions.length > 0) {
            console.log("Transactions: ", parameters.transactions);
            // Use transactions from parameters (manual entry)
            setTransactions(parameters.transactions);
            calculateTotals(parameters.transactions);
        } else if (template && template.template_id) {
            console.log("Template: ", template);
            console.log("Parameters: ", parameters);
            // Fetch suggestions from the backend for template-based verifications
            generateBookkeepingSuggestion(template.template_id, parameters.parameters, parameters.userDefinedAccounts);
        }
    }, [template, parameters, companyId]);

    const calculateTotals = (transactions) => {
        const totalDebit = transactions.reduce((acc, transaction) => acc + parseFloat(transaction.debit || 0), 0);
        const totalCredit = transactions.reduce((acc, transaction) => acc + parseFloat(transaction.credit || 0), 0);
        setTotalDebit(totalDebit);
        setTotalCredit(totalCredit);
    };

    const generateBookkeepingSuggestion = async (templateId, parameters, userSelectedAccount) => {
        try {
            const suggestionApiUrl = getApiUrl(`${companyId}/templates/${templateId}/suggestion`);
            const response = await axios.post(suggestionApiUrl, { parameters, userSelectedAccount }, { withCredentials: true });
            const sortedTransactions = response.data.suggestedTransactions.sort((a, b) => a.account_number - b.account_number);

            console.log("Sorted transactions: ", sortedTransactions);

            setTransactions(sortedTransactions);

            calculateTotals(sortedTransactions);
        } catch (error) {
            console.error('Error generating bookkeeping suggestion:', error);
        }
    };

    const handleAddVerification = async () => {
        try {

            const { date, description } = parameters;
            const series = 'V';
            console.log("Transactions: ", transactions);
            console.log("Attachments: ", attachments);
            console.log("Date: ", date);
            console.log("Description: ", description);

            const verificationData = {
                date,
                description,
                series,
                transactions
            };

            onVerificationAdded(verificationData);

            //const response = await axios.post(createVerificationApiUrl, { date, description, series, transactions, attachments }, { withCredentials: true });
            //if (response.data.success) {
            //    onVerificationAdded();
            //}
        } catch (error) {
            console.error('Error adding verification:', error);
        }
    };

    return (
        <div>
            <h3 className='mb-5'>Så här kommer ditt verifikat se ut</h3>
            <Form.Group>
                <Form.Label>Beskrivning</Form.Label>
                <p>{parameters.description}</p>
            </Form.Group>
            <Form.Group>
                <Form.Label>Datum</Form.Label>
                <p>{parameters.date}</p>
            </Form.Group>

            {transactions.length > 0 && (
                <>
                    <Table responsive className="mt-3">
                        <thead>
                            <tr>
                                <th>Konto</th>
                                <th>Debet</th>
                                <th>Kredit</th>
                            </tr>
                        </thead>
                        <tbody>
                            {transactions.map((transaction, index) => (
                                <tr key={index}>
                                    <td>{transaction.account_number} - {transaction.account_name}</td>
                                    <td>{transaction.debit ? new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(transaction.debit) : new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format("0")}</td>
                                    <td>{transaction.credit ? new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(transaction.credit) : new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format("0")}</td>
                                </tr>
                            ))}
                            <tr>
                                <td><strong>Total:</strong></td>
                                <td><strong>{new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(totalDebit)}</strong></td>
                                <td><strong>{new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(totalCredit)}</strong></td>
                            </tr>
                        </tbody>
                    </Table>
                    <Button className="mt-5" variant="primary" onClick={handleAddVerification}>
                        Bokför
                    </Button>
                </>
            )}
        </div>
    );
}

export default ReviewVerification;
