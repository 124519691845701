
import React, { useState } from 'react';
import { Formik } from "formik";
import * as Yup from "yup";

const emailSchema = Yup.object().shape({
  emails: Yup
    .string()
    .email('Invalid email format')
    .required('Email is required'),
});

const DeliveryOptions = ({ onDeliveryOptionChange }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [emailValue, setEmailValue] = useState('');

  const deliveryOptions = [
    { value: 'email', label: 'Email', description: 'We will email a link to the invoice to the customer. You will be able to see when the customer opens the invoice.' },
    { value: 'download', label: 'Download PDF', description: 'Download the invoice as a PDF file.' },
  ];

  const handleOptionChange = (value) => {
    setSelectedOption(value);
    setEmailValue(''); // Reset email when the option changes
    onDeliveryOptionChange({ method: value, email: '' }); // Pass the selected option without email
  };

  const handleEmailChange = (event) => {
    const email = event.target.value;
    setEmailValue(email);
    onDeliveryOptionChange({ method: selectedOption, email }); // Pass the selected option with email
  };

  return (
    <div style={{ padding: '10px', margin: '10px' }}>
      {deliveryOptions.map((option) => (
        <div key={option.value} style={{ border: '1px solid #e1e2e3', padding: '10px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <input
              type="radio"
              id={option.value}
              value={option.value}
              checked={selectedOption === option.value}
              onChange={() => handleOptionChange(option.value)}
              className='form-check-input'
            />
            <div style={{ marginLeft: '8px', flexGrow: 1 }}>
              <label htmlFor={option.value} style={{ fontWeight: 'bold' }}>{option.label}</label>
              <p style={{ color: '#888' }}>{option.description}</p>
            </div>
          </div>

          {selectedOption === 'email' && option.value === 'email' && (
            <Formik
              initialValues={{ email: emailValue }}
              validationSchema={emailSchema}
              onSubmit={(values) => console.log('Email:', values.email)}
            >
              {({
                values,
                errors,
                handleChange,
                handleBlur,
                touched,
              }) => (
                <div style={{ marginTop: '8px', padding: '8px' }}>
                  <label htmlFor="email">Enter customer email:</label>
                  <input
                    type="text"
                    name="email"
                    id="email"
                    className={`form-control ${touched.email && errors.email ? 'is-invalid' : ''}`}
                    onChange={(e) => {
                      handleChange(e);
                      handleEmailChange(e); // Trigger email change event
                    }}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                  {touched.email && errors.email ? (
                    <div className="invalid-feedback">{errors.email}</div>
                  ) : null}
                </div>
              )}
            </Formik>
          )}
        </div>
      ))}
    </div>
  );
};

export default DeliveryOptions;








/*import React, { useState, useEffect, useCallback } from 'react';
import { Formik } from "formik";
import * as Yup from "yup";

const emailSchema = Yup.object().shape({
  emails: Yup
    .string()
    .transform((originalValue, originalObject) => {
      // Convert the string value to an array of emails
      const emailArray = originalValue.split(',').map(email => email.trim());
      return emailArray;
    })
    .test({
      name: 'isValidEmails',
      exclusive: true,
      message: 'Invalid email format',
      test: emails => {
        // Check if each email in the array is valid
        return emails.every(email => Yup.string().email().isValidSync(email));
      },
    })
    .required('Emails are required'),
});

const DeliveryOptions = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [additionalInput, setAdditionalInput] = useState('');

  const deliveryOptions = [
    { value: 'email', label: 'Email', description: 'Vi kommer att maila en länk till fakturan till kunden. Du kommer kunna se när kunden har öppnat fakturan.' },
    { value: 'download', label: 'Ladda ner fakturan', description: 'Ladda ner fakturan som en PDF file på din dator.' },
    //{ value: 'e-invoice', label: 'E-Invoice', description: 'Send the invoice electronically (coming soon)' },
  ];

  const handleOptionChange = (value) => {
    setSelectedOption(value);
    setAdditionalInput(''); // Reset additional input when the option changes
  };

  const handleAdditionalInputChange = (event) => {
    setAdditionalInput(event.target.value);
  };

  return (
    <div style={{ padding: '10px', margin: '10px' }}>
      {deliveryOptions.map((option, index) => (
        <div key={option.value} style={{ border: '1px solid #e1e2e3', borderBottom: index !== deliveryOptions.length - 1 ? 'none' : '1px solid #e1e2e3', padding: '10px', display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <input
              type="radio"
              id={option.value}
              value={option.value}
              checked={selectedOption === option.value}
              onChange={() => handleOptionChange(option.value)}
              className='form-check-input'
            />
            <div style={{ marginLeft: '8px', flexGrow: 1, paddingLeft: '10px', paddingTop: '10px' }}>
              <label htmlFor={option.value} style={{ fontWeight: 'bold', fontSize: '12px', color: 'black' }}>{option.label}</label>
              <p style={{ color: '#888', fontSize: '10px' }}>{option.description}</p>
            </div>
          </div>
          {selectedOption === option.value && (
            <div style={{ marginTop: '8px', backgroundColor: '#f5f5f5', padding: '8px', borderRadius: '4px' }}>
              {/* Additional input section }
{ option.value === 'email' && <InvoiceEmailOptionInput /> }
{ option.value === 'download' && <InvoicePdfOption /> }
            </div >
          )}
        </div >
      ))}
    </div >
  );
};

const InvoicePdfOption = () => {
  return (
    <div className="col-xl-12">
    </div >
  );
};

const InvoiceEmailOptionInput = () => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className="col-xl-12">
      <div className="basic-form">
        <Formik
          initialValues={{ emails: "", aditionalText: "" }}
          validationSchema={emailSchema}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              alert(JSON.stringify(values, null, 2));
              setSubmitting(false);
            }, 400);
          }}
        >
          {({
            values,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className={`form-group mb-3 ${values.emails ? errors.emails ? "is-invalid" : "is-valid" : ""}`}>
                <label className="text-label">E-post adress till kund</label>
                <div className="input-group">
                  <span className="input-group-text">
                    <i className="fa fa-envelope" />{" "}
                  </span>
                  <input
                    type="text" className="form-control" id="val-emails" placeholder="Enter one or more emails..."
                    name="emails"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.emails}
                  />
                  <div id="val-emaillist-error" className="invalid-feedback animated fadeInUp" style={{ display: "block" }}>
                    {errors.emails && errors.emails}
                  </div>
                  <div id="val-emaillist-error" className="invalid-feedback animated fadeInUp" style={{ display: "block" }} />
                </div>
              </div>
              <div className={`form-group mb-3 ${values.additionalText ? errors.additionalText ? "is-invalid" : "is-valid" : ""}`}>
                <label className="text-label">Meddelande till kunden</label>
                <div className="input-group">
                  <span className="input-group-text">
                    <i className="fa fa-pen" />{" "}
                  </span>
                  <textarea className="form-control" rows="4" id="comment" />
                </div>

              </div>

              <div className="form-group mb-3">
                <div className="form-check">
                  <input id="checkbox1" className="form-check-input" type="checkbox" />
                  <label htmlFor="checkbox1" className="form-check-label">
                    Check me out
                  </label>
                </div>
              </div>

              <button type="submit" className="btn me-2 btn-primary" disabled={isSubmitting}>
                Submit
              </button>
              <button type="submit" className="btn btn-light">
                cencel
              </button>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default DeliveryOptions;

*/