import React, { useEffect, useState } from 'react';
import axiosInstance from '../../../services/AxiosInstance';

const ImageComponent = ({ imageUrl, alt, style }) => {
    const [imageSrc, setImageSrc] = useState('');

    useEffect(() => {
        const fetchImage = async () => {
            try {
                const response = await axiosInstance.get(imageUrl, { responseType: 'blob' });
                const imageBlob = response.data;
                const localUrl = URL.createObjectURL(imageBlob);
                setImageSrc(localUrl);
            } catch (error) {
                console.error('Error fetching image:', error);
                // Handle errors, e.g., by setting a fallback image source
            }
        };

        fetchImage();

        // Cleanup function to revoke the created blob URL
        return () => {
            if (imageSrc) {
                URL.revokeObjectURL(imageSrc);
            }
        };
    }, [imageUrl]);

    // Merge the passed style with borderRadius
    const combinedStyle = { borderRadius: '30px', ...style };

    return (
        <img src={imageSrc} alt={alt} style={combinedStyle} />
    );
};

export default ImageComponent;