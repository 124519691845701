import React, { useMemo, useState, useEffect } from 'react';
import { useTable, useGlobalFilter, useFilters, usePagination } from 'react-table';
import { useParams, Link, useHistory } from 'react-router-dom';
import axios from '../../../../../services/AxiosInstance';
import { GlobalFilter } from './GlobalFilter';
import NewCustomerModal from './NewCustomerModal';
import './filtering.css';
import PageTitle from '../../../../layouts/PageTitle';
import { NoData } from '../../../SharedComponents/NoData';
import { Dropdown } from 'react-bootstrap';
import { Spinner } from 'react-bootstrap';

import { getApiUrl } from '../../../../../utils/ApiUtils';

export const CustomersTable = () => {
  const { companyId } = useParams();
  const history = useHistory();

  const CUSTOMERS_COLUMNS = [
    {
      Header: 'Kundnummer',
      Footer: 'Kundnummer',
      accessor: 'kundnummer',
    },
    {
      Header: 'Namn',
      Footer: 'Namn',
      accessor: 'namn',
    },
    {
      Header: 'Kontakt',
      Footer: 'Kontakt',
      accessor: 'kontakt',
    },
    {
      Header: '',
      className: 'column-right',
      Cell: ({ row }) => (
        <Dropdown
          className="dropdown ms-auto text-right"
          onClick={(e) => e.stopPropagation()}
        >
          <Dropdown.Toggle
            variant=""
            className="btn-link i-false border-0 p-0"
            data-toggle="dropdown"
          >
            <svg
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
            >
              <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                <rect x={0} y={0} width={24} height={24} />
                <circle fill="#000000" cx={5} cy={12} r={2} />
                <circle fill="#000000" cx={12} cy={12} r={2} />
                <circle fill="#000000" cx={19} cy={12} r={2} />
              </g>
            </svg>
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
            <Dropdown.Item onClick={() => handleEditCustomer(row.original)}>
              Redigera kund
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleDeleteCustomer(row.original.id)}>
              Ta bort kund
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ),
      id: 'actions',
    },
  ];

  const columns = useMemo(() => CUSTOMERS_COLUMNS, []);
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [show, setShow] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const handleClose = () => {
    setShow(false);
    setSelectedCustomer(null);
  };

  const handleSave = (newCustomerData) => {
    saveNewCustomer(newCustomerData);
    setShow(false);
  };

  const saveNewCustomer = (newData) => {
    const newCustomer = {
      name: newData.name,
      orgnumber: newData.orgNumber,
      vatnumber: newData.vatNumber,
      type: 'company',
      postadress1: newData.address,
      postadress2: '',
      city: newData.city,
      postnumber: newData.postalCode,
      country: newData.country,
      phonenumber: newData.phoneNumber,
      contacts: newData.contacts.map((contact) => ({
        contactname: contact.name,
        contactemail: contact.email,
        contactphone: contact.phone,
      })),
      invoicedetails: {
        paymentterms: newData.invoiceDetails.paymentTerms,
        paymentduration: newData.invoiceDetails.paymentDuration,
      },
    };

    const customersApiUrl = getApiUrl(`${companyId}/sales/customers`);
    axios
      .post(customersApiUrl, newCustomer, { withCredentials: true })
      .then(() => {
        fetchCustomers();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleEditCustomer = (customer) => {
    setSelectedCustomer(customer);
    setShow(true);
  };

  const handleUpdateCustomer = (updatedCustomerData) => {
    const updatedCustomerApi = getApiUrl(
      `${companyId}/sales/customers/${updatedCustomerData.id}`
    );

    axios
      .put(updatedCustomerApi, updatedCustomerData, { withCredentials: true })
      .then(() => {
        fetchCustomers();
      })
      .catch((error) => {
        console.log(error);
      });

    setShow(false);
  };

  const handleDeleteCustomer = (customerId) => {
    const deleteCustomerApiUrl = getApiUrl(
      `${companyId}/sales/customers/${customerId}`
    );
    axios
      .delete(deleteCustomerApiUrl, { withCredentials: true })
      .then(() => {
        fetchCustomers();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleRowClick = (customerId) => {
    history.push(`/${companyId}/customers/${customerId}`);
  };

  useEffect(() => {
    fetchCustomers();
  }, []);

  const fetchCustomers = () => {
    setLoading(true);
    const customersApiUrl = getApiUrl(`${companyId}/sales/customers`);
    axios
      .get(customersApiUrl, { withCredentials: true })
      .then((response) => {
        setCustomers(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const data = useMemo(() => customers, [customers]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
    setGlobalFilter,
  } = tableInstance;

  const { globalFilter } = state;

  return (
    <>
      <PageTitle activeMenu="Kunder" motherMenu="Fakturor och Offerter" />
      <div className="col-12">
        {loading ? (
          <div className="vh-100 d-flex justify-content-center align-items-center">
            <Spinner animation="border" role="status" className="me-2">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            <div>Loading...</div>
          </div>
        ) : customers.length === 0 ? (
          <>
            <NoData message="Det finns inga kunder att visa. Skapa en för att kunna se dem här." />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                textAlign: 'center',
                padding: '10px',
              }}
            >
              <Link
                style={{ marginTop: '20px', alignItem: 'center' }}
                to={`#`}
                onClick={() => setShow(true)}
                className="btn btn-primary"
              >
                Skapa en ny kund
              </Link>
            </div>
          </>
        ) : (
          <>
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Kunder</h4>

                <Link
                  to={`#`}
                  onClick={() => setShow(true)}
                  className="btn btn-primary"
                >
                  Ny Kund
                </Link>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <GlobalFilter
                    filter={globalFilter}
                    setFilter={setGlobalFilter}
                  />
                  <table
                    {...getTableProps()}
                    className="table dataTable table-hover display"
                  >
                    <thead>
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <th {...column.getHeaderProps()}>
                              {column.render('Header')}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {page.map((row) => {
                        prepareRow(row);
                        return (
                          <tr
                            className="clickable-row"
                            onClick={() => handleRowClick(row.original.id)}
                            {...row.getRowProps()}
                          >
                            {row.cells.map((cell) => (
                              <td {...cell.getCellProps()}>
                                {cell.value !== null
                                  ? cell.render('Cell')
                                  : '-'}
                              </td>
                            ))}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        )}
        <NewCustomerModal
          show={show}
          handleClose={handleClose}
          handleSave={handleSave}
          handleUpdate={handleUpdateCustomer}
          customerDetails={selectedCustomer}
        />
      </div>
    </>
  );
};

export default CustomersTable;
