import React, { useState, useEffect } from 'react';
import { Table, Card, Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import axios from '../../../../services/AxiosInstance';
import { getApiUrl } from '../../../../utils/ApiUtils';
import PageTitle from '../../../layouts/PageTitle';
import Select from 'react-select';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { NoData } from '../../SharedComponents/NoData';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

function BalanceReport() {
    const [fiscalYears, setFiscalYears] = useState([]);
    const [selectedFiscalYear, setSelectedFiscalYear] = useState(null);
    const { companyId } = useParams();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [ledgerData, setLedgerData] = useState({});

    const history = useHistory();

    useEffect(() => {
        getFiscalYears();
    }, [companyId]);

    useEffect(() => {
        if (selectedFiscalYear) {
            getBalanceReport();
        }
    }, [selectedFiscalYear]);

    const getFiscalYears = async () => {
        try {
            const result = await axios.get(getApiUrl(`${companyId}/accounting/fiscalyears`), { withCredentials: true });
            setFiscalYears(result.data.fiscal_years);
            if (result.data.fiscal_years.length > 0) {
                const latestFiscalYear = result.data.fiscal_years.sort((a, b) => b.year - a.year)[0];
                setSelectedFiscalYear(latestFiscalYear);
            }
        } catch (err) {
            setError('Failed to fetch fiscal years');
            setFiscalYears([]);
        } finally {
            setLoading(false);
        }
    };

    const getBalanceReport = async () => {
        if (!selectedFiscalYear) return;

        const queryParams = {
            fiscalYearId: selectedFiscalYear.fiscal_year_id,
        };

        try {
            const response = await axios.get(getApiUrl(`${companyId}/reporting/balance`), {
                params: queryParams,
                withCredentials: true
            });
            setLedgerData(response.data);
        } catch (err) {
            setError('Failed to fetch balance report');
        } finally {
            setLoading(false);
        }
    };

    const handleFiscalYearChange = (selectedOption) => {
        setSelectedFiscalYear(selectedOption.value);
        setLoading(true);
    };

    const exportToPDF = () => {
        const doc = new jsPDF();

        // Add header information
        doc.setFontSize(14);
        doc.text("Balansräkning", 14, 10);
        doc.setFontSize(11);
        doc.text("Företagsnamn: Example Company AB", 14, 20);
        doc.text("Organisationsnummer: 123456-7890", 14, 26);
        doc.text("Räkenskapsår: 2024-01-01 - 2024-12-31", 14, 32);

        const tableColumn = ["", "Ingående balans", "Förändring", "Utgående saldo"];

        // Helper function to convert section to rows for PDF
        const getSectionRows = (section, depth = 0) => {
            if (!section || typeof section !== 'object') return [];
            let rows = [];

            Object.keys(section).forEach((key) => {
                const subSection = section[key];
                if (!subSection || typeof subSection !== 'object') return;

                const isLeafNode = subSection.accounts && Array.isArray(subSection.accounts) && subSection.accounts.length > 0;

                // Add header row for the subsection
                rows.push([{ content: key, colSpan: 4, styles: { halign: 'left', fontStyle: 'bold' } }]);

                if (isLeafNode) {
                    // Add account details
                    subSection.accounts.forEach((account) => {
                        rows.push([
                            `${' '.repeat(depth * 2)}${account.account_number} - ${account.account_name}`,
                            account.opening_balance,
                            account.change,
                            account.closing_balance,
                        ]);
                    });

                    // Add subtotal row for the subsection
                    rows.push([
                        { content: `Summa ${key}`, colSpan: 1, styles: { fontStyle: 'bold' } },
                        { content: subSection.total_opening_balance, styles: { fontStyle: 'bold' } },
                        { content: subSection.total_change, styles: { fontStyle: 'bold' } },
                        { content: subSection.total_closing_balance, styles: { fontStyle: 'bold' } }
                    ]);
                } else {
                    // Recursively process nested sections
                    rows = rows.concat(getSectionRows(subSection, depth + 1));

                    // Add a sum row for the non-leaf subsection
                    rows.push([
                        { content: `Summa ${key}`, colSpan: 1, styles: { fontStyle: 'bold' } },
                        { content: subSection.total_opening_balance, styles: { fontStyle: 'bold' } },
                        { content: subSection.total_change, styles: { fontStyle: 'bold' } },
                        { content: subSection.total_closing_balance, styles: { fontStyle: 'bold' } }
                    ]);
                }
            });

            return rows;
        };

        // Render for Tillgångar
        let tillgangarRows = getSectionRows(ledgerData.TILLGANGAR);

        // Add Tillgångar section header
        doc.autoTable({
            head: [tableColumn],
            body: [[{ content: "Tillgångar", colSpan: 4, styles: { halign: 'left', fontStyle: 'bold' } }]],
            startY: 40,
        });

        doc.autoTable({
            body: tillgangarRows,
            startY: doc.lastAutoTable.finalY,
            styles: { cellPadding: 2, fontSize: 10 },
        });

        // Add Summa Tillgångar at the end of the Tillgångar section
        doc.autoTable({
            body: [
                [
                    { content: "Summa Tillgångar", colSpan: 1, styles: { fontStyle: 'bold' } },
                    { content: ledgerData.TILLGANGAR.total_opening_balance, styles: { fontStyle: 'bold' } },
                    { content: ledgerData.TILLGANGAR.total_change, styles: { fontStyle: 'bold' } },
                    { content: ledgerData.TILLGANGAR.total_closing_balance, styles: { fontStyle: 'bold' } }
                ]
            ],
            startY: doc.lastAutoTable.finalY,
            styles: { cellPadding: 2, fontSize: 10 },
        });

        // Add space between major sections
        let egetKapitalStartY = doc.lastAutoTable.finalY + 10;

        // Render for Eget Kapital och Skulder
        let egetKapitalRows = getSectionRows(ledgerData['EGET KAPITAL OCH SKULDER']);

        // Add Eget Kapital och Skulder section header
        doc.autoTable({
            head: [tableColumn],
            body: [[{ content: "Eget Kapital och Skulder", colSpan: 4, styles: { halign: 'left', fontStyle: 'bold' } }]],
            startY: egetKapitalStartY,
        });

        doc.autoTable({
            body: egetKapitalRows,
            startY: doc.lastAutoTable.finalY,
            styles: { cellPadding: 2, fontSize: 10 },
        });

        // Add Summa Eget Kapital och Skulder at the end
        doc.autoTable({
            body: [
                [
                    { content: "Summa Eget Kapital och Skulder", colSpan: 1, styles: { fontStyle: 'bold' } },
                    { content: ledgerData['EGET KAPITAL OCH SKULDER'].total_opening_balance, styles: { fontStyle: 'bold' } },
                    { content: ledgerData['EGET KAPITAL OCH SKULDER'].total_change, styles: { fontStyle: 'bold' } },
                    { content: ledgerData['EGET KAPITAL OCH SKULDER'].total_closing_balance, styles: { fontStyle: 'bold' } }
                ]
            ],
            startY: doc.lastAutoTable.finalY,
            styles: { cellPadding: 2, fontSize: 10 },
        });

        doc.save(`BalanceReport_${selectedFiscalYear.year}.pdf`);
    };

    const renderSection = (section, depth = 0) => {
        if (!section || typeof section !== 'object') return null;

        return Object.keys(section).map((key) => {
            const subSection = section[key];
            if (!subSection || typeof subSection !== 'object') return null;

            const isLeafNode = subSection.accounts && Array.isArray(subSection.accounts) && subSection.accounts.length > 0;

            return (
                <React.Fragment key={`${depth}-${key}`}>
                    <tr>
                        <td style={{ paddingLeft: `${depth * 20}px` }}><strong>{key}</strong></td>
                        <td><strong>{subSection.total_opening_balance || ''}</strong></td>
                        <td><strong>{subSection.total_change || ''}</strong></td>
                        <td><strong>{subSection.total_closing_balance || ''}</strong></td>
                    </tr>
                    {isLeafNode && subSection.accounts.map((account) => (
                        <tr key={account.account_id}>
                            <td style={{ paddingLeft: `${(depth + 1) * 20}px` }}>{account.account_number} - {account.account_name}</td>
                            <td>{account.opening_balance}</td>
                            <td>{account.change}</td>
                            <td>{account.closing_balance}</td>
                        </tr>
                    ))}
                    {!isLeafNode && subSection !== section && renderSection(subSection, depth + 1)}
                </React.Fragment>
            );
        });
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <>
            <PageTitle motherMenu={"Rapporter"} activeMenu={"Balansrapport"} />

            {loading ? (
                <p>Loading...</p>
            ) : fiscalYears.length === 0 ? (
                <>
                    <NoData message="Det finns inga räkenskapsår att visa. Skapa ett nytt räkenskapsår för att börja lägga till verifikationer." />
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100%',
                        textAlign: 'center',
                        padding: '10px'
                    }}>
                        <Link
                            style={{ marginTop: "20px", alignItem: "center" }}
                            to={`#`}
                            onClick={() => history.push(`/${companyId}/settings`)}
                            className="btn btn-primary"
                        >
                            Gå till Inställningar
                        </Link>
                    </div>
                </>
            ) : (
                <>
                    <div className="d-flex justify-content-between align-items-center mb-5" style={{ width: '100%' }}>
                        <div className="d-flex align-items-center gap-4">
                            <span>Räkenskapsår:</span>
                            <Select
                                value={{ value: selectedFiscalYear, label: selectedFiscalYear?.year }}
                                onChange={handleFiscalYearChange}
                                options={fiscalYears.map(fy => ({ value: fy, label: fy.year }))}
                            />
                        </div>
                        <Button variant="primary" onClick={exportToPDF}>Exportera till PDF</Button>
                    </div>

                    {/* Table for Tillgångar */}
                    <Card className="mb-3">
                        <Card.Header>
                            <h5>Balansrapport</h5>
                        </Card.Header>
                        <Card.Body>
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th>Konto</th>
                                        <th>Ingående saldo</th>
                                        <th>Förändring</th>
                                        <th>Utgående saldo</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><strong>Tillgångar</strong></td>
                                        <td><strong>{ledgerData.TILLGANGAR?.total_opening_balance || ''}</strong></td>
                                        <td><strong>{ledgerData.TILLGANGAR?.total_change || ''}</strong></td>
                                        <td><strong>{ledgerData.TILLGANGAR?.total_closing_balance || ''}</strong></td>
                                    </tr>
                                    {renderSection(ledgerData.TILLGANGAR)}
                                </tbody>
                            </Table>

                            {/* Adding some space between the two tables */}
                            <div style={{ marginBottom: '30px' }} />

                            {/* Table for Eget Kapital och Skulder */}
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><strong>Eget Kapital och Skulder</strong></td>
                                        <td><strong>{ledgerData['EGET KAPITAL OCH SKULDER']?.total_opening_balance || ''}</strong></td>
                                        <td><strong>{ledgerData['EGET KAPITAL OCH SKULDER']?.total_change || ''}</strong></td>
                                        <td><strong>{ledgerData['EGET KAPITAL OCH SKULDER']?.total_closing_balance || ''}</strong></td>
                                    </tr>
                                    {renderSection(ledgerData['EGET KAPITAL OCH SKULDER'])}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </>
            )
            }
        </>
    );
}

export default BalanceReport;
