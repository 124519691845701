import React, { useState, useEffect } from 'react';
import { Modal, Button, Accordion } from 'react-bootstrap';
import Select from 'react-select';
import axios from '../../../../../services/AxiosInstance';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import swal from 'sweetalert';
import { getApiUrl } from '../../../../../utils/ApiUtils';

const NewCustomerModal = ({ show, handleClose, handleSave, handleUpdate, customerDetails }) => {
  const [customerData, setCustomerData] = useState({
    orgnumber: '',
    vatnumber: '',
    name: '',
    address: '',
    city: '',
    postalcode: '',
    country: '',
    phonenumber: '',
    contacts: [
      { name: '', email: '', phone: '' } // Default contact field
    ],
    invoicedetails: {
      paymentterms: '',
      paymentduration: ''
    }
  });

  const [companies, setCompanies] = useState([]); // To store search results
  const [selectedCompany, setSelectedCompany] = useState(null); // To store the selected company

  console.log('Customer details:', customerDetails);

  // Determine if we are in edit mode
  const isEditMode = !!customerDetails;

  // Update customerData when customerDetails or show changes
  useEffect(() => {
    if (show) {
      if (customerDetails) {
        setCustomerData({
          id: customerDetails.id || '',
          orgnumber: customerDetails.orgnumber || '',
          vatnumber: customerDetails.vatnumber || '',
          name: customerDetails.namn || '',
          address: customerDetails.street1 || '',
          city: customerDetails.city || '',
          postalcode: customerDetails.postalcode || '',
          country: customerDetails.country || '',
          phonenumber: customerDetails.phone || '',
          contacts: [
            {
              name: customerDetails.kontakt ? customerDetails.kontakt.name : '',
              email: customerDetails.email || '',
              phone: customerDetails.phone || ''
            }
          ],
          invoicedetails: {
            paymentterms: customerDetails.payment_terms, // Set this if you have the data
            paymentduration: customerDetails.payment_terms // Set this if you have the data
          }
        });
      } else {
        // Reset to default values if creating a new customer
        setCustomerData({
          orgnumber: '',
          vatnumber: '',
          name: '',
          address: '',
          city: '',
          postalnode: '',
          country: '',
          phonenumber: '',
          contacts: [
            { name: '', email: '', phone: '' }
          ],
          invoicedetails: {
            paymentterms: '',
            paymentduration: ''
          }
        });
      }
      // Reset company search state
      setCompanies([]);
      setSelectedCompany(null);
    }
  }, [customerDetails, show]);

  // Handlers for form fields
  const handleInputChange = (field) => (e) => {
    setCustomerData({ ...customerData, [field]: e.target.value });
  };

  // Handlers for dynamic contacts
  const handleContactChange = (index, field, value) => {
    const updatedContacts = [...customerData.contacts];
    updatedContacts[index][field] = value;
    setCustomerData({ ...customerData, contacts: updatedContacts });
  };

  const addNewContact = () => {
    setCustomerData({
      ...customerData,
      contacts: [...customerData.contacts, { name: '', email: '', phone: '' }]
    });
  };

  const removeContact = (index) => {
    const updatedContacts = [...customerData.contacts];
    updatedContacts.splice(index, 1);
    setCustomerData({ ...customerData, contacts: updatedContacts });
  };

  // Handlers for invoice details
  const handleInvoiceDetailsChange = (field, value) => {
    setCustomerData({
      ...customerData,
      invoicedetails: { ...customerData.invoicedetails, [field]: value }
    });
  };

  const searchCompany = async (inputValue) => {
    if (!inputValue) {
      return;
    }

    try {
      const searchComapnyApiUrl = getApiUrl('generic/search-company');
      const response = await axios.get(searchComapnyApiUrl, {
        params: { query: inputValue },
      });

      console.log('Search results:', response.data);

      setCompanies(
        response.data.hits.map((company) => ({
          label: `${company.document.names[0].nameOrIdentifier} (${company.document.registrationNumber})`,
          value: company,
        }))
      );
    } catch (err) {
      console.error('Error fetching company data:', err.message);
      swal(
        'Fel',
        'Kunde inte hämta företagsinformation, försök igen senare eller ange informationen manuellt.',
        'error'
      );
    }
  };

  const handleCompanySelect = (selectedOption) => {
    const company = selectedOption.value;
    setSelectedCompany(company);

    const visitingAddress = company.document.addresses?.find(addr => addr.addressType === 'visitingAddress');
    const mailAddress = company.document.addresses?.find(addr => addr.addressType === 'mailAddress');
    const selectedAddress = visitingAddress || mailAddress || {};

    const phoneNumber = company.document.phoneNumbers?.[0]?.e164PhoneNumber || '';
    const email = company.document.emailAddresses?.[0] || '';
    const name = company.document.names?.[0]?.nameOrIdentifier || '';

    // Since we now allow multiple contacts, you can set the first one from the API
    const contacts = [
      {
        name: '', // If you have specific contact person data from the API, set it here
        email: email,
        phone: phoneNumber
      }
    ];

    // Assuming that invoice details might be included in the future API response
    const invoiceDetails = {
      paymentterms: '', // Set default or API-provided terms if available
      paymentduration: '' // Set default or API-provided duration if available
    };

    // Update customerData state with all the fields
    setCustomerData({
      orgnumber: company.document.registrationNumber || '',
      vatnumber: company.document.isRegisteredForVAT ? `SE${company.document.registrationNumber}01` : '',
      name: name,
      address: `${selectedAddress.street || ''} ${selectedAddress.houseNumber || ''}`.trim(),
      city: selectedAddress.city || '',
      postalcode: selectedAddress.postalCode || '',
      country: company.document.iso3166CountryCode || '',
      phonenumber: phoneNumber,
      contacts: contacts, // Set the contacts array here
      invoicedetails: invoiceDetails // Set the invoice details here
    });
  };

  const handleSaveClick = () => {
    console.log('Customer to be saved', customerData);
    if (isEditMode) {
      handleUpdate(customerData);
    } else {
      handleSave(customerData);
    }
    handleClose();
  };

  return (
    <Modal
      className='fade bd-example-modal-lg'
      size='lg'
      show={show}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>{isEditMode ? 'Redigera kund' : 'Ny kund'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="basic-form">
          <div className="col-xl-12">
            <div className="col-xl-4 mb-5">
              Fyll i information om företaget.
            </div>
            {/* Company Search Section */}
            {!isEditMode && (
              <>
                <label>Sök företag</label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Ange företagsnamn eller org-nummer"
                    value={customerData.name}
                    onChange={handleInputChange('name')}
                  />
                  <Button variant="primary" onClick={() => searchCompany(customerData.name)}>
                    Hämta företagsinformation
                  </Button>
                </div>
                {companies.length > 0 && (
                  <>
                    <label>Hittade {companies.length} företag</label>
                    <Select
                      options={companies}
                      onChange={handleCompanySelect}
                      placeholder="Välj ett företag"
                      className='mb-5'
                      value={
                        selectedCompany
                          ? {
                            label: selectedCompany.document.names[0].nameOrIdentifier,
                            value: selectedCompany
                          }
                          : null
                      }
                    />
                  </>
                )}
              </>
            )}

            {/* Form fields */}
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="row">
                <div className="form-group mb-3 col-md-6">
                  <label>Företagsnamn</label>
                  <input
                    type="text"
                    className="form-control"
                    value={customerData.name}
                    onChange={handleInputChange('name')}
                  />
                </div>
                <div className="form-group mb-3 col-md-6">
                  <label>Org-nummer</label>
                  <input
                    type="text"
                    className="form-control"
                    value={customerData.orgnumber}
                    onChange={handleInputChange('orgNumber')}
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group mb-3 col-md-6">
                  <label>Vat-nummer</label>
                  <input
                    type="text"
                    className="form-control"
                    value={customerData.vatnumber}
                    onChange={handleInputChange('vatNumber')}
                  />
                </div>
                <div className="form-group mb-3 col-md-6">
                  <label>Telefonnummer</label>
                  <input
                    type="text"
                    className="form-control"
                    value={customerData.phonenumber}
                    onChange={handleInputChange('phoneNumber')}
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group mb-3 col-md-12">
                  <label>Adress</label>
                  <input
                    type="text"
                    className="form-control"
                    value={customerData.address}
                    onChange={handleInputChange('address')}
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group mb-3 col-md-4">
                  <label>Ort</label>
                  <input
                    type="text"
                    className="form-control"
                    value={customerData.city}
                    onChange={handleInputChange('city')}
                  />
                </div>
                <div className="form-group mb-3 col-md-4">
                  <label>Postnummer</label>
                  <input
                    type="text"
                    className="form-control"
                    value={customerData.postalcode}
                    onChange={handleInputChange('postalCode')}
                  />
                </div>
                <div className="form-group mb-3 col-md-4">
                  <label>Land</label>
                  <input
                    type="text"
                    className="form-control"
                    value={customerData.country}
                    onChange={handleInputChange('country')}
                  />
                </div>
              </div>
            </form>

            <hr />
            <h5>Kontaktinformation</h5>

            {/* Accordion for Contact and Invoice Information */}
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="1">
                <Accordion.Header>Kontaktpersoner</Accordion.Header>
                <Accordion.Body>
                  {customerData.contacts.map((contact, index) => (
                    <div key={index}>
                      <div className="row align-items-center">
                        <div className="form-group mb-3 col-md-4">
                          <label>Namn</label>
                          <input
                            type="text"
                            className="form-control"
                            value={contact.name}
                            onChange={(e) => handleContactChange(index, 'name', e.target.value)}
                          />
                        </div>
                        <div className="form-group mb-3 col-md-4">
                          <label>E-post</label>
                          <input
                            type="email"
                            className="form-control"
                            value={contact.email}
                            onChange={(e) => handleContactChange(index, 'email', e.target.value)}
                          />
                        </div>
                        <div className="form-group mb-3 col-md-3">
                          <label>Telefonnummer</label>
                          <input
                            type="text"
                            className="form-control"
                            value={contact.phone}
                            onChange={(e) => handleContactChange(index, 'phone', e.target.value)}
                          />
                        </div>
                        <div className="form-group col-md-1">
                          <Button variant="link" onClick={() => removeContact(index)}>
                            <FontAwesomeIcon icon={faTrash} />
                          </Button>
                        </div>
                      </div>
                      <hr />
                    </div>
                  ))}
                  <Button variant="link" onClick={addNewContact}>Lägg till ny kontaktperson</Button>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="2">
                <Accordion.Header>Faktureringsinformation</Accordion.Header>
                <Accordion.Body>
                  <div className="row align-items-center">
                    <div className="form-group mb-3 col-md-6">
                      <label>Betalningsvillkor</label>
                      <input
                        type="text"
                        className="form-control"
                        value={customerData.invoicedetails.paymentterms}
                        onChange={(e) => handleInvoiceDetailsChange('paymentTerms', e.target.value)}
                      />
                    </div>
                    <div className="form-group mb-3 col-md-6">
                      <label>Betalningstid</label>
                      <input
                        type="text"
                        className="form-control"
                        value={customerData.invoicedetails.paymentterms}
                        onChange={(e) => handleInvoiceDetailsChange('paymentDuration', e.target.value)}
                      />
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Avbryt
        </Button>
        <Button variant="primary" onClick={handleSaveClick}>
          {isEditMode ? 'Uppdatera' : 'Spara'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NewCustomerModal;
