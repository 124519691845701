import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from '../../../../../services/AxiosInstance';
import InvoicesTable from '../../Invoices/InvoicesTable';

import { getApiUrl } from '../../../../../utils/ApiUtils';

const ViewCustomerDetailed = () => {
  const { companyId, customerId } = useParams();
  const [customer, setCustomer] = useState(null);
  const [invoices, setInvoices] = useState([]);
  const [statistics, setStatistics] = useState({ totalEarned: 0 });

  useEffect(() => {
    // Fetch customer details
    const fetchCustomerDetails = async () => {
      try {

        const getCustomerDetailsUrl = getApiUrl(`${companyId}/sales/customers/${customerId}`);
        const response = await axios.get(getCustomerDetailsUrl, { withCredentials: true });
        console.log('Customer details:', response.data);
        setCustomer(response.data);
      } catch (error) {
        console.error('Error fetching customer details:', error);
      }
    };

    // Fetch customer invoices
    const fetchCustomerInvoices = async () => {
      try {
        const getCustomerInvoicesApiUrl = getApiUrl(`${companyId}/sales/customers/${customerId}/invoices`);
        const response = await axios.get(getCustomerInvoicesApiUrl, { withCredentials: true });
        setInvoices(response.data);
        calculateStatistics(response.data);
      } catch (error) {
        console.error('Error fetching customer invoices:', error);
      }
    };

    fetchCustomerDetails();
    fetchCustomerInvoices();
  }, [companyId, customerId]);

  const calculateStatistics = (invoices) => {
    const totalEarned = invoices.reduce((sum, invoice) => sum + invoice.amount, 0);
    setStatistics({ totalEarned });
  };

  if (!customer) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Om kunden</h4>
          </div>
          <div className="card-body pb-3 d-flex flex-wrap justify-content-between align-items-top">
            <div className="me-3 mb-3">
              <p className="mb-2">Namn</p>
              <h5 className="mb-0">{customer.name}</h5>
            </div>

            <div className="me-3 mb-3">
              <p className="mb-2">Adress</p>
              <h5 className="mb-0">{customer.street1}</h5>
              <h5 className="mb-0">{customer.postalcode} {customer.city}</h5>
              <h5 className="mb-0">{customer.country}</h5>
            </div>
            <div className="me-3 mb-3">
              <p className="mb-2">Organisationsnummer</p>
              <h5 className="mb-0">{customer.orgnumber} </h5>
            </div>
            <div className="me-3 mb-3">
              <p className="mb-2">VAT-nummer</p>
              <h5 className="mb-0">SE{customer.orgnumber}01</h5>
            </div>
            <div className="me-3 mb-3">
              <p className="mb-2">Kontakt</p>
              <h5 className="mb-0">{customer.contactname}</h5>
              <h5 className="mb-0">{customer.email}</h5>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Fakturor</h4>
          </div>
          <div className="card-body">
            <InvoicesTable invoices={invoices} />
          </div>
        </div>

        {/*
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Statistik</h4>
          </div>
          <div className="card-body">
            <p><strong>Total Earned:</strong> {statistics.totalEarned}</p>
          </div>
        </div>
*/}
      </div>
    </>
  );
};

export default ViewCustomerDetailed;
