import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import axios from '../../../../services/AxiosInstance';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid, Typography, TextField, Button } from '@material-ui/core';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import SelectWageTypeModal from './SelectWageTypeModal';
import EditPayslipRowModal from './EditPayslipRowModal';

import { makeStyles } from '@material-ui/core/styles';

import { getApiUrl } from '../../../../utils/ApiUtils';


function Payslip({ match }) {

  const { companyId } = useParams();

  const useStyles = makeStyles((theme) => ({
    header: {
      marginBottom: theme.spacing(2),
    },
    grid: {
      marginTop: theme.spacing(2),
    },
  }));

  const classes = useStyles();


  const [wageTypes, setWageTypes] = useState([]);
  const [payslip, setPayslip] = useState(null);
  const [newRow, setNewRow] = useState('');

  console.log(match.params.id);

  useEffect(() => {

    handleFetchWageTypes();

    const getPayslipDataApiUrl = getApiUrl(`${companyId}/payslip/${match.params.id}`);

    // Fetch the payslip data when the component is mounted
    axios.get(getPayslipDataApiUrl, { withCredentials: true })
      .then(response => {
        console.log(response.data);
        setPayslip(response.data);
      })
      .catch(error => {
        console.error('Error fetching payslip', error);
      });
  }, [match.params.id]);

  const handleFetchWageTypes = () => {
    const getWageTypesApiUrl = getApiUrl(`${companyId}/payroll/wage_types`);
    return axios.get(getWageTypesApiUrl, { withCredentials: true })
      .then(response => {
        console.log("fetch wage types");
        console.log(response.data);
        setWageTypes(response.data);
      });
  };

  const handleAddRow = (selectedWageTypes) => {
    const newRows = selectedWageTypes.map(wageType => ({
      wage_type_id: wageType.wage_type_id,
      code: wageType.code,
      description: wageType.description,
      // add other necessary properties here
    }));

    const addRowsApiUrl = getApiUrl(`${companyId}/payslip/${match.params.id}/rows`);
    axios.post(addRowsApiUrl, { rows: newRows, employeeId: payslip.values.employeeId }, { withCredentials: true })
      .then(response => {
        // Update the payslip data with the new rows
        setPayslip(prevPayslip => ({ ...prevPayslip, ...response.data }));
      })
      .catch(error => {
        console.error('Error adding rows', error);
      });
  };

  const [showModal, setShowModal] = useState(false);
  const [showRowEditModal, setShowRowEditModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleShowModal = (modalToShow) => {
    console.log('Show Modal', modalToShow);
    if (modalToShow === 'add') {
      setShowModal(true);
    } else if (modalToShow === 'edit') {
      setShowRowEditModal(true);
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleRowEditModalClose = () => {
    setShowRowEditModal(false);
  };

  function handleEdit(editedItem) {
    console.log('Edit Row', editedItem);
    updatePayslipRow(editedItem);
    // Edit the selected item
    setShowRowEditModal(false);
  }

  async function updatePayslipRow(updatedRow) {
    try {
      const updatePayslipRowApiUrl = getApiUrl(`${companyId}/payslip/${match.params.id}/row/${updatedRow.id}`);
      const response = await axios.put(updatePayslipRowApiUrl, {
        employeeId: payslip.values.employeeId,
        quantity: updatedRow.quantity,
        wageCode: updatedRow.wageType.code
      }, { withCredentials: true });
      console.log(response.data);
      setPayslip(response.data);
    } catch (err) {
      console.error(err);
    }
  }

  function handleDelete() {
    console.log('Delete Row', selectedItem);
    deletePayslipRow(match.params.id, selectedItem.id);
    setSelectedItem(null);
    // Delete the selected item
    setShowRowEditModal(false);
  }

  async function deletePayslipRow(payslipId, rowId) {
    try {
      const deletePayslipRowApiUrl = getApiUrl(`${companyId}/payslip/${payslipId}/row/${rowId}`);
      const response = await axios.delete(deletePayslipRowApiUrl, { withCredentials: true });
      console.log(response.data);
      setPayslip(response.data);
    } catch (err) {
      console.error(err);
    }
  }

  const handleWageTypeSelect = async (selectedWageType) => {
    console.log('Selected Wagetype:', selectedWageType);
    handleAddRow(selectedWageType);
    handleModalClose();
  };

  const handleButtonClick = () => {
    handleShowModal('add');
  };

  const handleRowEdit = () => {
    console.log('Edit Row');
    handleShowModal('edit');
  };

  const handleItemSelect = (item) => {
    // Make a call to the server to add the item
    //setSalaryItems(prevItems => [...prevItems, item]);
  };

  const onDragEnd = (result) => {
    // Update the order of salaryItems based on the drag and drop result
  };

  if (!payslip) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div>

        <Grid container justifyContent="space-between" alignItems="center" style={{ marginBottom: '20px' }}>
          <Grid item>
            <Typography variant="h6" color='textSecondary'>Lönespecifikation för perioden 2024 Februari</Typography>
            {/* Replace '2024 Februari' with the actual year and month from the payslip data */}
          </Grid>
          <Grid item>
            <Typography variant="h6" color='textSecondary'>Riza Consulting AB</Typography>
            {/* Replace 'payslip.companyName' with the actual property name for the company name in the payslip data */}
          </Grid>
        </Grid>

        {/*<Grid container direction="column" justify="flex-start" alignItems="flex-start" style={{ marginBottom: '20px' }}>
					<hr style={{ width: '100%', marginBottom: '10px' }} />
					<Grid item>
						<Typography variant="body2" color='textSecondary'>Betalas till</Typography>
					</Grid>
					<Grid item>
						<Typography variant="h6" style={{ fontWeight: 'bold' }}>{payslip.name}</Typography>
					</Grid>
				</Grid>*/}

        <Grid container alignItems="center" spacing={6} style={{ marginBottom: '20px' }}>
          <Grid item xs>
            <div style={{ width: '100%' }}>
              <hr style={{ marginBottom: '10px' }} />
              <Typography variant="body2" color="textSecondary">Betalas till</Typography>
              <Typography variant="h6" style={{ fontWeight: 300 }}>{payslip.values.name}</Typography>
              <Typography variant="h6" style={{ fontWeight: 300 }}>{payslip.values.ssn}</Typography>
              {/* Replace 'payslip.name' with the actual property name for the employee's name in the payslip data */}
            </div>
          </Grid>
          <Grid item xs>
            <div style={{ width: '100%' }}>
              <hr style={{ marginBottom: '10px' }} />
              <Typography variant="body2" color="textSecondary">Skatt</Typography>
              <Typography variant="h6" style={{ fontWeight: 300 }}>Skattetabell: {payslip.values.taxTable}</Typography>
              <Typography variant="h6" style={{ fontWeight: 300 }}>Skattekolumn: {payslip.values.taxColumn}</Typography>
              {/* Replace 'payslip.taxTable' and 'payslip.taxColumn' with the actual property names for the tax table and tax column in the payslip data */}
            </div>
          </Grid>
        </Grid>

        <Grid container alignItems="center" spacing={6} style={{ marginBottom: '20px' }}>
          <Grid item xs>
            <hr style={{ width: '100%', marginBottom: '10px' }} />
            <Typography variant="body2" color="textSecondary">Utbetalningsdatum</Typography>
            <Typography variant="h6" style={{ fontWeight: 300 }}>Februari 25, 2024{payslip.values.payoutDate}</Typography>
            {/* Replace 'payslip.payoutDate' with the actual property name for the payout date in the payslip data */}
          </Grid>
          <Grid item xs>
            <div style={{ width: '100%' }}>
              <hr style={{ marginBottom: '10px' }} />
              <Typography variant="body2" color="textSecondary">Till konto</Typography>
              <Typography variant="h6" style={{ fontWeight: 300 }}>{payslip.values.accountnumber}</Typography>
            </div>
          </Grid>
        </Grid>
        <Card className="card-body">
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="salaryItems">
              {(provided) => (
                <Table ref={provided.innerRef} {...provided.droppableProps}>
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>Benämning</TableCell>
                      <TableCell>Antal</TableCell>
                      <TableCell>Kostnad</TableCell>
                      <TableCell>Summa</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {payslip.paySlipRows.length > 0 ? (
                      payslip.paySlipRows.map((item, index) => (
                        <Draggable key={item.id} draggableId={item.id} index={index}>
                          {(provided) => (
                            <TableRow ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                              <TableCell onClick={() => {
                                setSelectedItem(item);
                                handleRowEdit();
                              }}>
                                <div className="icon">
                                  <i className="bi bi-list-ul"></i>
                                </div>
                              </TableCell>
                              <TableCell> {item.wageType.code + ", " + item.wageType.description} </TableCell>
                              <TableCell> {item.quantity + " " + item.wageType.wageTypeUnit}</TableCell>
                              <TableCell>{item.rate}</TableCell>
                              <TableCell>{item.wageType.wageTypeEffectType === 'dec' ? -item.amount : item.amount}</TableCell>
                            </TableRow>
                          )}
                        </Draggable>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={4}>No data</TableCell>
                      </TableRow>
                    )}
                    {provided.placeholder}
                  </TableBody>
                </Table>
              )}
            </Droppable>
          </DragDropContext>
          <button onClick={() => handleButtonClick()} className="btn btn-primary btn-block mt-5 mb-2">
            Lägg till ny rad
          </button>
        </Card>

        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell style={{ textTransform: 'uppercase', lineHeight: '23px', fontSize: '14px', fontWeight: '400' }} component="th" scope="row">Total bruttolön för perioden</TableCell>
                <TableCell style={{ letterSpacing: '0.5px', lineHeight: '30px', fontSize: '20pt', fontWeight: '400' }} align="right">{payslip.values.grossIncome} kr</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ textTransform: 'uppercase', lineHeight: '23px', fontSize: '14px', fontWeight: '400' }} component="th" scope="row">Din skatt enligt tabell</TableCell>
                <TableCell style={{ letterSpacing: '0.5px', lineHeight: '30px', fontSize: '20pt', fontWeight: '400' }} align="right">{payslip.values.taxTotal} kr</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ textTransform: 'uppercase', lineHeight: '23px', fontSize: '14px', fontWeight: '400' }} component="th" scope="row">Sociala avgifter</TableCell>
                <TableCell style={{ letterSpacing: '0.5px', lineHeight: '30px', fontSize: '20pt', fontWeight: '400' }} align="right">{payslip.values.socialTaxTotal} kr</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ textTransform: 'uppercase', lineHeight: '23px', fontSize: '14px', fontWeight: '400' }} component="th" scope="row">Total lönekostnad för företaget</TableCell>
                <TableCell style={{ letterSpacing: '0.5px', lineHeight: '30px', fontSize: '20pt', fontWeight: '400' }} align="right">{(Number(payslip.values.grossIncome) + Number(payslip.values.socialTaxTotal)).toFixed(2)} kr</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ textTransform: 'uppercase', lineHeight: '23px', fontSize: '14px', fontWeight: '400' }} component="th" scope="row">Nettolön</TableCell>
                <TableCell style={{ letterSpacing: '0.5px', lineHeight: '30px', fontSize: '20pt', fontWeight: '400' }} align="right">{payslip.values.netTotal} kr</TableCell>
              </TableRow>
              {/* Add more rows for other properties as needed */}
            </TableBody>
          </Table>
        </TableContainer>

        <Grid container spacing={5} justifyContent="space-between" className={classes.grid}>
          <Grid item xs={6}>
            <div style={{ width: '100%' }}>
              <hr style={{ marginBottom: '10px' }} />
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="h6" className={classes.header}>Semesterdagar</Typography>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="body1">Betalda:</Typography>
                <Typography variant="body1">{payslip.vacationDays.allowed}</Typography>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="body1">Sparade:</Typography>
                <Typography variant="body1">{payslip.vacationDays.allowed - payslip.vacationDays.taken}</Typography>
              </div>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div style={{ width: '100%' }}>
              <hr style={{ marginBottom: '10px' }} />
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="h6" className={classes.header}>Totalt detta år</Typography>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="body1">Brutto:</Typography>
                <Typography variant="body1">{payslip.yearlyTotals.salaryTotal} kr</Typography>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="body1">Skatt:</Typography>
                <Typography variant="body1">{payslip.yearlyTotals.taxTotal} kr</Typography>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="body1">Förmåner:</Typography>
                <Typography variant="body1">{payslip.yearlyTotals.benefits} kr</Typography>
              </div>
            </div>
          </Grid>
        </Grid>
      </div >
      <SelectWageTypeModal show={showModal} handleClose={handleModalClose} handleSelect={handleWageTypeSelect} wageTypes={wageTypes} />
      <EditPayslipRowModal show={showRowEditModal} handleClose={handleRowEditModalClose} item={selectedItem} handleEdit={handleEdit} handleDelete={handleDelete} />
    </>
  );
}

export default Payslip;