import React, { useState, useEffect } from 'react';
import { Table, Card, Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import axios from '../../../../services/AxiosInstance';
import { getApiUrl } from '../../../../utils/ApiUtils';
import PageTitle from '../../../layouts/PageTitle';
import Select from 'react-select';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { NoData } from '../../SharedComponents/NoData';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

function ResultReport() {
    const [fiscalYears, setFiscalYears] = useState([]);
    const [selectedFiscalYear, setSelectedFiscalYear] = useState(null);
    const { companyId } = useParams();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [ledgerData, setLedgerData] = useState({});

    const history = useHistory();

    useEffect(() => {
        getFiscalYears();
    }, [companyId]);

    useEffect(() => {
        if (selectedFiscalYear) {
            getResultReport();
        }
    }, [selectedFiscalYear]);

    const getFiscalYears = async () => {
        try {
            const result = await axios.get(getApiUrl(`${companyId}/accounting/fiscalyears`), { withCredentials: true });
            setFiscalYears(result.data.fiscal_years);
            if (result.data.fiscal_years.length > 0) {
                const latestFiscalYear = result.data.fiscal_years.sort((a, b) => b.year - a.year)[0];
                setSelectedFiscalYear(latestFiscalYear);
            }
        } catch (err) {
            setError('Failed to fetch fiscal years');
            setFiscalYears([]);
        } finally {
            setLoading(false);
        }
    };

    const getResultReport = async () => {
        if (!selectedFiscalYear) return;

        const queryParams = {
            fiscalYearId: selectedFiscalYear.fiscal_year_id,
        };

        try {
            const response = await axios.get(getApiUrl(`${companyId}/reporting/result`), {
                params: queryParams,
                withCredentials: true
            });
            setLedgerData(response.data);
        } catch (err) {
            setError('Failed to fetch result report');
        } finally {
            setLoading(false);
        }
    };

    const handleFiscalYearChange = (selectedOption) => {
        setSelectedFiscalYear(selectedOption.value);
        setLoading(true);
    };

    const renderSection = (sectionData, title, showSum = false) => {
        if (!sectionData) return null;

        const rows = Object.keys(sectionData)
            .filter((key) => key !== 'total_opening_balance' && key !== 'total_change' && key !== 'total_closing_balance')
            .map((key) => {
                const subSection = sectionData[key];
                if (typeof subSection === 'object' && subSection.accounts && subSection.accounts.length > 0) {
                    return (
                        <React.Fragment key={key}>
                            <tr>
                                <td style={{ fontWeight: 'bold' }}>{key}</td>
                                <td></td>
                            </tr>
                            {subSection.accounts.map((account, idx) => (
                                <tr key={idx}>
                                    <td>{`${account.account_number} - ${account.account_name}`}</td>
                                    <td>{parseFloat(account.change).toLocaleString('sv-SE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                </tr>
                            ))}
                            {subSection.total_change && (
                                <tr style={{ fontWeight: 'bold' }}>
                                    <td>{`Summa ${key.toLowerCase()}`}</td>
                                    <td>{parseFloat(subSection.total_change).toLocaleString('sv-SE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                </tr>
                            )}
                        </React.Fragment>
                    );
                } else if (typeof subSection === 'object') {
                    return renderSection(subSection, key, false);
                }
                return null;
            });

        return (
            <>
                <tr>
                    <td colSpan="2" style={{ fontWeight: 'bold', fontSize: '1.2em' }}>{title}</td>
                </tr>
                {rows}
                {showSum && sectionData.total_change && (
                    <tr style={{ fontWeight: 'bold', fontSize: '1.2em' }}>
                        <td>{`Summa ${title.toLowerCase()}`}</td>
                        <td>{parseFloat(sectionData.total_change).toLocaleString('sv-SE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                    </tr>
                )}
            </>
        );
    };

    const calculateResult = () => {
        const income = parseFloat(ledgerData['Rörelseintäkter, lagerförändringar m.m.']?.total_change || 0);
        const expenses = parseFloat(ledgerData['Rörelsekostnader']?.total_change || 0);
        return income + expenses; // Expenses are typically negative, so summing gives the result.
    };

    const result = calculateResult();

    const exportToPDF = () => {
        const doc = new jsPDF();
        const tableColumn = ["Beskrivning", "Belopp"];

        const getSectionRows = (section, title) => {
            if (!section || typeof section !== 'object') return [];
            let rows = [];

            Object.keys(section).forEach((key) => {
                const subSection = section[key];
                if (typeof subSection === 'object' && subSection.accounts && subSection.accounts.length > 0) {
                    rows.push([{ content: key, colSpan: 2, styles: { halign: 'left', fontStyle: 'bold' } }]);
                    subSection.accounts.forEach((account) => {
                        rows.push([
                            `${account.account_number} - ${account.account_name}`,
                            parseFloat(account.change).toLocaleString('sv-SE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                        ]);
                    });
                    if (subSection.total_change) {
                        rows.push([
                            `Summa ${key.toLowerCase()}`,
                            parseFloat(subSection.total_change).toLocaleString('sv-SE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                        ]);
                    }
                } else if (typeof subSection === 'object') {
                    rows = rows.concat(getSectionRows(subSection, key));
                }
            });

            rows.unshift([{ content: title, colSpan: 2, styles: { halign: 'left', fontSize: 14, fontStyle: 'bold' } }]);

            return rows;
        };

        const incomeRows = getSectionRows(ledgerData['Rörelseintäkter, lagerförändringar m.m.'], 'Rörelsens intäkter');
        const expenseRows = getSectionRows(ledgerData['Rörelsekostnader'], 'Rörelsens kostnader');

        doc.text("Resultaträkning", 14, 10);
        doc.autoTable({
            head: [tableColumn],
            body: incomeRows,
            startY: 20,
        });

        doc.autoTable({
            head: [tableColumn],
            body: expenseRows,
            startY: doc.previousAutoTable.finalY + 10,
        });

        doc.text(`Resultat: ${result.toLocaleString('sv-SE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`, 14, doc.previousAutoTable.finalY + 20);

        doc.save(`Resultatrapport_${selectedFiscalYear.year}.pdf`);
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <>
            <PageTitle motherMenu={"Rapporter"} activeMenu={"Resultatrapport"} />

            {loading ? (
                <p>Loading...</p>
            ) : fiscalYears.length === 0 ? (
                <>
                    <NoData message="Det finns inga räkenskapsår att visa. Skapa ett nytt räkenskapsår för att börja lägga till verifikationer." />
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100%',
                        textAlign: 'center',
                        padding: '10px'
                    }}>
                        <Link
                            style={{ marginTop: "20px", alignItem: "center" }}
                            to={`#`}
                            onClick={() => history.push(`/${companyId}/settings`)}
                            className="btn btn-primary"
                        >
                            Gå till Inställningar
                        </Link>
                    </div>
                </>
            ) : (
                <>

                    <div className="d-flex justify-content-between align-items-center mb-5" style={{ width: '100%' }}>
                        <div className="d-flex align-items-center gap-4">
                            <span>Räkenskapsår:</span>
                            <Select
                                value={{ value: selectedFiscalYear, label: selectedFiscalYear?.year }}
                                onChange={handleFiscalYearChange}
                                options={fiscalYears.map(fy => ({ value: fy, label: fy.year }))}
                            />
                        </div>
                        <Button variant="primary" onClick={exportToPDF}>Exportera till PDF</Button>
                    </div>

                    <Card className="mb-3">
                        <Card.Header>
                            <h5>Resultaträkning</h5>
                        </Card.Header>
                        <Card.Body>
                            <Table responsive>
                                <tbody>
                                    {renderSection(ledgerData['Rörelseintäkter, lagerförändringar m.m.'], 'Rörelsens intäkter', true)}
                                    {renderSection(ledgerData['Rörelsekostnader'], 'Rörelsens kostnader', true)}
                                    {renderSection(ledgerData['Bokslutsdispositioner'], 'Bokslutsdispositioner', true)}
                                    {renderSection(ledgerData['Finansiella poster'], 'Finansiella poster', true)}
                                    {renderSection(ledgerData['Skatter'], 'Skatter', true)}

                                </tbody>
                            </Table>
                            <hr />
                            <div style={{ fontWeight: 'bold', fontSize: '1.2em', textAlign: 'left' }}>
                                <span>Beräknat resultat:</span> <span>{result.toLocaleString('sv-SE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                            </div>
                        </Card.Body>
                    </Card>
                </>
            )}
        </>
    );
}

export default ResultReport;
