import React, { useMemo, useState, useEffect } from 'react';
import { useTable, useSortBy } from 'react-table';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Badge, Spinner } from 'react-bootstrap';
import axios from '../../../../services/AxiosInstance';
import {
  Card,
  Table,
  Dropdown,
} from 'react-bootstrap';

import { getApiUrl } from '../../../../utils/ApiUtils';
import { NoData } from '../../../components/SharedComponents/NoData';
import PageTitle from '../../../layouts/PageTitle';

export const InvoiceList = () => {
  const { companyId } = useParams();
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const history = useHistory();

  const COLUMNS = useMemo(() => [
    {
      Header: 'Id',
      accessor: 'invoicenumber',
    },
    {
      Header: 'Kund',
      accessor: 'name',
    },
    {
      Header: 'Fakturadatum',
      accessor: 'invoicedate',
    },
    {
      Header: 'Förfallodatum',
      accessor: 'deadline',
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: props => {
        const { original } = props.row;
        if (props.value === 'Draft') {
          return (
            <Badge bg="light">
              {props.value}
            </Badge>
          );
        } else if (props.value === 'Published') {
          return (
            <Badge bg="success">
              {props.value}
            </Badge>
          );
        } else if (props.value === 'Paid') {
          return (
            <Badge bg="primary">
              {props.value}
            </Badge>
          );
        } else if (props.value === 'Overdue') {
          return (
            <Badge bg="danger">
              {props.value}
            </Badge>
          );
        } else {
          return (
            <Badge bg="secondary">
              {props.value}
            </Badge>
          );
        }
      },
    },
    {
      Header: 'Bellopp (inkl. moms)',
      accessor: 'total_with_tax',
      Cell: props => {
        const { original } = props.row;
        if (original.state === 'Draft') {
          return (
            <span> - </span>
          );
        }
        return (
          <span>{new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(props.value)}</span>);
      }
    },
    {
      Header: "",
      className: "column-right",
      Cell: ({ row }) => (
        <Dropdown className="dropdown ms-auto text-right"
          onClick={(e) => e.stopPropagation()}>
          <Dropdown.Toggle
            variant=""
            className="btn-link i-false border-0 p-0"
            data-toggle="dropdown"
          >
            <svg
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
            >
              <g
                stroke="none"
                strokeWidth={1}
                fill="none"
                fillRule="evenodd"
              >
                <rect x={0} y={0} width={24} height={24} />
                <circle fill="#000000" cx={5} cy={12} r={2} />
                <circle fill="#000000" cx={12} cy={12} r={2} />
                <circle fill="#000000" cx={19} cy={12} r={2} />
              </g>
            </svg>
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
            {/*<Dropdown.Item onClick={() => handleRowClick(row.original.id)}>Visa Faktura</Dropdown.Item>*/}
            <Dropdown.Item onClick={() => /*handleDelete(row.original.id)*/ alert("Should handle betalning")}>Registrera Betalning</Dropdown.Item>
            <Dropdown.Item onClick={() => handleDelete(row.original.id)}>Radera Faktura</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ),
      id: 'actions',
    },
  ], [companyId]);

  useEffect(() => {
    fetchInvoiceData();
  }, [companyId]);

  const handleRowClick = (invoiceId) => {
    const invoice = invoices.find(invoice => invoice.id === invoiceId);

    if (invoice) {
      if (invoice.is_published) {
        history.push(`/${companyId}/view-invoice/${invoiceId}`);
      } else {
        history.push(`/${companyId}/edit-invoice/${invoiceId}`);
      }
    }
  };

  const handleDelete = (id) => {
    const deleteInvoiceApiUrl = getApiUrl(`${companyId}/invoices/${id}`);
    axios.delete(deleteInvoiceApiUrl, { withCredentials: true })
      .then(res => {
        fetchInvoiceData();
      })
      .catch(err => {
        console.log(err);
      });
  };

  const fetchInvoiceData = async () => {
    setLoading(true);
    try {
      const getInvoicesApiUrl = getApiUrl(`${companyId}/invoices`);
      const result = await axios.get(getInvoicesApiUrl, { withCredentials: true });
      setInvoices(result.data);
    } catch (err) {
      setError('Could not fetch invoice data.');
    } finally {
      setLoading(false);
    }
  };

  const columns = useMemo(() => COLUMNS, [COLUMNS]);
  const invoiceListData = useMemo(() => invoices, [invoices]);

  const tableInstance = useTable({
    columns,
    data: invoiceListData,
    initialState: { hiddenColumns: ['id'] }
  }, useSortBy);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  return (
    <>
      <PageTitle activeMenu="Kundfakturor" motherMenu="Fakturor" />
      <div className="col-12">
        {loading ? (
          <div className="vh-100 d-flex justify-content-center align-items-center">
            <Spinner animation="border" role="status" className="me-2">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            <div>Loading...</div>
          </div>
        ) : error ? (
          <div>{error}</div>
        ) : invoices.length === 0 ? (
          <>
            <NoData message="Det finns inga fakturor att visa. Skapa en för att kunna se dem här." />
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              textAlign: 'center',
              padding: '10px'
            }}>
              <Link
                style={{ marginTop: "20px", alignItem: "center" }}
                to={`#`}
                onClick={() => history.push(`/${companyId}/create-invoices`)}
                className="btn btn-primary"
              >
                Skapa en faktura
              </Link>
            </div>
          </>
        ) : (
          <>
            <Card>
              <Card.Header>
                <h4 className="card-title">Kundfakturor</h4>
                <Link
                  to={`#`}
                  onClick={() => history.push(`/${companyId}/create-invoices`)}
                  className="btn btn-primary"
                >
                  Ny Faktura
                </Link>
              </Card.Header>
              <Card.Body>
                <div className="table-responsive">
                  <Table {...getTableProps()} className="table dataTable table-hover display">
                    <thead>
                      {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                              {column.render('Header')}
                              <span className="ml-1">
                                {column.isSorted ? (
                                  column.isSortedDesc ?
                                    <i className="fa fa-arrow-down ms-2 fs-14" style={{ opacity: '0.7' }} />
                                    :
                                    <i className="fa fa-arrow-up ms-2 fs-14" style={{ opacity: '0.7' }} />
                                )
                                  :
                                  (<i className="fa fa-sort ms-2 fs-14" style={{ opacity: '0.3' }} />)
                                }
                              </span>
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {rows.map(row => {
                        prepareRow(row);
                        return (
                          <tr className='clickable-row' onClick={() => handleRowClick(row.original.id)} {...row.getRowProps()}>
                            {row.cells.map(cell => (
                              <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                            ))}
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          </>
        )}
      </div>
    </>
  );
};

export default InvoiceList;
