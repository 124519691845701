import React from 'react';
import { Modal, Button, Spinner, Alert, Table } from 'react-bootstrap';

const VatPaymentVerificationModal = ({ showModal, setShowModal, totalVat, verifying, error, transactions, totalDebit, totalCredit, handleConfirmVerification }) => {
    return (
        <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Bekräfta Verifikation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Du är på väg att skapa ett verifikatat för din betalning av {new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(totalVat)} till Skatteverket.</p>
                <p>Är du säker på att du vill fortsätta?</p>
                {verifying && <Spinner animation="border" />}
                {error && <Alert variant="danger">{error}</Alert>}

                <Table responsive className="mt-3">
                    <thead>
                        <tr>
                            <th>Konto</th>
                            <th>Debet</th>
                            <th>Kredit</th>
                        </tr>
                    </thead>
                    <tbody>
                        {transactions.map((transaction, index) => (
                            <tr key={index}>
                                <td>{transaction.account_number} - {transaction.account_name}</td>
                                <td>{transaction.debit ? new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(transaction.debit) : new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format("0")}</td>
                                <td>{transaction.credit ? new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(transaction.credit) : new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format("0")}</td>
                            </tr>
                        ))}
                        <tr>
                            <td><strong>Total:</strong></td>
                            <td><strong>{new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(totalDebit)}</strong></td>
                            <td><strong>{new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(totalCredit)}</strong></td>
                        </tr>
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowModal(false)}>
                    Avbryt
                </Button>
                <Button variant="primary" onClick={handleConfirmVerification} disabled={verifying}>
                    Bekräfta och Betala
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default VatPaymentVerificationModal;