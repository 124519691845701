import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Select from 'react-select';

const CreateFiscalYearModal = ({ show, handleClose, handleSave }) => {
    const currentYear = new Date().getFullYear();
    const defaultStartDate = `${currentYear}-01-01`;
    const defaultEndDate = `${currentYear}-12-31`;

    const [startDate, setStartDate] = useState(defaultStartDate);
    const [endDate, setEndDate] = useState(defaultEndDate);
    const [accountingMethod, setAccountingMethod] = useState(null);
    const [vatPeriod, setVatPeriod] = useState(null);
    const [isFormValid, setIsFormValid] = useState(false);

    useEffect(() => {
        setIsFormValid(
            startDate && endDate && accountingMethod && vatPeriod
        );
    }, [startDate, endDate, accountingMethod, vatPeriod]);

    const handleSubmit = () => {
        if (isFormValid) {
            handleSave({
                startDate,
                endDate,
                accountingMethod: accountingMethod?.value,
                vatPeriod: vatPeriod?.value
            });
            handleClose();
        }
    };

    const accountingMethodOptions = [
        { value: 'accrual', label: 'Faktureringsmetoden' },
        { value: 'cash', label: 'Kontantmetoden' }
    ];

    const vatPeriodOptions = [
        { value: 'monthly', label: 'Månadsvis' },
        { value: 'quarterly', label: 'Kvartalsvis' },
        { value: 'yearly', label: 'Årsvis' }
    ];

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Skapa nytt räkenskapsår</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="startDate">
                        <Form.Label>Startdatum</Form.Label>
                        <Form.Control
                            type="date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="endDate">
                        <Form.Label>Slutdatum</Form.Label>
                        <Form.Control
                            type="date"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="accountingMethod">
                        <Form.Label>Bokföringsmetod</Form.Label>
                        <Select
                            value={accountingMethod}
                            onChange={setAccountingMethod}
                            options={accountingMethodOptions}
                            placeholder="Välj bokföringsmetod..."
                        />
                    </Form.Group>
                    <Form.Group controlId="vatPeriod">
                        <Form.Label>Momsperiod</Form.Label>
                        <Select
                            value={vatPeriod}
                            onChange={setVatPeriod}
                            options={vatPeriodOptions}
                            placeholder="Välj momsperiod..."
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Avbryt
                </Button>
                <Button variant="primary" onClick={handleSubmit} disabled={!isFormValid}>
                    Spara
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CreateFiscalYearModal;