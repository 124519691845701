import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const SelectWageTypeModal = ({ show, handleClose, handleSelect, wageTypes }) => {
  const [selectedWageTypes, setSelectedWageTypes] = useState([]);

  const handleSelectChange = (event) => {
    const wageTypeId = event.target.name;
    const isChecked = event.target.checked;

    setSelectedWageTypes((prevSelectedWageTypes) => {
      if (isChecked) {
        const wageType = wageTypes.find((wageType) => wageType.wage_type_id === wageTypeId);
        console.log(wageType);
        return [...prevSelectedWageTypes, wageType];
      } else {
        return prevSelectedWageTypes.filter((wageType) => wageType.wage_type_id !== wageTypeId);
      }
    });
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Välj löneartiklar att lägga till på lönen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {wageTypes.map((wagetype) => (
            <Form.Check
              key={wagetype.wage_type_id}
              type="checkbox"
              label={wagetype.code + ' - ' + wagetype.description}
              value={wagetype.wage_type_id}
              name={wagetype.wage_type_id}
              onChange={handleSelectChange}
            />
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Stäng
          </Button>
          <Button variant="primary" onClick={() => { handleSelect(selectedWageTypes); setSelectedWageTypes([]); handleClose(); }}>
            Lägg till
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SelectWageTypeModal;
