import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import swal from "sweetalert";
import { connect, useDispatch } from 'react-redux';
import {
  loadingToggleAction,
  signupAction,
} from '../../store/actions/AuthActions';
// image
import logo from "../../images/logo-full.png";
import loginbg from "../../images/bg-login.jpg";


function Register(props) {
  const [email, setEmail] = useState('');
  let errorsObj = { email: '', password: '' };
  const [errors, setErrors] = useState(errorsObj);
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');


  const dispatch = useDispatch();
  const location = useLocation();

  const redirectUrl = new URLSearchParams(location.search).get('redirect') || '/';


  function onSignUp(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (email === '') {
      errorObj.email = 'Du måste ange en mail adress.';
      error = true;
      swal('Oops', errorObj.email, "error");
    }
    if (password === '') {
      errorObj.password = 'Du måste ha ett lösenord';
      error = true;
      swal('Oops', errorObj.password, "error");
    }
    if (firstName === '') {
      errorObj.firstName = 'Du måste ange ett förnamn';
      error = true;
      swal('Oops', errorObj.firstName, "error");
    }
    if (lastName === '') {
      errorObj.lastName = 'Du måste ange ett efternamn';
      error = true;
      swal('Oops', errorObj.lastName, "error");
    }

    setErrors(errorObj);
    if (error) return;
    dispatch(loadingToggleAction(true));
    dispatch(signupAction(firstName, lastName, phone, email, password, props.history, redirectUrl));
  }
  return (
    <div className="authincation h-100 p-meddle" style={{ backgroundImage: "url(" + loginbg + ")" }}>
      <div className="container h-100">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="text-center mb-3">
                      <Link to="/login">
                      </Link>
                    </div>
                    <h4 className="text-center mb-4 ">Registrera dig nu för att komma igång med Sveriges enklaste applikation för administration av företag. (Psst! Allt detta gratis!)</h4>
                    {props.errorMessage && (
                      <div className=''>
                        {props.errorMessage}
                      </div>
                    )}
                    {props.successMessage && (
                      <div className=''>
                        {props.successMessage}
                      </div>
                    )}
                    <form onSubmit={onSignUp}>
                      {/*<div className="form-group mb-3">
                        <label className="mb-1 ">
                          <strong>Username</strong>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="username"
                        />
                      </div>*/}
                      <div className="form-group mb-3">
                        <label className="mb-1 ">
                          <strong>Förnammn</strong>
                        </label>
                        <input
                          defaultValue={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                          type="text"
                          className="form-control"
                          placeholder="förnamn"
                        />
                      </div>
                      <div className="form-group mb-3">
                        <label className="mb-1 ">
                          <strong>Efternamn</strong>
                        </label>
                        <input
                          defaultValue={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                          type="text"
                          className="form-control"
                          placeholder="efternamn"
                        />
                      </div>
                      <div className="form-group mb-3">
                        <label className="mb-1 ">
                          <strong>Telefonnummer</strong>
                        </label>
                        <input
                          defaultValue={phone}
                          onChange={(e) => setPhone(e.target.value)}
                          type="text"
                          className="form-control"
                          placeholder="07212345678"
                        />
                      </div>
                      <div className="form-group mb-3">
                        <label className="mb-1 ">
                          <strong>Email</strong>
                        </label>
                        <input
                          defaultValue={email}
                          onChange={(e) => setEmail(e.target.value)}
                          className="form-control"
                          placeholder="email@email.com"
                        />
                      </div>
                      {errors.email && <div>{errors.email}</div>}
                      <div className="form-group mb-3">
                        <label className="mb-1 ">
                          <strong>Password</strong>
                        </label>
                        <input
                          defaultValue={password}
                          onChange={(e) =>
                            setPassword(e.target.value)
                          }
                          className="form-control"
                          placeholder="lösenord"
                        //defaultValue="Password"
                        />
                      </div>
                      {errors.password && <div>{errors.password}</div>}
                      <div className="text-center mt-4">
                        <button
                          type="submit"
                          className="btn btn-primary btn-block"
                        >
                          Skapa konto
                        </button>
                      </div>
                    </form>
                    <div className="new-account mt-3">
                      <p className="">
                        Har du redan ett konto?{" "}
                        <Link className="text-primary" to="/login">
                          Logga in
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};

export default connect(mapStateToProps)(Register);

