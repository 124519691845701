// api/axiosInstance.js


// api/axiosInstance.js

import axios from 'axios';
import { store } from '../store/store';
import { setAccessToken, logout } from '../store/actions/AuthActions';
import { getApiUrl } from '../utils/ApiUtils';

// Create the Axios instance
const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL, // Replace with your backend URL
    withCredentials: true, // Include cookies in requests by default
});

// Request interceptor to add the access token to headers
axiosInstance.interceptors.request.use(
    (config) => {
        // Check if the request should skip authentication
        if (config.skipAuth) {
            // Do not attach the Authorization header or include credentials
            config.withCredentials = false; // Override withCredentials if needed
            return config;
        }

        const state = store.getState();
        const accessToken = state.auth.accessToken;
        if (accessToken) {
            config.headers['Authorization'] = `Bearer ${accessToken}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

// Response interceptor to handle token refreshing
axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        // Check if the error is due to an expired access token and if retry is not already attempted
        if (
            error.response &&
            error.response.status === 401 &&
            !originalRequest._retry &&
            !originalRequest.skipAuth // Ensure we don't retry for external API calls
        ) {
            originalRequest._retry = true;

            try {
                // Attempt to refresh the access token
                const refreshTokenUrlPath = getApiUrl('auth/refresh');
                console.log('refreshTokenUrlPath', refreshTokenUrlPath);
                const response = await axios.post(refreshTokenUrlPath, {}, { withCredentials: true });
                const { accessToken } = response.data;

                // Update the access token in the Redux store
                store.dispatch(setAccessToken(accessToken));

                // Update the Authorization header and retry the original request
                originalRequest.headers['Authorization'] = `Bearer ${accessToken}`;
                return axiosInstance(originalRequest);
            } catch (refreshError) {
                // If refresh fails, log out the user
                store.dispatch(logout());
                return Promise.reject(refreshError);
            }
        }

        return Promise.reject(error);
    }
);

export default axiosInstance;


/*import axios from 'axios';
import { store } from '../store/store';
import { setAccessToken, logout } from '../store/actions/AuthActions';
import { getApiUrl } from '../utils/ApiUtils';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL, // Replace with your backend URL
    withCredentials: true, // Include cookies in requests
});

// Request interceptor to add the access token to headers
axiosInstance.interceptors.request.use(
    (config) => {
        const state = store.getState();
        const accessToken = state.auth.accessToken;
        if (accessToken) {
            config.headers['Authorization'] = `Bearer ${accessToken}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

// Response interceptor to handle token refreshing
axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        // Check if the error is due to an expired access token
        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            try {
                // Attempt to refresh the access token
                const refreshTokenUrlPath = getApiUrl('auth/refresh');
                console.log('refreshTokenUrlPath', refreshTokenUrlPath);
                const response = await axios.post(refreshTokenUrlPath, {}, { withCredentials: true });
                const { accessToken } = response.data;

                // Update the access token in the Redux store
                store.dispatch(setAccessToken(accessToken));

                // Update the Authorization header and retry the original request
                originalRequest.headers['Authorization'] = `Bearer ${accessToken}`;
                return axiosInstance(originalRequest);
            } catch (refreshError) {
                // If refresh fails, log out the user
                store.dispatch(logout());
                return Promise.reject(refreshError);
            }
        }

        return Promise.reject(error);
    }
);

export default axiosInstance;




/*import axios from 'axios';
import { store } from '../store/store';

const axiosInstance = axios.create({
    baseURL: `https://react-course-b798e-default-rtdb.firebaseio.com/`,
});

axiosInstance.interceptors.request.use((config) => {
    const state = store.getState();
    const token = state.auth.auth.idToken;
    config.params = config.params || {};
    config.params['auth'] = token;
    return config;
});

export default axiosInstance;
*/
