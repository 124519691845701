import React, { useState, useEffect } from 'react';
import { Button, Form, Row, Col } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import axios from '../../../../services/AxiosInstance';
import { getApiUrl } from '../../../../utils/ApiUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

function ParameterInput({ template, onNext, companyId }) {
  const [date, setDate] = useState(new Date());
  const [description, setDescription] = useState('');
  const [series, setSeries] = useState('V');
  const [accounts, setAccounts] = useState([]);
  const [manualRows, setManualRows] = useState([
    { account_id: '', account_number: '', account_name: '', debit: '', credit: '' },
    { account_id: '', account_number: '', account_name: '', debit: '', credit: '' }
  ]);
  const [parameters, setParameters] = useState({});
  const [userDefinedAccounts, setUserDefinedAccounts] = useState({});
  const [balanceStatus, setBalanceStatus] = useState('');

  useEffect(() => {
    fetchAccounts();

    if (template) {
      initializeParameters();  // Handle template-based input
    } else {
      setManualRows([
        { account_id: '', account_number: '', account_name: '', debit: '', credit: '' },
        { account_id: '', account_number: '', account_name: '', debit: '', credit: '' }
      ]);  // Initialize empty rows for manual input
    }
  }, [template]);

  useEffect(() => {
    calculateBalance();
  }, [manualRows]);

  const fetchAccounts = async () => {
    try {
      const result = await axios.get(getApiUrl(`${companyId}/accounting/accounts`), { withCredentials: true });
      const options = result.data.map(account => ({
        value: account.account_id,
        label: `${account.account_number} - ${account.account_name}`,
        account_number: account.account_number,
        account_name: account.account_name
      }));
      setAccounts(options);
    } catch (error) {
      console.error('Error fetching accounts:', error);
    }
  };

  const initializeParameters = () => {
    const params = {};

    console.log("Initialize Parameters with template parameters: ", template.parameters);

    template.parameters.forEach(parameter => {
      params[parameter.parameter_name] = ''; // Initialize parameter with empty string
    });

    setParameters(params);
  };

  const handleParameterChange = (name, value) => {
    setParameters(prev => ({ ...prev, [name]: value }));
  };

  const handleUserDefinedAccountChange = (transactionId, selectedOption) => {
    setUserDefinedAccounts(prev => ({ ...prev, [transactionId]: selectedOption ? selectedOption.value : '' }));
  };

  const handleAddRow = () => {
    setManualRows([...manualRows, { account_id: '', account_number: '', account_name: '', debit: '', credit: '' }]);
  };

  const handleRemoveRow = (index) => {
    setManualRows(manualRows.filter((row, rowIndex) => rowIndex !== index));
  };

  const handleRowChange = (index, field, value) => {
    const newRows = [...manualRows];

    if (field === 'account_id') {
      // When the account is selected, store the account_id, account_number, and account_name
      const selectedAccount = accounts.find(option => option.value === value);
      if (selectedAccount) {
        newRows[index] = {
          account_id: selectedAccount.value,
          account_number: selectedAccount.account_number,
          account_name: selectedAccount.account_name,
          debit: newRows[index].debit,
          credit: newRows[index].credit
        };
      } else {
        // If no account is selected, reset the account fields
        newRows[index] = {
          account_id: '',
          account_number: '',
          account_name: '',
          debit: newRows[index].debit,
          credit: newRows[index].credit
        };
      }
    } else {
      newRows[index][field] = value;
    }

    setManualRows(newRows);
  };

  const calculateBalance = () => {
    let totalDebit = 0;
    let totalCredit = 0;

    manualRows.forEach(row => {
      totalDebit += Math.round(parseFloat(row.debit || 0) * 100) / 100;  // Round to 2 decimal places
      totalCredit += Math.round(parseFloat(row.credit || 0) * 100) / 100;  // Round to 2 decimal places
    });

    totalDebit = Math.round(totalDebit * 100) / 100;  // Round to 2 decimal places
    totalCredit = Math.round(totalCredit * 100) / 100;  // Round to 2 decimal places

    if (totalDebit === totalCredit && totalDebit > 0) {
      setBalanceStatus('Debet och Kredit balanserar');
    } else if (totalDebit > totalCredit) {
      setBalanceStatus(`Debet är mer än kredit med ${totalDebit - totalCredit}`);
    } else if (totalCredit > totalDebit) {
      setBalanceStatus(`Kredit är mer än debet med ${totalCredit - totalDebit}`);
    } else {
      setBalanceStatus('');
    }
  };

  const handleNext = () => {
    let data;

    if (template) {
      // Template-based input
      data = {
        date: date.toISOString().split('T')[0],
        description,
        series,
        parameters,
        userDefinedAccounts,
      };
    } else {
      // Manual input
      data = {
        date: date.toISOString().split('T')[0],
        description,
        series,
        transactions: manualRows.filter(row => row.account_id),  // Filter out empty rows
      };
    }

    console.log("Form Data: ", data);

    onNext(data);
  };

  return (
    <div>
      <Form>
        <Form.Group controlId="date">
          <Form.Label>Datum</Form.Label>
          <DatePicker
            selected={date}
            onChange={setDate}
            dateFormat="yyyy-MM-dd"
            className="form-control"
          />
        </Form.Group>

        <Form.Group className="mt-3" controlId="description">
          <Form.Label>Titel</Form.Label>
          <Form.Control
            type="text"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Fyll i titel"
          />
        </Form.Group>

        {template ? (
          <>
            {template.parameters.map(param => (
              <Form.Group className='mt-3' key={param.parameter_name} controlId={param.parameter_name}>
                <Form.Label>{param.parameter_label}</Form.Label>
                {param.parameter_type === 'Date' ? (
                  <DatePicker
                    selected={parameters[param.parameter_name] ? new Date(parameters[param.parameter_name]) : null}
                    onChange={(date) => handleParameterChange(param.parameter_name, date ? date.toISOString().split('T')[0] : '')}
                    dateFormat="yyyy-MM-dd"
                    className="form-control"
                  />
                ) : (
                  <Form.Control
                    type={param.parameter_type === 'Number' ? 'number' : 'text'}
                    value={parameters[param.parameter_name]}
                    onChange={(e) => handleParameterChange(param.parameter_name, e.target.value)}
                    placeholder={`Ange ${param.parameter_label}`}
                  />
                )}
              </Form.Group>
            ))}

            {template.transactions.filter(transaction => transaction.is_user_defined).map((transaction, index) => (
              <Form.Group key={transaction.template_transaction_id} controlId={`user-defined-account-${index}`}>
                <Form.Label>Välj konto för transaktion {index + 1}</Form.Label>
                <Select
                  options={accounts}
                  isSearchable={true}
                  value={accounts.find(option => option.value === userDefinedAccounts[transaction.template_transaction_id])}
                  onChange={(selectedOption) => handleUserDefinedAccountChange(transaction.template_transaction_id, selectedOption)}
                />
              </Form.Group>
            ))}
          </>
        ) : (
          <>
            <h5 className="mt-4">Transaktioner</h5>
            {manualRows.map((row, index) => (
              <Row key={index} className="align-items-center mb-1">
                <Col>
                  {index === 0 && <Form.Label>Konto</Form.Label>}
                  <Select
                    options={accounts}
                    isSearchable={true}
                    value={accounts.find(option => option.value === row.account_id)}
                    onChange={(selectedOption) => handleRowChange(index, 'account_id', selectedOption ? selectedOption.value : '')}
                    styles={{ control: base => ({ ...base, minWidth: '250px' }) }}  // Larger account selection box
                  />
                </Col>
                <Col>
                  {index === 0 && <Form.Label>Debet</Form.Label>}
                  <Form.Control
                    type="number"
                    value={row.debit}
                    onChange={(e) => handleRowChange(index, 'debit', e.target.value)}
                    placeholder="Debet"
                    step="0.01"
                  />
                </Col>
                <Col>
                  {index === 0 && <Form.Label>Kredit</Form.Label>}
                  <Form.Control
                    type="number"
                    value={row.credit}
                    onChange={(e) => handleRowChange(index, 'credit', e.target.value)}
                    placeholder="Kredit"
                    step="0.01"
                  />
                </Col>
                <Col xs="auto">
                  {index > 1 && (
                    <Button variant="link" onClick={() => handleRemoveRow(index)}>
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  )}
                </Col>
              </Row>
            ))}

            <Button className="mt-3" variant="link" onClick={handleAddRow}>
              Lägg till rad
            </Button>

            {/* Balance Indicator */}
            <div className="mt-4">
              <h6>Balance Status: {balanceStatus}</h6>
            </div>
          </>
        )}

        <Button
          className="mt-3"
          onClick={handleNext}
          disabled={!description || (!template && manualRows.some(row => !row.account_id || (!row.debit && !row.credit)))}
        >
          Next
        </Button>
      </Form>
    </div>
  );
}

export default ParameterInput;
