import React, { useMemo } from 'react';
import { useTable, useSortBy } from 'react-table';
import { Badge } from "react-bootstrap";
import { Link, useHistory, useParams } from 'react-router-dom';

const SupplierInvoiceTable = ({ data, handleEdit, handleDelete }) => {

  console.log('SupplierInvoiceTable data: ', data);

  const columns = useMemo(() => [
    {
      Header: 'Id',
      Footer: 'Id',
      accessor: 'invoice_id',
      id: 'id',
    },
    {
      Header: 'Leverantör',
      Footer: 'Supplier',
      accessor: 'supplier_name',
      id: 'name',
    },
    {
      Header: 'Fakturanummer',
      Footer: 'Invoicenumber',
      accessor: 'invoice_number',
      id: 'invoicenumber',
    },
    {
      Header: 'Fakturadatum',
      Footer: 'Invoicedate',
      accessor: 'invoice_date',
      id: 'invoicedate',
    },
    {
      Header: 'Förfallodatum',
      Footer: 'Deadline',
      accessor: 'due_date',
      id: 'deadline',
    },
    {
      Header: 'Status',
      Footer: 'State',
      accessor: 'status',
      id: 'state',
      Cell: props => <Badge bg="primary">{props.value}</Badge>,
    },
    {
      Header: "Actions",
      className: "column-right",
      Cell: ({ row }) => (
        <div className="d-flex">
          <Link
            to='#'
            onClick={(e) => {
              e.stopPropagation();
              handleEdit(row.original.invoice_id);
            }}
            className="btn btn-primary shadow btn-xs sharp me-3"
          >
            <i className="fas fa-pencil-alt"></i>
          </Link>
          <Link
            to='#'
            onClick={(e) => {
              e.stopPropagation();
              handleDelete(row.original.invoice_id);
            }}
            className="btn btn-danger shadow btn-xs sharp"
          >
            <i className="fa fa-trash"></i>
          </Link>
        </div>
      ),
      id: 'actions',
    },
  ], [handleEdit, handleDelete]);

  const history = useHistory();
  const { companyId } = useParams();

  const initialState = { hiddenColumns: ['id'] };

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState,
    },
    useSortBy
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  const handleRowClick = (invoiceId) => {
    console.log('Row clicked: ', invoiceId);
    history.push(`/${companyId}/supplier-invoices/${invoiceId}`);
  };

  return (
    <div className="table-responsive">
      <div className="dataTables_wrapper">
        <table {...getTableProps()} className="table dataTable table-hover display">
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                    <span className="ml-1">
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <i className="fa fa-arrow-down ms-2 fs-14" style={{ opacity: '0.7' }} />
                        ) : (
                          <i className="fa fa-arrow-up ms-2 fs-14" style={{ opacity: '0.7' }} />
                        )
                      ) : (
                        <i className="fa fa-sort ms-2 fs-14" style={{ opacity: '0.3' }} />
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row);
              return (
                <tr
                  className='clickable-row'
                  onClick={() => handleRowClick(row.original.invoice_id)}
                  {...row.getRowProps()}
                >
                  {row.cells.map(cell => (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SupplierInvoiceTable;
