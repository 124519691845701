import React, { useState } from 'react';
import { Modal, Button, Form, Table } from 'react-bootstrap';
const BankgirotFileDownloadModal = ({ show, handleClose, handleSelect, employeeSalaries }) => {
	const [selectedEmployeeSalaries, setSelectedEmployeeSalaries] = useState([]);

	const handleSelectChange = (event) => {
		const id = event.target.value;
		const selectedEmployeeSalary = employeeSalaries.find(salary => salary.id === id);
		console.log(selectedEmployeeSalary);
		setSelectedEmployeeSalaries(prevState =>
			prevState.includes(selectedEmployeeSalary) ? prevState.filter(salary => salary.id !== id) : [...prevState, selectedEmployeeSalary]
		);
	};

	const [formData, setFormData] = useState({
		customerNumber: '',
		bankgirotNumber: '',
	});

	const handleInputChange = (event) => {
		const { name, value } = event.target;

		console.log(name, value);

		setFormData({ ...formData, [name]: value });
	};




	return (
		<>
			<Modal show={show} onHide={handleClose} size='lg'>
				<Modal.Header closeButton>
					<Modal.Title>Välje Löner att inkludera med i betalningen</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Group className="mb-5" controlId="customerNumber">
						<Form.Label>Ditt kundnummer</Form.Label>
						<Form.Control type="text" placeholder="Ange ditt kundnummer hos bankgirot" onChange={handleInputChange} name='customerNumber' />
					</Form.Group>

					<Form.Group controlId="bankgirotNumber">
						<Form.Label>Bankgironummer</Form.Label>
						<Form.Control type="text" placeholder="Ange ditt bankgironummer" onChange={handleInputChange} name='bankgirotNumber' />
					</Form.Group>
					<Table responsive className="header-border mt-5">
						<thead>
							<tr>
								<th></th>
								<th>Namn</th>
								<th>Att betala</th>
								<th>Clearingnummer</th>
								<th>Kontonummer</th>
								{/* Add more columns as needed */}
							</tr>
						</thead>
						<tbody>
							{employeeSalaries.map((employeeSalary, index) => (
								<tr key={employeeSalary.id}>
									<td>
										<Form.Check
											key={employeeSalary.id}
											type="checkbox"
											value={employeeSalary.id}
											onChange={handleSelectChange}
										/>
									</td>
									<td>{employeeSalary.name}</td>
									<td>{employeeSalary.netpay}</td>
									<td>{employeeSalary.clearingnumber}</td>
									<td>{employeeSalary.accountnumber}</td>
									{/* Add more cells as needed */}
								</tr>
							))}
						</tbody>
					</Table>

				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Stäng
					</Button>
					<Button variant="primary" onClick={() => { handleSelect(selectedEmployeeSalaries, formData.customerNumber, formData.bankgirotNumber); handleClose(); }}>
						Lägg till
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default BankgirotFileDownloadModal;
