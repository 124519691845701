import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from '../../../../services/AxiosInstance';
import PDFViewer from './PdfViewer';
import dayjs from 'dayjs';
import {
  Spinner,
  Card,
  Button,
  Collapse,
  Badge,
  Alert,
  Modal,
  Form,
  Table,
  Row,
} from 'react-bootstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { getApiUrl } from '../../../../utils/ApiUtils';
import PageTitle from '../../../layouts/PageTitle';
import PaymentReceivedModal from './PaymentReceivedModal';

// Import Font Awesome components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Import specific icons
import {
  faFileAlt,       // Draft
  faPaperPlane,    // Published
  faCheckCircle,   // Paid
  faExclamationCircle, // Overdue
  faTimesCircle,   // Cancelled
  faBell,          // Reminder Sent
} from '@fortawesome/free-solid-svg-icons';


const InvoiceViewer = () => {
  const { companyId, invoiceId } = useParams();
  const [pdfData, setPdfData] = useState(null);
  const [invoiceData, setInvoiceData] = useState(null);
  const [remainingDays, setRemainingDays] = useState(null);
  const [formattedDate, setFormattedDate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [openCustomerInfo, setOpenCustomerInfo] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  // New state variables for status history and actions
  const [statusHistory, setStatusHistory] = useState([]);
  const [nextAction, setNextAction] = useState(null);
  const [markAsPaidLoading, setMarkAsPaidLoading] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  useEffect(() => {
    fetchInvoice();
    fetchPdf();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, invoiceId]);

  useEffect(() => {
    if (invoiceData) {
      fetchStatusHistory();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceData]);

  const fetchPdf = async () => {
    try {
      const getInvoicePdfApiUrl = getApiUrl(`${companyId}/invoices/${invoiceId}/pdf`);
      const response = await axios.get(getInvoicePdfApiUrl, {
        responseType: 'blob', // Important to handle binary data
        withCredentials: true,
      });
      const file = new Blob([response.data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      setPdfData(fileURL);
    } catch (error) {
      console.error('Error fetching the PDF:', error);
      setError('Kunde inte hämta PDF-filen. Vänligen försök igen senare.');
    }
  };

  const fetchInvoice = async () => {
    try {
      const getInvoiceDetailsApiUrl = getApiUrl(`${companyId}/invoices/${invoiceId}/details`);
      const response = await axios.get(getInvoiceDetailsApiUrl, { withCredentials: true });
      const data = response.data;
      setInvoiceData(data);

      // Calculate remaining days and formatted date
      const inputDate = data.deadline;
      const today = dayjs();
      const futureDate = dayjs(inputDate);

      setRemainingDays(futureDate.diff(today, 'day'));
      const formattedDate = futureDate.format('D MMMM YYYY');
      setFormattedDate(formattedDate);

      // Fetch status history
      //await fetchStatusHistory();

      setLoading(false);
    } catch (error) {
      console.error('Error fetching invoice data:', error);
      setError('Kunde inte hämta fakturadetaljer. Vänligen försök igen senare.');
      setLoading(false);
    }
  };

  const fetchStatusHistory = async () => {
    try {
      const getStatusHistoryApiUrl = getApiUrl(
        `${companyId}/invoices/${invoiceId}/status-history`
      );
      const response = await axios.get(getStatusHistoryApiUrl, { withCredentials: true });
      console.log('Status history:', response.data);
      setStatusHistory(response.data);

      // Determine next action based on current status
      const currentStatus = response.data.currentStatus || invoiceData.status;
      determineNextAction(currentStatus);
    } catch (error) {
      console.error('Error fetching status history:', error);
      setError('Kunde inte hämta statushistorik. Vänligen försök igen senare.');

      // Use invoiceData.status if status history fetch fails
      determineNextAction(invoiceData.status);
    }
  };


  const determineNextAction = (currentStatus) => {

    console.log('Current status:', currentStatus);

    switch (currentStatus) {
      case 'Draft':
        setNextAction({ text: 'Publicera faktura', nextStatus: 'Published' });
        break;
      case 'Published':
        setNextAction({ text: 'Registrera betalning', nextStatus: 'Paid' });
        break;
      case 'Paid':
        setNextAction(null); // No further actions
        break;
      case 'Overdue':
        setNextAction({ text: 'Påminn kund', nextStatus: 'Reminder Sent' });
        break;
      default:
        setNextAction(null);
    }
  };

  const getStatusDisplayName = (status) => {
    const statusNames = {
      Draft: 'Utkast',
      Published: 'Skickad',
      Paid: 'Betald',
      Overdue: 'Förfallen',
      Cancelled: 'Makulerad',
      'Reminder Sent': 'Påminnelse skickad',
    };
    return statusNames[status] || status;
  };

  const getStatusIcon = (status) => {
    const statusIcons = {
      Draft: { icon: faFileAlt },           // Gray color
      Published: { icon: faPaperPlane },    // Blue color
      Paid: { icon: faCheckCircle },        // Green color
      Overdue: { icon: faExclamationCircle }, // Red color
      Cancelled: { icon: faTimesCircle },   // Gray color
      'Reminder Sent': { icon: faBell },    // Yellow color
    };
    return statusIcons[status] || { icon: faFileAlt }; // Default icon
  };


  const handleActionClick = () => {
    if (nextAction.nextStatus === 'Paid') {
      // Open payment modal
      setShowPaymentModal(true);
    } else {
      // Show confirmation modal for other actions
      setShowConfirmModal(true);
    }
  };

  const handleStatusUpdate = async (nextStatus) => {
    setMarkAsPaidLoading(true);
    setError(null);

    try {
      // API endpoint to update the invoice status
      const updateInvoiceStatusApiUrl = getApiUrl(
        `${companyId}/invoices/${invoiceId}/status`
      );

      // Make the API call to update the status
      await axios.put(
        updateInvoiceStatusApiUrl,
        { status: nextStatus },
        { withCredentials: true }
      );

      // Update the invoice state
      setInvoiceData((prevInvoice) => ({
        ...prevInvoice,
        status: nextStatus,
      }));

      // Update status history
      setStatusHistory((prevHistory) => [
        ...prevHistory,
        { status: nextStatus, changed_at: new Date().toISOString() },
      ]);

      // Determine next action
      determineNextAction(nextStatus);

      // Display success message
      setSuccessMessage(`Fakturan har uppdaterats till ${getStatusDisplayName(nextStatus).toLowerCase()}.`);
    } catch (error) {
      console.error('Error updating invoice status:', error);
      setError('Kunde inte uppdatera fakturastatus. Vänligen försök igen senare.');
    } finally {
      setMarkAsPaidLoading(false);
      setShowConfirmModal(false);
    }
  };

  const handlePaymentReceived = () => {
    setShowPaymentModal(true);
  };

  const handleCloseModal = () => {
    setShowPaymentModal(false);
  };

  const handlePaymentSubmit = async (paymentDetails) => {
    try {
      // API call to register payment
      const registerPaymentApiUrl = getApiUrl(`${companyId}/invoices/${invoiceId}/register-payment`);
      await axios.post(registerPaymentApiUrl, paymentDetails, { withCredentials: true });

      // Update invoice status to 'Paid'
      setInvoiceData((prevInvoice) => ({
        ...prevInvoice,
        status: 'Paid',
      }));

      // Update status history
      setStatusHistory((prevHistory) => [
        ...prevHistory,
        { status: 'Paid', changed_at: new Date().toISOString() },
      ]);

      // Determine next action
      determineNextAction('Paid');

      // Close modal and display success message
      setShowPaymentModal(false);
      setSuccessMessage('Betalning registrerad och fakturan är markerad som betald.');
    } catch (error) {
      console.error('Error registering payment:', error);
      setError('Kunde inte registrera betalningen. Vänligen försök igen senare.');
    }
  };

  return (
    <>
      <PageTitle activeMenu="Kundfakturor" motherMenu="Fakturor" />
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: '100vh' }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Laddar...</span>
          </Spinner>
        </div>
      ) : (
        <div className="col-xl-12 mt-4">
          {/* Display error messages */}
          {error && (
            <Alert variant="danger" onClose={() => setError(null)} dismissible>
              {error}
            </Alert>
          )}
          {/* Display success messages */}
          {successMessage && (
            <Alert variant="success" onClose={() => setSuccessMessage(null)} dismissible>
              {successMessage}
            </Alert>
          )}

          {/*<Card className="mb-4">
            <Card.Header>
              <div className="d-flex justify-content-between align-items-center">
                <h3>Faktura {invoiceData.invoicenumber}</h3>
                {nextAction && (
                  <Button
                    variant="primary"
                    onClick={handleActionClick}
                    disabled={markAsPaidLoading}
                  >
                    {markAsPaidLoading ? 'Uppdaterar...' : nextAction.text}
                  </Button>
                )}
              </div>
            </Card.Header>
            <Card.Body>
              <h5>Fakturanummer: {invoiceData.invoicenumber}</h5>
              <p>Skapad: {dayjs(invoiceData.created_at).format('D MMMM YYYY')}</p>
              <p>
                Sista betalningsdag: {formattedDate} (
                {remainingDays > 0 ? `${remainingDays} dagar kvar` : 'Förfallen'})
              </p>
              <p>
                Summa:{' '}
                {new Intl.NumberFormat('sv-SE', {
                  style: 'currency',
                  currency: invoiceData.currency || 'SEK',
                }).format(invoiceData.totals.grand_total)}
              </p>
            </Card.Body>
          </Card>*/}

          <div className='col-xl-12 mb-5' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center-horizontal' }}>
            <h2>Fakturadetaljer</h2>
            <div>
              {nextAction && (
                <div className="mt-4">
                  <Button
                    variant="primary"
                    onClick={() => handleActionClick()}
                    disabled={markAsPaidLoading}
                  >
                    {markAsPaidLoading ? 'Uppdaterar...' : nextAction.text}
                  </Button>
                </div>
              )}
            </div>
          </div>

          {/* Timeline */}
          {/* Timeline */}
          <div className="col-xl-12 col-xxl-12 col-lg-12 mb-4 widget-timeline">
            <ul className="timeline">
              {statusHistory && statusHistory.length > 0 ? (
                statusHistory.map((statusEvent, index) => {
                  const { icon, color } = getStatusIcon(statusEvent.status);
                  return (
                    <li key={index}>
                      <div className="timeline-badge primary"></div>
                      <div className="timeline-panel text-muted">
                        <span>{dayjs(statusEvent.changed_at).format('YYYY-MM-DD HH:mm')}</span>
                        <h6 className="mb-0 d-flex align-items-center">
                          <FontAwesomeIcon icon={icon} color={color} size="lg" />
                          <span className="ms-2">{getStatusDisplayName(statusEvent.status)}</span>
                        </h6>
                      </div>
                    </li>
                  );
                })
              ) : (
                invoiceData && (
                  <ul className="timeline">
                    <li>
                      <div className="timeline-badge primary"></div>
                      <div className="timeline-panel text-muted">
                        <span>{dayjs(invoiceData.created_at).format('YYYY-MM-DD HH:mm')}</span>
                        <h6 className="mb-0 d-flex align-items-center">
                          <FontAwesomeIcon
                            icon={getStatusIcon(invoiceData.status).icon}
                            color={getStatusIcon(invoiceData.status).color}
                            size="lg"
                          />
                          <span className="ms-2">{getStatusDisplayName(invoiceData.status)}</span>
                        </h6>
                      </div>
                    </li>
                  </ul>
                )
              )}
            </ul>
          </div>

          <Card className="mt-4">
            <Card.Body>
              <Row>
                <div className="accordion_body">
                  <div style={{ display: "flex", flexFlow: "wrap", justifyContent: "space-between" }}>
                    <div className="me-3 mb-3">
                      <p className="fs-12 mb-2">Kund</p>
                      <span className="font-w500">
                        <strong>
                          {invoiceData.customername}
                        </strong>
                      </span>
                    </div>
                    <div className="me-3 mb-3">
                      <p className="fs-12 mb-2">Fakturanr.</p>
                      <span className="font-w500">
                        <strong>
                          {invoiceData.invoicenumber}
                        </strong>
                      </span>
                    </div>
                    <div className="me-3 mb-3">
                      <p className="fs-12 mb-2">Fakturadatum</p>
                      <span className="font-w500">
                        <strong>{dayjs(invoiceData.created_at).format('DD MMM YYYY')}
                        </strong>
                      </span>
                    </div>
                    <div className="me-3 mb-3">
                      <p className="fs-12 mb-2">Förfallodatum</p>
                      <span className="font-w500">
                        <strong>{dayjs(invoiceData.deadline).format('DD MMM YYYY')}</strong>
                      </span>
                    </div>
                    <div className="me-3 mb-3">
                      <p className="fs-12 mb-2">Status</p>
                      <span className="font-w500">
                        <strong>
                          <Badge bg="primary">{getStatusDisplayName(invoiceData.status)}</Badge>
                        </strong>
                      </span>
                    </div>
                    <div className="me-3 mb-3">
                      <p className="fs-12 mb-2">Valuta</p>
                      <span className="font-w500">
                        <strong>
                          {invoiceData.currency}
                        </strong>
                      </span>
                    </div>
                    <div className="me-3 mb-3">
                      <p className="fs-12 mb-2">Summa</p>
                      <span className="font-w500">
                        <strong>
                          {new Intl.NumberFormat('sv-SE', {
                            style: 'currency',
                            currency: invoiceData.currency || 'SEK',
                          }).format(invoiceData.totals.grand_total)}
                        </strong>
                      </span>
                    </div>
                  </div>
                </div>

              </Row>


            </Card.Body>
          </Card>

          {/* Invoice PDF */}
          {pdfData ? <PDFViewer fileUrl={pdfData} /> : <Spinner animation="border" />}

          {/* Confirmation Modal */}
          {nextAction && (
            <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
              <Modal.Header closeButton>
                <Modal.Title>Bekräfta åtgärd</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Är du säker på att du vill {nextAction.text.toLowerCase()}?
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
                  Avbryt
                </Button>
                <Button
                  variant="success"
                  onClick={() => handleStatusUpdate(nextAction.nextStatus)}
                  disabled={markAsPaidLoading}
                >
                  {markAsPaidLoading ? 'Uppdaterar...' : 'Ja, ' + nextAction.text.toLowerCase()}
                </Button>
              </Modal.Footer>
            </Modal>
          )}

          {/* Payment Received Modal */}
          {invoiceData && (
            <PaymentReceivedModal
              show={showPaymentModal}
              handleClose={handleCloseModal}
              invoiceData={invoiceData}
              handlePaymentSubmit={handlePaymentSubmit}
            />
          )}
        </div>
      )}
    </>
  );
};

export default InvoiceViewer;
