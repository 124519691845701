import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { Image, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFilePdf,
    faFileWord,
    faFileExcel,
    faFileVideo,
    faFile,
    faTrash,
    faPlus,
} from '@fortawesome/free-solid-svg-icons';

function FileDropZone({ width = '150px', height = '150px', maxFiles = 3, onFileUpload }) {
    const [files, setFiles] = useState([]);

    const { getRootProps, getInputProps } = useDropzone({
        accept:
            'image/*, application/pdf, text/plain, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, video/*',
        onDrop: (acceptedFiles) => {
            const remainingSlots = maxFiles - files.length;

            if (remainingSlots <= 0) {
                alert(`You can only add up to ${maxFiles} files.`);
                return;
            }

            const newFiles = acceptedFiles.slice(0, remainingSlots);

            if (acceptedFiles.length > remainingSlots) {
                alert(`You can only add up to ${maxFiles} files.`);
            }

            const mappedFiles = newFiles.map((file) => {
                const preview = URL.createObjectURL(file);
                return Object.assign(file, { preview });
            });

            setFiles([...files, ...mappedFiles]);

            // Upload each file
            mappedFiles.forEach((file) => {
                onFileUpload(file);
            });
        },
    });

    // Cleanup to revoke object URLs
    useEffect(() => {
        return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
    }, [files]);

    const renderFilePreview = (file) => {
        const commonStyle = {
            width: width,
            height: height,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: '1px solid #ccc',
            borderRadius: '4px',
            overflow: 'hidden',
        };

        if (file.type.startsWith('image/')) {
            return (
                <Image
                    src={file.preview}
                    thumbnail
                    style={{ ...commonStyle, objectFit: 'cover' }}
                />
            );
        } else if (file.type === 'application/pdf') {
            return (
                <div style={commonStyle}>
                    <FontAwesomeIcon
                        icon={faFilePdf}
                        size="3x"
                        style={{ color: '#d9534f' }}
                    />
                </div>
            );
        } else if (file.type === 'text/plain') {
            return (
                <div style={commonStyle}>
                    <pre style={{ fontSize: '10px' }}>{file.preview.slice(0, 100)}...</pre>
                </div>
            );
        } else if (
            file.type.startsWith('application/msword') ||
            file.type ===
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        ) {
            return (
                <div style={commonStyle}>
                    <FontAwesomeIcon
                        icon={faFileWord}
                        size="3x"
                        style={{ color: '#007bff' }}
                    />
                </div>
            );
        } else if (
            file.type ===
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ) {
            return (
                <div style={commonStyle}>
                    <FontAwesomeIcon
                        icon={faFileExcel}
                        size="3x"
                        style={{ color: '#28a745' }}
                    />
                </div>
            );
        } else if (file.type.startsWith('video/')) {
            return (
                <div style={commonStyle}>
                    <FontAwesomeIcon
                        icon={faFileVideo}
                        size="3x"
                        style={{ color: '#dc3545' }}
                    />
                </div>
            );
        } else {
            return (
                <div style={commonStyle}>
                    <FontAwesomeIcon
                        icon={faFile}
                        size="3x"
                        style={{ color: '#6c757d' }}
                    />
                </div>
            );
        }
    };

    const removeFile = (index, event) => {
        event.stopPropagation(); // Prevent the dropzone click from triggering
        const newFiles = [...files];
        URL.revokeObjectURL(newFiles[index].preview); // Revoke URL to free memory
        newFiles.splice(index, 1);
        setFiles(newFiles);
    };

    return (
        <div
            className="file-dropzone-container"
            style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}
        >
            {files.map((file, index) => (
                <div
                    key={index}
                    className="preview-item"
                    style={{
                        position: 'relative',
                    }}
                >
                    {renderFilePreview(file)}
                    <Button
                        variant="link"
                        onClick={(event) => removeFile(index, event)}
                        style={{ position: 'absolute', top: '5px', right: '5px' }}
                    >
                        <FontAwesomeIcon icon={faTrash} style={{ color: '#dc3545' }} />
                    </Button>
                </div>
            ))}
            {files.length < maxFiles && (
                <div
                    {...getRootProps({
                        className: 'dropzone',
                        style: {
                            width: width,
                            height: height,
                            border: '2px dashed #44814e',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                        },
                    })}
                >
                    <input {...getInputProps()} />
                    <FontAwesomeIcon icon={faPlus} size="2x" style={{ color: '#6c757d' }} />
                </div>
            )}
        </div>
    );
}

export default FileDropZone;
