import React, { useMemo, useState, useEffect } from 'react';
import { useTable, useGlobalFilter, useFilters, usePagination } from 'react-table';
import { useParams, Link, useHistory } from 'react-router-dom';
import axios from '../../../../../services/AxiosInstance';
import { GlobalFilter } from './GlobalFilter';
import './filtering.css';
import NewSupplierModal from './NewSupplierModal';
import { Dropdown } from 'react-bootstrap';
import { Pie } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import { Spinner } from 'react-bootstrap';

import { getApiUrl } from '../../../../../utils/ApiUtils';
import { NoData } from '../../../SharedComponents/NoData';
import PageTitle from '../../../../layouts/PageTitle';

export const SuppliersTable = () => {

  const { companyId } = useParams();
  const history = useHistory();

  const SUPPLIERS_COLUMNS = [
    {
      Header: 'Namn',
      Footer: 'Namn',
      accessor: 'name',
    },
    {
      Header: 'Telefonnummer',
      Footer: 'Telefonnummer',
      accessor: 'phone',
    },
    {
      Header: 'Antal fakturor',
      Footer: 'Antal fakturor',
      accessor: 'total_invoices',
    },
    {
      Header: 'Skuld',
      Footer: 'Skuld',
      accessor: 'amount_not_paid',
    },
    {
      Header: "",
      className: "column-right",
      Cell: ({ row }) => (
        <Dropdown className="dropdown ms-auto text-right"
          onClick={(e) => e.stopPropagation()}>
          <Dropdown.Toggle
            variant=""
            className="btn-link i-false border-0 p-0"
            data-toggle="dropdown"
          >
            <svg
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
            >
              <g
                stroke="none"
                strokeWidth={1}
                fill="none"
                fillRule="evenodd"
              >
                <rect x={0} y={0} width={24} height={24} />
                <circle fill="#000000" cx={5} cy={12} r={2} />
                <circle fill="#000000" cx={12} cy={12} r={2} />
                <circle fill="#000000" cx={19} cy={12} r={2} />
              </g>
            </svg>
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
            <Dropdown.Item onClick={() => handleEditSupplier(row.original)}>Redigera leverantör</Dropdown.Item>
            <Dropdown.Item onClick={() => handleDeleteSupplier(row.original)}>Ta bort leverantör</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ),
      id: 'actions',
    },
  ];

  const columns = useMemo(() => SUPPLIERS_COLUMNS, []);

  const [suppliersTableData, setSuppliersTableData] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState(null);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [show, setShow] = useState(false);


  const handleEditSupplier = (supplier) => {
    console.log('Edit clicked:', supplier);
    setSelectedSupplier(supplier);
    setShow(true);
  };

  const handleDeleteSupplier = (supplier) => {
    console.log('Delete clicked:', supplier);
    const deleteSupplierApiUrl = getApiUrl(`${companyId}/purchasing/suppliers/${supplier.supplier_id}`);
    axios.delete(deleteSupplierApiUrl, { withCredentials: true })
      .then((response) => {
        if (response.status === 200) {
          fetchSuppliers();
        } else {
          console.error('Error deleting supplier:', response);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const handleClose = () => setShow(false);

  const handleSave = (newSupplierData) => {
    saveNewSupplier(newSupplierData);
    setShow(false);
  };

  const handleUpdate = (updatedSupplierData) => {
    console.log('Update supplier:', updatedSupplierData);
    const updateSupplierApiUrl = getApiUrl(`${companyId}/purchasing/suppliers/${updatedSupplierData.supplier_id}`);
    axios.put(updateSupplierApiUrl, updatedSupplierData, { withCredentials: true })
      .then((response) => {
        if (response.status === 200) {
          fetchSuppliers();
        } else {
          console.error('Error updating supplier:', response);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleRowClick = (id) => {
    console.log('Row clicked:', id);
    history.push(`/${companyId}/suppliers/${id}`);
  };

  useEffect(() => {
    fetchSuppliers();
  }, []);

  const fetchSuppliers = async () => {
    setLoading(true);
    const getSuppliersApiUrl = getApiUrl(`${companyId}/purchasing/suppliers`);
    axios.get(getSuppliersApiUrl, { withCredentials: true })
      .then((response) => {
        console.log(response.data);
        const processedData = response.data.map(supplier => ({
          ...supplier,
          total_invoices: parseInt(supplier.total_invoices, 10),
          amount_not_paid: parseFloat(supplier.amount_not_paid),
          amount_paid: parseFloat(supplier.amount_paid)
        }));
        setSuppliersTableData(processedData || []);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const saveNewSupplier = (newData) => {
    const newSupplier = {
      name: newData.name,
      orgnumber: newData.orgNumber,
      vatnumber: newData.vatNumber,
      postadress1: newData.address,
      postadress2: '',
      city: newData.city,
      postnumber: newData.postalCode,
      country: newData.country,
      phonenumber: newData.phoneNumber
    };

    console.log('New supplier:', newSupplier);

    const createSupplierApiUrl = getApiUrl(`${companyId}/purchasing/suppliers`);
    axios.post(createSupplierApiUrl, newSupplier, { withCredentials: true })
      .then((response) => {
        if (response.status === 201) {
          fetchSuppliers();
        } else {
          console.error('Error creating supplier:', response);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const totalPaid = suppliersTableData.reduce((sum, supplier) => sum + supplier.amount_paid, 0);
  const totalNotPaid = suppliersTableData.reduce((sum, supplier) => sum + supplier.amount_not_paid, 0);
  const totalInvoices = suppliersTableData.reduce((sum, supplier) => sum + supplier.total_invoices, 0);

  const pieData = {
    labels: suppliersTableData.map(supplier => supplier.name),
    datasets: [{
      data: suppliersTableData.map(supplier => supplier.amount_not_paid),
      backgroundColor: suppliersTableData.map(() => '#' + Math.floor(Math.random() * 16777215).toString(16))
    }]
  };

  const data = useMemo(() => suppliersTableData, [suppliersTableData]);

  const tableInstance = useTable({
    columns,
    data,
    initialState: { pageIndex: 0 }
  }, useFilters, useGlobalFilter, usePagination)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
    setGlobalFilter,
  } = tableInstance


  const { globalFilter, pageIndex } = state

  return (
    <>
      <PageTitle activeMenu="Leverantörer" motherMenu="Fakturor" />
      <div className="col-12">
        {loading ? (
          <div className="vh-100 d-flex justify-content-center align-items-center">
            <Spinner animation="border" role="status" className="me-2">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            <div>Loading...</div>
          </div>
        ) : suppliersTableData.length === 0 ? (
          <>
            <NoData message="Det finns inga leverantörer att visa. Skapa en för att kunna se dem här." />
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              textAlign: 'center',
              padding: '10px'
            }}>
              <Link
                style={{ marginTop: "20px", alignItem: "center" }}
                to={`#`}
                onClick={() => setShow(true)}
                className="btn btn-primary"
              >
                Skapa ny leverantör
              </Link>
            </div>
          </>
        ) : (
          <>
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Leverantörer</h4>

                <Link to={`#`} onClick={() => setShow(true)} className="btn btn-primary">
                  Ny Leverantör
                </Link>

              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
                  <table {...getTableProps()} className="table dataTable table-hover display">
                    <thead>
                      {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()}>
                              {column.render('Header')}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()} className="" >

                      {page.map((row) => {
                        prepareRow(row)
                        return (
                          <tr className='clickable-row' onClick={() => handleRowClick(row.original.supplier_id)} {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                              return <td {...cell.getCellProps()}> {cell.render('Cell')} </td>
                            })}
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="card mt-4">
              <div className="card-header">
                <h4 className="card-title">Skuldfördelning</h4>
              </div>
              <div className="card-body">
                <div style={{ width: '300px', height: '300px' }}>
                  <Pie data={pieData} options={{ maintainAspectRatio: false }} />
                </div>
              </div>
            </div>
          </>
        )}
        <NewSupplierModal
          show={show}
          handleClose={handleClose}
          handleSave={handleSave}
          handleUpdate={handleUpdate}
          initialSupplierData={selectedSupplier}
        />
      </div>
    </>
  );
};

export default SuppliersTable;
