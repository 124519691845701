import React, { useState, useEffect, useCallback } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import axios from '../../../../services/AxiosInstance';
import DeliveryOptions from './components/InvoiceDeliveryOptions'
import FileDropZone from "../../../components/SharedComponents/FileDropZone.js"
import { Row, Card, Col, Table, Button, Modal, Container, Form } from 'react-bootstrap';
import * as Yup from "yup";

import DatePicker from "react-datepicker";
import ProductsAndArticlesCard from './components/ProductAndArticlesCard.js';

import { getApiUrl } from '../../../../utils/ApiUtils.js';

import { parseISO } from 'date-fns';

const initialTasks = [
];

const loginSchema = Yup.object().shape({
  username: Yup.string()
    .min(3, "Your username must consist of at least 3 characters ")
    .max(50, "Your username must consist of at least 3 characters ")
    .required("Please enter a username"),
  password: Yup.string()
    .min(5, "Your password must be at least 5 characters long")
    .max(50, "Your password must be at least 5 characters long")
    .required("Please provide a password"),
});



const EditInvoiceDepricated = () => {

  const [items, setItems] = useState([]);
  const [inputValue, setValue] = useState('');
  const [invoiceDate, setInvoiceDate] = useState(null);
  const [invoiceDeadline, setInvoiceDeadline] = useState(null);
  const [articles, setArticles] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const { companyId, invoiceId } = useParams();
  const [invoiceData, setInvoiceData] = useState(null);
  const [files, setFiles] = useState([]);
  const [invoiceAttachments, setInvoiceAttachments] = useState([]);
  const [deliveryOption, setDeliveryOption] = useState({ method: '', email: '' });
  const [tasks, setTasks] = useState(initialTasks);
  const history = useHistory();

  useEffect(() => {
    // Make an API request when the component mounts
    const getInvoiceDetailsApiUrl = getApiUrl(`${companyId}/invoices/${invoiceId}/details`);
    axios.get(getInvoiceDetailsApiUrl, { withCredentials: true })
      .then(response => {
        console.log('Invoice data:', response.data);
        setInvoiceData(response.data);
        setItems(response.data.items);
        setInvoiceAttachments(response.data.attachments);
        setSelectedCustomerValue(response.data);
        setInvoiceDate(new Date(response.data.invoicedate));
        setInvoiceDeadline(new Date(response.data.deadline));
      })
      .catch(error => {
        console.error('Error fetching invoice data:', error);
      });

    fetchArticles().then((res) => {
      console.log("Articles: ", res);
      setArticles(res.data);
    });

  }, []); // The empty array [] ensures the effect runs only once, similar to componentDidMount

  const [selectedCustomerValue, setSelectedCustomerValue] = useState(null);
  const [selectedArticleValue, setSelectedArticleValue] = useState(null);

  const fetchArticles = () => {
    const fetchArticlesApiUrl = getApiUrl(`${companyId}/articles`);
    return axios.get(fetchArticlesApiUrl, { withCredentials: true }).then(result => {
      const res = result.data;
      console.log(res);
      return res;
    });
  }

  const fetchInvoiceDetails = (invoiceid) => {
    const getInvoiceApiUrl = getApiUrl(`${companyId}/invoices/${invoiceid}`);
    axios.get(getInvoiceApiUrl, { withCredentials: true }).then(res => {
      setInvoiceData(res.data);
      setItems(res.data.items);
      setInvoiceDate(parseISO(res.data.invoicedate));
      setInvoiceDeadline(parseISO(res.data.deadline));
    });
  };

  const onDragEnd = (result) => {
    if (!result.destination)
      return;

    const items = Array.from(tasks);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setTasks(items);
  };

  const addTask = () => {
    const newTask = {
      id: `${tasks.length + 1}`,
      title: `Task ${tasks.length + 1}`,
    };
    setTasks([...tasks, newTask]);
  };

  const [addRowModalEnabled, setAddRowModalEnabled] = useState(false);

  const [selectedOption, setSelectedOption] = useState(null);

  const handleSave = () => {
    //onSave(selectedOptions);
    //closeModal();
  };

  const handleDateChange = (date) => {
    setInvoiceDate(date);
    const updateDatesApiUrl = getApiUrl(`${companyId}/invoices/updatedates`);
    axios.post(updateDatesApiUrl, {
      invoiceId: invoiceId,
      invoicedate: date,
      deadline: invoiceDeadline
    }, { withCredentials: true })
      .then((response) => {
        console.log(response.data);
        setInvoiceData(response.data);
        setItems(response.data.items);
        setSelectedCustomerValue(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleDeadlineChange = (date) => {
    setInvoiceDeadline(date);
    const updateDatesApiUrl = getApiUrl(`${companyId}/invoices/updatedates`);
    axios.post(updateDatesApiUrl, {
      invoiceId: invoiceId,
      deadline: date,
      invoicedate: invoiceDate
    }, { withCredentials: true })
      .then((response) => {
        console.log(response.data);
        setInvoiceData(response.data);
        setItems(response.data.items);
        setSelectedCustomerValue(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleCheckChange = (e) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      console.log('The checkbox is checked!');
    } else {
      console.log('The checkbox is not checked.');
    }

    const updateRoundingApiUrl = getApiUrl(`${companyId}/invoices/updaterounding`);
    axios.post(updateRoundingApiUrl, {
      invoiceId: invoiceId,
      disable_rounding: isChecked ? 0 : 1
    }, { withCredentials: true })
      .then((response) => {
        console.log(response.data);
        setInvoiceData(response.data);
        setItems(response.data.items);
        setSelectedCustomerValue(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };



  const handleAddInvoiceItem = (item, amount) => {
    addInvoiceItem(item, amount);
  };

  const handleEditInvoiceItem = (item) => {
    editInvoiceItem(item);
  };

  const handleDeleteInvoiceItem = (item) => {
    deleteInvoiceItem(item);
  };

  const addInvoiceItem = (item, amount, hasChanged) => {
    const addInvoiceItemApiUrl = getApiUrl(`${companyId}/invoices/${invoiceId}/items`);

    axios.post(addInvoiceItemApiUrl, {
      articleid: hasChanged ? '' : item.id,
      quantity: amount,
      productcode: item.productcode,
      description: item.description,
      unitprice: item.unitprice,
      tax_rate: item.tax_rate,
      unittype: item.unittype,
      unit: item.unit,
    }, { withCredentials: true })
      .then(res => {
        if (res.data.success) {
          // Fetch the full invoice details after successfully adding the item
          fetchInvoiceDetails(invoiceId);  // A separate function that calls the API to get the full invoice details
        } else {
          console.error('Failed to add invoice item');
        }
      })
      .catch(err => {
        console.error('Error adding invoice item:', err);
      });
  };

  const editInvoiceItem = (item) => {
    console.log("editInvoiceItem called ", item);
    const editInvoiceItemApiUrl = getApiUrl(`${companyId}/invoices/${invoiceId}/items/${item.item_id}`);

    axios.put(editInvoiceItemApiUrl, {
      itemid: item.item_id,
      quantity: item.quantity,
      productcode: item.productcode,
      description: item.description,
      unitprice: item.unitprice,
      tax_rate: item.tax_rate,
      unittype: item.unittype,
      unit: item.unit,
    }, { withCredentials: true })
      .then(res => {
        if (res.data.success) {
          console.log('Item updated successfully');
          // Fetch the full invoice details after the item is successfully edited
          fetchInvoiceDetails(invoiceId);
        } else {
          console.error('Failed to update invoice item:', res.data.message);
        }
      })
      .catch(err => {
        console.error('Error updating invoice item:', err);
      });
  };


  const deleteInvoiceItem = (item) => {
    console.log("deleteInvoiceItem called for item ID: " + item.itemid);

    const deleteInvoiceItemApiUrl = getApiUrl(`${companyId}/invoices/${invoiceId}/items/${item.itemid}`);

    axios.delete(deleteInvoiceItemApiUrl, { withCredentials: true })
      .then((res) => {
        console.log(res);
        if (res.data.success) {
          // After successful deletion, make a call to fetch updated invoice details
          fetchInvoiceDetails(invoiceId);
        } else {
          console.error('Error deleting item:', res.data.message);
        }
      })
      .catch((err) => {
        console.error('Error deleting item:', err);
      });
  };


  const handleRoundingCheckChange = (e) => {
    const isChecked = e.target.checked;
    const updateRoundingApiUrl = getApiUrl(`${companyId}/invoices/${invoiceId}/rounding`);

    axios.put(updateRoundingApiUrl, {
      invoiceId: invoiceId,
      disable_rounding: isChecked ? 0 : 1
    }, { withCredentials: true })
      .then((response) => {
        if (response.data.success) {
          fetchInvoiceDetails(invoiceId);
        } else {
          console.error('Failed to update rounding');
        }
      })
      .catch((error) => {
        console.error('Error updating rounding:', error);
      });
  };

  const handlePublish = () => {
    const publishInvoiceApiUrl = getApiUrl(`${companyId}/invoices/${invoiceId}/publish`);

    // Check if the delivery method is selected
    if (!deliveryOption.method) {
      alert("Please select a delivery option.");
      return;
    }

    console.log("Publishing invoice with method:", deliveryOption.method, "and email:", deliveryOption.email);

    // Make the API call with the selected option and email if needed
    axios.post(publishInvoiceApiUrl, {
      invoiceId: invoiceId,
      action: deliveryOption.method, // This could be 'email' or 'download'
      email: deliveryOption.method === 'email' ? deliveryOption.email : undefined, // Pass email only if the 'email' option is selected
    }, {
      withCredentials: true,
      responseType: deliveryOption.method === 'download' ? 'blob' : 'json',  // Set response type for file download
    })
      .then(response => {
        if (deliveryOption.method === 'download') {
          // Handle file download
          const blob = new Blob([response.data], { type: 'application/pdf' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'invoice.pdf';
          link.click();

          // After the download is triggered, navigate away from the page
          history.push(`/${companyId}/invoices`);
        } else {
          // If it's an email, handle the response (maybe redirect after successful email)
          console.log(response.data);
          history.push(`/${companyId}/invoices`);
        }
      })
      .catch(error => {
        console.log('Error publishing invoice:', error);
      });
  };


  const handleFileUpload = async (file) => {

    console.log('Uploading file:', file);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('relatedId', invoiceData.invoiceid);
    formData.append('relatedType', "invoice");

    const uploadAttachmentApiUrl = getApiUrl(`${companyId}/invoices/${invoiceId}/addattachment`);

    try {
      const response = await axios.post(uploadAttachmentApiUrl, formData, {
        withCredentials: true,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response.data.success) {
        alert(`File ${file.name} uploaded successfully`);
      } else {
        alert(`Failed to upload file ${file.name}`);
      }
    } catch (error) {
      console.error(`Failed to upload file ${file.name}:`, error);
      alert(`Failed to upload file ${file.name}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileDelete = async (attachment_id) => {
    try {
      const deleteFileAttachmentApiUrl = getApiUrl(`${companyId}/invoices/${invoiceId}/removeattachment/${attachment_id}`);
      const response = await axios.post(deleteFileAttachmentApiUrl, {}, { withCredentials: true });
      if (response.data.success) {
        console.log(`File ${attachment_id} deleted successfully`);
      } else {
        console.error(`Failed to delete file ${attachment_id}`);
      }
    } catch (error) {
      console.error(`Error deleting file ${attachment_id}:`, error);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <h4 className="fs-24 font-w800">Kund</h4>
              <div className="row  border-bottom pb-4 mb-4">
                <div className="col-xl-3">
                  <span className="d-block fs-18 font-w600">
                    {invoiceData && invoiceData.customername}
                  </span>
                  { }
                </div>
                <div className="col-xl-3">
                  <div className="d-flex">
                    <div className="me-4">
                      <i className="fas fa-map-marker-alt text-primary fs-18 mt-1"></i>
                    </div>
                    <div>
                      <span className="mb-2 font-w600">ADDRESS</span>
                      <span className="d-block fs-18 font-w600">
                        {selectedCustomerValue && selectedCustomerValue.street1}<br />
                        {selectedCustomerValue && selectedCustomerValue.postalcode} {selectedCustomerValue && selectedCustomerValue.city}<br />
                        {selectedCustomerValue && selectedCustomerValue.Country}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3">
                  <div className="d-flex">
                    <div className="me-4">
                      <i className="fas fa-envelope fs-18 mt-1 text-primary"></i>
                    </div>
                    <div>
                      <span className="mb-2 font-w600">Kontakt</span>
                      <span className="d-block fs-18 font-w600">
                        {selectedCustomerValue && selectedCustomerValue.contactname}<br />
                        {selectedCustomerValue && selectedCustomerValue.email}<br />
                        {selectedCustomerValue && selectedCustomerValue.phone}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3">
                  <div className="d-flex">
                    <div className="me-4">
                      <i className="fas fa-phone-alt fs-18 mt-1 text-primary"></i>
                    </div>
                    <div>
                      <span className="mb-2 font-w600">Företagsdetaljer</span>
                      <span className="d-block fs-18 font-w600">
                        Org. Nr. {selectedCustomerValue && selectedCustomerValue.orgnumber}<br />
                        VAT. Nr. {selectedCustomerValue && selectedCustomerValue.orgnumber}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-12">
          <ProductsAndArticlesCard
            invoiceData={invoiceData}
            articles={articles}
            items={items}
            handleAddInvoiceItem={handleAddInvoiceItem}
            handleEditInvoiceItem={handleEditInvoiceItem}
            handleDeleteInvoiceItem={handleDeleteInvoiceItem}
            handleCheckChange={handleRoundingCheckChange}
          />
        </div>

        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">

              <h4 className="fs-24 font-w800">Vilkor</h4>
              <div className="row mb-4">
                <div className="col-md-6 mb-3">
                  <div className="example rangeDatePicker">
                    <p className="mb-1">Fakturadatum</p>
                    <DatePicker className='form-control' dateFormat='yyyy-MM-dd' selected={invoiceDate} onChange={(date) => handleDateChange(date)} />
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="example rangeDatePicker">
                    <p className="mb-1">Förfallodatum</p>
                    <DatePicker className='form-control' dateFormat='yyyy-MM-dd' selected={invoiceDeadline} onChange={(date) => handleDeadlineChange(date)} />
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-md-3 mb-3">
                  <div className="mb-3">
                    <p className="fs-14 mb-2">Leverans</p>
                    <span className="text-black me-2 font-w700"> Fritt vårt lager </span>
                  </div>
                </div>
                <div className="col-md-3 mb-3">
                  <div className="mb-3">
                    <p className="fs-14 mb-2">Dröjsmålsränta</p>
                    <span className="text-black me-2 font-w700"> 12 % </span>
                  </div>
                </div>
                <div className="col-md-3 mb-3">
                  <div className="mb-3">
                    <p className="fs-14 mb-2">Betalningsvilkor</p>
                    <span className="text-black me-2 font-w700"> 30 dagar </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">

              <h4 className="fs-24 font-w800">Tillägsinformation</h4>

              <h4 className="fs-24 font-w800 mt-5">Bifoga fil</h4>
              <div className="row mt-4 ">
                <div className="col-xl-12">
                  <div className="dropzone dropzone-multi dz-dropzone-box d-flex" id="kt_dropzone_5">
                    <FileDropZone
                      setFiles={setFiles}
                      mainText="Dra och släpp filer inom det sträckade området for att lägga till bilagor till ding faktura"
                      minHeight='150px'
                      autoUpload={true}
                      handleUpload={handleFileUpload}
                      handleFileDelete={handleFileDelete}
                      predefinedFiles={invoiceAttachments.map(attachment => ({ id: attachment.attachment_id, name: attachment.file_name, size: 10 }))} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">

              <h4 className="fs-24 font-w800">Leveransmetod</h4>
              <div className="row mb-4">
                <DeliveryOptions onDeliveryOptionChange={setDeliveryOption} />
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-6">
          <div className="text-begin mt-4">
            <Link to={`#`} onClick={(event) => handlePublish(event)} className="btn btn-primary btn me-1 me-sm-3">Publicera</Link>
            <Link to={`#`} className="btn btn-primary light btn me-sm-3">Förhandsgranska</Link>
          </div>
        </div>

        <Modal size="lg" className="fade" show={addRowModalEnabled} onHide={() => setAddRowModalEnabled(false)}>
          <Modal.Header>
            <Modal.Title>Lägg till ny rad</Modal.Title>
            <Button variant="" className="btn-close" onClick={() => setAddRowModalEnabled(false)}></Button>
          </Modal.Header>
          <Modal.Body>
            <AsyncSelect
              cacheOptions
              defaultOptions
              value={selectedArticleValue}
              getOptionLabel={e => e.produktkod + ' ' + e.artikelnamn}
              getOptionValue={e => e.id}
              loadOptions={fetchArticles}
              onInputChange={() => console.log('input change')}
              onChange={() => console.log('change')}
            />
            <br />
            {selectedArticleValue ? <AdditionalSection selectedOption={selectedArticleValue} /> : null}
            <br />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger light" onClick={() => setAddRowModalEnabled(false)}>Close</Button>
            <Button variant="primary" onClick={() => setAddRowModalEnabled(false)}>Save changes</Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  )
}

function AdditionalSection({ selectedOption }) {

  console.log(selectedOption);
  var optionPrice = 0;
  if (selectedOption) {
    optionPrice = selectedOption.pris;
    console.log('the option price is ' + optionPrice);
  }

  const [amount, setAmount] = useState(parseInt(1));
  const [sum, setSum] = useState(optionPrice * amount);

  const handleChangeAmount = (e) => {
    // Ensure that the input value is always a positive integer
    console.log("e says " + e.target.value);
    const inputValue = parseInt(e.target.value);
    console.log("after parse " + e.target.value);
    if (!isNaN(inputValue) && inputValue > 0) {
      setAmount(inputValue);
      setSum(inputValue * optionPrice);
    }
  };

  const handleChangeUnitPrice = (e) => {
  };

  console.log('the sum is ' + optionPrice * amount);

  return (
    <div>
      {selectedOption ? (
        <div>
          {/* Input field for additional input */}
          <Container className='m-0 p-0'>
            <Row>
              <Col md="6">
                <label>
                  Antal
                </label>
                <input
                  type="number"
                  className="form-control input-default "
                  placeholder="input-default"
                  value={amount}
                  onChange={handleChangeAmount}
                />
              </Col>
              <br />
              <Col md="6">
                <label>
                  Pris / Enhet (ex. moms)
                </label>
                <input
                  type="number"
                  className="form-control input-default "
                  placeholder="input-default"
                  value={optionPrice}
                  onChange={handleChangeUnitPrice}
                />
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <div className="mb-3 me-3">
                  <label>
                    Totalt (ex. moms)
                  </label>
                  {console.log(sum)}
                  <p className="fs-18 font-w100">{sum}</p>
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3 me-3">
                  <label>
                    Totalt (ink. Moms)
                  </label>
                  <p className="fs-22 font-w400">{sum * 1.25}</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      ) : null}
    </div>
  );
};

export default EditInvoiceDepricated; 