import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

const CreatePayrollModal = ({ show, handleClose, handleSave }) => {
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const handleSaveClick = () => {
    handleSave(selectedDate);
    handleClose();
  };

  return (
    <Modal className='fade bd-example-modal-lg' size='lg' show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Ny lönekörning</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='col-lg-12'>
          <div className='row'>
            <div className="col-lg-4">
              <div>
                Välje det datum då löner kommer att betalas ut.
              </div>
            </div>
            <div className="col-xl-4">
              <div className="form-group mb-3 invoice">
                <label>Utbetalningsdatum</label>
                <input size="16" type="date" className="form-control" />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSaveClick}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

/*
<label>Select Date for Salary Payout:</label>
                <input
                    type="date"
                    value={selectedDate}
                    onChange={handleDateChange}
                />
*/

export default CreatePayrollModal;
