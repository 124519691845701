import React, { useState } from 'react';
import { Worker, Viewer, SpecialZoomLevel, DocumentLoadEvent } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { Button, Row, Col } from 'react-bootstrap';

const PDFViewer = ({ fileUrl }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [numPages, setNumPages] = useState(null);

  const handleDocumentLoad = (doc) => {
    console.log(`Number of pages: ${doc.doc.numPages}`);
    setNumPages(doc.doc.numPages);
    setCurrentPage(1);
  };

  const onPageChange = (e) => {
    setCurrentPage(e.pageIndex + 1); // Since e.pageIndex is 0-based
  };


  return (
    <div>
      <Row className="mb-3">
        <Col className="d-flex justify-content-center">
          {numPages > 1 && (
            <>
              <Button
                disabled={currentPage === 1}
                onClick={() => setCurrentPage(prev => prev - 1)}
                className="me-2"
              >
                Previous
              </Button>
              <span>Page {currentPage} of {numPages}</span>
              <Button
                disabled={currentPage === numPages}
                onClick={() => setCurrentPage(prev => prev + 1)}
                className="ms-2"
              >
                Next
              </Button>
            </>
          )}
        </Col>
      </Row>
      <Worker workerUrl={`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js`}>
        <div style={{ height: '100%' }}>
          <Viewer
            fileUrl={fileUrl}
            defaultScale={SpecialZoomLevel.PageWidth}
            onDocumentLoad={handleDocumentLoad}
            currentPage={currentPage}
            onPageChange={(page) => onPageChange(page)}
          />
        </div>
      </Worker>
    </div>
  );
};

export default PDFViewer;
