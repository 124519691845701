import React, { useState, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import axios from '../../../../services/AxiosInstance';

import { getApiUrl } from "../../../../utils/ApiUtils";

function TemplateSelection({ eventType, onNext, companyId }) {
    const [templates, setTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(null);

    useEffect(() => {
        fetchTemplates();
    }, [eventType, companyId]);

    async function fetchTemplates() {
        const getTemplatesApiUrl = getApiUrl(`${companyId}/templates`);
        const response = await axios.get(getTemplatesApiUrl, { withCredentials: true });
        setTemplates(response.data.templates);
    }

    const handleTemplateSelect = (template) => {
        console.log("Template selected: ", template);
        setSelectedTemplate(template);
    };

    const handleNext = () => {
        if (selectedTemplate) {
            onNext(selectedTemplate);
        }
    };

    const handleDoManual = () => {
        console.log("Do manual");
        onNext(null);
    };

    return (
        <div>
            <PerfectScrollbar style={{ maxHeight: '400px', border: '1px solid #ccc', borderRadius: '5px', padding: '10px' }}>
                <ul className="list-unstyled">
                    {templates.map((template) => (
                        <li
                            key={template.template_id}
                            className={`d-flex justify-content-between align-items-center p-2 border-bottom ${selectedTemplate === template ? 'bg-light' : ''}`}
                            onClick={() => handleTemplateSelect(template)}
                            style={{ cursor: 'pointer' }}
                        >
                            <div>
                                <strong>{template.template_name}</strong>
                                <p className="mb-0 text-muted">{template.description}</p>
                            </div>
                            <Form.Check
                                type="radio"
                                name="template"
                                checked={selectedTemplate === template}
                                onChange={() => handleTemplateSelect(template)}
                            />
                        </li>
                    ))}
                </ul>
            </PerfectScrollbar>
            <Button onClick={handleNext} disabled={!selectedTemplate} className="mt-3">
                Next
            </Button>
            <Button variant="link" onClick={() => handleDoManual()} className="mt-3">Bokför manuellt </Button>
        </div>
    );
}

export default TemplateSelection;
