import React, { useEffect, useState } from 'react';
import { Card, Table, Badge, Button, Form } from 'react-bootstrap';
import axios from '../../../services/AxiosInstance';
import { useParams, useHistory } from 'react-router-dom';
import swal from "sweetalert";
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { getApiUrl } from '../../../utils/ApiUtils';
import PageTitle from '../../layouts/PageTitle';
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import sv from 'date-fns/locale/sv';

const FiscalYearDetails = () => {
  const { companyId, fiscalYearId } = useParams();
  const [fiscalYear, setFiscalYear] = useState({});
  const [openingBalances, setOpeningBalances] = useState([]);
  const [newOpeningLiabilityBalances, setNewOpeningLiabilityBalances] = useState([]);
  const [liabilityAccountsOptions, setLiabilityAccountsOptions] = useState([]);
  const [newOpeningAssetsBalances, setNewOpeningAssetsBalances] = useState([]);
  const [assetsAccountsOptions, setAssetsAccountsOptions] = useState([]);
  const [assets, setAssets] = useState([]);
  const [totalAssets, setTotalAssets] = useState(0);
  const [totalLiabilitiesEquity, setTotalLiabilitiesEquity] = useState(0);
  const [liabilities, setLiabilities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [closingDate, setClosingDate] = useState(null);
  const [selectedAccountingMethod, setSelectedAccountingMethod] = useState(null);
  const [selectedVatPeriod, setSelectedVatPeriod] = useState(null);
  const history = useHistory();
  const [deletedBalances, setDeletedBalances] = useState([]);

  const accountingMethodOptions = [
    { value: 'accrual', label: 'Fakturametoden' },
    { value: 'cash', label: 'Kontantmetoden' }
  ];

  const vatPeriodOptions = [
    { value: 'monthly', label: 'Varje månad' },
    { value: 'quarterly', label: 'En gång per kvartal' },
    { value: 'yearly', label: 'En gång per år' }
  ];

  useEffect(() => {
    const fetchData = async () => {
      await fetchFiscalYearDetails();

      const liabilityOptions = await fetchAccountsOptions('Eget kapital och skulder');
      setLiabilityAccountsOptions(liabilityOptions);

      const assetOptions = await fetchAccountsOptions('Tillgångar');
      setAssetsAccountsOptions(assetOptions);
    };

    fetchData();
  }, [companyId, fiscalYearId]);

  const fetchAccountsOptions = async (type) => {
    try {
      const result = await axios.get(getApiUrl(`${companyId}/accounting/accounts?accountType=${type}`), { withCredentials: true });
      return result.data.map(account => ({ value: account.account_id, label: `${account.account_number} - ${account.account_name}` }));
    } catch (error) {
      console.error('Error fetching accounts options:', error);
    }
  };

  const calculateTotalOpeningBalance = (balances) => {
    return balances.reduce((acc, balance) => acc + parseFloat(balance.opening_balance), 0);
  };

  const fetchFiscalYearDetails = async () => {
    try {
      const result = await axios.get(getApiUrl(`${companyId}/accounting/fiscalyears/${fiscalYearId}`), { withCredentials: true });

      const fiscalYearData = result.data.fiscal_year;
      const openingBalancesData = result.data.opening_balances;

      setFiscalYear(fiscalYearData);
      setOpeningBalances(openingBalancesData);

      const assetsBalances = openingBalancesData.filter(balance => balance.account_number < 2000);
      const liabilitiesEquityBalances = openingBalancesData.filter(balance => balance.account_number >= 2000);

      setTotalAssets(calculateTotalOpeningBalance(assetsBalances));
      setTotalLiabilitiesEquity(calculateTotalOpeningBalance(liabilitiesEquityBalances));

      setAssets(assetsBalances);
      setLiabilities(liabilitiesEquityBalances);

      setStartDate(new Date(fiscalYearData.start_date));
      setEndDate(new Date(fiscalYearData.end_date));
      setClosingDate(fiscalYearData.closing_date ? new Date(fiscalYearData.closing_date) : null);
      setSelectedAccountingMethod(accountingMethodOptions.find(option => option.value === fiscalYearData.accounting_method));
      setSelectedVatPeriod(vatPeriodOptions.find(option => option.value === fiscalYearData.vat_period));
    } catch (error) {
      swal({
        title: "Error",
        text: "Failed to fetch fiscal year details",
        icon: "error",
        button: "OK",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    try {
      const data = {
        start_date: startDate.toISOString().split('T')[0],
        end_date: endDate.toISOString().split('T')[0],
        accounting_method: selectedAccountingMethod.value,
        vat_period: selectedVatPeriod.value,
      };

      await axios.put(getApiUrl(`${companyId}/accounting/fiscalyears/${fiscalYearId}`), data, { withCredentials: true });

      swal({
        title: "Success",
        text: "Fiscal year updated successfully",
        icon: "success",
        button: "OK",
      });

      fetchFiscalYearDetails();
    } catch (error) {
      swal({
        title: "Error",
        text: "Failed to update fiscal year",
        icon: "error",
        button: "OK",
      });
    }
  }

  const handleDelete = async () => {
    swal({
      title: "Are you sure?",
      text: "Once deleted the fiscal year cannot be recovered, this will delete all data related to the fiscal year!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          await axios.delete(getApiUrl(`${companyId}/accounting/fiscalyears/${fiscalYearId}`), { withCredentials: true });

          swal({
            title: "Success",
            text: "Fiscal year deleted successfully",
            icon: "success",
            button: "OK",
          });

          history.push(`/${companyId}/settings`);
        } catch (error) {
          swal({
            title: "Error",
            text: "Failed to delete fiscal year",
            icon: "error",
            button: "OK",
          });
        }
      }
    });
  };

  const handleDateChange = (setDate) => (date) => {
    // Adjust for timezone differences
    const adjustedDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    setDate(adjustedDate);
  };

  const saveOpeningBalances = async () => {
    // Implement save logic here as required
    // Sum the existing and new opening balances for assets and liabilities
    const totalExistingAssets = assets.reduce((acc, balance) => acc + parseFloat(balance.opening_balance || 0), 0);
    const totalExistingLiabilities = liabilities.reduce((acc, balance) => acc + parseFloat(balance.opening_balance || 0), 0);

    const totalNewAssets = newOpeningAssetsBalances.reduce((acc, balance) => acc + parseFloat(balance.opening_balance || 0), 0);
    const totalNewLiabilities = newOpeningLiabilityBalances.reduce((acc, balance) => acc + parseFloat(balance.opening_balance || 0), 0);

    const totalAssets = totalExistingAssets + totalNewAssets;
    const totalLiabilities = totalExistingLiabilities + totalNewLiabilities;

    console.log('Total Assets:', totalAssets);
    console.log('Total Liabilities:', totalLiabilities);

    if (Math.abs(totalAssets) !== Math.abs(totalLiabilities)) {
      swal({
        title: "Error",
        text: "Total assets and total liabilities must be equal.",
        icon: "error",
        button: "OK",
      });
      return;
    }

    try {
      // Filter out entries that are not set (e.g., account_id is empty or opening_balance is zero)
      const validOpeningBalances = newOpeningAssetsBalances.concat(newOpeningLiabilityBalances).filter(
        balance => balance.account_id && balance.opening_balance !== 0
      );

      const data = {
        openingBalances: validOpeningBalances,
        deletedBalances: deletedBalances // Send deleted balances to the backend
      };

      const response = await axios.post(getApiUrl(`${companyId}/accounting/fiscalyears/${fiscalYearId}/opening-balances`),
        data,
        { withCredentials: true });

      if (response.data.success) {
        swal({
          title: "Success",
          text: "Opening balances saved successfully",
          icon: "success",
          button: "OK",
        });

        setNewOpeningAssetsBalances([]);
        setNewOpeningLiabilityBalances([]);
        setDeletedBalances([]); // Reset deleted balances after successful save

        // Refresh the fiscal year details
        fetchFiscalYearDetails();
      }
    } catch (error) {
      console.error('Error saving opening balances:', error);
      swal({
        title: "Error",
        text: "Failed to save opening balances",
        icon: "error",
        button: "OK",
      });
    }
  };

  // Function to remove a fetched asset balance
  const removeFetchedAssetBalance = (balance, index) => {
    const updatedAssets = assets.filter((_, i) => i !== index);
    setAssets(updatedAssets);

    // Recalculate total assets
    const totalAssets = updatedAssets.reduce((acc, balance) => acc + parseFloat(balance.opening_balance || 0), 0);
    setTotalAssets(totalAssets + newOpeningAssetsBalances.reduce((acc, balance) => acc + parseFloat(balance.opening_balance || 0), 0));

    setDeletedBalances([...deletedBalances, balance.account_id]);
  };

  // Function to handle asset account change
  const handleAssetAccountChange = (index, selectedOption) => {
    const updatedBalances = [...newOpeningAssetsBalances];
    const [account_number, account_name] = selectedOption.label.split(' - ');
    updatedBalances[index].account_id = selectedOption.value;
    updatedBalances[index].account_name = account_name;
    updatedBalances[index].account_number = account_number;
    setNewOpeningAssetsBalances(updatedBalances);
  };

  // Function to handle asset balance change
  const handleAssetBalanceChange = (index, event) => {
    const updatedBalances = [...newOpeningAssetsBalances];
    updatedBalances[index].opening_balance = event.target.value;
    setNewOpeningAssetsBalances(updatedBalances);

    // Recalculate total assets
    const totalNewAssets = updatedBalances.reduce((acc, balance) => acc + parseFloat(balance.opening_balance || 0), 0);
    const totalAssets = totalNewAssets + assets.reduce((acc, balance) => acc + parseFloat(balance.opening_balance || 0), 0);
    setTotalAssets(totalAssets);
  };

  // Function to remove a new asset balance row
  const removeNewAssetBalanceRow = (index) => {
    const updatedBalances = newOpeningAssetsBalances.filter((_, i) => i !== index);
    setNewOpeningAssetsBalances(updatedBalances);
  };

  // Function to add a new asset balance row
  const addNewAssetsBalanceRow = () => {
    setNewOpeningAssetsBalances([...newOpeningAssetsBalances, { account_id: '', account_name: '', opening_balance: 0 }]);
  };

  // Function to remove a fetched liability balance
  const removeFetchedLiabilityBalance = (balance, index) => {
    const updatedLiabilities = liabilities.filter((_, i) => i !== index);
    setLiabilities(updatedLiabilities);

    // Recalculate total liabilities
    const totalLiabilities = updatedLiabilities.reduce((acc, balance) => acc + parseFloat(balance.opening_balance || 0), 0);
    setTotalLiabilitiesEquity(totalLiabilities + newOpeningLiabilityBalances.reduce((acc, balance) => acc + parseFloat(balance.opening_balance || 0), 0));

    setDeletedBalances([...deletedBalances, balance.account_id]);
  };

  // Function to handle liability account change
  const handleLiabilityAccountChange = (index, selectedOption) => {
    const updatedBalances = [...newOpeningLiabilityBalances];
    const [account_number, account_name] = selectedOption.label.split(' - ');
    updatedBalances[index].account_id = selectedOption.value;
    updatedBalances[index].account_name = account_name;
    updatedBalances[index].account_number = account_number;
    setNewOpeningLiabilityBalances(updatedBalances);
  };

  // Function to handle liability balance change
  const handleLiabilityBalanceChange = (index, event) => {
    const updatedBalances = [...newOpeningLiabilityBalances];
    updatedBalances[index].opening_balance = event.target.value;
    setNewOpeningLiabilityBalances(updatedBalances);

    // Recalculate total liabilities and equity
    const totalNewLiabilities = updatedBalances.reduce((acc, balance) => acc + parseFloat(balance.opening_balance || 0), 0);
    const totalLiabilities = totalNewLiabilities + liabilities.reduce((acc, balance) => acc + parseFloat(balance.opening_balance || 0), 0);
    setTotalLiabilitiesEquity(totalLiabilities);
  };

  // Function to remove a new liability balance row
  const removeNewLiabilityBalanceRow = (index) => {
    const updatedBalances = newOpeningLiabilityBalances.filter((_, i) => i !== index);
    setNewOpeningLiabilityBalances(updatedBalances);
  };

  // Function to add a new liability balance row
  const addNewLiabilityBalanceRow = () => {
    setNewOpeningLiabilityBalances([...newOpeningLiabilityBalances, { account_id: '', account_name: '', opening_balance: 0 }]);
  };


  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <PageTitle activeMenu="Räkenskapsår" motherMenu="Bokföring" />
      <div className='col-xl-12'>
        <Card>
          <Card.Header>
            <div className='float-right'>
              <h4>{fiscalYear.year}</h4>
              <Badge variant={fiscalYear.state === 'open' ? 'success' : 'secondary'}>
                {fiscalYear.state === 'open' ? 'Öppen' : 'Stängd'}
              </Badge>
            </div>
          </Card.Header>
          <Card.Body>
            <div className='col-xl-12'>
              <div className='row'>
                <div className='col-sm-3 mb-3'>
                  <label>Startdatum:</label>
                  <div className="input-group">
                    <DatePicker
                      selected={startDate}
                      onChange={handleDateChange(setStartDate)}
                      dateFormat="yyyy-MM-dd"
                      className="form-control"
                      locale={sv}
                    />
                    <div className="input-group-append">
                      <span className="input-group-text">
                        <FontAwesomeIcon icon={faCalendarAlt} />
                      </span>
                    </div>
                  </div>
                </div>
                <div className='col-sm-3 mb-3'>
                  <label>Slutdatum:</label>
                  <div className="input-group">
                    <DatePicker
                      selected={endDate}
                      onChange={handleDateChange(setEndDate)}
                      dateFormat="yyyy-MM-dd"
                      className="form-control"
                      locale={sv}
                    />
                    <div className="input-group-append">
                      <span className="input-group-text">
                        <FontAwesomeIcon icon={faCalendarAlt} />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-5 mb-3'>
                <label data-tooltip-id="redovisningsmetod"
                  data-tooltip-content="Du kan se vilken metod du har på verksamt.se">Redovisningsmetod:</label>
                <Select
                  options={accountingMethodOptions}
                  value={selectedAccountingMethod}
                  onChange={setSelectedAccountingMethod}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-5 mb-3'>
                <label data-tooltip-id="momsperiod"
                  data-tooltip-content="Du kan se vilken momsperiod du har på verksamt.se">Momsperiod:</label>
                <Select
                  options={vatPeriodOptions}
                  value={selectedVatPeriod}
                  onChange={setSelectedVatPeriod}
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-5 mb-3 mt-3'>
                <Button variant='primary' onClick={handleSave}>Spara</Button>
                <Button className='ms-2' variant='danger' onClick={handleDelete}>Radera</Button>
              </div>
            </div>
          </Card.Body>
        </Card>

        {/* Opening Balances */}

        <Card>
          <Card.Header>
            <h4>Ingångande balans</h4>
          </Card.Header>
          <Card.Body>
            {/* Assets Table */}
            <h3>Tillgångar</h3>
            <Table responsive hover className='mt-3'>
              <thead>
                <tr>
                  <th>Kontonummer</th>
                  <th>Kontonamn</th>
                  <th>Ingångssaldo</th>
                </tr>
              </thead>
              <tbody>
                {assets.map((balance, index) => (
                  <tr key={index}>
                    <td>{balance.account_number}</td>
                    <td>{balance.account_name}</td>
                    <td>{new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(balance.opening_balance)}</td>
                    <td>
                      <Button variant="link" onClick={() => removeFetchedAssetBalance(balance, index)}>
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </td>
                  </tr>
                ))}
                {newOpeningAssetsBalances.map((balance, index) => (
                  <tr key={index}>
                    <td>
                      <Select
                        options={assetsAccountsOptions}
                        value={assetsAccountsOptions.find(option => option.value === balance.account_id)}
                        onChange={(selectedOption) => handleAssetAccountChange(index, selectedOption)}
                      />
                    </td>
                    <td>{balance.account_name}</td>
                    <td>
                      <Form.Control
                        type="number"
                        value={balance.opening_balance}
                        onChange={(event) => handleAssetBalanceChange(index, event)}
                      />
                    </td>
                    <td>
                      <Button variant="link" onClick={() => removeNewAssetBalanceRow(index)}>
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </td>
                  </tr>
                ))}
                {/* Display total for assets */}
                <tr>
                  <td colSpan="2"><strong>Summa tillgångar</strong></td>
                  <td><strong>{new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(totalAssets)}</strong></td>
                </tr>
              </tbody>
            </Table>
            <div className='row'>
              <div className='col-sm-5 mb-3 mt-3'>
                <Button variant="link" onClick={addNewAssetsBalanceRow}>Lägg till ny rad</Button>
              </div>
            </div>

            <div className="custom-divider my-4" style={{
              "height": "1px",
              "backgroundColor": "#000",
              "margin": "20px 0",
            }}>
            </div>


            {/* Liabilities and Equity Table */}
            <h3 className='mt-5'>Liabilities and Equity</h3>
            <Table responsive hover className='mt-3'>
              <thead>
                <tr>
                  <th>Kontonummer</th>
                  <th>Kontonamn</th>
                  <th>Ingångssaldo</th>
                </tr>
              </thead>
              <tbody>
                {liabilities.map((balance, index) => (
                  <tr key={index}>
                    <td>{balance.account_number}</td>
                    <td>{balance.account_name}</td>
                    <td>{new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(balance.opening_balance)}</td>
                    <td>
                      <Button variant="link" onClick={() => removeFetchedLiabilityBalance(balance, index)}>
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </td>
                  </tr>
                ))}
                {newOpeningLiabilityBalances.map((balance, index) => (
                  <tr key={index}>
                    <td>
                      <Select
                        options={liabilityAccountsOptions}
                        value={liabilityAccountsOptions.find(option => option.value === balance.account_id)}
                        onChange={(selectedOption) => handleLiabilityAccountChange(index, selectedOption)}
                      />
                    </td>
                    <td>{balance.account_name}</td>
                    <td>
                      <Form.Control
                        type="number"
                        value={balance.opening_balance}
                        onChange={(event) => handleLiabilityBalanceChange(index, event)}
                      />
                    </td>
                    <td>
                      <Button variant="link" onClick={() => removeNewLiabilityBalanceRow(index)}>
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </td>
                  </tr>
                ))}
                {/* Display total for liabilities and equity */}
                <tr>
                  <td colSpan="2"><strong>Total Liabilities and Equity</strong></td>
                  <td><strong>{new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(totalLiabilitiesEquity)}</strong></td>
                </tr>
              </tbody>
            </Table>
            <div className='row'>
              <div className='col-sm-5 mb-3 mt-3'>
                <Button variant="link" onClick={addNewLiabilityBalanceRow}>Lägg till ny rad</Button>
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-5 mb-3 mt-3'>
                <Button variant="success" onClick={saveOpeningBalances} className="ms-2">Spara</Button>
              </div>
            </div>
          </Card.Body>
        </Card>

        <div className='row'>
          <div className='col-sm-12'>
            {Math.abs(totalAssets) !== Math.abs(totalLiabilitiesEquity) && (
              <div style={{ marginTop: '20px', color: 'red', fontWeight: 'bold' }}>
                <p>The total assets and liabilities & equity do not balance.</p>
                <p>The difference is: {new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(totalAssets - Math.abs(totalLiabilitiesEquity))}</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <ReactTooltip place="right" effect="solid" id="redovisningsmetod" />
      <ReactTooltip place="right" effect="solid" id="momsperiod" />
    </>
  );
};

export default FiscalYearDetails;

