// CreateNewSupplierInvoice.jsx




import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import axios from '../../../../services/AxiosInstance';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Alert, Card, Spinner, Button } from 'react-bootstrap';
import FileDropZone from '../../SharedComponents/FileDropZone';
import { getApiUrl } from '../../../../utils/ApiUtils';
import { format } from 'date-fns';
import NewSupplierModal from '../Factoring/Suppliers/NewSupplierModal';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

const CreateNewSupplierInvoice = () => {
  const { companyId } = useParams();

  const history = useHistory();

  // State variables
  const [suppliers, setSuppliers] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState('');
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [invoiceDate, setInvoiceDate] = useState(new Date());
  const [dueDate, setDueDate] = useState(new Date());
  const [amount, setAmount] = useState('');
  const [status, setStatus] = useState('Pending');
  const [error, setError] = useState(null);
  const [files, setFiles] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [showNewSupplierModal, setShowNewSupplierModal] = useState(false);
  const [initialSupplierData, setInitialSupplierData] = useState({});
  const [previewUrl, setPreviewUrl] = useState(null);
  const [showInfo, setShowInfo] = useState(true);

  const handleClose = () => setShowNewSupplierModal(false);
  const handleSave = (newSupplierData) => {
    saveNewSupplier(newSupplierData);
    setShowNewSupplierModal(false);
  };

  const saveNewSupplier = (newData) => {
    const newSupplier = {
      name: newData.name,
      orgnumber: newData.orgNumber,
      vatnumber: newData.vatNumber,
      postadress1: newData.address,
      postadress2: '',
      city: newData.city,
      postnumber: newData.postalCode,
      country: newData.country,
      phonenumber: newData.phoneNumber,
    };

    console.log('New supplier:', newSupplier);

    const createSupplierApiUrl = getApiUrl(`${companyId}/purchasing/suppliers`);
    axios
      .post(createSupplierApiUrl, newSupplier, { withCredentials: true })
      .then((response) => {
        if (response.status === 201) {
          fetchSuppliers();
        } else {
          setError('Kunde inte lägga till leverantören. Vänligen försök igen.');
        }
      })
      .catch((error) => {
        setError('Kunde inte lägga till leverantören. Vänligen försök igen.');
      });
  };

  useEffect(() => {
    fetchSuppliers();
  }, []);

  // Fetch suppliers from the backend
  const fetchSuppliers = async () => {
    try {
      const getSuppliersApiUrl = getApiUrl(`${companyId}/purchasing/suppliers`);
      const result = await axios.get(getSuppliersApiUrl, { withCredentials: true });
      setSuppliers(result.data);
    } catch (err) {
      console.error('Error fetching suppliers:', err);
      setError('Kunde inte hämta leverantörer. Vänligen försök igen senare.');
    }
  };

  // Handle files uploaded via FileDropZone
  const handleFilesUploaded = async (uploadedFiles) => {
    setIsProcessing(true);
    setError(null);
    try {
      const file = uploadedFiles[0];
      //setFiles([file]); // Keep only the first file

      const formData = new FormData();
      formData.append('file', file);

      const response = await axios.post(
        getApiUrl(`${companyId}/purchasing/supplier-invoices/analyze-invoice`),
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          withCredentials: true,
        }
      );

      const extractedData = response.data;
      console.log('Extracted data:', extractedData);

      // Update form fields with extracted data
      setInvoiceNumber(extractedData.invoiceId.value || '');
      setInvoiceDate(extractedData.invoiceDate ? new Date(extractedData.invoiceDate.value) : new Date());
      setDueDate(extractedData.dueDate ? new Date(extractedData.dueDate.value) : new Date());
      setAmount(extractedData.invoiceTotal.amount || '');

      // Match supplier by name (case-insensitive)
      const matchingSupplier = suppliers.find(
        (supplier) => supplier.name.toLowerCase() === extractedData.vendorName.value?.toLowerCase()
      );

      console.log('Matching supplier:', matchingSupplier);
      console.log('Extracted vendor name:', extractedData.vendorName.value);

      if (matchingSupplier) {
        setSelectedSupplier(matchingSupplier.supplier_id);
      } else {
        setError('Leverantören hittades inte. Vänligen lägg till leverantören.');
        // Set initial supplier data
        setInitialSupplierData({
          name: extractedData.vendorName.value || '',
          address: extractedData.vendorAddress.text || '',
        });
        // Optionally open the new supplier modal
        // setShowNewSupplierModal(true);
      }
    } catch (err) {
      console.error('Error uploading and processing invoice:', err);
      setError('Ett fel uppstod vid behandling av fakturan. Vänligen försök igen.');
    } finally {
      setIsProcessing(false);
    }
  };

  // Handle form submission to create a new invoice
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccessMessage('');
    try {
      const newInvoice = {
        supplier_id: selectedSupplier,
        invoice_number: invoiceNumber,
        invoice_date: format(new Date(invoiceDate), 'yyyy-MM-dd'),
        due_date: format(new Date(dueDate), 'yyyy-MM-dd'),
        amount: parseFloat(amount),
        status,
      };

      console.log('Creating invoice:', newInvoice);

      const createInvoiceApiUrl = getApiUrl(`${companyId}/purchasing/supplier-invoices`);

      const response = await axios.post(createInvoiceApiUrl, newInvoice, { withCredentials: true });

      setSuccessMessage('Fakturan har skapats framgångsrikt!');

      const invoiceId = response.data.invoice_id;

      // If files are attached, upload them now
      if (files.length > 0) {
        await uploadFiles(invoiceId);
      }

      history.push(`supplier-invoices`); // Redirect to the supplier invoices list

      // Optionally, clear the form fields
      clearForm();
    } catch (err) {
      console.error('Error creating invoice:', err);
      setError('Kunde inte skapa fakturan. Vänligen kontrollera uppgifterna och försök igen.');
    }
  };

  // Function to clear form fields (optional)
  const clearForm = () => {
    setSelectedSupplier('');
    setInvoiceNumber('');
    setInvoiceDate(new Date());
    setDueDate(new Date());
    setAmount('');
    setStatus('Pending');
    setFiles([]);
    setPreviewUrl(null);
    setError(null);
    setSuccessMessage('');
  };

  // Function to upload files
  const uploadFiles = async (invoiceId) => {
    try {
      const uploadFileApiUrl = getApiUrl(`${companyId}/purchasing/supplier-invoices/${invoiceId}/attachments`);
      const formData = new FormData();
      files.forEach((file) => {
        formData.append('file', file);
      });

      await axios.post(uploadFileApiUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true,
      });
    } catch (err) {
      console.error('Error uploading files:', err);
      // Handle errors, e.g., inform the user
      setError('Fakturan skapades, men filuppladdningen misslyckades.');
    }
  };

  // Generate preview URL when files change
  useEffect(() => {
    if (files.length > 0) {
      const url = URL.createObjectURL(files[0]);
      setPreviewUrl(url);
      return () => {
        URL.revokeObjectURL(url);
        setPreviewUrl(null);
      };
    } else {
      setPreviewUrl(null);
    }
  }, [files]);

  // Function to remove the uploaded file
  const handleRemoveFile = () => {
    setFiles([]);
    setPreviewUrl(null);
  };

  return (
    <>
      <div className="row">
        <Alert variant="primary" dismissible show={showInfo} >
          <strong>Hej!</strong> Välkommen till leverantörsfakturor.
          Genom att vidarebefordra dina leverantörsfakturor till oss kan vi hjälpa dig att hålla koll på dina betalningar och göra din bokföring med hjälp av AI.
          <button className="btn-close" onClick={() => setShowInfo(false)}></button>
        </Alert>
        <div className="col-lg-6">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Skapa leverantörsfaktura</h4>
              {error && (
                <Alert variant="danger">
                  {error}
                  {error.includes('Leverantören hittades inte') && (
                    <>
                      {' '}
                      <Button variant="link" onClick={() => setShowNewSupplierModal(true)}>
                        Lägg till!
                      </Button>
                    </>
                  )}
                </Alert>
              )}
              {successMessage && <Alert variant="success">{successMessage}</Alert>}
              <form onSubmit={handleSubmit}>
                {/* Form fields */}
                <div className="row">
                  <div className="form-group mt-5 mb-4 col-md-8">
                    <label htmlFor="supplier">Leverantör</label>
                    <select
                      id="supplier"
                      className="form-control"
                      value={selectedSupplier}
                      onChange={(e) => setSelectedSupplier(e.target.value)}
                      required
                    >
                      <option value="">Välj leverantör</option>
                      {suppliers.map((supplier) => (
                        <option
                          key={supplier.supplier_id}
                          value={supplier.supplier_id}
                        >
                          {supplier.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group mb-4 col-md-8">
                    <label htmlFor="invoiceNumber">Fakturanummer</label>
                    <input
                      type="text"
                      id="invoiceNumber"
                      className="form-control"
                      value={invoiceNumber}
                      onChange={(e) => setInvoiceNumber(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group mb-4 col-md-4">
                    <label htmlFor="invoiceDate">Fakturadatum</label>
                    <DatePicker
                      id="invoiceDate"
                      className="form-control"
                      selected={invoiceDate}
                      onChange={(date) => setInvoiceDate(date)}
                      dateFormat="yyyy-MM-dd"
                      required
                    />
                  </div>
                  <div className="form-group mb-4 col-md-4">
                    <label htmlFor="dueDate">Förfallodatum</label>
                    <DatePicker
                      id="dueDate"
                      className="form-control"
                      selected={dueDate}
                      onChange={(date) => setDueDate(date)}
                      dateFormat="yyyy-MM-dd"
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group mb-4 col-md-8">
                    <label htmlFor="amount">Summa (inkl. moms)</label>
                    <input
                      type="number"
                      id="amount"
                      className="form-control"
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                      required
                      min="0"
                      step="0.01"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group mb-4 col-md-8">
                    <label htmlFor="status">Status</label>
                    <select
                      id="status"
                      className="form-control"
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                      required
                    >
                      <option value="Pending">Obetald</option>
                      <option value="Paid">Betald</option>
                      <option value="Overdue">Förfallen</option>
                    </select>
                  </div>
                </div>
                <button type="submit" className="btn btn-primary mt-5">
                  Skapa faktura
                </button>
              </form>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <Card>
            <Card.Body>
              <h4 className="card-title">Ladda upp faktura</h4>
              {isProcessing && (
                <div className="text-center mb-3">
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Laddar...</span>
                  </Spinner>
                  <p>Bearbetar fakturan, vänligen vänta...</p>
                </div>
              )}
              {files.length === 0 && (
                <FileDropZone
                  minHeight="300px"
                  setFiles={setFiles}
                  onFilesUploaded={handleFilesUploaded}
                  autoUpload={true}
                  acceptedFileTypes="image/*, application/pdf"
                  mainText="Släpp fil här eller klicka för att välja fil"
                  subText="Endast PDF- eller bildfiler stöds"
                  multiple={false} // Prevent multiple file uploads
                />
              )}
              {files.length > 0 && previewUrl && (
                <div className="mt-3">
                  <h5>Förhandsgranskning</h5>
                  {files[0].type.startsWith('image/') && (
                    <img
                      src={previewUrl}
                      alt="Preview"
                      style={{ maxWidth: '100%', maxHeight: '400px' }}
                    />
                  )}
                  {files[0].type === 'application/pdf' && (
                    <div style={{ height: '500px' }}>
                      <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js">
                        <Viewer fileUrl={previewUrl} />
                      </Worker>
                    </div>
                  )}
                  <Button variant="danger" className="mt-2" onClick={handleRemoveFile}>
                    Ta bort fil
                  </Button>
                </div>
              )}
            </Card.Body>
          </Card>
        </div>
      </div>
      <NewSupplierModal
        show={showNewSupplierModal}
        handleClose={handleClose}
        handleSave={handleSave}
        initialSupplierData={initialSupplierData}
      />
    </>
  );
};

export default CreateNewSupplierInvoice;






/*import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Alert, Card, Spinner } from 'react-bootstrap';
import FileDropZone from '../../SharedComponents/FileDropZone';
import { getApiUrl } from '../../../../utils/ApiUtils';
import Button from 'react-bootstrap/Button';
import { format } from 'date-fns';
import NewSupplierModal from '../Factoring/Suppliers/NewSupplierModal';

const CreateNewSupplierInvoice = () => {
  const { companyId } = useParams();

  // State variables
  const [suppliers, setSuppliers] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState('');
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [invoiceDate, setInvoiceDate] = useState(new Date());
  const [dueDate, setDueDate] = useState(new Date());
  const [amount, setAmount] = useState('');
  const [status, setStatus] = useState('Pending');
  const [error, setError] = useState(null);
  const [files, setFiles] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const [showNewSupplierModal, setShowNewSupplierModal] = useState(false);

  const [initialSupplierData, setInitialSupplierData] = useState({});

  const handleClose = () => setShowNewSupplierModal(false);
  const handleSave = (newSupplierData) => {
    saveNewSupplier(newSupplierData);
    setShowNewSupplierModal(false);
  };

  const saveNewSupplier = (newData) => {
    const newSupplier = {
      name: newData.name,
      orgnumber: newData.orgNumber,
      vatnumber: newData.vatNumber,
      postadress1: newData.address,
      postadress2: '',
      city: newData.city,
      postnumber: newData.postalCode,
      country: newData.country,
      phonenumber: newData.phoneNumber
    };

    console.log('New supplier:', newSupplier);

    const createSupplierApiUrl = getApiUrl(`${companyId}/suppliers`);
    axios.post(createSupplierApiUrl, newSupplier, { withCredentials: true })
      .then((response) => {
        if (response.status === 201) {
          fetchSuppliers();
        } else {
          setError('Kunde inte lägga till leverantören. Vänligen försök igen.');
        }
      })
      .catch((error) => {
        setError('Kunde inte lägga till leverantören. Vänligen försök igen.');
      });
  };

  useEffect(() => {
    fetchSuppliers();
  }, []);

  // Fetch suppliers from the backend
  const fetchSuppliers = async () => {
    try {
      const getSuppliersApiUrl = getApiUrl(`${companyId}/suppliers`);
      const result = await axios.get(getSuppliersApiUrl, { withCredentials: true });
      setSuppliers(result.data);
    } catch (err) {
      console.error('Error fetching suppliers:', err);
      setError('Kunde inte hämta leverantörer. Vänligen försök igen senare.');
    }
  };

  // Handle files uploaded via FileDropZone
  const handleFilesUploaded = async (uploadedFiles) => {
    setIsProcessing(true);
    setError(null);
    try {
      const file = uploadedFiles[0];
      const formData = new FormData();
      formData.append('file', file);

      const response = await axios.post(
        getApiUrl(`${companyId}/supplier-invoices/analyze-invoice`), // Adjust the URL as needed
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          withCredentials: true,
        }
      );

      const extractedData = response.data;
      console.log('Extracted data:', extractedData);

      // Update form fields with extracted data
      setInvoiceNumber(extractedData.invoiceId.value || '');
      setInvoiceDate(extractedData.invoiceDate ? new Date(extractedData.invoiceDate.value) : new Date());
      setDueDate(extractedData.dueDate ? new Date(extractedData.dueDate.value) : new Date());
      setAmount(extractedData.invoiceTotal.amount || '');

      // Match supplier by name (case-insensitive)
      const matchingSupplier = suppliers.find(
        (supplier) => supplier.name.toLowerCase() === extractedData.vendorName.value?.toLowerCase()
      );

      console.log('Matching supplier:', matchingSupplier);
      console.log('Extracted vendor name:', extractedData.vendorName.value);

      if (matchingSupplier) {
        setSelectedSupplier(matchingSupplier.supplier_id);
      } else {
        setError('Leverantören hittades inte. Vänligen lägg till leverantören.');
        // Set initial supplier data
        setInitialSupplierData({
          name: extractedData.vendorName.value || '',
          address: extractedData.vendorAddress.text || '',
        });
        //setShowNewSupplierModal(true);
      }
    } catch (err) {
      console.error('Error uploading and processing invoice:', err);
      setError('Ett fel uppstod vid behandling av fakturan. Vänligen försök igen.');
    } finally {
      setIsProcessing(false);
    }
  };

  // Handle form submission to create a new invoice
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccessMessage('');
    try {
      const newInvoice = {
        supplier_id: selectedSupplier,
        invoice_number: invoiceNumber,
        invoice_date: format(new Date(invoiceDate), 'yyyy-MM-dd'),
        due_date: format(new Date(dueDate), 'yyyy-MM-dd'),
        amount: parseFloat(amount),
        status,
      };

      console.log('Creating invoice:', newInvoice);

      const createInvoiceApiUrl = getApiUrl(
        `${companyId}/supplier-invoices`
      );

      const response = await axios.post(createInvoiceApiUrl, newInvoice, { withCredentials: true });

      setSuccessMessage('Fakturan har skapats framgångsrikt!');

      const invoiceId = response.data.invoice_id;

      // If files are attached, offer to upload them now
      if (files.length > 0) {
        // Optionally prompt the user or automatically upload
        await uploadFiles(invoiceId);
      }

      // Optionally, clear the form fields
      clearForm();
    } catch (err) {
      console.error('Error creating invoice:', err);
      setError('Kunde inte skapa fakturan. Vänligen kontrollera uppgifterna och försök igen.');
    }
  };

  // Function to clear form fields (optional)
  const clearForm = () => {
    setSelectedSupplier('');
    setInvoiceNumber('');
    setInvoiceDate(new Date());
    setDueDate(new Date());
    setAmount('');
    setStatus('Pending');
    setFiles([]);
  };

  // Function to upload files
  const uploadFiles = async (invoiceId) => {
    try {
      const uploadFileApiUrl = getApiUrl(
        `${companyId}/supplier-invoices/${invoiceId}/attachments`
      );
      const formData = new FormData();
      files.forEach((file) => {
        formData.append('file', file);
      });

      await axios.post(uploadFileApiUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true,
      });
    } catch (err) {
      console.error('Error uploading files:', err);
      // Handle errors, e.g., inform the user
      setError('Fakturan skapades, men filuppladdningen misslyckades.');
    }
  };

  return (
    <>
      <div className="row">
        <Alert variant="primary" dismissible>
          <strong>Hej!</strong> Välkommen till leverantörsfakturor.
          Genom att vidarebefordra dina leverantörsfakturor till oss kan vi hjälpa dig att hålla koll på dina betalningar och göra din bokföring med hjälp av AI.
          <button className="btn-close" onClick={() => console.log('1')}></button>
        </Alert>
        <div className="col-lg-6">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Skapa leverantörsfaktura</h4>
              {error &&
                <Alert variant="danger">
                  {error}
                  {error.includes('Leverantören hittades inte') && (
                    <>
                      {''}
                      <Button variant="link" onClick={() => setShowNewSupplierModal(true)}>
                        Lägg till!
                      </Button>
                    </>
                  )}
                </Alert>}
              {successMessage && <Alert variant="success">{successMessage}</Alert>}
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="form-group mt-5 mb-4 col-md-8">
                    <label htmlFor="supplier">Leverantör</label>
                    <select
                      id="supplier"
                      className="form-control"
                      value={selectedSupplier}
                      onChange={(e) => setSelectedSupplier(e.target.value)}
                      required
                    >
                      <option value="">Välj leverantör</option>
                      {suppliers.map((supplier) => (
                        <option
                          key={supplier.supplier_id}
                          value={supplier.supplier_id}
                        >
                          {supplier.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group mb-4 col-md-8">
                    <label htmlFor="invoiceNumber">Fakturanummer</label>
                    <input
                      type="text"
                      id="invoiceNumber"
                      className="form-control"
                      value={invoiceNumber}
                      onChange={(e) => setInvoiceNumber(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group mb-4 col-md-4">
                    <label htmlFor="invoiceDate">Fakturadatum</label>
                    <DatePicker
                      id="invoiceDate"
                      className="form-control"
                      selected={invoiceDate}
                      onChange={(date) => setInvoiceDate(date)}
                      dateFormat="yyyy-MM-dd"
                      required
                    />
                  </div>
                  <div className="form-group mb-4 col-md-4">
                    <label htmlFor="dueDate">Förfallodatum</label>
                    <DatePicker
                      id="dueDate"
                      className="form-control"
                      selected={dueDate}
                      onChange={(date) => setDueDate(date)}
                      dateFormat="yyyy-MM-dd"
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group mb-4 col-md-8">
                    <label htmlFor="amount">Summa (inkl. moms)</label>
                    <input
                      type="number"
                      id="amount"
                      className="form-control"
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                      required
                      min="0"
                      step="0.01"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group mb-4 col-md-8">
                    <label htmlFor="status">Status</label>
                    <select
                      id="status"
                      className="form-control"
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                      required
                    >
                      <option value="Pending">Obetald</option>
                      <option value="Paid">Betald</option>
                      <option value="Overdue">Förfallen</option>
                    </select>
                  </div>
                </div>
                <button type="submit" className="btn btn-primary mt-5">
                  Skapa faktura
                </button>
              </form>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <Card>
            <Card.Body>
              <h4 className="card-title">Ladda upp faktura</h4>
              {isProcessing && (
                <div className="text-center mb-3">
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Laddar...</span>
                  </Spinner>
                  <p>Bearbetar fakturan, vänligen vänta...</p>
                </div>
              )}
              <FileDropZone
                minHeight="300px"
                setFiles={setFiles}
                onFilesUploaded={handleFilesUploaded}
                autoUpload={true}
                acceptedFileTypes="image/*, application/pdf"
                mainText="Släpp filer här eller klicka för att välja fil"
                subText="Endast PDF- eller bildfiler stöds"
              />
            </Card.Body>
          </Card>
        </div>
      </div>
      <NewSupplierModal
        show={showNewSupplierModal}
        handleClose={handleClose}
        handleSave={handleSave}
        initialSupplierData={initialSupplierData}
      />
    </>
  );
};

export default CreateNewSupplierInvoice;*/
