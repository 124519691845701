import React, { useState, useEffect } from 'react';
import PageTitle from "../../../layouts/PageTitle";
import { useParams, Link, useHistory } from "react-router-dom";
import axios from '../../../../services/AxiosInstance';
import InvoiceTable from './SupplierInvoiceTable';
import { getApiUrl } from '../../../../utils/ApiUtils';
import { NoData } from '../../../components/SharedComponents/NoData';
import swal from 'sweetalert';
import { Spinner } from 'react-bootstrap'; // Import Spinner

export const SupplierInvoiceList = () => {
  const { companyId } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true); // Set initial loading to true
  const [fiscalYears, setFiscalYears] = useState([]);

  const history = useHistory();

  // Function to fetch supplier invoices
  const fetchSupplierInvoices = async () => {
    setLoading(true);
    const apiUrl = getApiUrl(`${companyId}/purchasing/supplier-invoices`);
    try {
      const res = await axios.get(apiUrl);
      setData(res.data);
    } catch (err) {
      console.error('Error fetching supplier invoices:', err);
      // Optionally handle error (e.g., display a message to the user)
    } finally {
      setLoading(false);
    }
  };

  const fetchFiscalYears = async () => {
    const apiUrl = getApiUrl(`${companyId}/fiscal-years`);
    try {
      const res = await axios.get(apiUrl);
      console.log('Fiscal years:', res.data);
      setFiscalYears(res.data);
    } catch (err) {
      console.error('Error fetching fiscal years:', err);
      // Optionally handle error (e.g., display a message to the user)
    }
  };

  useEffect(() => {
    fetchFiscalYears();
    fetchSupplierInvoices();
  }, [companyId]);

  const handleEdit = (invoiceId) => {
    history.push(`/${companyId}/edit-supplier-invoice/${invoiceId}`);
  };

  const handleDelete = async (invoiceId) => {
    swal({
      title: "Är du säker?",
      text: "Om du tar bort leverantörsfakturan kommer den att tas bort permanent från systemet.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then(async (willDelete) => {
        if (willDelete) {
          setLoading(true);
          const deleteApiUrl = getApiUrl(`${companyId}/purchasing/supplier-invoices/${invoiceId}`);
          try {
            await axios.delete(deleteApiUrl);
            // Refresh data after successful deletion
            await fetchSupplierInvoices();
          } catch (err) {
            console.error('Error deleting supplier invoice:', err);
            // Optionally handle error (e.g., display a message to the user)
          } finally {
            setLoading(false);
          }
        }
      });
  };

  return (
    <>
      <PageTitle activeMenu="Leverantörsfakturor" motherMenu="Fakturor" />
      <div className="col-12">
        {loading ? (
          <div className="vh-100 d-flex justify-content-center align-items-center">
            <Spinner animation="border" role="status" className="me-2">
              <span className="visually-hidden">Laddar...</span>
            </Spinner>
            <div>Laddar...</div>
          </div>
        ) : data.length === 0 ? (
          <>
            <NoData message="Det finns inga leverantörsfakturor att visa. Skapa en för att kunna se dem här." />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                textAlign: 'center',
                padding: '10px',
              }}
            >
              <Link
                style={{ marginTop: '20px' }}
                to={`#`}
                onClick={() => history.push(`/${companyId}/create-supplier-invoices`)}
                className="btn btn-primary"
              >
                Skapa ny leverantörsfaktura
              </Link>
            </div>
          </>
        ) : (
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Leverantörsfakturor</h4>
              <Link
                to={`#`}
                onClick={() => history.push(`/${companyId}/create-supplier-invoices`)}
                className="btn btn-primary"
              >
                Ny Faktura
              </Link>
            </div>
            <div className="card-body">
              <InvoiceTable data={data} handleEdit={handleEdit} handleDelete={handleDelete} />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SupplierInvoiceList;
