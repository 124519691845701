import { Row, Card, Col, Table, Button, Modal, Container, Form } from 'react-bootstrap';
import InvoiceItemsTable from './InvoiceItemsTable.js';



const ProductsAndArticlesCard = ({ invoiceData, items, articles, handleAddInvoiceItem, handleEditInvoiceItem, handleDeleteInvoiceItem, setAddRowModalEnabled, handleCheckChange }) => {

  const handleEditItem = (item) => {
    console.log("ProductsAndArticlesCard: Edit item: ", item);
    handleEditInvoiceItem(item);
  };

  const handleDeleteItem = (item) => {
    console.log("ProductsAndArticlesCard: Delete item: ", item);
    handleDeleteInvoiceItem(item);
  };

  const handleAddItem = (item, amount, hasChanged) => {
    console.log("ProductsAndArticlesCard: Add item: ", item, amount);
    handleAddInvoiceItem(item, amount, hasChanged);
  };

  return (
    <div className="card">
      <div className="card-body">
        <h4 className="fs-24 font-w400">Produkter/Artiklar</h4>
        <div className="col-xxl-12 mt-4 ">
          <InvoiceItemsTable
            items={items}
            articles={articles}
            handleAddItem={handleAddItem}
            handleEditItem={handleEditItem}
            handleDeleteItem={handleDeleteItem}
          />
        </div>

        <div className="d-flex flex-wrap justify-content-between align-items-center mb-4 mt-4 ms-4">
          <div className="mb-4">
            <div>
              <p className="fs-14 mb-2">Summa att betala</p>
              <span className="text-black me-2 font-w700">
                {invoiceData && invoiceData.totals.grand_total}
              </span>
            </div>
          </div>
          <div className="mb-4">
            <div>
              <p className="fs-14 mb-2">Summa (exkl. moms)</p>
              <span className="text-black me-2 font-w700">
                {invoiceData && invoiceData.totals.subtotal}
              </span>
            </div>
          </div>
          {invoiceData && Object.keys(invoiceData.totals.tax_breakdown).map((taxRate, index) => (
            <div className="mb-4" key={index}>
              <div>
                <p className="fs-14 mb-2">Moms ({taxRate}%)</p>
                <span className="text-black me-2 font-w700">
                  {invoiceData && invoiceData.totals.tax_breakdown[taxRate].total_taxes}
                </span>
              </div>
            </div>
          ))}
          <div className="mb-4">
            <div>
              <p className="fs-14 mb-2">Avrundat belopp</p>
              <span className="text-black me-2 font-w700">
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label={invoiceData && invoiceData.disable_rounding === 0 ? invoiceData.totals.rounding : '-'}
                  defaultChecked={invoiceData && invoiceData.disable_rounding === 0}
                  onChange={handleCheckChange}
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsAndArticlesCard;