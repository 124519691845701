import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Route, Switch, withRouter, Redirect, useHistory, useLocation } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';

// Components
import Index from "./jsx";
import SelectCompany from './jsx/pages/SelectCompany';
import NewCompany from './jsx/pages/NewCompany';
import AcceptInvitation from './jsx/pages/AcceptInvite';

// Actions and Selectors
import { isAuthenticated, isCompanySelected, getUid } from './store/selectors/AuthSelectors';
import { logout, setAccessToken, setPreSelectedCompany, setUserData } from './store/actions/AuthActions';

// Services and Utilities
import axiosInstance from './services/AxiosInstance';
import { getApiUrl } from './utils/ApiUtils';

// Styles
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";

// Lazy-loaded components
const SignUp = lazy(() => import('./jsx/pages/Registration'));
const ForgotPassword = lazy(() => import('./jsx/pages/ForgotPassword'));
const Login = lazy(() => import('./jsx/pages/Login'));

function App(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const userIsAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const companyIsSelected = useSelector((state) => isCompanySelected(state));
  const googleUid = useSelector((state) => getUid(state));

  const [isInitializing, setIsInitializing] = useState(true);

  useEffect(() => {
    const initializeApp = async () => {
      console.log('Initializing app...');
      try {
        // Attempt to refresh access token
        const refreshTokenUrlPath = getApiUrl('auth/refresh');
        const response = await axiosInstance.post(refreshTokenUrlPath, {}, { withCredentials: true });
        const { accessToken } = response.data;

        // Store access token in state
        dispatch(setAccessToken(accessToken));

        // Fetch user data
        const userApiUrlPath = getApiUrl('auth/users');
        const userResponse = await axiosInstance.get(userApiUrlPath);
        const userData = userResponse.data;
        console.log("User data: ", userData);
        dispatch(setUserData(userData));

        // Fetch user's associated companies
        const companiesApiUrlPath = getApiUrl('auth/users/companies');
        const companiesResponse = await axiosInstance.get(companiesApiUrlPath, { withCredentials: true });
        const companiesData = companiesResponse.data;
        console.log("Companies data: ", companiesData);

        // Check for selectedCompanyId in localStorage
        const storedCompanyString = localStorage.getItem('selectedCompany');

        const currentPath = history.location.pathname;
        const isInvitationPath = currentPath.includes('/invite');

        console.log("Current path: ", currentPath);
        console.log("Is invitation path: ", isInvitationPath);
        console.log("Stored company: ", storedCompanyString);

        if (storedCompanyString && !companyIsSelected) {
          const storedCompany = JSON.parse(storedCompanyString);
          dispatch(setPreSelectedCompany(storedCompany.companyId, storedCompany.companyName));

          // Redirect to dashboard if not on the invitation path
          if (!isInvitationPath && !currentPath.includes(`/${storedCompany.companyId}`)) {
            console.log("Redirecting to dashboard");
            history.push(`/${storedCompany.companyId}/dashboard`);
          }
        } else if (!storedCompanyString && !companyIsSelected && !isInvitationPath) {
          console.log("Redirecting to select-company");
          // Redirect to company selection if no company is selected and not on invitation path
          history.push('/select-company');
        }
      } catch (error) {
        console.error('Error during app initialization:', error);
        const currentPath = history.location.pathname;
        const isInvitationPath = currentPath.includes('/invite');

        console.log("Current path: ", currentPath);
        console.log("Is invitation path: ", isInvitationPath);

        // If on invitation path, do not redirect to /login
        if (!isInvitationPath) {
          dispatch(logout(history));
          history.push('/login');
        }
        // Else, allow the user to proceed to the invitation route
      } finally {
        setIsInitializing(false); // Initialization completed
      }
    };

    initializeApp();
  }, [dispatch, history, companyIsSelected]);

  // Display loading indicator until initialization is done
  if (isInitializing) {
    return (
      <div className="vh-100 d-flex justify-content-center align-items-center">
        <div>Loading...</div>
      </div>
    );
  }

  return (
    <div className="vh-100">
      <Suspense fallback={
        <div id="preloader">
          <div className="sk-three-bounce">
            <div className="sk-child sk-bounce1"></div>
            <div className="sk-child sk-bounce2"></div>
            <div className="sk-child sk-bounce3"></div>
          </div>
        </div>
      }>
        <Switch>
          {/* Invitation Route - accessible to all */}
          <Route path='/:companyId/invite' component={AcceptInvitation} />

          {/* Authentication Routes */}
          {!userIsAuthenticated && (
            <>
              <Route path='/login' component={Login} />
              <Route path='/page-register' component={SignUp} />
              <Route path='/page-forgot-password' component={ForgotPassword} />
              <Redirect to={{
                pathname: '/login',
                search: location.search,
              }} />
            </>
          )}

          {/* Company Selection Routes */}
          {userIsAuthenticated && !companyIsSelected && (
            <>
              <Route
                path='/create-company'
                render={(props) => <NewCompany {...props} googleId={googleUid} />}
              />
              <Route
                path='/select-company'
                render={(props) => <SelectCompany {...props} googleId={googleUid} />}
              />
              <Redirect to='/select-company' />
            </>
          )}

          {/* Main Application */}
          {userIsAuthenticated && companyIsSelected && (
            <>
              <Route path='/' component={Index} />
            </>
          )}

          {/* Fallback Route */}
          <Redirect to='/' />
        </Switch>
      </Suspense>
    </div>
  );
}

export default withRouter(connect()(App));
