import React from 'react';

const NonEditableContractDetails = ({ contractDetails }) => {
  return (
    <div className="basic-form">
      <div className="row">
        <div className="form-group mb-3 col-md-4">
          <label>Startdatum</label>
          <div>{contractDetails.valid_from}</div>
        </div>
        <div className="form-group mb-3 col-md-4">
          <label>Anställningsform</label>
          <div>{contractDetails.employmenttype}</div>
        </div>
      </div>
      <div className="row">
        <div className="form-group mb-3 col-md-4">
          <label>Lön</label>
          <div>{contractDetails.salary}</div>
        </div>
        <div className="form-group mb-3 col-md-4">
          <label>Löneperiod</label>
          <div>{contractDetails.salary_period}</div>
        </div>
      </div>
      <div className="row">
        <div className="form-group mb-3 col-md-4">
          <label>Arbetsvecka (Timmar)</label>
          <div>{contractDetails.work_week_hours}</div>
        </div>
        <div className="form-group mb-3 col-md-4">
          <label>Omfattning</label>
          <div>{contractDetails.employmentextent}</div>
        </div>
      </div>
      <div className="row">
        <div className="form-group mb-3 col-md-4">
          <label>Skattetabell</label>
          <div>{contractDetails.taxtable}</div>
        </div>
        <div className="form-group mb-3 col-md-4">
          <label>Skattekollumn</label>
          <div>{contractDetails.taxcolumn}</div>
        </div>
      </div>
      <div className="row">
        <div className="form-group mb-3 col-md-6">
          <label>Tjänsteställe</label>
          <div>{contractDetails.workplace}</div>
        </div>
      </div>
      <div className="row">
        <div className="form-group mb-3 col-md-6">
          <label>Övriga vilkor</label>
          <div>{contractDetails.otherterms}</div>
        </div>
      </div>
    </div>
  );
};

export default NonEditableContractDetails;
