// FileDropZone.jsx

import React, { useState } from 'react';
import Dropzone from 'react-dropzone';

function FileDropZone({
  setFiles,
  onFilesUploaded,
  minHeight = '150px',
  acceptedFileTypes = 'image/*, application/pdf',
  mainText = 'Ladda upp filer genom att dra hit dem eller klicka här för att välja en fil.',
  subText = '',
  autoUpload = false,
  multiple = true,
}) {
  const [fileList, setFileList] = useState([]);

  const handleDrop = async (acceptedFiles) => {
    const newFiles = acceptedFiles.map((file) => ({
      name: file.name,
      size: file.size,
      isNew: true,
    }));

    setFileList([...fileList, ...newFiles]);
    setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);

    // Automatically upload files if enabled
    if (autoUpload && onFilesUploaded) {
      await onFilesUploaded(acceptedFiles);
    }
  };

  const handleDelete = (fileName) => {
    const updatedFileList = fileList.filter((file) => file.name !== fileName);
    setFileList(updatedFileList);
    setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
  };

  return (
    <div style={{ width: '100%', padding: '20px', margin: '20px 0' }}>
      <Dropzone onDrop={handleDrop} accept={acceptedFileTypes}>
        {({ getRootProps, getInputProps }) => (
          <div
            {...getRootProps({
              className: 'dropzone',
              style: {
                border: '2px dashed #44814E',
                padding: '20px',
                borderRadius: '15px',
                minHeight: minHeight,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                cursor: 'pointer',
                backgroundColor: '#f9f9f9',
              },
            })}
          >
            <input {...getInputProps()} />
            <div className="dropzone-panel mb-lg-0 mb-2">
              <div className="d-flex flex-column align-items-center">
                <i className="fas fa-cloud-upload-alt me-3 fa-3x mb-3"></i>
                <div>
                  <span className="fs-18 font-w600 d-block text-primary">{mainText}</span>
                  <span className="text-primary">{subText}</span>
                </div>
              </div>
            </div>
          </div>
        )}
      </Dropzone>
      <div>
        {fileList.map((file) => (
          <div className="dropzone-items" key={file.name}>
            <div className="dropzone-item mt-3">
              <div className="file-icon">
                <i className="fas fa-file-alt me-3"></i>
              </div>
              <div className="dropzone-file">
                <div className="dropzone-filename" title={file.name}>
                  <p className="fs-18 font-w600 mb-0">{file.name}</p>
                  <span className="fs-16 font-w600 text-primary">
                    {(file.size / 1024).toFixed(2)} KB
                  </span>
                </div>
                <div className="dropzone-error"></div>
              </div>
              <div className="dropzone-progress">
                <div className="progress">
                  <div
                    className="progress-bar bg-primary"
                    role="progressbar"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    aria-valuenow="100"
                  ></div>
                </div>
              </div>
              <div className="dropzone-toolbar">
                <span className="dropzone-delete" onClick={() => handleDelete(file.name)}>
                  <i className="fas fa-times"></i>
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FileDropZone;
