import React, { useState } from 'react';

const EmployeePersonalDetails = ({ employee, handleInputChange }) => {
  return (
    <>
      <div className="basic-form">
        <div className="row">
          <div className="form-group mb-3 col-md-4">
            <label>Förnamn</label>
            <input
              type="text" className="form-control"
              name="firstname"
              value={employee.firstname}
              onChange={(e) => handleInputChange(e)}
            />
          </div>
          <div className="form-group mb-3 col-md-4">
            <label>Efternamn</label>
            <input
              type="text" className="form-control"
              name='lastname'
              value={employee.lastname}
              onChange={(e) => handleInputChange(e)}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group mb-3 col-md-4">
            <label>Personnummer</label>
            <input
              type="text" className="form-control"
              name='ssn'
              value={employee.ssn}
              onChange={(e) => handleInputChange(e)}
            />
          </div>
          <div className="form-group mb-3 col-md-4">
            <label>Jobbtitel</label>
            <input
              type="text" className="form-control"
              name='jobtitle'
              value={employee.jobtitle}
              onChange={(e) => handleInputChange(e)}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group mb-3 col-md-4">
            <label>Clearingnummer</label>
            <input
              type="text" className="form-control"
              name='clearingnumber'
              value={employee.clearingnumber}
              onChange={(e) => handleInputChange(e)}
            />
          </div>
          <div className="form-group mb-3 col-md-4">
            <label>Bankkonto</label>
            <input
              type="text" className="form-control"
              name='bankaccountnumber'
              value={employee.bankaccountnumber}
              onChange={(e) => handleInputChange(e)}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group mb-3 col-md-4">
            <label>Telefonnummer</label>
            <input
              type="text" className="form-control"
              name='phonenumber'
              value={employee.phonenumber}
              onChange={(e) => handleInputChange(e)}
            />
          </div>
          <div className="form-group mb-3 col-md-4">
            <label>Email</label>
            <input
              type="text" className="form-control"
              name='email'
              value={employee.email}
              onChange={(e) => handleInputChange(e)}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group mb-3 col-md-4">
            <label>Adress</label>
            <input
              type="text" className="form-control"
              name='address'
              value={employee.address}
              onChange={(e) => handleInputChange(e)}
            />
          </div>
          <div className="form-group mb-3 col-md-4">
            <label>Postnummer</label>
            <input
              type="text" className="form-control"
              name='postnummer'
              value={employee.postnummer}
              onChange={(e) => handleInputChange(e)}
            />
          </div>
          <div className="form-group mb-3 col-md-4">
            <label>Ort</label>
            <input
              type="text" className="form-control"
              name='ort'
              value={employee.ort}
              onChange={(e) => handleInputChange(e)}
            />
          </div>
          <div className="form-group mt-4">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                name='active'
                value={employee.active}
                checked={employee.active}
                onChange={(e) => handleInputChange(e)}
              />
              <label className="form-check-label">Aktivera anställd</label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeePersonalDetails;