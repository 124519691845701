import { useLocation, useHistory, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import PageTitle from "../../../layouts/PageTitle";
import { Form, Button, Row, Col, Card } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import "react-datepicker/dist/react-datepicker.css";
import axios from '../../../../services/AxiosInstance';
import { getApiUrl } from "../../../../utils/ApiUtils";


const CreateVerificationFromCopy = () => {
    const { companyId } = useParams();
    const location = useLocation();
    const { verificationData, from } = location.state || {};
    const history = useHistory();

    // Initialize date, description, and transactions based on verificationData
    const [date, setDate] = useState(new Date());
    const [description, setDescription] = useState(verificationData?.description || "");
    const [manualRows, setManualRows] = useState([]);

    const [accounts, setAccounts] = useState([]);
    const [balanceStatus, setBalanceStatus] = useState("");

    const fetchAccounts = async () => {
        try {
            const result = await axios.get(getApiUrl(`${companyId}/accounting/accounts`), { withCredentials: true });
            const options = result.data.map(account => ({
                value: account.account_id,
                label: `${account.account_number} - ${account.account_name}`,
                account_number: account.account_number,
                account_name: account.account_name
            }));
            setAccounts(options);
        } catch (error) {
            console.error('Error fetching accounts:', error);
        }
    };

    // Handle initializing the transactions from verificationData
    useEffect(() => {
        if (verificationData?.transactions) {
            const rows = verificationData.transactions.map((transaction) => ({
                account_id: transaction.account_id,
                account_number: transaction.account_number,
                account_name: transaction.account_name,
                debit: transaction.transaction_type === "debit" ? transaction.amount : "",
                credit: transaction.transaction_type === "credit" ? transaction.amount : "",
            }));
            setManualRows(rows);
        }
        fetchAccounts(); // Fetch accounts when component mounts
    }, [verificationData]);

    // Function to calculate and show balance status
    const calculateBalance = () => {
        let totalDebit = 0;
        let totalCredit = 0;

        manualRows.forEach((row) => {
            totalDebit += parseFloat(row.debit || 0);
            totalCredit += parseFloat(row.credit || 0);
        });

        if (totalDebit === totalCredit && totalDebit > 0) {
            setBalanceStatus("Debet och Kredit balanserar");
        } else if (totalDebit > totalCredit) {
            setBalanceStatus(`Debet är mer än kredit ${totalDebit - totalCredit}`);
        } else if (totalCredit > totalDebit) {
            setBalanceStatus(`Kredit är mer än debet ${totalCredit - totalDebit}`);
        } else {
            setBalanceStatus("");
        }
    };

    useEffect(() => {
        calculateBalance(); // Recalculate balance when rows change
    }, [manualRows]);

    const handleAddRow = () => {
        setManualRows([...manualRows, { account_id: "", account_number: "", account_name: "", debit: "", credit: "" }]);
    };

    const handleRemoveRow = (index) => {
        setManualRows(manualRows.filter((row, rowIndex) => rowIndex !== index));
    };

    const handleRowChange = (index, field, value) => {
        const newRows = [...manualRows];
        if (field === "account_id") {
            const selectedAccount = accounts.find((option) => option.value === value);
            if (selectedAccount) {
                newRows[index] = {
                    account_id: selectedAccount.value,
                    account_number: selectedAccount.account_number,
                    account_name: selectedAccount.account_name,
                    debit: newRows[index].debit,
                    credit: newRows[index].credit,
                };
            }
        } else {
            newRows[index][field] = value;
        }
        setManualRows(newRows);
    };

    const handleSave = () => {
        // Implement your save logic here
        console.log("Save verification:", { date, description, manualRows });

        handleAddVerification();
    };

    const handleAbort = () => {
        // Implement your abort logic here
        console.log("Abort verification");
        history.push(from || `/${companyId}/verifications`);
    };

    const handleAddVerification = async () => {
        try {
            const createVerificationApiUrl = getApiUrl(`${companyId}/verifications`);
            //const { date, description } = parameters;
            const series = 'V';
            const attachemnts = [];
            const theDate = date.toISOString().split('T')[0];

            const response = await axios.post(createVerificationApiUrl, { date: theDate, description, series, transactions: manualRows, attachments: attachemnts }, { withCredentials: true });
            if (response.data.success) {
                history.push(from || `/${companyId}/verifications`);
                //onVerificationAdded();
            }
        } catch (error) {
            console.error('Error adding verification:', error);
        }
    };

    return (
        <>
            <div className="col-12">
                <PageTitle motherMenu="Bokföring" activeMenu={"Verifikationer"} />
            </div>

            <h3>Skapa verifikat från kopia</h3>

            {verificationData && (
                <div className="col-12">
                    <div className="row">
                        <div className="col-8">
                            <Card>
                                <Card.Body>
                                    <Form>
                                        <Form.Group controlId="date">
                                            <Form.Label>Datum</Form.Label>
                                            <DatePicker selected={date} onChange={setDate} dateFormat="yyyy-MM-dd" className="form-control" />
                                        </Form.Group>

                                        <Form.Group className="mt-3" controlId="description">
                                            <Form.Label>Titel</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={description}
                                                onChange={(e) => setDescription(e.target.value)}
                                                placeholder="Fyll i titel"
                                            />
                                        </Form.Group>

                                        <h5 className="mt-4">Transactions</h5>
                                        {manualRows.map((row, index) => (
                                            <Row key={index} className="align-items-center mb-1">
                                                <Col>
                                                    {index === 0 && <Form.Label>Konto</Form.Label>}
                                                    <Select
                                                        options={accounts}
                                                        isSearchable={true}
                                                        value={accounts.find((option) => option.value === row.account_id)}
                                                        onChange={(selectedOption) =>
                                                            handleRowChange(index, "account_id", selectedOption ? selectedOption.value : "")
                                                        }
                                                        styles={{ control: (base) => ({ ...base, minWidth: "250px" }) }}
                                                    />
                                                </Col>
                                                <Col>
                                                    {index === 0 && <Form.Label>Debet</Form.Label>}
                                                    <Form.Control
                                                        type="number"
                                                        value={row.debit}
                                                        onChange={(e) => handleRowChange(index, "debit", e.target.value)}
                                                        placeholder="Debet"
                                                        step="0.01"
                                                    />
                                                </Col>
                                                <Col>
                                                    {index === 0 && <Form.Label>Kredit</Form.Label>}
                                                    <Form.Control
                                                        type="number"
                                                        value={row.credit}
                                                        onChange={(e) => handleRowChange(index, "credit", e.target.value)}
                                                        placeholder="Kredit"
                                                        step="0.01"
                                                    />
                                                </Col>
                                                <Col xs="auto">
                                                    {index >= 0 && (
                                                        <Button variant="link" onClick={() => handleRemoveRow(index)}>
                                                            <FontAwesomeIcon icon={faTrash} />
                                                        </Button>
                                                    )}
                                                </Col>
                                            </Row>
                                        ))}

                                        <Button className="mt-3" variant="link" onClick={handleAddRow}>
                                            Lägg till rad
                                        </Button>

                                        {/* Balance Indicator */}
                                        <div className="mt-4">
                                            <h6>Balance Status: {balanceStatus}</h6>
                                        </div>

                                        <Button className="mt-3" onClick={handleSave} disabled={!description || manualRows.some((row) => !row.account_id || (!row.debit && !row.credit))}>
                                            Spara
                                        </Button>
                                        <Button variant="link" onClick={handleAbort}>
                                            Ångra
                                        </Button>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-4">
                            <Card>
                                <Card.Body>
                                    <h5>Upload</h5>
                                    <p>Upload files here</p>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default CreateVerificationFromCopy;
