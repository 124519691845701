import React, { useEffect, useState, useMemo } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import { useTable, useGlobalFilter, useFilters, usePagination } from 'react-table';
import { Button, Dropdown, Badge } from "react-bootstrap";
import SelectEmployeeModal from './SelectEmployeeModal';
import swal from "sweetalert";

import axios from 'axios';
import { set } from 'date-fns';
import BankgirotFileDownloadModal from './BangirotFileDownloadModal';

import { getApiUrl } from '../../../../utils/ApiUtils';

const MonthlyPayroll = () => {
  const { companyId } = useParams();
  const { year, month } = useParams();
  const [reloadPayslips, setReloadPayslips] = useState(0);

  const [payrollData, setPayrollData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [employees, setEmployees] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showBGModal, setShowBGModal] = useState(false);
  const [selectedEmployees, setSelectedEmployees] = useState([]); // Assuming you have a list of employees

  const [payslips, setPayslips] = useState([]);


  const handleShowModal = () => {
    console.log('Show Modal');
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleEmployeeSelect = async (selectedEmployees) => {
    console.log('Selected Employee:', selectedEmployees);
    handleModalClose();
    for (const employeeId of selectedEmployees) {
      try {
        // Replace this URL with the URL of your API
        const createPayslipApiUrl = getApiUrl(`${companyId}/payroll/payslip`);
        const response = await axios.post(createPayslipApiUrl, {
          employeeId: employeeId,
          payrollId: payrollData[0].id, // Replace this with the ID of the current payroll
          payRollMonth: month,
          payRollYear: year,
        }, { withCredentials: true });

        setReloadPayslips(reloadPayslips + 1);

        console.log('Payslip created:', response.data);
      } catch (error) {
        console.error('Error creating payslip:', error);
      }
    }
  };

  const handleShowBGModal = () => {
    console.log('Show Modal');
    setShowBGModal(true);
  };

  const handleBGModalClose = () => {
    setShowBGModal(false);
  };

  const handleBGEmployeeSalariesSelect = async (selectedEmployeeSalaries, customerNumber, bankgirotNumber) => {

    console.log('Selected Employee Salaries:', selectedEmployeeSalaries);

    handleBGModalClose();
    downloadBankGirotFile(customerNumber, bankgirotNumber, selectedEmployeeSalaries);
  };

  useEffect(() => {
    setLoading(true);
    Promise.all([fetchData(), handleFetchEmployees()])
      .then(() => setLoading(false))
      .catch(error => setError(error));
  }, [year, month]);

  const fetchData = () => {
    const getPayrollForPeriodApiUrl = getApiUrl(`${companyId}/payroll/${year}/${month}`);
    return axios.get(getPayrollForPeriodApiUrl, { withCredentials: true })
      .then(response => {
        console.log("fetch payroll data");
        console.log(response.data);
        setPayrollData(response.data);
      });
  };

  const handleFetchEmployees = () => {
    const getEmployeeApiUrl = getApiUrl(`${companyId}/hr/employees?active=true`);
    return axios.get(getEmployeeApiUrl, { withCredentials: true })
      .then(response => {
        console.log("fetch employees");
        console.log(response.data);
        setEmployees(response.data.activeEmployees);
      });
  };

  const getXML = async () => {
    try {
      // Make an HTTP request to the API endpoint
      const getPayrollAgiXmlApiUrl = getApiUrl(`${companyId}/payroll/${payrollData[0].id}/agixml`);
      const response = await axios.get(getPayrollAgiXmlApiUrl, {
        responseType: 'blob', // Specify response type as blob
      }, { withCredentials: true });

      // Get the XML content from the response
      const xmlContent = await response.data.text();

      console.log('XML Content:', xmlContent);

      // Save the XML content to a file
      const fileName = `agi_${new Date().getMonth() + 1}_${new Date().getFullYear()}.xml`;
      const blob = new Blob([xmlContent], { type: 'application/xml' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const changePayrollLockState = async (payrollId, isLocked) => {
    try {
      const updatePayrollLockStateApiUrl = getApiUrl(`${companyId}/payroll/${payrollId}/lock`);
      const response = await axios.put(updatePayrollLockStateApiUrl, {
        isLocked: isLocked,
      }, { withCredentials: true });

      console.log("lock payroll");
      console.log(response.data);

      // If the operation was successful, update the state
      if (response.status === 200) {
        setPayrollData(response.data);
      }

    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  function convertDateFormat(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear().toString().substr(-2); // Get last two digits of year
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Get month and pad with 0 if needed
    const day = date.getDate().toString().padStart(2, '0'); // Get day and pad with 0 if needed
    return year + month + day;
  }


  // Fetch the Bankgirot row from the server using axios
  async function downloadBankGirotFile(customerNumber, bankgirotNumber, payments) {
    try {
      // Make the GET request to the API endpoint

      const getPayrollBankgirotApiUrl = getApiUrl(`${companyId}/payroll/${payrollData[0].id}/bankgirot-file`);
      const response = await axios.post(getPayrollBankgirotApiUrl,
        {
          product: 'LÖN',
          customerNumber: customerNumber,
          bankGiroNumber: bankgirotNumber,
          paymentDate: convertDateFormat(payrollData[0].payoutdate),
          payments: payments.map(payment => ({
            amount: payment.netpay,
            reference: 'LÖN',
            accountNumber: payment.accountnumber,
            clearingNumber: payment.clearingnumber,
            name: payment.name,
            ssn: payment.ssn.replace('-', ''),
            message: 'LÖN ' + payrollData[0].year + payrollData[0].month,
            senderorgnr: '5592139090',
          })),
        },
        {
          responseType: 'text', // Expecting plain text as the response type
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        });

      // Retrieve the data as plain text
      const text = response.data;

      // Create a downloadable file on the client
      const blob = new Blob([text], { type: 'text/plain; charset=latin1' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'bankgirot.txt'; // Adjust the file name as needed
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Clean up the DOM

    } catch (error) {
      console.error('Error downloading the Bankgirot row:', error);
    }
  }

  async function createBookKeepingEntry(totalAmount, taxes, socialSecurity) {
    try {
      // Make the POST request to the API endpoint
      const getVerificationForPayroll = getApiUrl(`${companyId}/verificationforpayroll`);
      const ver_response = await axios.get(getVerificationForPayroll, {
        params: {
          id: 11,
          totalamount: totalAmount,
          taxes: taxes,
          social: socialSecurity,
        },
        withCredentials: true
      });

      console.log('Verification created:', ver_response.data);

      const data = {
        companyName: "CompanyName",
        organizationNumber: "556677-8899",
        fiscalYear: {
          start: "2024-01-01",
          end: "2024-12-31",
          year: 2024,
          periods: 12
        },
        accounts: [
          /*{ number: 3000, name: "Sales" },
          { number: 4000, name: "Purchases" }*/
        ],
        costCenters: [
          /*{ id: 1, code: 1000, name: "Cost Center A" },
          { id: 2, code: 2000, name: "Cost Center B" }*/
        ],
        verifications: [
          {
            id: "",
            date: new Date().toISOString().split('T')[0],
            comment: "Löneutbetalning",
            transactions: ver_response.data.accountingEntries.map(element => ({
              "account": element.account,
              "amount": element.amount
            }))
          }
        ]
      };

      const generateSalarySiFileApiUrl = getApiUrl(`${companyId}/generateSalarySiFile`);
      const response = await axios.post(generateSalarySiFileApiUrl, data, { responseType: 'blob', withCredentials: true });

      //const blob = response.blob();
      const url = window.URL.createObjectURL(response.data);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'accounting.si';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);

      // Log the response data
      console.log('Bookkeeping entry created:', response.data);
    } catch (error) {
      console.error('Error creating bookkeeping entry:', error);
    }
  }

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <>
      <div className="d-flex mb-3">
        <div className="mb-3 align-items-center me-auto">
          <h4 className="fs-24 font-w800">Löneperiod {year} / {month} </h4>
        </div>
        <Link
          to={`#`}
          onClick={() => {
            if (payrollData[0].isLocked) {
              swal('Oops', 'Löneperiod är låst!', "error", {
                button: false, content: {
                  element: 'div',
                  attributes: {
                    innerHTML: '<div style="text-align: center;">För att kunna lägga till flera lönespecifikationer behöver du låsa upp perioden.</div>'
                  },
                },
              });
            } else {
              handleShowModal();
            }
          }}
          className="btn btn-outline-primary mb-3"
        >
          Lägg till lönespecifikation
        </Link>
        {/*<Link to={"#"} className="btn btn-outline-primary mb-3"><i className="fa fa-calendar me-3 scale3"></i>Filter Date<i className="fas fa-caret-down ms-2"></i></Link>*/}
      </div >

      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <span style={{
                fontSize: '25px',
                display: 'inline-block',
                background: '#44814E',
                color: 'white',
                width: '30px',
                height: '30px',
                lineHeight: '30px',
                textAlign: 'center',
                marginRight: '6px',
                borderRadius: '100%',
              }}>1</span>
              <h2 style={{ display: 'inline-block' }}>Hantera lönespecificationer</h2>
              <p className="text-primary"> Lägg till lönespecifikationer för dina anställda genom att klicka på knappen uppe till höger.</p>
              <p className="text-primary">När en ny lönespecifikation skapas, kommer den automatiskt att innehålla information om bruttolön, skatt, nettolön, sociala avgifter och total lönekostnad baserat på anställdas kontrakt och/eller tidrapporter. Du kan som användare redigera varje individuell lönespecifikation manuellt vid behov, till exempel för att lägga till bilförmån.</p>
              <p className="text-primary">När alla lönespecifikationer är färdiga kan du låsa löneperioden. När löneperioden är låst är det inte längre möjligt att lägga till fler lönespecifikationer. Därefter kan du betala ut lönerna och skapa underlag för bokföring.</p>
              <PayslipsTable payrollid={payrollData[0].id} reloadKey={reloadPayslips} setPayslips={setPayslips} />
              <Button className="me-2" variant="primary" onClick={() => changePayrollLockState(payrollData[0].id, !payrollData[0].isLocked)}>
                {payrollData[0].isLocked ? 'Lås upp lönekörning' : 'Klarmarkera lönekörning'}
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <div>
                <span style={{
                  fontSize: '25px',
                  display: 'inline-block',
                  background: '#44814E',
                  color: 'white',
                  width: '30px',
                  height: '30px',
                  lineHeight: '30px',
                  textAlign: 'center',
                  marginRight: '6px',
                  borderRadius: '100%',
                }}>2</span>
                <h3 style={{ display: 'inline-block' }}>Betala och bokför löneutbetalningar</h3>
                <p className="text-primary">När en löneperiod är låst är det möjligt att betala ut lönerna till de anställda. Om du har bankgiro är det enklast att ladda ner bankgirofilen. Annars måste du manuellt skapa en överföring till de anställdas bankkonton. När betalningarna är färdiga kan du skapa underlag för bokföring. Dessa kan du exportera och importera till ditt bokföringsprogram.</p>
              </div>

              <Button
                className="me-2"
                variant="primary"
                onClick={() => handleShowBGModal()}
                disabled={!payrollData[0].isLocked}>
                {payrollData[0].isLocked ? 'Ladda ner BG fil' : 'Klarmarkera lönekörning först'}
              </Button>

              <Button
                className="me-2"
                variant="primary"
                onClick={() => createBookKeepingEntry(payrollData[0].totalAmountPaid, payrollData[0].taxesPaid, payrollData[0].socialSecurityPaid)}
                disabled={!payrollData[0].isLocked}>
                {payrollData[0].isLocked ? 'Ladda ner underlag för bokföring' : 'Klarmarkera lönekörning först'}
              </Button>
            </div>
          </div>
        </div>
      </div >

      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <div className='mb-5'>
                <span style={{
                  fontSize: '25px',
                  display: 'inline-block',
                  background: '#44814E',
                  color: 'white',
                  width: '30px',
                  height: '30px',
                  lineHeight: '30px',
                  textAlign: 'center',
                  marginRight: '6px',
                  borderRadius: '100%',
                }}>3</span>
                <h3 style={{ display: 'inline-block' }}>Deklarera arbetsgivaravgifter</h3>
                <p className="text-primary">När löneperioden är låst och lönerna är utbetalda kan du ladda ner en AGI-fil. Denna fil innehåller information om de anställdas löner och skatter som du behöver skicka till Skatteverket. Du kan ladda ner filen och skicka den till Skatteverket via deras e-tjänst. Notera att detta moment måste göras varje måndad oavsett om du har betalat ut några löner eller ej.</p>

              </div>
              <Button
                className="me-2"
                variant="primary"
                onClick={() => changePayrollLockState(payrollData[0].id, !payrollData[0].isLocked)}
                disabled={!payrollData[0].isLocked}>
                {payrollData[0].isLocked ? 'Ladda ner AGI' : 'Klarmarkera lönekörning först'}
              </Button>
            </div>
          </div>
        </div>
      </div >

      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <div className='mb-5'>
                <Badge as="a" href="" bg=" badge-circle" className='badge-outline-primary badge-lg'> 4 </Badge>
                <h3 style={{ display: 'inline-block' }} className='ms-2'>Betala skatter till skatteverket</h3>
              </div>
              <button onClick={getXML} disabled={payrollData && !payrollData.isLocked}>Skapa underlag för bokföring</button>
            </div>
          </div>
        </div>
      </div>


      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <div>
                <h2>Payroll for {year}/{month}</h2>
                {payrollData ? (
                  // Render payroll data here
                  <pre>{JSON.stringify(payrollData, null, 2)}</pre>
                ) : (
                  <p>No payroll data available.</p>
                )
                }
              </div>
              <button onClick={getXML} disabled={payrollData && !payrollData.isLocked}>Get XML</button>
            </div>
          </div>
        </div>
      </div>
      <SelectEmployeeModal show={showModal} handleClose={handleModalClose} handleSelect={handleEmployeeSelect} employees={employees} />
      <BankgirotFileDownloadModal show={showBGModal} handleClose={handleBGModalClose} handleSelect={handleBGEmployeeSalariesSelect} employeeSalaries={payslips} />
    </>
  );
};


const PayslipsTable = ({ payrollid, reloadKey, setPayslips }) => {

  const { companyId } = useParams();

  const history = useHistory();

  const PAYSLIPS_COLUMNS = [
    {
      Header: 'Id',
      accessor: 'id', // Assuming your data structure has an 'id' property
      show: false, // This hides the column
      hidden: 'true',
    },
    {
      Header: 'Employee Id',
      accessor: 'employeeId', // Assuming your data structure has an 'id' property
      show: false, // This hides the column
      hidden: 'true',
    },
    {
      Header: 'Anställd',
      Footer: 'Anställd',
      accessor: 'name',
      //Filter: ColumnFilter,
      //disableFilters: true,
    },
    {
      Header: 'Bruttolön',
      Footer: 'Bruttolön',
      accessor: 'grosspay',
      //Filter: ColumnFilter,
    },
    {
      Header: 'Skatt',
      Footer: 'Skatt',
      accessor: 'tax',
      //Filter: ColumnFilter,
    },
    {
      Header: 'Netto',
      Footer: 'Netto',
      accessor: 'netpay',
      //Filter: ColumnFilter,
    },
    {
      Header: 'Soc. Avg.',
      Footer: 'Soc. Avg.',
      accessor: 'socialseccontr',
      //Filter: ColumnFilter,
    },
    {
      Header: 'Tot. Lönekostnad',
      Footer: 'Tot. Lönekostnad',
      accessor: 'totalEmployeeCost',
      //Filter: ColumnFilter,
    },

    {
      Header: "",
      className: "column-right",
      Cell: ({ row }) => (
        <Dropdown className="dropdown ms-auto text-right"
          onClick={(e) => e.stopPropagation()}>
          <Dropdown.Toggle
            variant=""
            className="btn-link i-false"
            data-toggle="dropdown"
          >
            <svg
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
            >
              <g
                stroke="none"
                strokeWidth={1}
                fill="none"
                fillRule="evenodd"
              >
                <rect x={0} y={0} width={24} height={24} />
                <circle fill="#000000" cx={5} cy={12} r={2} />
                <circle fill="#000000" cx={12} cy={12} r={2} />
                <circle fill="#000000" cx={19} cy={12} r={2} />
              </g>
            </svg>
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
            <Dropdown.Item onClick={() => handleRowClick(row.original.id)}>Ändra lönebesked</Dropdown.Item>
            <Dropdown.Item onClick={() => handleDelete(row.original.id)}>Radera lönebesked</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ),
      id: 'actions',
    },
  ]

  const columns = useMemo(() => PAYSLIPS_COLUMNS, [])
  //const data = useMemo( () => MOCK_DATA, [] )

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchPaySlips();
  }, []);

  const handleRowClick = (id) => {
    history.push(`/${companyId}/payslip/${id}`);
  };

  const handleDelete = async (id) => {
    try {
      console.log('Delete payslip ', id);
      const deletePayslipApiUrl = getApiUrl(`${companyId}/payslip/${id}`);
      const response = await axios.delete(deletePayslipApiUrl, { withCredentials: true });
      console.log(response.data);
    } catch (error) {
      console.error(error);
      // Handle the error here
    }
  };

  const fetchPaySlips = async () => {
    try {
      const getPayslipsApiUrl = getApiUrl(`${companyId}/payroll/${payrollid}/payslips`);
      const response = await axios.get(getPayslipsApiUrl, { withCredentials: true });
      console.log("fetch payslips");
      console.log(response.data);
      setData(response.data);
      setPayslips(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const tableInstance = useTable({
    columns,
    data,
    initialState: { pageIndex: 0 }
  }, useFilters, usePagination)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
    gotoPage,
    pageCount,
    pageOptions,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
  } = tableInstance


  const { pageIndex } = state

  return (
    <div className="table-responsive">

      <table {...getTableProps()} className="table dataTable table-hover display">
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()} style={{ display: column.hidden ? 'none' : 'table-cell' }}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className="" >

          {
            page.length > 0 ? (page.map((row) => {
              prepareRow(row)
              return (
                <tr className='clickable-row' onClick={() => handleRowClick(row.original.id)} {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return <td className='text-primary' {...cell.getCellProps()} style={{ display: cell.column.hidden ? 'none' : 'table-cell' }}> {cell.render('Cell')} </td>
                  })}
                </tr>
              )
            })
            ) : (
              <tr>
                <td colSpan="4">Hittade inga lönespecar, Lägg till ny lenspec för en anställd.</td>
              </tr>
            )
          }
        </tbody>
      </table>

    </div>
  );

};


export default MonthlyPayroll;
