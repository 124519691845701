import React, { useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';



const InviteUserModal = ({ show, handleClose, allRoles, handleInviteUser }) => {
    const [email, setEmail] = useState('');
    const [selectedRoles, setSelectedRoles] = useState([]);

    const handleRoleChange = (e) => {
        const roleName = e.target.name;
        if (e.target.checked) {
            setSelectedRoles([...selectedRoles, roleName]);
        } else {
            setSelectedRoles(selectedRoles.filter((role) => role !== roleName));
        }
    };

    const handleSubmit = () => {
        handleInviteUser(email, selectedRoles);
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Bjud in ny användare</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="inviteEmail">
                        <Form.Label>E-postadress</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="Ange e-postadress"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Label className="mt-3">Välj roller</Form.Label>
                    {allRoles.map((role) => (
                        <Form.Group controlId={`invite-role-${role.role_name}`} key={role.role_id}>
                            <Form.Check
                                type="checkbox"
                                label={role.role_name}
                                name={role.role_name}
                                onChange={handleRoleChange}
                            />
                        </Form.Group>
                    ))}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Avbryt</Button>
                <Button variant="primary" onClick={handleSubmit}>Skicka inbjudan</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default InviteUserModal;
