import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col, Container } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';

function InvoiceItemModal({ show, handleClose, handleSave, articles, item }) {
  // Determine if we are in 'add' or 'edit' mode
  const isEditMode = !!item;

  // State variables for editable fields
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [quantity, setQuantity] = useState(item ? item.quantity : 1);
  const [productCode, setProductCode] = useState(item ? item.productcode : '');
  const [description, setDescription] = useState(item ? item.description : '');
  const [unitPrice, setUnitPrice] = useState(item ? item.unitprice : 0);
  const [unitType, setUnitType] = useState(item ? item.unittype : 'Goods');
  const [unit, setUnit] = useState(item ? item.unit : 'Piece');
  const [taxRate, setTaxRate] = useState(item ? (item.tax_rate + '%') : '25%');

  // Totals calculation
  const [totalExTax, setTotalExTax] = useState(0);
  const [totalIncTax, setTotalIncTax] = useState(0);

  useEffect(() => {
    const taxRateDecimal = parseFloat(taxRate.replace('%', '')) / 100;
    const totalExcludingTax = unitPrice * quantity;
    const totalIncludingTax = totalExcludingTax * (1 + taxRateDecimal);

    setTotalExTax(totalExcludingTax);
    setTotalIncTax(totalIncludingTax);
  }, [unitPrice, quantity, taxRate]);

  // Handle article selection (only in add mode)
  const handleChangeArticlesSelection = (selectedOption) => {
    setSelectedArticle(selectedOption);
    setQuantity(1); // Reset quantity

    // Initialize editable fields with selected article's values
    setProductCode(selectedOption.productcode || '');
    setDescription(selectedOption.description || '');
    setUnitPrice(selectedOption.unitprice || 0);
    setUnitType(selectedOption.unittype || 'Goods');
    setUnit(selectedOption.unit || 'Piece');
    setTaxRate(selectedOption.tax_rate || '25%');
  };

  const handleSaveChanges = () => {
    // Prepare the item data
    const updatedItem = {
      ...item,
      productcode: productCode,
      description: description,
      unitprice: unitPrice,
      unittype: unitType,
      unit: unit,
      tax_rate: taxRate,
      quantity: quantity,
    };

    // If adding and an article is selected, include the article ID
    if (!isEditMode && selectedArticle) {
      updatedItem.article_id = selectedArticle.article_id;
    }

    handleSave(updatedItem);
  };

  // Load options for the AsyncSelect component
  const loadOptions = (inputValue, callback) => {
    const filteredOptions = articles.filter(article =>
      article.productcode.toLowerCase().includes(inputValue.toLowerCase()) ||
      article.description.toLowerCase().includes(inputValue.toLowerCase())
    );
    callback(filteredOptions);
  };

  return (
    <Modal size="lg" className="fade" show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>{isEditMode ? 'Ändra fakturarad' : 'Lägg till ny rad'}</Modal.Title>
        <Button variant="" className="btn-close" onClick={handleClose}></Button>
      </Modal.Header>
      <Modal.Body>
        {!isEditMode && (
          <>
            <AsyncSelect
              cacheOptions
              defaultOptions={articles}
              value={selectedArticle}
              getOptionLabel={e => `${e.productcode} - ${e.description}`}
              getOptionValue={e => e.article_id}
              loadOptions={loadOptions}
              onChange={handleChangeArticlesSelection}
              placeholder="Välj en artikel..."
            />
            <br />
          </>
        )}
        {/* Form Fields */}
        <Container className='m-0 p-0'>
          <Row className='mt-3'>
            <Col md="6">
              <Form.Group controlId="productCode">
                <Form.Label>Produktkod</Form.Label>
                <Form.Control
                  type="text"
                  value={productCode}
                  onChange={(e) => setProductCode(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group controlId="description">
                <Form.Label>Beskrivning</Form.Label>
                <Form.Control
                  type="text"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className='mt-3'>
            <Col md="4">
              <Form.Group controlId="quantity">
                <Form.Label>Antal</Form.Label>
                <Form.Control
                  type="number"
                  min="1"
                  value={quantity}
                  onChange={(e) => setQuantity(parseInt(e.target.value) || 1)}
                />
              </Form.Group>
            </Col>
            <Col md="4">
              <Form.Group controlId="unitPrice">
                <Form.Label>Pris (Exkl. Moms)</Form.Label>
                <Form.Control
                  type="number"
                  min="0"
                  step="0.01"
                  value={unitPrice}
                  onChange={(e) => setUnitPrice(parseFloat(e.target.value) || 0)}
                />
              </Form.Group>
            </Col>
            <Col md="4">
              <Form.Group controlId="taxRate">
                <Form.Label>Moms</Form.Label>
                <Form.Control
                  as="select"
                  value={taxRate}
                  onChange={(e) => setTaxRate(e.target.value)}
                >
                  <option value="0%">0%</option>
                  <option value="6%">6%</option>
                  <option value="12%">12%</option>
                  <option value="25%">25%</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>

          <Row className='mt-3'>
            <Col md="6">
              <Form.Group controlId="unitType">
                <Form.Label>Typ</Form.Label>
                <Form.Control
                  as="select"
                  value={unitType}
                  onChange={(e) => setUnitType(e.target.value)}
                >
                  <option value="Goods">Varor</option>
                  <option value="Service">Tjänst</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group controlId="unit">
                <Form.Label>Enhet</Form.Label>
                <Form.Control
                  as="select"
                  value={unit}
                  onChange={(e) => setUnit(e.target.value)}
                >
                  <option value="Piece">Styck</option>
                  <option value="Hour">Timmar</option>
                  <option value="Liter">Liter</option>
                  <option value="Kilogram">Kilogram</option>
                  <option value="Square meter">Kvadratmeter</option>
                  <option value="Meter">Meter</option>
                  <option value="Package">Paket</option>
                  <option value="Set">Set</option>
                  <option value="Cubic meter">Kubikmeter</option>
                  <option value="Day">Dag</option>
                  <option value="Month">Månad</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col md="6">
              <Form.Group>
                <Form.Label>Totalt (Exkl. Moms)</Form.Label>
                <div>{new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(totalExTax)}</div>
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group>
                <Form.Label>Totalt (Inkl. Moms)</Form.Label>
                <div>{new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(totalIncTax)}</div>
              </Form.Group>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger light" onClick={handleClose}>Stäng</Button>
        <Button variant="primary" onClick={handleSaveChanges}>{isEditMode ? 'Spara ändringar' : 'Lägg till'}</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default InvoiceItemModal;
