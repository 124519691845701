import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

function EventTypeSelection({ onNext }) {
    const [selectedEventType, setSelectedEventType] = useState('');

    const expenseEventTypes = [
        { id: 'receipt', label: 'Kvitto / Utgift', description: 'Kvitton och utlägg vid ett direkt inköp, dvs man betalar dirrekt på plats och får ett kvitto.' },
        { id: 'income', label: 'Inkomst', description: 'Ersättningar du erhåller när du har sålt en vara eller en tjänst.' },
        { id: 'other', label: 'Annat', description: 'Allt som inte hamnar inom kategorierna ovan. Kan vara sådant som överföringar mellan konton, betalning till skattekonto osv.' },

    ];

    const handleNext = () => {
        if (selectedEventType) {
            onNext(selectedEventType);
        }
    };

    return (
        <div>
            <div style={{ maxHeight: '350px', border: '1px solid #ccc', borderRadius: '5px', padding: '10px' }}>
                <ul className="timeline">
                    {expenseEventTypes.map((eventType) => (
                        <li
                            key={eventType.id}
                            className={`d-flex justify-content-between align-items-center p-2 border-bottom`}
                            onClick={() => setSelectedEventType(eventType.id)}
                            style={{ cursor: 'pointer' }}
                        >
                            <div className='pe-3 pb-2 ps-2'>
                                <strong>{eventType.label}</strong>
                                <p className="mb-0 text-muted">{eventType.description}</p>
                            </div>
                            <Form.Check
                                type="checkbox"
                                name="eventType"
                                checked={selectedEventType === eventType.id}
                                onChange={() => setSelectedEventType(eventType.id)}
                            />
                        </li>
                    ))}
                </ul>
            </div>

            <Button onClick={handleNext} disabled={!selectedEventType} className="mt-5">
                Next
            </Button>
        </div>
    );
}

export default EventTypeSelection;
