import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Table } from 'react-bootstrap';
import axios from '../../../services/AxiosInstance';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { getApiUrl } from '../../../utils/ApiUtils';

function CreateTemplateModal({ show, handleClose, templateId, onTemplateSaved, companyId }) {
  // State variables
  const [templateName, setTemplateName] = useState('');
  const [description, setDescription] = useState('');
  const [templateType, setTemplateType] = useState('Income'); // Default to Income
  const [transactions, setTransactions] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [parameters, setParameters] = useState([]); // New state for parameters

  const filterOption = (option, inputValue) => {
    const accountNumber = option.label.split(' - ')[0];
    return accountNumber.startsWith(inputValue);
  };

  useEffect(() => {
    if (show) {
      fetchAccounts();
      if (templateId) {
        fetchTemplateDetails(templateId);
      } else {
        resetForm();
      }
    }
  }, [show, templateId]);

  const fetchAccounts = async () => {
    try {
      const getCompanyBasAccountsApiURl = getApiUrl(`${companyId}/accounting/accounts`);
      const result = await axios.get(getCompanyBasAccountsApiURl, { withCredentials: true });
      const options = result.data.map(account => ({
        value: account.account_id,
        label: `${account.account_number} - ${account.account_name}`,
      }));
      setAccounts(options);
    } catch (error) {
      console.error('Error fetching accounts:', error);
    }
  };

  const fetchTemplateDetails = async (id) => {
    const createTemplateApiUrl = getApiUrl(`${companyId}/templates/${id}`);
    const response = await axios.get(createTemplateApiUrl, { withCredentials: true });
    setTemplateName(response.data.template.template_name);
    setDescription(response.data.template.description);
    setTemplateType(response.data.template.template_type);
    setTransactions(response.data.template.transactions || []);
    setParameters(response.data.template.parameters || []);
  };

  const resetForm = () => {
    setTemplateName('');
    setDescription('');
    setTemplateType('Income');
    setTransactions([]);
    setParameters([]);
  };

  // Handlers for transactions
  const handleAddTransaction = () => {
    setTransactions([...transactions, { account_id: '', debit_formula: '', credit_formula: '', is_user_defined: false }]);
  };

  const handleTransactionChange = (index, field, value) => {
    const newTransactions = [...transactions];
    newTransactions[index][field] = value;
    setTransactions(newTransactions);
  };

  const handleRemoveTransaction = (index) => {
    const newTransactions = transactions.filter((_, i) => i !== index);
    setTransactions(newTransactions);
  };

  // Handlers for parameters
  const handleAddParameter = () => {
    setParameters([...parameters, { parameter_name: '', parameter_label: '', parameter_type: 'Number' }]);
  };

  const handleParameterChange = (index, field, value) => {
    const newParameters = [...parameters];
    newParameters[index][field] = value;
    setParameters(newParameters);
  };

  const handleRemoveParameter = (index) => {
    const newParameters = parameters.filter((_, i) => i !== index);
    setParameters(newParameters);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      template_name: templateName,
      description,
      template_type: templateType,
      transactions,
      parameters,
    };

    if (templateId) {
      await axios.put(getApiUrl(`${companyId}/templates/${templateId}`), payload, { withCredentials: true });
    } else {
      await axios.post(getApiUrl(`${companyId}/templates`), payload, { withCredentials: true });
    }

    onTemplateSaved();
    handleClose();
  };

  return (
    <Modal size='lg' show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{templateId ? 'Update Template' : 'Create Template'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          {/* Template Details */}
          <Form.Group>
            <Form.Label>Template Name</Form.Label>
            <Form.Control
              type="text"
              value={templateName}
              onChange={(e) => setTemplateName(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Template Type</Form.Label>
            <Form.Control
              as="select"
              value={templateType}
              onChange={(e) => setTemplateType(e.target.value)}
              required
            >
              <option value="Income">Income</option>
              <option value="Expense">Expense</option>
              <option value="Transfer">Transfer</option>
              <option value="Other">Other</option>
            </Form.Control>
          </Form.Group>

          {/* Parameters Section */}
          <h5 className="mt-4">Parameters</h5>
          <Table responsive className="mt-3">
            <thead>
              <tr>
                <th>Parameter Name</th>
                <th>Label</th>
                <th>Type</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {parameters.map((parameter, index) => (
                <tr key={index}>
                  <td>
                    <Form.Control
                      type="text"
                      value={parameter.parameter_name}
                      onChange={(e) => handleParameterChange(index, 'parameter_name', e.target.value)}
                      required
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      value={parameter.parameter_label}
                      onChange={(e) => handleParameterChange(index, 'parameter_label', e.target.value)}
                      required
                    />
                  </td>
                  <td>
                    <Form.Control
                      as="select"
                      value={parameter.parameter_type}
                      onChange={(e) => handleParameterChange(index, 'parameter_type', e.target.value)}
                    >
                      <option value="Number">Number</option>
                      <option value="Text">Text</option>
                      <option value="Date">Date</option>
                    </Form.Control>
                  </td>
                  <td>
                    <Button variant="link" onClick={() => handleRemoveParameter(index)}>
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="mt-2">
            <Button variant="link" onClick={handleAddParameter}>Add New Parameter</Button>
          </div>

          {/* Transactions Section */}
          <h5 className="mt-4">Transactions</h5>
          <Table responsive className="mt-3">
            <thead>
              <tr>
                <th>Account</th>
                <th>Debit Formula</th>
                <th>Credit Formula</th>
                <th>User Defined Account</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {transactions.map((transaction, index) => (
                <tr key={index}>
                  <td>
                    <Select
                      options={accounts}
                      isSearchable={true}
                      filterOption={filterOption}
                      value={accounts.find(option => option.value === transaction.account_id)}
                      onChange={(selectedOption) => handleTransactionChange(index, 'account_id', selectedOption ? selectedOption.value : '')}
                      isDisabled={transaction.is_user_defined}
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      value={transaction.debit_formula}
                      onChange={(e) => handleTransactionChange(index, 'debit_formula', e.target.value)}
                      disabled={transaction.credit_formula !== ''}
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      value={transaction.credit_formula}
                      onChange={(e) => handleTransactionChange(index, 'credit_formula', e.target.value)}
                      disabled={transaction.debit_formula !== ''}
                    />
                  </td>
                  <td>
                    <Form.Check
                      type="checkbox"
                      checked={transaction.is_user_defined}
                      onChange={(e) => handleTransactionChange(index, 'is_user_defined', e.target.checked)}
                    />
                  </td>
                  <td>
                    <Button variant="link" onClick={() => handleRemoveTransaction(index)}>
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="mt-4">
            <Button variant="link" onClick={handleAddTransaction}>Add New Transaction</Button>
          </div>
          <div className="mt-4">
            <Button variant="primary" type="submit" className="mt-3">
              {templateId ? 'Save Changes' : 'Create Template'}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default CreateTemplateModal;













/*import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Table } from 'react-bootstrap';
import axios from 'axios';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { getApiUrl } from '../../../utils/ApiUtils';

function CreateTemplateModal({ show, handleClose, templateId, onTemplateSaved, companyId }) {
  const [templateName, setTemplateName] = useState('');
  const [description, setDescription] = useState('');
  const [templateType, setTemplateType] = useState('Income'); // Default to Income
  const [transactions, setTransactions] = useState([]);
  const [accounts, setAccounts] = useState([]);

  const filterOption = (option, inputValue) => {
    const accountNumber = option.label.split(' - ')[0];
    return accountNumber.startsWith(inputValue);
  };

  useEffect(() => {
    if (show) {
      fetchAccounts();
      if (templateId) {
        fetchTemplateDetails(templateId);
      } else {
        resetForm();
      }
    }
  }, [show, templateId]);

  const fetchAccounts = async () => {
    try {
      const getCompanyBasAccountsApiURl = getApiUrl(`${companyId}/accounting/accounts`);
      const result = await axios.get(getCompanyBasAccountsApiURl, { withCredentials: true });
      const options = result.data.map(account => ({
        value: account.account_id,
        label: `${account.account_number} - ${account.account_name}`,
      }));
      setAccounts(options);
    } catch (error) {
      console.error('Error fetching accounts:', error);
    }
  };

  const fetchTemplateDetails = async (id) => {
    const createTemplateApiUrl = getApiUrl(`${companyId}/templates/${id}`);
    const response = await axios.get(createTemplateApiUrl, { withCredentials: true });
    setTemplateName(response.data.template.template_name);
    setDescription(response.data.template.description);
    setTemplateType(response.data.template.template_type);
    setTransactions(response.data.template.transactions);
  };

  const resetForm = () => {
    setTemplateName('');
    setDescription('');
    setTemplateType('Income');
    setTransactions([]);
  };

  const handleAddTransaction = () => {
    setTransactions([...transactions, { account_id: '', debit_formula: '', credit_formula: '', parameter_name: '', is_user_defined: false }]);
  };

  const handleTransactionChange = (index, field, value) => {
    const newTransactions = [...transactions];
    newTransactions[index][field] = value;
    setTransactions(newTransactions);
  };

  const handleRemoveTransaction = (index) => {
    const newTransactions = transactions.filter((_, i) => i !== index);
    setTransactions(newTransactions);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      template_name: templateName,
      description,
      template_type: templateType,
      transactions,
    };

    if (templateId) {
      await axios.put(getApiUrl(`${companyId}/templates/${templateId}`), payload, { withCredentials: true });
    } else {
      await axios.post(getApiUrl(`${companyId}/templates`), payload, { withCredentials: true });
    }

    onTemplateSaved();
    handleClose();
  };

  return (
    <Modal size='lg' show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{templateId ? 'Update Template' : 'Create Template'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>Template Name</Form.Label>
            <Form.Control
              type="text"
              value={templateName}
              onChange={(e) => setTemplateName(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Template Type</Form.Label>
            <Form.Control
              as="select"
              value={templateType}
              onChange={(e) => setTemplateType(e.target.value)}
              required
            >
              <option value="Income">Income</option>
              <option value="Expense">Expense</option>
              <option value="Transfer">Transfer</option>
              <option value="Other">Other</option>
            </Form.Control>
          </Form.Group>

          <h5 className="mt-4">Transactions</h5>
          <Table responsive className="mt-3">
            <thead>
              <tr>
                <th>Account</th>
                <th>Debit</th>
                <th>Credit</th>
                <th>Parameter Name</th>
                <th>User Defined Account</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {transactions.map((transaction, index) => (
                <tr key={index}>
                  <td>
                    <Select
                      options={accounts}
                      isSearchable={true}
                      filterOption={filterOption}
                      value={accounts.find(option => option.value === transaction.account_id)}
                      onChange={(selectedOption) => handleTransactionChange(index, 'account_id', selectedOption ? selectedOption.value : '')}
                      isDisabled={transaction.is_user_defined}
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      value={transaction.debit_formula}
                      onChange={(e) => handleTransactionChange(index, 'debit_formula', e.target.value)}
                      disabled={transaction.credit_formula !== ''}
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      value={transaction.credit_formula}
                      onChange={(e) => handleTransactionChange(index, 'credit_formula', e.target.value)}
                      disabled={transaction.debit_formula !== ''}
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      value={transaction.parameter_name}
                      onChange={(e) => handleTransactionChange(index, 'parameter_name', e.target.value)}
                      placeholder="Optional Parameter"
                    />
                  </td>
                  <td>
                    <Form.Check
                      type="checkbox"
                      checked={transaction.is_user_defined}
                      onChange={(e) => handleTransactionChange(index, 'is_user_defined', e.target.checked)}
                    />
                  </td>
                  <td>
                    <Button variant="link" onClick={() => handleRemoveTransaction(index)}>
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="mt-4">
            <Button variant="link" onClick={handleAddTransaction}>Add New Transaction</Button>
          </div>
          <div className="mt-4">
            <Button variant="primary" type="submit" className="mt-3">
              {templateId ? 'Save Changes' : 'Create Template'}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default CreateTemplateModal;
*/