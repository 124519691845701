import { React, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { v4 as uuid } from 'uuid';
import { parse as uuidParse } from 'uuid';
import { Table, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import InvoiceItemModal from '../InvoiceItemModal';

const InvoiceItemsTable = ({ items, articles, handleAddItem, handleEditItem, handleDeleteItem }) => {
  let parsedItems = items;

  // Check if items is a string (potentially a JSON)
  if (typeof items === 'string') {
    try {
      // Try to parse the JSON string into an array
      parsedItems = JSON.parse(items);
      console.log(parsedItems);
      console.log(uuidParse(parsedItems[0].item_id));
    } catch (error) {
      // Handle JSON parsing error
      console.error('Error parsing JSON:', error);
      // Set parsedItems as an empty array or handle the error accordingly
      parsedItems = [];
    }
  }


  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const updatedItems = [...items];
    const [reorderedItem] = updatedItems.splice(result.source.index, 1);
    updatedItems.splice(result.destination.index, 0, reorderedItem);

    //setItems(updatedItems);
  };
  const handleDDAddItem = () => {
    const newItem = {
      id: `item-${items.length + 1}`,
      content: `New Item ${items.length + 1}`,
    };
    //setItems([...items, newItem]);
  };

  const [showModal, setShowModal] = useState(false);

  const handleShowModal = (modalToShow) => {
    console.log('Show Modal', modalToShow);

    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleSave = (item, amount, hasChanged) => {
    console.log("Save: ", item, amount);
    handleAddItem(item, amount, hasChanged);
    setShowModal(false);
  };

  const handleEdit = (item) => {
    console.log("Edited item: ", item);
    handleEditItem(item);
    setShowModal(false);
  };

  const handleDelete = (item) => {
    console.log("Delete: ", item);
    handleDeleteItem(item);
    setShowModal(false);
  };


  return (
    <div>
      {items.length > 0 ?
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <Table responsive className="header-border " {...provided.droppableProps} ref={provided.innerRef}>
                {/*<table {...provided.droppableProps} ref={provided.innerRef}>*/}
                <thead>
                  <tr>
                    <th></th>
                    <th>Beskrivning</th>
                    <th>Antal</th>
                    <th>Á-Pris</th>
                    <th>Moms</th>
                    <th>Summa</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {parsedItems.map((item, index) => (
                    <Draggable key={uuidParse(item.item_id)} draggableId={item.item_id} index={index}>
                      {(provided) => (
                        <TableRow
                          key={uuidParse(item.item_id)}
                          index={index}
                          provided={provided}
                          item={item}
                          removeItem={handleDelete}
                          editItem={handleEdit}
                        />
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </tbody>
                {/*</table>*/}
              </Table>
            )}
          </Droppable>
        </DragDropContext>
        :
        <div className='mb-4 ms-4'>Lägg till rader på din faktura.</div>
      }
      <Button className="me-2 mt-4" variant="primary" onClick={() => handleShowModal("new")}>
        Lägg till ny rad
      </Button>

      <InvoiceItemModal
        show={showModal}
        handleClose={() => handleModalClose()}
        handleSave={handleSave}
        articles={articles}
      />
    </div>
  );
};

const TableRow = ({ index, provided, item, removeItem, editItem }) => {

  const [showModal, setShowModal] = useState(false);

  const handleShowModal = (modalToShow) => {
    console.log('Show Modal', modalToShow);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleSave = (item) => {
    console.log("Updated row: ", item);
    editItem(item);
    setShowModal(false);
  };


  return (
    <>
      <tr ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
        <td>
          <div className="icon">
            <i className="bi bi-list-ul"></i>
          </div>
        </td>
        <td>{item.description}</td>
        <td>{item.quantity}</td>
        <td>{item.unitprice}</td>
        <td>{item.tax_rate}%</td>
        <td>{item.total_amount} </td>
        <td	>
          <div className="d-flex">
            <Link onClick={(event) => { event.preventDefault(); setShowModal(true) }} to="#" className="btn btn-primary shadow btn-xs sharp me-3">
              <i className="fas fa-pencil-alt"></i>
            </Link>
            <Link onClick={(event) => { event.preventDefault(); removeItem(item); }} to="#" className="btn btn-danger shadow btn-xs sharp">
              <i className="fa fa-trash"></i>
            </Link>
          </div>
        </td>
      </tr>

      <InvoiceItemModal
        show={showModal}
        handleClose={() => handleModalClose()}
        handleSave={handleSave}
        item={item}
      />
    </>
  );
};


export default InvoiceItemsTable; 
