import React, { useState, useEffect } from 'react';
import { Table, Card, Accordion } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import axios from '../../../../services/AxiosInstance';
import { getApiUrl } from '../../../../utils/ApiUtils';
import PageTitle from '../../../layouts/PageTitle';
import Select from 'react-select';
import { NoData } from '../../SharedComponents/NoData';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

function MainLedgerReport() {
    const [fiscalYears, setFiscalYears] = useState([]);
    const [selectedFiscalYear, setSelectedFiscalYear] = useState(null);
    const { companyId } = useParams();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [ledgerData, setLedgerData] = useState({});
    const history = useHistory();

    useEffect(() => {
        getFiscalYears();
    }, [companyId]);

    const fetchLedgerReport = async (fiscalYearId) => {
        try {
            const response = await axios.get(getApiUrl(`${companyId}/reporting/ledger`), {
                params: { fiscalYearId },
                withCredentials: true
            });

            setLedgerData(response.data);
        } catch (err) {
            setError('Failed to fetch ledger report');
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    const getFiscalYears = async () => {
        try {
            const result = await axios.get(getApiUrl(`${companyId}/accounting/fiscalyears`), { withCredentials: true });
            setFiscalYears(result.data.fiscal_years);
            if (result.data.fiscal_years.length > 0) {
                const latestFiscalYear = result.data.fiscal_years.sort((a, b) => b.year - a.year)[0];
                setSelectedFiscalYear(latestFiscalYear);
                fetchLedgerReport(latestFiscalYear.fiscal_year_id);
            }
        } catch (err) {
            setError('Failed to fetch fiscal years');
            setFiscalYears([]);
        } finally {
            setLoading(false);
        }
    };


    const handleFiscalYearChange = (selectedOption) => {
        setSelectedFiscalYear(selectedOption.value);
        setLoading(true);
        fetchLedgerReport(selectedOption.value.fiscal_year_id);
    };

    const calculateOutgoingBalance = (account) => {
        let balance = parseFloat(account.opening_balance) || 0;
        account.verifications.forEach((verification) => {
            verification.transactions.forEach((transaction) => {
                if (transaction.transaction_type === 'debit') {
                    balance += parseFloat(transaction.amount);
                } else if (transaction.transaction_type === 'credit') {
                    balance -= parseFloat(transaction.amount);
                }
            });
        });
        return balance.toFixed(2);
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <>
            <PageTitle motherMenu={"Rapporter"} activeMenu={"Huvudbok"} />

            {loading ? (
                <p>Loading...</p>
            ) : fiscalYears.length === 0 ? (
                <>
                    <NoData message="Det finns inga räkenskapsår att visa. Skapa ett nytt räkenskapsår för att börja lägga till verifikationer." />
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100%',
                        textAlign: 'center',
                        padding: '10px'
                    }}>
                        <Link
                            style={{ marginTop: "20px", alignItem: "center" }}
                            to={`#`}
                            onClick={() => history.push(`/${companyId}/settings`)}
                            className="btn btn-primary"
                        >
                            Gå till Inställningar
                        </Link>
                    </div>
                </>
            ) : (
                <>
                    <div className="d-flex align-items-center gap-4 mb-5">
                        <span>Räkenskapsår:</span>
                        <Select
                            value={{ value: selectedFiscalYear, label: selectedFiscalYear?.year }}
                            onChange={handleFiscalYearChange}
                            options={fiscalYears.map(fy => ({ value: fy, label: fy.year }))}
                        />
                    </div>

                    <Card className="mb-3">
                        <Card.Header>
                            <h5>Huvudbok</h5>
                        </Card.Header>
                        <Card.Body>
                            <Accordion>
                                {Object.values(ledgerData).map((account, index) => {
                                    const outgoingBalance = calculateOutgoingBalance(account);

                                    return (
                                        <Accordion.Item key={index} eventKey={`${index}`} className="accordion accordion-solid-bg">
                                            <Accordion.Header className="accordion-header  accordion-header-primary">
                                                <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                                    <span>{`${account.account_number} - ${account.account_name}`}</span>
                                                    <div style={{ marginTop: '5px', color: 'grey', fontWeight: 'bold' }}>
                                                        {`Ingående saldo: ${new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(account.opening_balance)}`}
                                                        {` | Utgående saldo: ${new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(outgoingBalance)}`}
                                                    </div>
                                                </div>
                                            </Accordion.Header>

                                            <Accordion.Collapse eventKey={`${index}`} className="accordion__body">
                                                <div>
                                                    <Table responsive>
                                                        <thead>
                                                            <tr>
                                                                <th>Vernr.</th>
                                                                <th>Datum</th>
                                                                <th>Beskrivning</th>
                                                                <th>Debet</th>
                                                                <th>Kredit</th>
                                                                <th>Balans</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {account.verifications.length > 0 ? (
                                                                // Initialize the current balance to the opening balance once per account
                                                                (() => {
                                                                    let currentBalance = parseFloat(account.opening_balance);

                                                                    return account.verifications.flatMap((verification, vIndex) =>
                                                                        verification.transactions.map((transaction, tIndex) => {
                                                                            const isDebit = transaction.transaction_type === 'debit';
                                                                            const amount = parseFloat(transaction.amount);

                                                                            // Update the balance based on the transaction type
                                                                            currentBalance = isDebit ? currentBalance + amount : currentBalance - amount;

                                                                            return (
                                                                                <tr key={`${vIndex}-${tIndex}`}>
                                                                                    <td>{`${verification.series}${verification.number}`}</td>
                                                                                    <td>{new Date(verification.date).toLocaleDateString()}</td>
                                                                                    <td>{verification.description}</td>
                                                                                    <td>{new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(isDebit ? amount : 0)}</td>
                                                                                    <td>{new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(!isDebit ? amount : 0)}</td>
                                                                                    <td>{new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(currentBalance)}</td>
                                                                                </tr>
                                                                            );
                                                                        })
                                                                    );
                                                                })()
                                                            ) : (
                                                                <tr>
                                                                    <td colSpan="6">Inga verifikationer för detta konto.</td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </Accordion.Collapse>


                                            {/*<Accordion.Collapse eventKey={`${index}`}>
                                        <div>
                                            <Table responsive>
                                                <thead>
                                                    <tr>
                                                        <th>Verifikationsnummer</th>
                                                        <th>Datum</th>
                                                        <th>Beskrivning</th>
                                                        <th>Belopp</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {account.verifications.length > 0 ? (
                                                        account.verifications.map((verification, vIndex) => (
                                                            verification.transactions.map((transaction, tIndex) => (
                                                                <tr key={`${vIndex}-${tIndex}`}>
                                                                    <td>{`${verification.series}${verification.number}`}</td>
                                                                    <td>{new Date(verification.date).toLocaleDateString()}</td>
                                                                    <td>{verification.description}</td>
                                                                    <td>{new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(transaction.amount)}</td>
                                                                </tr>
                                                            ))
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td colSpan="4">Inga verifikationer för detta konto.</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Accordion.Collapse>*/}
                                        </Accordion.Item>
                                    );
                                })}
                            </Accordion>
                        </Card.Body>
                    </Card>
                </>
            )}
        </>
    );
}

export default MainLedgerReport;
