import React, { useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import axios from '../../../../services/AxiosInstance';
import EmployeePersonalDetails from './EmployeeDetailsComponent.js';
import EmployeeContractDetails from './ContractDetailsComponent.js';

import { getApiUrl } from '../../../../utils/ApiUtils';


const NewEmployee = () => {

  const { companyId } = useParams();
  const history = useHistory();

  console.log("Company id: ", companyId);

  const [employee, setEmployee] = useState({
    employeeDetails: {
      firstname: '',
      lastname: '',
      ssn: '',
      jobtitle: '',
      clearingnumber: '',
      bankaccountnumber: '',
      phonenumber: '',
      email: '',
      address: '',
      postnummer: '',
      ort: '',
      active: true,
    },
    contractDetails: {
      startdate: '',
      employmenttype: '',
      salary: '',
      salaryperiod: '',
      workweek: '',
      employmentextent: '',
      accordingtocollectiveagreement: true,
      taxtable: '',
      taxcolumn: '',
      workplace: '',
      otherterms: '',
    },
  });

  const [showAdditionalFields, setShowAdditionalFields] = useState(false);
  const [showContractDetails, setShowContractDetails] = useState(false);

  const handleInputChange = (e, nestedField = null) => {
    var { name, value } = e.target;

    if (name === 'active' || name === 'accordingtocollectiveagreement') {
      value = e.target.checked;
    }

    if (nestedField) {
      setEmployee((prevEmployee) => ({
        ...prevEmployee,
        contractDetails: {
          ...prevEmployee.contractDetails,
          [nestedField]: value,
        },
      }));
    } else {
      setEmployee((prevEmployee) => ({
        ...prevEmployee,
        employeeDetails: {
          ...prevEmployee.employeeDetails,
          [name]: value,
        },
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Basic validation
    if (
      employee.employeeDetails.firstname &&
      employee.employeeDetails.lastname &&
      employee.employeeDetails.ssn &&
      employee.employeeDetails.jobtitle &&
      employee.employeeDetails.clearingnumber &&
      employee.employeeDetails.bankaccountnumber &&
      employee.employeeDetails.phonenumber &&
      employee.employeeDetails.email &&
      employee.employeeDetails.address &&
      employee.employeeDetails.postnummer &&
      employee.employeeDetails.ort &&
      employee.contractDetails.startdate &&
      employee.contractDetails.salary
    ) {
      // Send the form data to the server
      try {
        const createEmployeeApiUrl = getApiUrl(`${companyId}/hr/employees`);
        const response = await axios.post(createEmployeeApiUrl, employee, { withCredentials: true });

        console.log('Response:', response.data);
        // Handle response if needed
        if (response.data.success) {
          // Redirect to the employee list page
          history.push(`/${companyId}/employees`);
        }

      } catch (error) {
        console.error('Error:', error);
        // Handle error if needed
      }

      // Reset the form after submission
      setEmployee({
        employeeDetails: {
          firstname: '',
          lastname: '',
          ssn: '',
          jobtitle: '',
          clearingnumber: '',
          bankaccountnumber: '',
          phonenumber: '',
          email: '',
          address: '',
          postnummer: '',
          ort: '',
          active: true,
        },
        contractDetails: {
          startdate: '',
          employmenttype: '',
          salary: '',
          salaryperiod: '',
          workweek: '40',
          employmentextent: '',
          accordingtocollectiveagreement: true,
          taxtable: '',
          taxcolumn: '',
          workplace: '',
          otherterms: '',
        },
      });
    } else {
      // Show error message or handle invalid form state
      alert('Please fill in all required fields.');
    }
  };

  return (
    <div>
      <div className="d-flex mb-3">
        <div className="mb-3 align-items-center me-auto">
          <h4 className="fs-24 font-w800">Lägg till anställd</h4>
        </div>
        <Link to={"#"} className="btn btn-outline-primary mb-3"><i className="fa fa-calendar me-3 scale3"></i>Filter Date<i className="fas fa-caret-down ms-2"></i></Link>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <EmployeePersonalDetails employee={employee.employeeDetails} handleInputChange={handleInputChange} />
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='card'>
              <div className='card-body'>
                <EmployeeContractDetails employee={employee} handleInputChange={handleInputChange} />
              </div>
            </div>
          </div>
        </div>
        <button type="submit" className="btn btn-primary">Spara</button>
      </form>
    </div>
  );
}

export default NewEmployee;
