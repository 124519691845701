import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from '../../../../services/AxiosInstance';
import { getApiUrl } from '../../../../utils/ApiUtils';
import VATDetailView from './VatForm';
import { Card, Accordion, Button, Spinner, Alert, Modal, Table } from 'react-bootstrap';
import VatVerificationModal from './VatVerificationModal';  // Import the modal component
import VatPaymentVerificationModal from './VatPaymentVerificationModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

// Define enum values for each step
const StepEnum = {
    INSTRUCTIONS: 'instructions',
    REVIEW: 'review',
    SUBMISSION: 'submission',
    PAYMENT: 'payment',
    RECORD: 'record'
};

// Map enum values to display titles
const stepTitles = {
    [StepEnum.INSTRUCTIONS]: "Steg 1: Instruktioner",
    [StepEnum.REVIEW]: "Steg 2: Granska Momsrapporten",
    [StepEnum.SUBMISSION]: "Steg 3: Skicka momsrapport till Skatteverket",
    [StepEnum.PAYMENT]: "Steg 4: Betala momsen till Skatteverket",
    [StepEnum.RECORD]: "Steg 5: Bokför dragning av momsen på skattekontot"
};

// Define the order of steps
const stepOrder = [
    StepEnum.INSTRUCTIONS,
    StepEnum.REVIEW,
    StepEnum.SUBMISSION,
    StepEnum.PAYMENT,
    StepEnum.RECORD
];

const accountNumbers = [
    2611, 2621, 2631,
    2612, 2622, 2632,
    2613, 2623, 2633,
    2616, 2626, 2636,
    2614, 2624, 2634,
    2615, 2625, 2635,
];

function VatPeriodDetails() {
    const { companyId, vatPeriodId } = useParams();
    const { fiscalYearId } = useParams();
    const [vatPeriod, setVatPeriod] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [undoingClose, setUndoingClose] = useState(false);
    const [currentStep, setCurrentStep] = useState(StepEnum.INSTRUCTIONS); // Default to the first step
    const [activeAccordionKey, setActiveAccordionKey] = useState('0'); // Add activeAccordionKey state
    const [showModal, setShowModal] = useState(false);
    const [showVatModal, setShowVatModal] = useState(false);
    const [verifying, setVerifying] = useState(false);

    const [vatData, setVatData] = useState(null);
    const [totalVat, setTotalVat] = useState(null);

    const [transactions, setTransactions] = useState([]);
    const [totalDebit, setTotalDebit] = useState(0);
    const [totalCredit, setTotalCredit] = useState(0);

    useEffect(() => {
        const fetchVatPeriodDetails = async () => {
            try {
                const response = await axios.get(getApiUrl(`${companyId}/reporting/vat-period/${vatPeriodId}`), { withCredentials: true });
                if (response.data.success) {
                    console.log('VAT period details:', response.data.period);
                    setVatPeriod(response.data.period);
                    setCurrentStep(response.data.period.current_step);
                    setActiveAccordionKey(stepOrder.indexOf(response.data.period.current_step).toString()); // Automatically open the current step
                } else {
                    setError('Failed to load VAT period details');
                }
            } catch (err) {
                setError('Error fetching VAT period details');
            } finally {
                setLoading(false);
            }
        };

        fetchVatPeriodDetails();
    }, [companyId, vatPeriodId]);

    useEffect(() => {
        if (vatPeriod) {
            fetchVATCalculations();
        }
    }, [vatPeriod]);

    const fetchVATCalculations = async () => {
        try {
            const getVATCalculationsApiUrl = getApiUrl(`${companyId}/reporting/vat-calculation`);
            const response = await axios.get(getVATCalculationsApiUrl, {
                withCredentials: true,
                params: {
                    start_date: vatPeriod.start_date,
                    end_date: vatPeriod.end_date,
                },
            });

            if (response.data.success) {
                console.log('VAT calculations:', response.data.vatData);
                setVatData(response.data.vatData);
                setTotalVat(response.data.totalVat);
            } else {
                throw new Error(response.data.message);
            }
        } catch (error) {
            console.error('Error fetching VAT calculations:', error);
            //setError('Error fetching VAT calculations');
        } finally {
            setLoading(false);
        }
    };

    const handleStepComplete = async (nextStep) => {
        try {
            // Validate that nextStep is one of the valid enum values
            const validSteps = ['instructions', 'review', 'submission', 'payment', 'record'];

            if (!validSteps.includes(nextStep)) {
                throw new Error('Invalid step value');
            }

            // If the next step is submission (after review), generate the XML report
            if (nextStep === StepEnum.SUBMISSION) {

                const vatReportData = {
                    orgNr: '556000-0175',
                    period: '202401',
                    vatData: vatData,
                    totalVat: totalVat
                };

                const generateReportResponse = await axios.post(
                    getApiUrl(`${companyId}/reporting/vat-period/${vatPeriodId}/generate-report`),
                    { vatReportData: vatReportData },
                    { withCredentials: true }
                );
                if (!generateReportResponse.data.success) {
                    throw new Error('Failed to generate VAT report');
                }
            }

            // Send the valid step to the backend
            await axios.post(
                getApiUrl(`${companyId}/reporting/vat-period/${vatPeriodId}/update-step`),
                { current_step: nextStep },
                { withCredentials: true }
            );

            // Update the current step in the component state
            setCurrentStep(nextStep);
            setActiveAccordionKey(stepOrder.indexOf(nextStep).toString()); // Update the active accordion key

        } catch (err) {
            setError('Error updating current step');
            console.error('Error in handleStepComplete:', err.message || err);
        }
    };

    const handleVatPeriodClose = async () => {
        setLoading(true);
        setError(null);

        try {
            const response = await axios.post(
                getApiUrl(`${companyId}/reporting/vat-period/${vatPeriodId}/close`),
                { fiscalYearId: vatPeriod.fiscal_year_id },
                { withCredentials: true }
            );

            if (response.data.success) {
                setVatPeriod(prevState => ({ ...prevState, state: 'closed' }));
                setCurrentStep(StepEnum.RECORD); // Assuming the last step is when it's closed
            } else {
                setError('Failed to close VAT period');
            }
        } catch (err) {
            setError('Error closing VAT period');
            console.error('Error in handleVatPeriodClose:', err);
        } finally {
            setLoading(false);
        }
    };

    const handleUndoClose = async () => {
        setUndoingClose(true);
        setError(null);

        try {
            const response = await axios.post(
                getApiUrl(`${companyId}/reporting/vat-period/${vatPeriodId}/undo-close`),
                {},
                { withCredentials: true }
            );

            if (response.data.success) {
                setVatPeriod(prevState => ({ ...prevState, state: 'open' }));
                setCurrentStep(StepEnum.INSTRUCTIONS); // Reset to the first step or determine based on logic
                setActiveAccordionKey(stepOrder.indexOf(StepEnum.INSTRUCTIONS).toString()); // Open the first step
            } else {
                setError('Failed to reopen VAT period');
            }
        } catch (err) {
            setError('Error reopening VAT period');
            console.error('Error in handleUndoClose:', err);
        } finally {
            setUndoingClose(false);
        }
    };

    const handleReviewVatPeriod = () => {
        const getVatPeriodVerificationSuggestion = getApiUrl(`${companyId}/accounting/vat-period/${vatPeriodId}/vat-verification`);
        axios.get(getVatPeriodVerificationSuggestion, {
            withCredentials: true,
            params: {
                fiscalYearId,
                startDate: vatPeriod.start_date,
                endDate: vatPeriod.end_date,
                accountNumbers: accountNumbers
            }
        })
            .then((response) => {
                setTransactions(response.data.suggestions);
                calculateTotals(response.data.suggestions);
                setShowVatModal(true);  // Show modal after fetching suggestions
            })
            .catch((error) => {
                console.error('Error fetching VAT period verification suggestion:', error);
            });
    };

    const calculateTotals = (transactions) => {
        const totalDebit = transactions.reduce((acc, transaction) => acc + parseFloat(transaction.debit || 0), 0);
        const totalCredit = transactions.reduce((acc, transaction) => acc + parseFloat(transaction.credit || 0), 0);
        setTotalDebit(totalDebit);
        setTotalCredit(totalCredit);
    };

    const handlePayment = () => {

        const generateBookkeepingSuggestion = async (templateId, parameters, userSelectedAccount) => {
            try {
                const suggestionApiUrl = getApiUrl(`${companyId}/templates/${templateId}/suggestion`);

                const response = await axios.post(suggestionApiUrl, { parameters, userSelectedAccount }, { withCredentials: true });

                console.log("response: ", response.data);

                response.data.suggestedTransactions.sort((a, b) => a.account_number - b.account_number);
                setTransactions(response.data.suggestedTransactions);

                // Calculate total debit and credit
                const totalDebit = response.data.suggestedTransactions.reduce((acc, transaction) => acc + parseFloat(transaction.debit || 0), 0);
                const totalCredit = response.data.suggestedTransactions.reduce((acc, transaction) => acc + parseFloat(transaction.credit || 0), 0);
                setTotalDebit(totalDebit);
                setTotalCredit(totalCredit);
            } catch (error) {
                console.error('Error generating bookkeeping suggestion:', error);
            }
        };

        const paramsForVerification = {
            "date": "2024-09-12",
            "description": "test",
            "series": "V",
            "parameters": {
                "amount": totalVat,
            },
            "userDefinedAccounts": {}
        };

        const templateId = "355cbdc1-7a8e-4e29-9961-d764230faa46";

        generateBookkeepingSuggestion(templateId, paramsForVerification.parameters, paramsForVerification.userDefinedAccounts);

        // Show the modal to confirm the verification
        setShowModal(true);
    };

    const handleConfirmVatVerification = async () => {
        setVerifying(true);

        console.log("transactions: ", transactions);

        try {
            const createVerificationApiUrl = getApiUrl(`${companyId}/verifications`);
            const date = new Date().toISOString().split('T')[0];  // Use today's date
            const description = `Momsredovisning för perioden: ${vatPeriod.start_date} - ${vatPeriod.end_date}`;
            const series = 'V';
            const attachments = [];
            const response = await axios.post(createVerificationApiUrl, { date, description, series, transactions, attachments }, { withCredentials: true });
            if (response.data.success) {
                setShowVatModal(false);
                setCurrentStep(StepEnum.SUBMISSION); // Move to the next step
                handleStepComplete(StepEnum.SUBMISSION);
                setTransactions([]);
            } else {
                throw new Error('Failed to create verification');
            }
        } catch (error) {
            setError('Error creating verification');
            console.error(error);
        } finally {
            setVerifying(false);
        }

    };

    const handleConfirmVerification = async () => {
        setVerifying(true);
        setError(null);

        console.log("transactions: ", transactions);

        try {
            const createVerificationApiUrl = getApiUrl(`${companyId}/verifications`);
            const today = new Date().toISOString().split('T')[0]; // Gets today's date in YYYY-MM-DD format
            const date = today;
            const description = `Insättning momsfordran skattekonto för perioden ${vatPeriod.start_date} - ${vatPeriod.end_date}`; // Your hardcoded description
            const series = 'V';
            const attachments = [];
            const response = await axios.post(createVerificationApiUrl, { date, description, series, transactions, attachments }, { withCredentials: true });
            if (response.data.success) {
                setCurrentStep(StepEnum.RECORD); // Move to the next step
                handleStepComplete(StepEnum.RECORD);
                setShowModal(false); // Close the modal
            } else {
                setError('Failed to create payment verification');
            }
        } catch (error) {
            setError('Error creating payment verification');
            console.error('Error in handleConfirmVerification:', error);
        } finally {
            setVerifying(false);
        }
    };

    const isStepCompleted = (step) => {
        return stepOrder.indexOf(step) < stepOrder.indexOf(currentStep);
    };

    const handleAccordionToggle = (key) => {
        setActiveAccordionKey(key === activeAccordionKey ? null : key);
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    const stepContent = {
        [StepEnum.INSTRUCTIONS]: {
            content:
                <>
                    <div>Här är några instruktioner för hur du ska fortsätta med momsrapporten. <br />
                        Detta är en guide för att hjälpa dig att skicka in din momsrapport till Skatteverket.<br />
                        Följ dessa steg för att slutföra momsrapporten. <br />
                        Har du hanterat momsen manuellt eller i en annan mjukvara så går det bra att stänga den utan att följa dessa steg.<br />
                        <hr />
                    </div>
                    <div>
                        <Button variant="primary" className="mt-3" onClick={() => handleStepComplete(StepEnum.REVIEW)} disabled={vatPeriod.state === 'closed' || currentStep !== StepEnum.INSTRUCTIONS}>
                            Fortsätt
                            {loading && (
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    className="me-2"
                                />
                            )}
                        </Button>
                        {currentStep === StepEnum.INSTRUCTIONS && (<Button variant="link" onClick={() => handleVatPeriodClose()} disabled={undoingClose}>Redan bokförd</Button>)}
                    </div>
                </>
        },

        [StepEnum.REVIEW]: {
            content: vatPeriod ? (
                <>
                    <VATDetailView period={vatPeriod} />
                    <Button onClick={() => handleReviewVatPeriod()} disabled={currentStep !== StepEnum.REVIEW}>Godkänn momsrapport</Button>
                </>
            ) : (<p>Loading VAT details...</p>)
        },
        [StepEnum.SUBMISSION]: {
            content:
                <>
                    <div>
                        Här kan du skicka din momsrapport till Skatteverket eller ladda ner den och ladda upp den via skatteverkets hemsida..
                    </div>
                    {vatPeriod && vatPeriod.file_name && (
                        <div className="mt-3">
                            <a href={getApiUrl(`${companyId}/download/vat-report/${vatPeriodId}`)} target="_blank" rel="noopener noreferrer">
                                Ladda ner momsrapport (XML)
                            </a>
                        </div>
                    )}
                    <Button className='mt-4' onClick={() => handleStepComplete(StepEnum.PAYMENT)} disabled={currentStep !== StepEnum.SUBMISSION}>Skicka till Skatteverket</Button>
                    <Button className='mt-4' variant='link' onClick={() => handleStepComplete(StepEnum.PAYMENT)} disabled={currentStep !== StepEnum.SUBMISSION}>Markera som hanterad</Button>
                </>
        },
        [StepEnum.PAYMENT]: {
            content:
                <>
                    <div>
                        Detta steg kommer att skapa ett verifikat.<br /> Om du aktiverar bankkopplingen kan du göra detta dirrekt i denna vy (vi har koppling till de flesta svenska bankerna). <br />
                        Du ska betala {new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(totalVat)} till Skatteverket. <br />
                    </div>
                    <Button className='mt-4' onClick={handlePayment} disabled={currentStep !== StepEnum.PAYMENT}>Betala</Button>
                    <Button className='mt-4' variant='link' onClick={handlePayment} disabled={currentStep !== StepEnum.PAYMENT}>Markera manuellt hanterad</Button>
                </>
        },
        [StepEnum.RECORD]: {
            content:
                <>
                    <div>
                        Detta steg kommer att skapa ett verifikat.<br />
                        Med vår AI assistent och koppling till skatteverket så sköts detta per automatik. <br />
                        Om du vill göra detta manuellt så kan du göra det här. <br />
                    </div>
                    <Button className='mt-4' onClick={() => handleStepComplete(StepEnum.RECORD)} disabled={currentStep !== StepEnum.PAYMENT}>Bokför</Button>
                </>
        }
    };

    return (
        <>
            <div>
                <Card>
                    <Card.Body>
                        <h3>Momsrapport för perioden: {vatPeriod && vatPeriod.start_date} - {vatPeriod && vatPeriod.end_date}</h3>
                        <h4>Att Betala: {vatPeriod && vatPeriod.vat_total}</h4>
                        {vatPeriod && vatPeriod.state === 'closed' && (
                            <Alert variant="warning">
                                Denna momsperiod är stängd. <Button variant="link" onClick={handleUndoClose} disabled={undoingClose}>Ångra stängning</Button>
                                {undoingClose && <Spinner animation="border" size="sm" role="status" aria-hidden="true" />}
                            </Alert>
                        )}
                    </Card.Body>
                </Card>

                <Accordion activeKey={activeAccordionKey}>
                    {stepOrder.map((step, index) => (
                        <Accordion.Item
                            key={index}
                            eventKey={`${index}`}
                            className="accordion accordion-solid-bg"
                            onClick={() => handleAccordionToggle(`${index}`)}
                        >
                            <Accordion.Header className="accordion-header accordion-header-primary">
                                {stepTitles[step]}
                                {isStepCompleted(step) && <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green', marginLeft: '10px' }} />}
                            </Accordion.Header>

                            <Accordion.Collapse eventKey={`${index}`} className="accordion__body">
                                <Card.Body>
                                    {stepContent[step].content}
                                </Card.Body>
                            </Accordion.Collapse>
                        </Accordion.Item>
                    ))}
                </Accordion>



            </div>

            {/* VAT Verification Modal */}
            <VatVerificationModal
                showModal={showVatModal}
                setShowModal={setShowVatModal}
                transactions={transactions}
                totalDebit={totalDebit}
                totalCredit={totalCredit}
                verifying={verifying}
                error={error}
                handleConfirmVerification={handleConfirmVatVerification}
                vatPeriod={vatPeriod}
            />

            {/* Payment Verification Modal */}
            <VatPaymentVerificationModal
                showModal={showModal}
                setShowModal={setShowModal}
                totalVat={totalVat}
                verifying={verifying}
                error={error}
                transactions={transactions}
                totalDebit={totalDebit}
                totalCredit={totalCredit}
                handleConfirmVerification={handleConfirmVerification}
            />

        </>
    );
}

export default VatPeriodDetails;
