import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const SelectEmployeeModal = ({ show, handleClose, handleSelect, employees }) => {
    const [selectedEmployees, setSelectedEmployees] = useState([]);

    const handleSelectChange = (event) => {
        const value = event.target.value;
        setSelectedEmployees(prevState =>
            prevState.includes(value) ? prevState.filter(id => id !== value) : [...prevState, value]
        );
    };

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Välje anställda till lönekörning</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {employees.map((employee) => (
                        <Form.Check
                            key={employee.id}
                            type="checkbox"
                            label={employee.name}
                            value={employee.id}
                            onChange={handleSelectChange}
                        />
                    ))}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Stäng
                    </Button>
                    <Button variant="primary" onClick={() => { handleSelect(selectedEmployees); handleClose(); }}>
                        Lägg till
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default SelectEmployeeModal;



/*import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const SelectEmployeeModal = ({ show, handleClose, handleSelect, employees }) => {
    const [selectedEmployee, setSelectedEmployee] = useState('');

    const handleSelectChange = (event) => {
        setSelectedEmployee(event.target.value);
    };

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Select Employee</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control as="select" onChange={handleSelectChange}>
                        {employees.map((employee) => (
                            <option key={employee.id} value={employee.id}>
                                {employee.name}
                            </option>
                        ))}
                    </Form.Control>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={() => { handleSelect(selectedEmployee); handleClose(); }}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default SelectEmployeeModal;

*/