// NoData.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReceipt, faBoxOpen, faBoxArchive, faFolderOpen } from '@fortawesome/free-solid-svg-icons';


export const NoData = ({ message }) => {
    return (
        <div style={styles.container} className='text-green'>
            <FontAwesomeIcon icon={faBoxOpen} style={styles.icon} />
            <p style={styles.text}>{message}</p>
        </div>
    );
};

export const NoDataSupplierInvoice = ({ message }) => {
    return (
        <div style={styles.container} className='text-green'>
            <FontAwesomeIcon icon={faReceipt} style={styles.icon} />
            <p style={styles.text}>{message}</p>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        textAlign: 'center',
        padding: '60px',
    },
    icon: {
        fontSize: '6rem',
        marginBottom: '20px',
    },
    text: {
        fontSize: '1.5rem',
    },
};

export default { NoDataSupplierInvoice, NoData };

