import React from 'react';
import { Modal, Button, Table, Spinner, Alert } from 'react-bootstrap';

const VatVerificationModal = ({ showModal, setShowModal, transactions, totalDebit, totalCredit, verifying, error, handleConfirmVerification, vatPeriod }) => {

    return (
        <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
                {/* Display the title and date */}
                <Modal.Title>
                    Momsredovisning: {new Date(vatPeriod.start_date).toLocaleDateString('sv-SE')} - {new Date(vatPeriod.end_date).toLocaleDateString('sv-SE')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Du är på väg att skapa en verifikata för momsrapporten för perioden {vatPeriod.start_date} till {vatPeriod.end_date}.</p>
                Titel: Momsredovisning: {new Date(vatPeriod.start_date).toLocaleDateString('sv-SE')} - {new Date(vatPeriod.end_date).toLocaleDateString('sv-SE')}<br />
                Datum: {new Date().toLocaleDateString('sv-SE')}<br />



                {verifying && <Spinner animation="border" />}
                {error && <Alert variant="danger">{error}</Alert>}

                <Table responsive className="mt-3">
                    <thead>
                        <tr>
                            <th>Konto</th>
                            <th>Debet</th>
                            <th>Kredit</th>
                        </tr>
                    </thead>
                    <tbody>
                        {transactions.map((transaction, index) => (
                            <tr key={index}>
                                <td>{transaction.account_number} - {transaction.account_name}</td>
                                <td>{transaction.debit ? new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(transaction.debit) : "0"}</td>
                                <td>{transaction.credit ? new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(transaction.credit) : "0"}</td>
                            </tr>
                        ))}
                        <tr>
                            <td><strong>Total:</strong></td>
                            <td><strong>{new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(totalDebit)}</strong></td>
                            <td><strong>{new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(totalCredit)}</strong></td>
                        </tr>
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowModal(false)}>
                    Avbryt
                </Button>
                <Button variant="primary" onClick={handleConfirmVerification} disabled={verifying}>
                    Bekräfta
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default VatVerificationModal;
