import React, { useEffect, useState, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Dropdown, Tab, Nav, Spinner } from 'react-bootstrap'; // Import Spinner
import axios from '../../../../services/AxiosInstance';
import { getApiUrl } from '../../../../utils/ApiUtils';
import PageTitle from '../../../layouts/PageTitle';
import { useTable, useFilters, useGlobalFilter, usePagination } from 'react-table';
import { GlobalFilter } from './GlobalFilter';
import { useHistory } from 'react-router-dom';
import { NoData } from '../../SharedComponents/NoData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArchive, faUndo, faUsers, faUserSlash, faPlus } from '@fortawesome/free-solid-svg-icons';

const EmployeeList = () => {
  const { companyId } = useParams();
  const [employees, setEmployees] = useState({ activeEmployees: [], inactiveEmployees: [] });
  const [loading, setLoading] = useState(true); // Set initial loading to true
  const [error, setError] = useState(null);

  const history = useHistory();

  useEffect(() => {
    fetchEmployees();
  }, []);

  const fetchEmployees = () => {
    setLoading(true); // Ensure loading is true when fetching starts
    const getEmployeesApiUrl = getApiUrl(`${companyId}/hr/employees`);
    axios.get(getEmployeesApiUrl, { withCredentials: true })
      .then((response) => {
        console.log(response.data);
        setEmployees(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const archiveEmployee = (employeeId) => {
    console.log('Archiving employee:', employeeId);
    const apiUrl = getApiUrl(`${companyId}/hr/employees/${employeeId}`);
    axios.patch(apiUrl, { status: 'archived' }, { withCredentials: true })
      .then((response) => {
        fetchEmployees(); // Refresh the employee list after archiving
      })
      .catch((error) => {
        console.error('Error archiving employee:', error);
      });
  };

  const unarchiveEmployee = (employeeId) => {
    const apiUrl = getApiUrl(`${companyId}/hr/employees/${employeeId}`);
    axios.patch(apiUrl, { status: 'active' }, { withCredentials: true })
      .then((response) => {
        fetchEmployees(); // Refresh the employee list after restoring
      })
      .catch((error) => {
        console.error('Error restoring employee:', error);
      });
  };

  const tabData = [
    { name: "Aktiva", active: true, data: employees.activeEmployees, icon: faUsers, archiveAction: archiveEmployee, actionIcon: faArchive },
    { name: "Inaktiva", active: false, data: employees.inactiveEmployees, icon: faUserSlash, archiveAction: unarchiveEmployee, actionIcon: faUndo },
  ];

  return (
    <>
      <PageTitle activeMenu={"Anställda"} motherMenu={"Personal"} />

      {loading ? (
        <div className="vh-100 d-flex justify-content-center align-items-center">
          <Spinner animation="border" role="status" className="me-2">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          <div>Loading...</div>
        </div>
      ) : employees.activeEmployees.length === 0 && employees.inactiveEmployees.length === 0 ? (
        <>
          <NoData message="Det finns inga anställda att visa. Skapa en för att kunna se dem här." />
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            textAlign: 'center',
            padding: '10px'
          }}>
            <Link
              style={{ marginTop: "20px", alignItem: "center" }}
              to={`#`}
              onClick={() => history.push(`/${companyId}/create-employee`)}
              className="btn btn-primary"
            >
              Kom igång och skapa din första anställd
            </Link>
          </div>
        </>
      ) : (
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body">
                {/* Add the "Add New Employee" button */}
                <div className="d-flex justify-content-end mb-3">
                  <Link
                    to={`/${companyId}/create-employee`}
                    className="btn btn-primary"
                  >
                    <FontAwesomeIcon icon={faPlus} className="me-2" />
                    Lägg till ny anställd
                  </Link>
                </div>
                <div className="custom-tab-1">
                  <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
                    <Nav as="ul" className="nav-tabs">
                      {tabData.map((data, i) => (
                        <Nav.Item as="li" key={i}>
                          <Nav.Link eventKey={data.name.toLowerCase()}>
                            <FontAwesomeIcon icon={data.icon} className="me-2" />
                            {data.name}
                          </Nav.Link>
                        </Nav.Item>
                      ))}
                    </Nav>
                    <Tab.Content className="pt-4">
                      {tabData.map((data, i) => (
                        <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                          <EmployeeTable employees={data.data} archiveAction={data.archiveAction} actionIcon={data.actionIcon} />
                        </Tab.Pane>
                      ))}
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const EmployeeTable = ({ employees, archiveAction, actionIcon }) => {
  const { companyId } = useParams();
  const history = useHistory();

  console.log("Employees: ", employees);

  const EMPLOYEES_COLUMNS = [
    { Header: 'Id', accessor: 'employee_id', show: false, hidden: true },
    { Header: 'Namn', Footer: 'Namn', accessor: 'name' },
    { Header: 'E-post', Footer: 'Email', accessor: 'email' },
    { Header: 'Personnummer', Footer: 'Personnummer', accessor: 'ssn' },
    {
      Header: "", Footer: "", accessor: 'actions', hidden: false, disableFilters: true, Cell: props => (
        <div style={{ display: 'flex', justifyContent: 'space-between' }} onClick={(e) => e.stopPropagation()}>
          <FontAwesomeIcon
            icon={actionIcon}
            onClick={() => archiveAction(props.cell.row.original.employee_id)}
            style={{ cursor: 'pointer' }}
          />
        </div>
      )
    },
  ];

  const columns = useMemo(() => EMPLOYEES_COLUMNS, []);
  const data = useMemo(() => employees, [employees]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
    setGlobalFilter,
  } = useTable({
    columns,
    data,
    initialState: { pageIndex: 0 }
  }, useFilters, useGlobalFilter, usePagination);

  const { globalFilter } = state;

  const handleRowClick = (employeeId) => {
    history.push(`/${companyId}/employee/${employeeId}`);
  };

  return (
    <div className="table-responsive">
      <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
      <table {...getTableProps()} className="table dataTable table-hover display">
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()} style={{ display: column.hidden ? 'none' : 'table-cell' }}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row);
            return (
              <tr className='clickable-row' onClick={() => handleRowClick(row.original.employee_id)} {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()} style={{ display: cell.column.hidden ? 'none' : 'table-cell' }}>
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default EmployeeList;
