import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import axios from '../../../../services/AxiosInstance';
import { getApiUrl } from '../../../../utils/ApiUtils';

const EmployeeEditModal = ({ show, handleClose, employee, companyId, onSave }) => {
    const [employeeData, setEmployeeData] = useState(employee);

    console.log("Employee Data:", employeeData);  // Debugging

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEmployeeData({ ...employeeData, [name]: value });
    };

    const handleSave = () => {
        const updateEmployeeApiUrl = getApiUrl(`${companyId}/hr/employees/${employee.employee_id}`);

        // Create a shallow copy of employeeData
        const dataToSend = { ...employeeData };

        // Remove employee_id from the data to be sent
        delete dataToSend.employee_id;

        axios.put(updateEmployeeApiUrl, dataToSend, { withCredentials: true })
            .then((response) => {
                onSave(response.data); // callback to update the parent component
                handleClose();
            })
            .catch((error) => {
                console.error('Failed to update employee', error);
            });
    };


    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Employee</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="basic-form">
                    <div className='col-xl-12'>
                        <Form>
                            <div className="row">
                                <Form.Group className="mb-3 col-xl-6">
                                    <Form.Label>Förnamn</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="firstname"
                                        value={employeeData.firstname || ''}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3 col-xl-6">
                                    <Form.Label>Efternamn</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="lastname"
                                        value={employeeData.lastname || ''}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>
                            </div>
                            <div className="row">
                                <Form.Group className="mb-3 col-xl-6">
                                    <Form.Label>Personnummer</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="ssn"
                                        value={employeeData.ssn || ''}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3 col-xl-6">
                                    <Form.Label>Jobbtitel</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="jobtitle" // Updated name
                                        value={employeeData.jobtitle || ''} // Updated value
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>
                            </div>
                            <div className="row">
                                <Form.Group className="mb-3 col-xl-6">
                                    <Form.Label>Clearingnr.</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="clearingnumber" // Updated name
                                        value={employeeData.clearingnumber || ''} // Updated value
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3 col-xl-6">
                                    <Form.Label>Kontonr.</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="bankaccountnumber" // Updated name
                                        value={employeeData.bankaccountnumber || ''} // Updated value
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>
                            </div>
                            <div className="row">
                                <Form.Group className="mb-3 col-xl-6">
                                    <Form.Label>Telefonnummer</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="phonenumber"
                                        value={employeeData.phonenumber || ''} // Corrected value
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3 col-xl-6">
                                    <Form.Label>E-post</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="email"
                                        value={employeeData.email || ''}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>
                            </div>
                        </Form>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Avbryt
                </Button>
                <Button variant="primary" onClick={handleSave}>
                    Spara
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EmployeeEditModal;
