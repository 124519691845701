import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from '../../../../services/AxiosInstance';
import dayjs from 'dayjs';
import {
  Spinner,
  Alert,
  Card,
  Row,
  Col,
  Image,
  Button,
  Modal,
  Form,
  Table,
} from 'react-bootstrap';

import PerfectScrollbar from 'react-perfect-scrollbar';


import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

// Import your API utility function
import { getApiUrl } from '../../../../utils/ApiUtils';
import PageTitle from '../../../layouts/PageTitle';

const ViewSupplierInvoice = () => {
  const { companyId, invoiceId } = useParams();

  // State variables
  const [loading, setLoading] = useState(true);
  const [invoice, setInvoice] = useState(null);
  const [attachmentUrls, setAttachmentUrls] = useState({});
  const [error, setError] = useState(null);
  const [markAsPaidLoading, setMarkAsPaidLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const [paymentAccounts, setPaymentAccounts] = useState([]);
  const [verificationTemplates, setVerificationTemplates] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState('');

  const [transactions, setTransactions] = useState([]);
  const [totalDebit, setTotalDebit] = useState(0);
  const [totalCredit, setTotalCredit] = useState(0);

  // Add these state variables
  const [templateParameters, setTemplateParameters] = useState([]);
  const [parameterValues, setParameterValues] = useState({});
  const [showPreview, setShowPreview] = useState(false);
  const [verifications, setVerifications] = useState([]);



  // Fetch the invoice details
  useEffect(() => {
    const fetchInvoice = async () => {
      try {
        const getSupplierInvoiceApiUrl = getApiUrl(
          `${companyId}/purchasing/supplier-invoices/${invoiceId}`
        );
        const response = await axios.get(getSupplierInvoiceApiUrl, {
          withCredentials: true,
        });
        console.log('Invoice details:', response.data);
        setInvoice(response.data);
      } catch (error) {
        console.error('Error fetching invoice details:', error);
        setError('Kunde inte hämta fakturadetaljer. Vänligen försök igen senare.');
      } finally {
        setLoading(false);
      }
    };

    const fetchAssociatedVerifications = async () => {

      console.log('Fetching associated verifications...');

      try {
        const getAssociatedVerificationsApiUrl = getApiUrl(
          `${companyId}/purchasing/supplier-invoices/${invoiceId}/verifications`
        );

        const response = await axios.get(getAssociatedVerificationsApiUrl, {
          withCredentials: true,
        });

        console.log('Associated verifications:', response.data);
        setVerifications(response.data);
      } catch (error) {
        console.error('Error fetching associated verifications:', error);
        setError('Kunde inte hämta associerade verifikationer. Vänligen försök igen senare.');
      }

    };

    fetchInvoice();
    fetchAssociatedVerifications();

  }, [companyId, invoiceId]);

  // Fetch attachments when the invoice changes
  useEffect(() => {
    const fetchAttachments = async () => {
      if (invoice && invoice.attachments && invoice.attachments.length > 0) {
        const urls = {};
        for (const attachment of invoice.attachments) {
          try {
            const attachmentUrl = await fetchAttachment(attachment.attachment_id);
            urls[attachment.attachment_id] = attachmentUrl;
          } catch (error) {
            console.error('Error fetching attachment:', error);
            setError('Ett fel uppstod vid hämtning av bilagor.');
          }
        }
        setAttachmentUrls(urls);
      }
    };

    fetchAttachments();
  }, [invoice]);

  useEffect(() => {
    if (showVerificationModal) {
      fetchPaymentAccounts();
      fetchVerificationTemplates();
    }
  }, [showVerificationModal]);

  const fetchPaymentAccounts = async () => {
    setPaymentAccounts([
      {
        account_id: 1930,
        account_number: '1930',
        account_name: 'Bankkonto 1',
      },
      {
        account_id: 1931,
        account_number: '1931',
        account_name: 'Bankkonto 2',
      },
    ]);
  };

  const fetchVerificationTemplates = async () => {
    try {
      const getVerificationTemplatesApiUrl = getApiUrl(`${companyId}/templates`);
      const response = await axios.get(getVerificationTemplatesApiUrl, {
        withCredentials: true,
      });
      console.log('Verification templates:', response.data);
      setVerificationTemplates(response.data.templates);
    } catch (error) {
      console.error('Error fetching verification templates:', error);
      setError('Kunde inte hämta verifikationsmallar. Vänligen försök igen senare.');
    }
  };

  // Function to fetch an attachment from the backend and create a Blob URL
  const fetchAttachment = async (attachmentId) => {
    const attachmentApiUrl = getApiUrl(`${companyId}/attachments/${attachmentId}`);
    const response = await axios.get(attachmentApiUrl, {
      withCredentials: true,
      responseType: 'blob',
    });
    const blob = new Blob([response.data], {
      type: response.headers['content-type'],
    });
    const url = URL.createObjectURL(blob);
    return url;
  };

  // Cleanup Blob URLs when component unmounts or attachmentUrls change
  useEffect(() => {
    return () => {
      // Revoke Blob URLs to free memory
      Object.values(attachmentUrls).forEach((url) => {
        URL.revokeObjectURL(url);
      });
    };
  }, [attachmentUrls]);


  // Function to handle marking the invoice as paid
  const handleMarkAsPaid = async () => {
    setMarkAsPaidLoading(true);
    setError(null);
    try {
      // API endpoint to update the invoice status
      const updateInvoiceStatusApiUrl = getApiUrl(
        `${companyId}/purchasing/supplier-invoices/${invoiceId}/status`
      );

      // Make the API call to update the status
      await axios.put(
        updateInvoiceStatusApiUrl,
        { status: 'Betald' },
        { withCredentials: true }
      );

      // Update the invoice state
      setInvoice((prevInvoice) => ({
        ...prevInvoice,
        status: 'Betald',
      }));

      // Display success message
      setSuccessMessage('Fakturan har markerats som betald.');
    } catch (error) {
      console.error('Error updating invoice status:', error);
      setError('Kunde inte uppdatera fakturastatus. Vänligen försök igen senare.');
    } finally {
      setMarkAsPaidLoading(false);
    }
  };



  // Render loading state
  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: '100vh' }}
      >
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Laddar...</span>
        </Spinner>
      </div>
    );
  }

  const getStatusDisplayName = (status) => {
    const statusNames = {
      'Scanned': 'Faktura inläst',
      'Pending Approval': 'Väntar på godkännande',
      'Approved': 'Godkänd',
      'Pending Payment': 'Väntar på betalning',
      'Paid': 'Betald',
      'Verification Created': 'Bokförd',
      'Overdue': 'Förfallen'
    };
    return statusNames[status] || status;
  };

  const getNextAction = () => {
    console.log('Invoice status:', invoice.status);
    switch (invoice.status) {
      case 'Scanned':
        return { text: 'Godkänn faktura', nextStatus: 'Approved' };
      case 'Approved':
        return { text: 'Betala faktura', nextStatus: 'Paid' };
      case 'Paid':
        return { text: "Bokför", nextStatus: 'Verification Created' }; // No further action
      case 'Verification Created':
        return null; // No more actions
      case 'Overdue':
        return { text: 'Betala faktura', nextStatus: 'Paid' };
      default:
        return null;
    }
  };

  const nextAction = getNextAction();

  const handleStatusUpdate = async (nextStatus) => {
    setMarkAsPaidLoading(true);
    setError(null);

    try {
      // API endpoint to update the invoice status
      const updateInvoiceStatusApiUrl = getApiUrl(
        `${companyId}/purchasing/supplier-invoices/${invoiceId}/status`
      );

      // Make the API call to update the status
      const response = await axios.put(
        updateInvoiceStatusApiUrl,
        { status: nextStatus },
        { withCredentials: true }
      );

      // Update the invoice state
      setInvoice((prevInvoice) => ({
        ...prevInvoice,
        status: nextStatus,
        statusHistory: [...prevInvoice.statusHistory, { status: nextStatus, changed_at: new Date().toISOString() }]
      }));

      // Display success message
      setSuccessMessage(`Fakturan har uppdaterats till ${getStatusDisplayName(nextStatus).toLowerCase()}.`);
    } catch (error) {
      console.error('Error updating invoice status:', error);
      setError('Kunde inte uppdatera fakturastatus. Vänligen försök igen senare.');
    } finally {
      setMarkAsPaidLoading(false);
    }
  };

  const calculateTotals = (transactions) => {
    const totalDebit = transactions.reduce((acc, transaction) => acc + parseFloat(transaction.debit || 0), 0);
    const totalCredit = transactions.reduce((acc, transaction) => acc + parseFloat(transaction.credit || 0), 0);
    setTotalDebit(totalDebit);
    setTotalCredit(totalCredit);
  };

  const generateBookkeepingSuggestion = async (templateId, parameters, userSelectedAccount) => {
    try {
      const suggestionApiUrl = getApiUrl(`${companyId}/templates/${templateId}/suggestion`);
      const response = await axios.post(suggestionApiUrl, { parameters, userSelectedAccount }, { withCredentials: true });
      const sortedTransactions = response.data.suggestedTransactions.sort((a, b) => a.account_number - b.account_number);
      setTransactions(sortedTransactions);

      calculateTotals(sortedTransactions);
    } catch (error) {
      console.error('Error generating bookkeeping suggestion:', error);
    }
  };

  const handleActionClick = () => {
    if (nextAction.nextStatus === 'Verification Created') {
      console.log('Action clicked Will Create verification');
      // Open the modal for verification creation
      setShowVerificationModal(true);
    } else {
      // Show the standard confirmation modal
      setShowConfirmModal(true);
    }
  };

  const handleTemplateChange = async (e) => {

    console.log('Selected template:', e.target.value);

    const selectedTemplateId = e.target.value;
    setSelectedTemplate(selectedTemplateId);
    setTemplateParameters([]);
    setParameterValues({});
    setTransactions([]);
    setTotalDebit(0);
    setTotalCredit(0);
    setShowPreview(false);

    if (selectedTemplateId) {
      try {
        const templateDetailsUrl = getApiUrl(`${companyId}/templates/${selectedTemplateId}`);
        const response = await axios.get(templateDetailsUrl, { withCredentials: true });
        const template = response.data.template;
        setTemplateParameters(template.parameters || []);
      } catch (error) {
        setError('Kunde inte hämta malldetaljer. Vänligen försök igen senare.');
      }
    }
  };

  const areParametersFilled = () => {
    return templateParameters.every((param) => parameterValues[param.parameter_name]);
  };

  const canCreateVerification = () => {
    return (
      selectedAccount &&
      selectedTemplate &&
      areParametersFilled() &&
      transactions.length > 0 &&
      totalDebit === totalCredit &&
      !markAsPaidLoading
    );
  };

  const handleGeneratePreview = async () => {
    setError(null);
    setTransactions([]);
    setTotalDebit(0);
    setTotalCredit(0);
    setShowPreview(false);

    try {
      const parameters = { ...parameterValues };
      const userSelectedAccount = selectedAccount;

      const suggestionApiUrl = getApiUrl(`${companyId}/templates/${selectedTemplate}/suggestion`);
      const response = await axios.post(
        suggestionApiUrl,
        { parameters, userSelectedAccount },
        { withCredentials: true }
      );

      const suggestedTransactions = response.data.suggestedTransactions;

      // Calculate totals
      let totalDebit = 0;
      let totalCredit = 0;
      suggestedTransactions.forEach((txn) => {
        totalDebit += parseFloat(txn.debit || 0);
        totalCredit += parseFloat(txn.credit || 0);
      });

      setTransactions(suggestedTransactions);
      setTotalDebit(totalDebit);
      setTotalCredit(totalCredit);
      setShowPreview(true);
    } catch (error) {
      setError('Kunde inte generera förhandsgranskning. Vänligen försök igen senare.');
    }
  };

  const handleConfirmVerification = async () => {
    setMarkAsPaidLoading(true);
    setError(null);
    try {
      const createVerificationApiUrl = getApiUrl(`${companyId}/verifications`);
      const date = new Date().toISOString().split('T')[0];
      const description = `Betalning av faktura ${invoice.invoice_number}`;
      const series = 'V'; // Adjust as needed
      const attachments = []; //invoice.attachments.map((attachment) => attachment.attachment_id);
      const supplier_invoice_id = invoiceId;

      const response = await axios.post(
        createVerificationApiUrl,
        { date, description, series, transactions, attachments, supplier_invoice_id },
        { withCredentials: true }
      );

      if (response.data.success) {
        // Update the invoice status and history
        const nextStatus = 'Verification Created';
        setInvoice((prevInvoice) => ({
          ...prevInvoice,
          status: nextStatus,
          statusHistory: [
            ...prevInvoice.statusHistory,
            { status: nextStatus, changed_at: new Date().toISOString() },
          ],
        }));

        // Reset form fields
        setSelectedAccount('');
        setSelectedTemplate('');
        setTemplateParameters([]);
        setParameterValues({});
        setTransactions([]);
        setTotalDebit(0);
        setTotalCredit(0);
        setShowPreview(false);
        setShowVerificationModal(false);

        // Display success message
        setSuccessMessage('Verifikation skapad.');
      } else {
        throw new Error('Failed to create verification');
      }
    } catch (error) {
      console.error('Error creating verification:', error);
      setError('Kunde inte skapa verifikation. Vänligen försök igen senare.');
    } finally {
      setMarkAsPaidLoading(false);
    }
  };





  return (
    <>
      <PageTitle activeMenu={`Faktura ${invoice.invoice_number}`} motherMenu="Leverantörsfakturor">
      </PageTitle>
      {/* Display error messages */}
      {error && (
        <Alert variant="danger" onClose={() => setError(null)} dismissible>
          {error}
        </Alert>
      )}
      {/* Display success messages */}
      {successMessage && (
        <Alert variant="success" onClose={() => setSuccessMessage(null)} dismissible>
          {successMessage}
        </Alert>
      )}

      {/* Check if invoice exists */}
      {!invoice ? (
        <Alert variant="warning">Fakturan kunde inte hittas.</Alert>
      ) : (
        <>

          <div className='col-xl-12'>
            <div className='col-xl-12 mb-5' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center-horizontal' }}>
              <h2>Fakturadetaljer</h2>
              <div>
                {nextAction && (
                  <div className="mt-4">
                    <Button
                      variant="primary"
                      onClick={() => handleActionClick()}
                      disabled={markAsPaidLoading}
                    >
                      {markAsPaidLoading ? 'Uppdaterar...' : nextAction.text}
                    </Button>
                  </div>
                )}
              </div>
            </div>
            {/* Timeline */}
            <div className="col-xl-12 col-xxl-12 col-lg-12 widget-timeline">
              <ul className="timeline">
                {invoice.statusHistory && invoice.statusHistory.map((statusEvent, index) => (
                  <li key={index}>
                    <div className="timeline-badge primary"></div>
                    <div className="timeline-panel text-muted">
                      <span>{dayjs(statusEvent.changed_at).format('YYYY-MM-DD HH:mm')}</span>
                      <h6 className="mb-0">{getStatusDisplayName(statusEvent.status)}</h6>
                      {statusEvent.status === 'Verification Created' && verifications && verifications.length > 0 && (
                        <div className="mt-1" style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                          <span style={{ marginRight: '8px' }}>Verifikat:</span>
                          {verifications.map((verification, index) => (
                            <span key={verification.verification_id} style={{ display: 'flex', alignItems: 'center' }}>
                              <Link to={`/company/${companyId}/verifications/${verification.verification_id}`}>
                                {verification.series}{verification.number}
                              </Link>
                              {index < verifications.length - 1 ? ', ' : ''}
                            </span>
                          ))}
                        </div>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <Card className="mt-4">
            <Card.Body>
              <Row>
                <div className="accordion_body">
                  <div style={{ display: "flex", flexFlow: "wrap", justifyContent: "space-between" }}>
                    <div className="me-3 mb-3">
                      <p className="fs-12 mb-2">Mottagare</p>
                      <span className="font-w500">
                        <strong>
                          {invoice.supplier_name}
                        </strong>
                      </span>
                    </div>
                    <div className="me-3 mb-3">
                      <p className="fs-12 mb-2">Fakturanr.</p>
                      <span className="font-w500">
                        <strong>
                          {invoice.invoice_number}
                        </strong>
                      </span>
                    </div>
                    <div className="me-3 mb-3">
                      <p className="fs-12 mb-2">Fakturadatum</p>
                      <span className="font-w500">
                        <strong>{dayjs(invoice.invoice_date).format('DD MMM YYYY')}
                        </strong>
                      </span>
                    </div>
                    <div className="me-3 mb-3">
                      <p className="fs-12 mb-2">Förfallodatum</p>
                      <span className="font-w500">
                        <strong>{dayjs(invoice.due_date).format('DD MMM YYYY')}</strong>
                      </span>
                    </div>
                    <div className="me-3 mb-3">
                      <p className="fs-12 mb-2">Belopp</p>
                      <span className="font-w500">
                        <strong>{invoice.amount} kr</strong>
                      </span>
                    </div>
                  </div>
                </div>

              </Row>


            </Card.Body>
          </Card>

          {/* Confirmation Modal */}
          {nextAction && (
            <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
              <Modal.Header closeButton>
                <Modal.Title>Bekräfta åtgärd</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Är du säker på att du vill {nextAction.text.toLowerCase()}?
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
                  Avbryt
                </Button>
                <Button
                  variant="success"
                  onClick={() => {
                    setShowConfirmModal(false);
                    handleStatusUpdate(nextAction.nextStatus);
                  }}
                  disabled={markAsPaidLoading}
                >
                  {markAsPaidLoading ? 'Uppdaterar...' : 'Ja, ' + nextAction.text.toLowerCase()}
                </Button>
              </Modal.Footer>
            </Modal>
          )}

          {/* Verification selection modal*/}
          <Modal show={showVerificationModal} onHide={() => setShowVerificationModal(false)} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Skapa verifikation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {error && (
                <Alert variant="danger" onClose={() => setError(null)} dismissible>
                  {error}
                </Alert>
              )}
              <Form>
                {/* Payment Account Selection */}
                <Form.Group controlId="paymentAccount">
                  <Form.Label>Välj betalningskonto</Form.Label>
                  <Form.Control
                    as="select"
                    value={selectedAccount}
                    onChange={(e) => setSelectedAccount(e.target.value)}
                  >
                    <option value="">-- Välj konto --</option>
                    {paymentAccounts.map((account) => (
                      <option key={account.account_id} value={account.account_id}>
                        {account.account_number} - {account.account_name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>

                {/* Verification Template Selection */}
                <Form.Group controlId="verificationTemplate" className="mt-3">
                  <Form.Label>Välj verifikationsmall</Form.Label>
                  <Form.Control
                    as="select"
                    value={selectedTemplate}
                    onChange={handleTemplateChange}
                  >
                    <option value="">-- Välj mall --</option>
                    {verificationTemplates.map((template) => (
                      <option key={template.template_id} value={template.template_id}>
                        {template.template_name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>

                {/* Parameter Inputs */}
                {templateParameters.length > 0 && (
                  <div className="mt-3">
                    <h5>Parametrar</h5>
                    {templateParameters.map((param, index) => (
                      <Form.Group key={index} controlId={`param-${param.parameter_name}`} className="mt-2">
                        <Form.Label>{param.parameter_label}</Form.Label>
                        <Form.Control
                          type={param.parameter_type === 'Number' ? 'number' : 'text'}
                          value={parameterValues[param.parameter_name] || ''}
                          onChange={(e) =>
                            setParameterValues({
                              ...parameterValues,
                              [param.parameter_name]: e.target.value,
                            })
                          }
                        />
                      </Form.Group>
                    ))}
                  </div>
                )}

                {/* Generate Preview Button */}
                {selectedTemplate && (
                  <Button
                    variant="primary"
                    className="mt-3"
                    onClick={handleGeneratePreview}
                    disabled={!selectedAccount || !areParametersFilled()}
                  >
                    Generera förhandsgranskning
                  </Button>
                )}
              </Form>

              {/* Transactions Preview */}
              {showPreview && transactions.length > 0 && (
                <div className="mt-4">
                  <h5>Förhandsgranskning av verifikation</h5>
                  <Table bordered>
                    <thead>
                      <tr>
                        <th>Konto</th>
                        <th>Beskrivning</th>
                        <th>Debet</th>
                        <th>Kredit</th>
                      </tr>
                    </thead>
                    <tbody>
                      {transactions.map((transaction, index) => (
                        <tr key={index}>
                          <td>{transaction.account_number} - {transaction.account_name}</td>
                          <td>{transaction.description}</td>
                          <td>{transaction.debit ? parseFloat(transaction.debit).toFixed(2) : ''}</td>
                          <td>{transaction.credit ? parseFloat(transaction.credit).toFixed(2) : ''}</td>
                        </tr>
                      ))}
                      <tr>
                        <td colSpan="2"><strong>Totalt</strong></td>
                        <td><strong>{totalDebit.toFixed(2)}</strong></td>
                        <td><strong>{totalCredit.toFixed(2)}</strong></td>
                      </tr>
                    </tbody>
                  </Table>
                  <Alert variant={totalDebit === totalCredit ? 'success' : 'danger'}>
                    {totalDebit === totalCredit
                      ? 'Verifikationen är balanserad.'
                      : 'Debet och kredit balanserar inte!'}
                  </Alert>
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowVerificationModal(false)}>
                Avbryt
              </Button>
              <Button
                variant="success"
                onClick={handleConfirmVerification}
                disabled={!canCreateVerification()}
              >
                {markAsPaidLoading ? 'Skapar...' : 'Bekräfta och skapa verifikation'}
              </Button>
            </Modal.Footer>
          </Modal>


          {/* Display Attachments */}
          {
            invoice.attachments && invoice.attachments.length > 0 && (
              <div className="mt-4">
                <h4>Bifogade filer</h4>
                <ul className="list-group">
                  {invoice.attachments.map((attachment) => (
                    <li key={attachment.attachment_id} className="list-group-item">
                      <div className="d-flex flex-column">
                        <span>{attachment.file_name}</span>
                        <div className="mt-2">
                          {attachment.file_type === 'application/pdf' &&
                            attachmentUrls[attachment.attachment_id] ? (
                            <div style={{ height: '750px' }}>
                              <Worker
                                workerUrl={`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js`}>
                                <Viewer fileUrl={attachmentUrls[attachment.attachment_id]} />
                              </Worker>
                            </div>
                          ) : attachment.file_type.startsWith('image/') &&
                            attachmentUrls[attachment.attachment_id] ? (
                            <img
                              src={attachmentUrls[attachment.attachment_id]}
                              alt={attachment.file_name}
                              style={{ maxWidth: '100%', height: 'auto' }}
                            />
                          ) : attachmentUrls[attachment.attachment_id] ? (
                            <a
                              href={attachmentUrls[attachment.attachment_id]}
                              download={attachment.file_name}
                              className="btn btn-secondary btn-sm"
                            >
                              Download
                            </a>
                          ) : (
                            <span>Loading...</span>
                          )}
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>

            )
          }
        </>
      )
      }
    </>
  );
};

export default ViewSupplierInvoice;
