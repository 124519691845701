import React, { useMemo } from 'react';
import { useTable } from 'react-table';
import { useHistory } from 'react-router-dom';
import { Badge } from 'react-bootstrap';

const InvoiceTable = ({ invoices, companyId }) => {
  const history = useHistory();

  const COLUMNS = useMemo(() => [
    {
      Header: 'Id',
      accessor: 'invoicenumber',
      Cell: props => <a href="#">{props.value}</a>,
    },
    {
      Header: 'Kund',
      accessor: 'name',
      Cell: props => <a href="#">{props.value}</a>,
    },
    {
      Header: 'Fakturadatum',
      accessor: 'invoicedate',
    },
    {
      Header: 'Förfallodatum',
      accessor: 'deadline',
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: props => {
        const { original } = props.row;
        if (props.value === 'Draft') {
          return (
            <Badge bg="light">
              {props.value}
            </Badge>
          );
        } else if (props.value === 'Published') {
          return (
            <Badge bg="success">
              {props.value}
            </Badge>
          );

        }
      },
    },
  ], []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns: COLUMNS, data: invoices });

  const handleRowClick = (invoiceId) => {
    history.push(`/${companyId}/invoices/${invoiceId}`);
  };

  return (
    <div className="table-responsive">
      <table {...getTableProps()} className="table">
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} onClick={() => handleRowClick(row.original.id)} style={{ cursor: 'pointer' }}>
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default InvoiceTable;
