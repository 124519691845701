import React from 'react';

const NonEditableEmployeePersonalDetails = ({ employee }) => {
  return (
    <div className="basic-form">
      <div className="row">
        <div className="form-group mb-3 col-md-4">
          <label>Förnamn</label>
          <div>{employee.firstname}</div>
        </div>
        <div className="form-group mb-3 col-md-4">
          <label>Efternamn</label>
          <div>{employee.lastname}</div>
        </div>
      </div>
      <div className="row">
        <div className="form-group mb-3 col-md-4">
          <label>Personnummer</label>
          <div>{employee.ssn}</div>
        </div>
        <div className="form-group mb-3 col-md-4">
          <label>Jobbtitel</label>
          <div>{employee.jobtitle}</div>
        </div>
      </div>
      <div className="row">
        <div className="form-group mb-3 col-md-4">
          <label>Clearingnummer</label>
          <div>{employee.clearingnumber}</div>
        </div>
        <div className="form-group mb-3 col-md-4">
          <label>Bankkonto</label>
          <div>{employee.bankaccountnumber}</div>
        </div>
      </div>
      <div className="row">
        <div className="form-group mb-3 col-md-4">
          <label>Telefonnummer</label>
          <div>{employee.phonenumber}</div>
        </div>
        <div className="form-group mb-3 col-md-4">
          <label>Email</label>
          <div>{employee.email}</div>
        </div>
      </div>
      <div className="row">
        <div className="form-group mb-3 col-md-4">
          <label>Adress</label>
          <div>{employee.address}</div>
        </div>
        <div className="form-group mb-3 col-md-4">
          <label>Postnummer</label>
          <div>{employee.postnumber}</div>
        </div>
        <div className="form-group mb-3 col-md-4">
          <label>Ort</label>
          <div>{employee.city}</div>
        </div>
      </div>
    </div>
  );
};

export default NonEditableEmployeePersonalDetails;
