import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';

const EditPayslipRowModal = ({ show, handleClose, item, handleEdit, handleDelete }) => {
    const [editedItem, setEditedItem] = useState(item);

    useEffect(() => {
        setEditedItem(item);
    }, [item]);

    const handleInputChange = (event) => {
        setEditedItem({ ...editedItem, [event.target.name]: event.target.value });
    };

    console.log("The item:", editedItem);

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Item</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    {/* Add form fields for each property of the item that can be edited */}
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>Wage Type</Form.Label>
                                <Form.Control type="text" name="wageType" value={editedItem ? editedItem.wageType.code + ', ' + editedItem.wageType.description : ''} onChange={handleInputChange} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Quantity</Form.Label>
                                <Form.Control type="text" name="quantity" value={editedItem ? editedItem.quantity : ''} onChange={handleInputChange} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Rate</Form.Label>
                                <Form.Control type="text" name="rate" value={editedItem ? editedItem.rate : ''} onChange={handleInputChange} />
                            </Form.Group>
                        </Col>
                    </Row>
                    {/* Repeat for other properties */}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Close</Button>
                <Button variant="primary" onClick={() => handleEdit(editedItem)}>Save Changes</Button>
                <Button variant="danger" onClick={() => handleDelete(item)}>Delete</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditPayslipRowModal;