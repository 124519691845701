import React, { useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import axios from '../../../../services/AxiosInstance';
import FileDropZone from "../../../components/SharedComponents/FileDropZone.js";
import ProductsAndArticlesCard from './components/ProductAndArticlesCard.js';
import DeliveryOptions from './components/InvoiceDeliveryOptions';
import DatePicker from 'react-datepicker';
import { getApiUrl } from '../../../../utils/ApiUtils.js';
import { parseISO, formatISO } from 'date-fns';
import swal from 'sweetalert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faMapMarkerAlt, faBuilding } from '@fortawesome/free-solid-svg-icons';
import PageTitle from '../../../layouts/PageTitle';
import { tr } from 'date-fns/locale';

function CustomerDetails({ customer }) {
  if (!customer) {
    return null;
  }

  const sectionStyle = {
    border: '1px solid #ddd',
    padding: '15px',
    marginBottom: '15px',
    borderRadius: '5px'
  };

  return (
    <div className="row mt-4">
      <div className="col-md-4">
        <div style={sectionStyle}>
          <h5><FontAwesomeIcon icon={faUser} /> Kontaktperson</h5>
          <p>
            {customer.contactname}<br />
            {customer.email}<br />
            {customer.phone}
          </p>
        </div>
      </div>
      <div className="col-md-4">
        <div style={sectionStyle}>
          <h5><FontAwesomeIcon icon={faMapMarkerAlt} /> Adress</h5>
          <p>
            {customer.street1}<br />
            {customer.postalcode} {customer.city}<br />
            {customer.country}
          </p>
        </div>
      </div>
      <div className="col-md-4">
        <div style={sectionStyle}>
          <h5><FontAwesomeIcon icon={faBuilding} /> Företagsdetaljer</h5>
          <p>
            Org. Nr.: {customer.orgnumber}<br />
            VAT. Nr.: {customer.vatnumber || customer.orgnumber}
          </p>
        </div>
      </div>
    </div>
  );
}

const InvoiceForm = () => {
  const { companyId, invoiceId } = useParams();
  const isEditMode = !!invoiceId;
  const history = useHistory();

  // State variables
  const [invoiceData, setInvoiceData] = useState(null);
  const [items, setItems] = useState([]);
  const [articles, setArticles] = useState([]);
  const [selectedCustomerValue, setSelectedCustomerValue] = useState(null);
  const [invoiceDate, setInvoiceDate] = useState(new Date());
  const [invoiceDeadline, setInvoiceDeadline] = useState(new Date());
  const [deliveryOption, setDeliveryOption] = useState({ method: '', email: '' });
  const [files, setFiles] = useState([]);
  const [invoiceAttachments, setInvoiceAttachments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);


  // Fetch articles on mount
  useEffect(() => {
    fetchArticles();
    fetchCompanySettings();

  }, []);

  // Fetch invoice details if in edit mode
  useEffect(() => {
    if (isEditMode) {
      fetchInvoiceDetails(invoiceId);
    }
  }, [invoiceId]);

  const fetchArticles = async () => {
    const getArticlesApiUrl = getApiUrl(`${companyId}/articles`);
    try {
      const result = await axios.get(getArticlesApiUrl, { withCredentials: true });
      setArticles(result.data.data);
    } catch (error) {
      swal("Error", "Error fetching articles", "error");
    }
  };

  const fetchInvoiceDetails = async (invoiceId) => {
    const getInvoiceDetailsApiUrl = getApiUrl(`${companyId}/invoices/${invoiceId}/details`);
    try {
      const response = await axios.get(getInvoiceDetailsApiUrl, { withCredentials: true });
      setInvoiceData(response.data);
      setItems(response.data.items);
      setInvoiceAttachments(response.data.attachments);

      // Extract customer data from invoice data
      const customerData = {
        id: response.data.customerid,
        namn: response.data.customername,
        orgnumber: response.data.orgnumber,
        vatnumber: response.data.vatnumber,
        contactname: response.data.contactname,
        email: response.data.email,
        phone: response.data.phone,
        street1: response.data.street1,
        postalcode: response.data.postalcode,
        city: response.data.city,
        country: response.data.country,
        kundnummer: response.data.kundnummer,
      };

      console.log('selected customer Customer data:', customerData);
      setSelectedCustomerValue(customerData);

      setInvoiceDate(parseISO(response.data.invoicedate));
      setInvoiceDeadline(parseISO(response.data.deadline));
    } catch (error) {
      swal("Error", "Error fetching invoice data", "error");
    }
  };

  const fetchCompanySettings = async () => {
    const getCompanySettingsApiUrl = getApiUrl(`${companyId}/settings`);
    try {
      const response = await axios.get(getCompanySettingsApiUrl, { withCredentials: true });
      console.log('Company settings:', response.data);
    } catch (error) {
      swal("Error", "Error fetching company settings", "error");
    }
  };

  // Handle customer selection
  const handleChangeCustomerSelection = async (value) => {

    console.log('Selected customer:', value);
    setSelectedCustomerValue(value);



    try {
      if (!isEditMode) {
        const createInvoiceApiUrl = getApiUrl(`${companyId}/invoices`);

        // Create new invoice
        const res = await axios.post(createInvoiceApiUrl,
          {
            customerid: value.id,
            customername: value.namn
          }, { withCredentials: true }
        );

        if (res.data.success) {
          history.push(`/${companyId}/edit-invoice/${res.data.invoiceId}`);
        } else {
          swal("Error", "Failed to create invoice", "error");
        }
      } else {
        const updateInvoiceApiUrl = getApiUrl(`${companyId}/invoices/${invoiceId}`);

        const updatedCustomerData = {
          invoiceId: invoiceId,
          customer_id: value.id,
        };

        console.log('updatedCustomerData:', updatedCustomerData);

        // Update existing invoice with new customer
        const res = await axios.put(updateInvoiceApiUrl, updatedCustomerData, { withCredentials: true });

        if (res.data.success) {
          await fetchInvoiceDetails(invoiceId);
          swal("Success", "Customer updated successfully", "success");
        } else {
          swal("Error", "Failed to update invoice customer", "error");
        }
      }
    } catch (error) {
      swal("Error", "Error updating invoice", "error");
    }
  };

  // Handle adding invoice item
  const handleAddInvoiceItem = (item, hasChanged) => {
    addInvoiceItem(item, hasChanged);
  };

  const addInvoiceItem = async (item, hasChanged) => {
    const addInvoiceItemApiUrl = getApiUrl(`${companyId}/invoices/${invoiceId}/items`);

    try {
      const res = await axios.post(addInvoiceItemApiUrl, {
        articleid: hasChanged ? '' : item.id,
        quantity: item.quantity,
        productcode: item.productcode,
        description: item.description,
        unitprice: item.unitprice,
        tax_rate: item.tax_rate,
        unittype: item.unittype,
        unit: item.unit,
      }, { withCredentials: true });

      if (res.data.success) {
        fetchInvoiceDetails(invoiceId);
      } else {
        swal("Error", "Failed to add invoice item", "error");
      }
    } catch (err) {
      swal("Error", "Error adding invoice item", "error");
    }
  };

  // Handle editing invoice item
  const handleEditInvoiceItem = (item) => {
    console.log('item:', item);
    editInvoiceItem(item);
  };

  const editInvoiceItem = async (item) => {
    const editInvoiceItemApiUrl = getApiUrl(`${companyId}/invoices/${invoiceId}/items/${item.item_id}`);

    try {
      const res = await axios.put(editInvoiceItemApiUrl, {
        itemid: item.item_id,
        quantity: item.quantity,
        productcode: item.productcode,
        description: item.description,
        unitprice: item.unitprice,
        tax_rate: item.tax_rate,
        unittype: item.unittype,
        unit: item.unit,
      }, { withCredentials: true });

      if (res.data.success) {
        fetchInvoiceDetails(invoiceId);
      } else {
        swal("Error", "Failed to update invoice item", "error");
      }
    } catch (err) {
      swal("Error", "Error updating invoice item", "error");
    }
  };

  // Handle deleting invoice item
  const handleDeleteInvoiceItem = (item) => {
    deleteInvoiceItem(item);
  };

  const deleteInvoiceItem = async (item) => {
    const deleteInvoiceItemApiUrl = getApiUrl(`${companyId}/invoices/${invoiceId}/items/${item.item_id}`);

    try {
      const res = await axios.delete(deleteInvoiceItemApiUrl, { withCredentials: true });
      if (res.data.success) {
        fetchInvoiceDetails(invoiceId);
      } else {
        swal("Error", "Failed to delete invoice item", "error");
      }
    } catch (err) {
      swal("Error", "Error deleting invoice item", "error");
    }
  };

  // Handle date changes
  const handleDateChange = async (date) => {
    setInvoiceDate(date);
    await updateInvoiceDates(date, invoiceDeadline);
  };

  const handleDeadlineChange = async (date) => {
    setInvoiceDeadline(date);
    await updateInvoiceDates(invoiceDate, date);
  };

  const updateInvoiceDates = async (invoicedate, deadline) => {
    const updateDatesApiUrl = getApiUrl(`${companyId}/invoices/${invoiceId}/dates`);

    try {
      const response = await axios.put(updateDatesApiUrl, {
        invoiceId: invoiceId,
        invoicedate: formatISO(invoicedate, { representation: 'date' }),
        deadline: formatISO(deadline, { representation: 'date' }),
      }, { withCredentials: true });

      if (response.data.success) {
        fetchInvoiceDetails(invoiceId);
      } else {
        swal("Error", "Failed to update invoice dates", "error");
      }
    } catch (error) {
      swal("Error", "Error updating invoice dates", "error");
    }
  };

  // Handle rounding checkbox change
  const handleRoundingCheckChange = async (e) => {
    const isChecked = e.target.checked;
    const updateRoundingApiUrl = getApiUrl(`${companyId}/invoices/${invoiceId}/rounding`);

    try {
      const response = await axios.put(updateRoundingApiUrl, {
        invoiceId: invoiceId,
        disable_rounding: isChecked ? 0 : 1,
      }, { withCredentials: true });

      if (response.data.success) {
        fetchInvoiceDetails(invoiceId);
      } else {
        swal("Error", "Failed to update rounding", "error");
      }
    } catch (error) {
      swal("Error", "Error updating rounding", "error");
    }
  };

  // Handle publishing invoice
  const handlePublish = () => {
    if (!deliveryOption.method) {
      swal("Warning", "Please select a delivery option.", "warning");
      return;
    }

    publishInvoice();
  };

  const publishInvoice = async () => {
    const publishInvoiceApiUrl = getApiUrl(`${companyId}/invoices/${invoiceId}/publish`);

    try {
      const response = await axios.post(publishInvoiceApiUrl, {
        invoiceId: invoiceId,
        action: deliveryOption.method,
        email: deliveryOption.method === 'email' ? deliveryOption.email : undefined,
      }, {
        withCredentials: true,
        responseType: deliveryOption.method === 'download' ? 'blob' : 'json',
      });

      if (deliveryOption.method === 'download') {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'invoice.pdf';
        link.click();
      }

      swal("Success", "Invoice published successfully", "success").then(() => {
        history.push(`/${companyId}/invoices`);
      });
    } catch (error) {
      swal("Error", "Error publishing invoice", "error");
    }
  };

  // Handle file upload
  const handleFileUpload = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('relatedId', invoiceId);
    formData.append('relatedType', "invoice");

    const uploadAttachmentApiUrl = getApiUrl(`${companyId}/invoices/${invoiceId}/addattachment`);

    try {
      const response = await axios.post(uploadAttachmentApiUrl, formData, {
        withCredentials: true,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.success) {
        swal("Success", `File ${file.name} uploaded successfully`, "success");
        fetchInvoiceDetails(invoiceId);
      } else {
        swal("Error", `Failed to upload file ${file.name}`, "error");
      }
    } catch (error) {
      swal("Error", `Failed to upload file ${file.name}`, "error");
    } finally {
      setIsLoading(false);
    }
  };

  // Handle file delete
  const handleFileDelete = async (attachment_id) => {
    const deleteFileAttachmentApiUrl = getApiUrl(`${companyId}/invoices/${invoiceId}/removeattachment/${attachment_id}`);

    try {
      const response = await axios.post(deleteFileAttachmentApiUrl, {}, { withCredentials: true });

      if (response.data.success) {
        swal("Success", `File deleted successfully`, "success");
        fetchInvoiceDetails(invoiceId);
      } else {
        swal("Error", `Failed to delete file`, "error");
      }
    } catch (error) {
      swal("Error", `Error deleting file`, "error");
    }
  };

  // Fetch customers
  const fetchCustomers = async () => {
    const getCustomersApiUrl = getApiUrl(`${companyId}/sales/customers`);

    try {
      const result = await axios.get(getCustomersApiUrl, { withCredentials: true });
      console.log("The data: ", result);
      return result.data;
    } catch (error) {
      swal("Error", "Error fetching customers", "error");
    }
  };

  return (
    <>
      <PageTitle activeMenu="Skapa Faktura" motherMenu="Fakturor" />
      <div className="row">
        {/* Customer Selection */}
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <h4 className="fs-24 font-w800">Kund</h4>
              <div className="row">
                <div className="col-xl-12">
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    value={selectedCustomerValue}
                    getOptionLabel={e => `${e.kundnummer} - ${e.namn} - ${e.orgnumber}`}
                    getOptionValue={e => e.id}
                    loadOptions={fetchCustomers}
                    onChange={handleChangeCustomerSelection}
                    placeholder="Välj en kund..."
                  />
                </div>
              </div>
              <CustomerDetails customer={selectedCustomerValue} />
            </div>
          </div>
        </div>

        {/* Products and Articles */}
        <div className="col-xl-12">
          <ProductsAndArticlesCard
            invoiceData={invoiceData}
            items={items}
            articles={articles}
            handleAddInvoiceItem={handleAddInvoiceItem}
            handleEditInvoiceItem={handleEditInvoiceItem}
            handleDeleteInvoiceItem={handleDeleteInvoiceItem}
            handleCheckChange={handleRoundingCheckChange}
          />
        </div>

        {/* Invoice Terms */}
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <h4 className="fs-24 font-w800">Villkor</h4>
              <div className="row mb-4">
                <div className="col-md-6 mb-3">
                  <div className="example rangeDatePicker">
                    <p className="mb-1">Fakturadatum</p>
                    <DatePicker
                      className='form-control'
                      dateFormat='yyyy-MM-dd'
                      selected={invoiceDate}
                      onChange={handleDateChange}
                    />
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="example rangeDatePicker">
                    <p className="mb-1">Förfallodatum</p>
                    <DatePicker
                      className='form-control'
                      dateFormat='yyyy-MM-dd'
                      selected={invoiceDeadline}
                      onChange={handleDeadlineChange}
                    />
                  </div>
                </div>
              </div>
              {/* Additional terms can be added here */}
            </div>
          </div>
        </div>

        {/* Attachments */}
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <h4 className="fs-24 font-w800">Tilläggsinformation</h4>
              <h4 className="fs-24 font-w800 mt-5">Bifoga fil</h4>
              <div className="row mt-4">
                <div className="col-xl-6">
                  <FileDropZone
                    setFiles={setFiles}
                    mainText="Dra och släpp filer inom det sträckade området för att lägga till bilagor till din faktura"
                    minHeight='150px'
                    autoUpload={true}
                    handleUpload={handleFileUpload}
                    handleFileDelete={handleFileDelete}
                    predefinedFiles={invoiceAttachments.map(attachment => ({
                      id: attachment.attachment_id,
                      name: attachment.file_name,
                      size: 10,
                    }))}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Delivery Options */}
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <h4 className="fs-24 font-w800">Leveransmetod</h4>
              <div className="row mb-4">
                <DeliveryOptions onDeliveryOptionChange={setDeliveryOption} />
              </div>
            </div>
          </div>
        </div>

        {/* Publish and Preview Buttons */}
        <div className="col-xl-6">
          <div className="text-begin mt-4">
            <Link to={"#"} onClick={handlePublish} className="btn btn-primary btn me-1 me-sm-3">Publicera</Link>
            <Link to={"#"} className="btn btn-primary light btn me-sm-3">Förhandsgranska</Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvoiceForm;
