import { Modal, Form } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { useState, useEffect } from 'react';


// ...

const RoleModal = ({ show, handleClose, user, roles, allRoles, handleSaveRoles }) => {
    const [selectedRoles, setSelectedRoles] = useState([]);

    useEffect(() => {
        if (user) {
            setSelectedRoles(user.roles);
        }
    }, [user]);

    const handleRoleChange = (e) => {
        const roleName = e.target.name;
        if (e.target.checked) {
            setSelectedRoles([...selectedRoles, roleName]);
        } else {
            setSelectedRoles(selectedRoles.filter((role) => role !== roleName));
        }
    };

    const handleSubmit = () => {
        handleSaveRoles(user.user_id, selectedRoles);
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Ändra roller för {user?.first_name} {user?.last_name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    {allRoles.map((role) => (
                        <Form.Group controlId={`role-${role.role_name}`} key={role.role_id}>
                            <Form.Check
                                type="checkbox"
                                label={role.role_name}
                                name={role.role_name}
                                checked={selectedRoles.includes(role.role_name)}
                                onChange={handleRoleChange}
                            />
                        </Form.Group>
                    ))}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Avbryt</Button>
                <Button variant="primary" onClick={handleSubmit}>Spara</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default RoleModal;