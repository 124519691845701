import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from '../../../../services/AxiosInstance';
import EmployeeContractDetails from './ContractDetailsComponent.js';
import { tr } from 'date-fns/locale';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min.js';

import { getApiUrl } from '../../../../utils/ApiUtils';

const NewContract = () => {

  const { companyId, id } = useParams();

  const [employee, setEmployee] = useState({
    firstname: '',
    lastname: '',
    ssn: '',
    jobtitle: '',
    clearingnumber: '',
    bankaccountnumber: '',
    phonenumber: '',
    email: '',
    address: '',
    postnummer: '',
    ort: '',
    active: false,
    contractDetails: {
      startdate: '',
      employmenttype: '',
      salary: '',
      salaryperiod: '',
      workweek: '40',
      employmentextent: '',
      accordingtocollectiveagreement: true,
      taxtable: '',
      taxcolumn: '',
      workplace: '',
      otherterms: '',
    },
  });

  const onChange = e => setEmployee({ ...employee, [e.target.name]: e.target.value });

  const onSubmit = async e => {
    e.preventDefault();
    await axios.post('/api/employee', employee);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log("hej");
    console.log(employee.contractDetails);
    console.log("hej");

    try {
      const createEmployeeContractApiUrl = getApiUrl(`${companyId}/hr/employees/${id}/contracts`);
      const response = await axios.post(createEmployeeContractApiUrl, employee.contractDetails, { withCredentials: true });
      console.log(response);
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e, nestedField = null) => {
    var { name, value } = e.target;

    console.log(name);
    console.log(value);

    if (nestedField) {
      setEmployee({
        ...employee,
        contractDetails: {
          ...employee.contractDetails,
          [name]: value
        }
      });
    } else {
      setEmployee({
        ...employee,
        [name]: value
      });
    }
  };

  return (
    <div>
      <div className="page-title">
        <div className="row">
          <div className="col-12 col-md-6 order-md-1 order-last">
            <h3>Nytt kontrakt</h3>
          </div>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav aria-label="breadcrumb" className="breadcrumb-header float-start float-lg-end">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/apps/personell">Personell</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Ny anställning</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <EmployeeContractDetails handleInputChange={handleInputChange} employee={employee} />
                <div className="row">
                  <div className="col-lg-12">
                    <div className="mb-3">
                      <button type="submit" className="btn btn-primary">Submit</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewContract;