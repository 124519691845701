import { Link } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import axios from '../../services/AxiosInstance';
import { ListGroup, Button, Spinner, Modal } from "react-bootstrap";
import { selectCompany } from '../../store/actions/AuthActions';
import { getApiUrl } from "../../utils/ApiUtils";

import { logout } from '../../store/actions/AuthActions';
import { useSelector } from 'react-redux';
import { getUid } from '../../store/selectors/AuthSelectors';
import { el } from "date-fns/locale";

import loginbg from "../../images/bg-login.jpg";



function SelectCompany(props) {

  console.log("Props: ", props);

  let uid = props.googleId;
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState({ show: false, companyId: null, companyName: '' });
  const dispatch = useDispatch();
  const history = useHistory();

  if (uid === null) {
    console.log("UID is null, will get it from redux store");
  } else {
    console.log("UID is not null: ", uid);
  }

  uid = useSelector((state) => getUid(state));

  const getCompaniesUrl = getApiUrl(`auth/users/companies?googleId=${uid}`);

  useEffect(() => {
    setLoading(true);
    axios.get(getCompaniesUrl, { withCredentials: true })
      .then(response => {
        setCompanies(response.data);
      })
      .catch(error => {
        console.error('Error fetching companies:', error);
      })
      .finally(() => setLoading(false));
  }, [dispatch, history]);

  const handleCompanyClick = (companyId, companyName, companyRegNumber) => {
    dispatch(selectCompany(companyId, companyName, companyRegNumber, history));
  };

  const handleDelete = () => {
    const deleteCompanyUrl = getApiUrl(`auth/company/${deleteModal.companyId}`);
    axios.delete(deleteCompanyUrl, { withCredentials: true })
      .then(response => {
        setCompanies(companies.filter(company => company.company_id !== deleteModal.companyId));
      })
      .catch(error => {
        console.error('Error deleting company:', error);
      })
      .finally(() => setDeleteModal({ show: false, companyId: null, companyName: '' }));
  };

  const handleLogout = () => {
    dispatch(logout(history));
  };

  const handleCreateCompany = () => {
    history.push('/create-company');
  };

  return (
    <div className="authincation h-100 p-meddle" style={{ backgroundImage: "url(" + loginbg + ")" }}>
      <div className="container h-100">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <Link to="/login" className="text-primary h4">LedgerLoop</Link>
                      <Button variant="link" className="text-secondary" onClick={handleLogout}>Logga ut</Button>
                    </div>

                    {loading ? (
                      <div className="text-center">
                        <Spinner animation="border" />
                      </div>
                    ) : companies.length === 0 ? (
                      <h4 className="text-center mb-4">Du har ännu inga företag kopplade till dig. Registrera ett nytt.</h4>
                    ) : (
                      <>
                        <h4 className="text-center mb-4">Välj det företag som du vill administrera.</h4>
                        <div className="basic-list-group mb-5">
                          <ListGroup>
                            {companies.map(company => (
                              <ListGroup.Item
                                key={company.company_id}
                                action
                                onClick={() => handleCompanyClick(company.company_id, company.company_name, company.registration_number)}
                              >
                                <div className="d-flex justify-content-between align-items-center">
                                  <div>
                                    <h5 className="mb-1">{company.company_name}</h5>
                                    <small className="text-muted">Org. Nr: {company.registration_number}</small>
                                  </div>
                                  <Button
                                    variant="link"
                                    className="text-danger"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setDeleteModal({ show: true, companyId: company.company_id, companyName: company.company_name });
                                    }}
                                  >
                                    <i className="fa fa-trash" />
                                  </Button>
                                </div>
                              </ListGroup.Item>
                            ))}
                          </ListGroup>
                        </div>
                      </>
                    )}

                    <div className="text-center mb-4">eller</div>

                    <Button onClick={handleCreateCompany} className="btn btn-primary btn-block">
                      Registrera ett nytt företag
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Delete Confirmation Modal */}
      <Modal show={deleteModal.show} onHide={() => setDeleteModal({ show: false, companyId: null, companyName: '' })}>
        <Modal.Header closeButton>
          <Modal.Title>Bekräfta borttagning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Är du säker på att du vill ta bort företaget <strong>{deleteModal.companyName}</strong>?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setDeleteModal({ show: false, companyId: null, companyName: '' })}>
            Avbryt
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Ta bort
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default SelectCompany;











/*import { Link } from "react-router-dom";

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

import { ListGroup } from "react-bootstrap";

import {
  selectCompany,
} from '../../store/actions/AuthActions';

import { getApiUrl } from "../../utils/ApiUtils";



function SelectCompany(props) {

  const uid = props.googleId;
  console.log("Google ID: ", uid);

  //When page is loaded make an api call to fetch the companies the user is associated with
  //Display the companies in a list
  //When a company is clicked, store the company id in the redux store and redirect to the dashboard
  //If the user is associated with only one company, store the company id in the redux store and redirect to the dashboard

  const [companies, setCompanies] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();

  const getCompaniesUrl = getApiUrl(`auth/user/companies?googleId=${uid}`);
  //const googleId = useSelector(state => state.googleId); // Replace with your actual selector

  useEffect(() => {
    axios.get(getCompaniesUrl, { withCredentials: true })
      .then(response => {
        console.log(response.data);
        setCompanies(response.data);
      })
      .catch(error => {
        console.error('Error fetching companies:', error);
      });
  }, [dispatch, history]);

  const handleCompanyClick = (companyId, companyName) => {
    console.log("Company ID: ", companyId);
    dispatch(selectCompany(companyId, companyName, history));
  }

  const fetchCompanies = async () => {
    try {
      const response = await axios.get(getCompaniesUrl, { withCredentials: true });
      console.log(response.data);
      setCompanies(response.data);
    } catch (error) {
      console.error('Error fetching companies:', error);
    }
  };

  const handleTrashClick = (companyId) => {
    console.log("Delete company with ID: ", companyId);
    const deleteCompanyUrl = getApiUrl(`auth/company/${companyId}`);
    axios.delete(deleteCompanyUrl, { withCredentials: true })
      .then(response => {
        console.log("Company deleted:", response);
        fetchCompanies();
      })
      .catch(error => {
        console.error('Error deleting company:', error);
      });
  }

  const handleCreateCompany = () => {
    history.push('/create-company');
  }

  return (
    <div className="authincation h-100 p-meddle">
      <div className="container h-100">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="text-center mb-3">
                      <Link to="/login">
                        LedgerLoop
                      </Link>
                    </div>
                    {companies.length === 0 ? (
                      <h4 className="text-center mb-4 ">Du har ännu inga företag kopplade till dig. Registrera ett nytt.</h4>
                    ) : (
                      <h4 className="text-center mb-4 ">Välj det företag som du vill administrera.</h4>
                    )}

                    {props.errorMessage && (
                      <div className=''>
                        {props.errorMessage}
                      </div>
                    )}
                    {props.successMessage && (
                      <div className=''>
                        {props.successMessage}
                      </div>
                    )}
                    <div className="new-account mt-3">
                      {companies.length === 0 ? (
                        <div>Registrera ett företag eller ange koden för det företag du fått inbjudan till.</div>
                      ) : (
                        <div className="basic-list-group mb-5">
                          <ListGroup>
                            {companies.map(company => (
                              <ListGroup.Item
                                key={company.company_id}
                                action
                                className=""
                                onClick={() => handleCompanyClick(company.company_id, company.company_name)}
                              >
                                <div className="d-flex w-100 justify-content-between">
                                  <h5 className="mt-2 mb-2 text-green" style={{ flexGrow: 1 }}>
                                    {company.company_name}
                                  </h5>
                                  <h5 className="mt-2 mb-2 text-green">559213-9090</h5>
                                  <h5 className="mt-2 mb-2 text-green">
                                    <Link
                                      type="button"
                                      className="btn btn-link"
                                      style={{ marginLeft: '20px' }}
                                      onClick={(e) => {
                                        e.stopPropagation(); // Prevent ListGroup.Item onClick from being triggered
                                        handleTrashClick(company.company_id); // Replace with your actual function
                                      }}
                                      aria-label="Delete company"
                                    >
                                      <i className="fa fa-trash" aria-hidden="true"></i>
                                    </Link>
                                  </h5>
                                </div>
                              </ListGroup.Item>
                            ))}
                          </ListGroup>
                        </div>
                      )}
                      <p className="mb-4 text-center">eller</p>
                      <button onClick={handleCreateCompany} className="btn btn-primary btn-block">
                        Registrera ett nytt företag
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectCompany;
*/