import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Accordion, Card } from "react-bootstrap";
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import axios from '../../../../services/AxiosInstance';
import EmployeePersonalDetails from './EmployeeDetailsComponent.js';

import { getApiUrl } from '../../../../utils/ApiUtils';

const EditEmployee = () => {

  const { id } = useParams();

  const [employee, setEmployee] = useState({
    employeeDetails: {
      firstname: '',
      lastname: '',
      ssn: '',
      jobtitle: '',
      clearingnumber: '',
      bankaccountnumber: '',
      phonenumber: '',
      email: '',
      address: '',
      postnummer: '',
      ort: '',
      active: false,
    },
    contractDetails: {
      startdate: '',
      employmenttype: '',
      salary: '',
      salaryperiod: '',
      workweek: '',
      employmentextent: '',
      accordingtocollectiveagreement: true,
      taxtable: '',
      taxcolumn: '',
      workplace: '',
      otherterms: '',
    },
  });

  //  const { name, email, phone, address, city, state, zip, country, department, designation, role, status, manager } = employee;

  const onChange = e => setEmployee({ ...employee, [e.target.name]: e.target.value });

  const onSubmit = async e => {
    e.preventDefault();
    await axios.put(`/api/employee/${id}`, employee, { withCredentials: true });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log("hej");
    console.log(employee);
    console.log("hej");
  };

  const handleInputChange = (e, nestedField = null) => {
    var { name, value } = e.target;

    console.log(name);
    console.log(value);
  };

  const defaultAccordion = [
    {
      title: "Accordion Header One",
      text:
        "Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod.",
      bg: "primary",

    },
    {
      title: "Accordion Header Two",
      text:
        "Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod.",

      bg: "info",

    },
    {
      title: "Accordion Header Three",
      text:
        "Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod.",

      bg: "success",

    },
  ];

  const [contracts, setContracts] = useState([]);

  useEffect(() => {
    loadEmployee();
  }, []);

  const loadEmployee = async () => {
    const getEmployeeApiUrl = getApiUrl(`employee/${id}`);
    const result = await axios.get(getEmployeeApiUrl, { withCredentials: true });
    console.log(result.data);
    setEmployee(result.data);
  }

  const loadContracts = async () => {
    const getEmployeeContractsApiUrl = getApiUrl(`employee/${id}/contracts`);
    const result = await axios.get(getEmployeeContractsApiUrl, { withCredentials: true });
    console.log(result.data);
    setContracts(result.data);
  }

  return (
    <div className="container">

      <div className="row">
        <div className="col-md-6">
          <Link to="/employees" className="btn btn-link">
            <i className="fa fa-arrow-circle-left"></i> Back To Employees
          </Link>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <EmployeePersonalDetails employee={employee} handleInputChange={handleInputChange} />
              </div>
            </div>
          </div>
        </div>
      </form>

      <div className="d-flex mb-3">
        <div className="mb-3 align-items-center me-auto">
          <h4 className="fs-24 font-w800">Kontrakt</h4>
        </div>
      </div>

      <div className="row">
        <Card>
          <Card.Body>
            <Accordion className="accordion accordion-rounded-stylish accordion-bordered" defaultActiveKey="0">
              {defaultAccordion.map((d, i) => (
                <Accordion.Item key={i} eventKey={`${i}`}>
                  <Accordion.Header className="accordion-header accordion-header-primary">
                    {d.title}
                    <span className="accordion-header-indicator "></span>
                    {/* <span className="accordion-header-icon"></span>
                        <span className="accordion-header-text">{d.title}</span>
                        <span className="accordion-header-indicator "></span> */}
                  </Accordion.Header>
                  <Accordion.Collapse eventKey={`${i}`} className="accordion__body">
                    <div className="accordion-body">{d.text}</div>
                  </Accordion.Collapse>
                </Accordion.Item>
              ))}
            </Accordion>
          </Card.Body>
        </Card>
      </div>

      <div className="d-flex mb-3">
        <div className="mb-3 align-items-center me-auto">
          <h4 className="fs-24 font-w800">Lönespecar</h4>
        </div>
      </div>

      <div className="row">
        <Card>
          <Card.Body>
            <Accordion className="accordion accordion-rounded-stylish accordion-bordered" defaultActiveKey="0">
              {defaultAccordion.map((d, i) => (
                <Accordion.Item key={i} eventKey={`${i}`}>
                  <Accordion.Header className="accordion-header accordion-header-primary">
                    {d.title}
                    <span className="accordion-header-indicator "></span>
                    {/* <span className="accordion-header-icon"></span>
                        <span className="accordion-header-text">{d.title}</span>
                        <span className="accordion-header-indicator "></span> */}
                  </Accordion.Header>
                  <Accordion.Collapse eventKey={`${i}`} className="accordion__body">
                    <div className="accordion-body">{d.text}</div>
                  </Accordion.Collapse>
                </Accordion.Item>
              ))}
            </Accordion>
          </Card.Body>
        </Card>
      </div>

    </div>
  )
}

export default EditEmployee;