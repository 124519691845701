export const isAuthenticated = (state) => {
  //console.log('isAuthentidated: ', state);
  return state.auth.isAuthenticated;
};

export const isCompanySelected = (state) => {
  console.log('isCompanySelected: ', state);
  if (state.auth.selectedCompany && state.auth.selectedCompany.companyId) return true;
  return false;
  /*if (state.auth.auth.selectedCompany && state.auth.auth.selectedCompany.companyId) return true;
  return false;*/
}

export const getSelectedCompany = (state) => {
  //console.log('getSelectedCompany: ', state);
  if (state.auth.auth.selectedCompany && state.auth.auth.selectedCompany.companyId)
    return state.auth.auth.selectedCompany;
  else
    return '';
};

export const getAccessToken = (state) => {
  //console.log('getAccessToken: ', state);
  if (state.auth.auth._tokenResponse.idToken)
    return state.auth.auth._tokenResponse.idToken;
  else
    return '';
};

export const getRefreshToken = (state) => {
  //console.log('getRefreshToken: ', state);
  if (state.auth.auth._tokenResponse.refreshToken)
    return state.auth.auth._tokenResponse.refreshToken;
  else
    return '';
}

export const getExpiresIn = (state) => {
  //console.log('getExpiresIn: ', state);
  if (state.auth.auth._tokenResponse.expiresIn)
    return state.auth.auth._tokenResponse.expiresIn;
  else
    return '';
}


export const getUid = (state) => {
  console.log('Inside getUid: ', state);
  if (state.auth.user && state.auth.user.googleUid)
    return state.auth.user.googleUid;
  else
    return '';
};