import swal from "sweetalert";
import {
  loginConfirmedAction,
  logout,
} from '../store/actions/AuthActions';

import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: "AIzaSyC5Gyjy4-2QsicCUu99behsspUgtOXTdzc",
  authDomain: "ledgerloop.firebaseapp.com"
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export function signUp(email, password) {
  return createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // Signed up
      //const user = userCredential.user;
      console.log("signup ok: ", userCredential);
      return userCredential;
      // ...
    })
    .catch((error) => {
      //const errorCode = error.code;
      //const errorMessage = error.message;
      // ..
    });
}

export async function login(email, password) {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    console.log("login ok in signInWithEmailAndPassword: ", userCredential);
    return userCredential;
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    console.log("login error in signInWithEmailAndPassword: ", errorMessage, errorCode);
    throw error;
  }
}



// Old way of login
/*export function signUp(email, password) {
    //axios call
    const postData = {
        email,
        password,
        returnSecureToken: true,
    };
    return axios.post(
        `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
        postData,
    );
}

export function login(email, password) {
    const postData = {
        email,
        password,
        returnSecureToken: true,
    };
    return axios.post(
        `https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
        postData,
    );
}*/

export function formatError(firebaseError) {
  switch (firebaseError.code) {
    case 'auth/invalid-credential':
      //return 'Email already exists';
      swal("Oops", "Epost eller lösenord är felaktigt", "error");
      break;
    case 'auth/too-many-requests':
      swal("Oops", "För många inloggningsförsök, vänta en stund och försök igen senare", "error");
      break;
    case 'auth/user-not-found':
      swal("Oops", "Hittar ingen användare", "error", { button: "Try Again!", });
      break;
    case 'INVALID_PASSWORD':
      //return 'Invalid Password';
      swal("Oops", "Invalid Password", "error", { button: "Try Again!", });
      break;
    case 'USER_DISABLED':
      return 'User Disabled';

    default:
      return '';
  }
}

export function saveTokenInLocalStorage(tokenDetails) {

  console.log("saveTokenInLocalStorage: ", tokenDetails);

  tokenDetails.expireDate = new Date(
    new Date().getTime() + tokenDetails._tokenResponse.expiresIn * 1000,
  );
  localStorage.setItem('userDetails', JSON.stringify(tokenDetails));
}

export function runLogoutTimer(dispatch, timer, history) {
  setTimeout(() => {
    dispatch(logout(history));
  }, timer);
}

export function checkAutoLogin(dispatch, history) {
  const tokenDetailsString = localStorage.getItem('userDetails');
  let tokenDetails = '';
  if (!tokenDetailsString) {
    dispatch(logout(history));
    return;
  }

  tokenDetails = JSON.parse(tokenDetailsString);
  let expireDate = new Date(tokenDetails.expireDate);
  let todaysDate = new Date();

  if (todaysDate > expireDate) {
    dispatch(logout(history));
    return;
  }

  const selectedCompanyString = localStorage.getItem('selectedCompany');
  let selectedCompany = {
    companyName: '',
    companyId: '',
    companyRegNo: '',
  };
  if (selectedCompanyString) {
    selectedCompany = JSON.parse(selectedCompanyString);
  }

  dispatch(loginConfirmedAction(tokenDetails, selectedCompany));

  const timer = expireDate.getTime() - todaysDate.getTime();
  runLogoutTimer(dispatch, timer, history);
}
